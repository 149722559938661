<template>
    <transition
    enter-active-class="transition duration-300 ease delay-100"
    enter-from-class="transform translate-x-[-10px] lg:translate-x-0 lg:translate-y-[-10px] opacity-0"
    enter-to-class="transform translate-x-0 lg:translate-x-0 lg:translate-y-0 opacity-100"
    leave-active-class="transition duration-200 ease"
    leave-from-class="transform translate-x-0 lg:translate-x-0 lg:translate-y-0 opacity-100"
    leave-to-class="transform translate-x-[-10px] lg:translate-x-0 lg:translate-y-[-10px] opacity-0">
        <div class="bg-white absolute top-[190px] w-full md:w-fit lg:top-[130px] xl:top-[140px] left-0 lg:left-12 z-40 lg:rounded-b-xl shadow md:flex p-6 transition ease-linear duration-200"
        v-if="show">
            <div class="max-h-[calc(100vh-238px)] w-full md:w-fit md:max-h-[500px] overflow-y-auto space-y-2 scrollbar-neutral md:pr-4">
                <a 
                @mouseenter="showSubCategories = false"
                href="/shop"
                class="relative p-2 md:w-fit md:min-w-[265px] block text-sm font-normal transition cursor-pointer rounded-lg relative w-full text-left hover:bg-fuchsia-100">
                    <p>All</p>
                </a>
                <div
                v-for="item in categories"
                :key="item"
                class="relative p-2 md:w-fit md:min-w-[265px] block text-sm font-normal transition cursor-pointer rounded-lg relative w-full text-left hover:bg-fuchsia-100"
                :class="item.code == selectedSubCategory ? 'bg-fuchsia-100' : ''"
                @mouseenter="filterSubCategory(item.code)"
                >
                    {{ item.name }}
                    <ChevronRightIcon class="w-5 h-5 absolute top-1/2 -translate-y-1/2 right-3"/>
                </div>
            </div>

            <transition
            enter-active-class="transition"
            enter-from-class="transform opacity-0"
            enter-to-class="transform opacity-100"
            leave-active-class="transition"
            leave-from-class="transform opacity-100"
            leave-to-class="transform opacity-0">
                <div 
                :class="showSubCategories ? 'translate-x-0' : 'translate-x-full md:translate-x-0'"
                class="absolute left-0 transition md:relative top-0 bg-white px-6 md:p-0 min-h-[calc(100vh-48px)] md:min-h-full w-full md:w-fit">
                    <div class="flex items-center mb-10 md:hidden" @click="showSubCategories = false">
                        <ChevronLeftIcon class="w-4 h-4"/>
                        <p class="text-sm font-normal ml-4">Back</p>
                    </div>
                    <template
                    v-for="category in categories"
                    :key="`category-${category.code}`"
                    >
                        <div 
                        class="bg-white top-0 z-50 md:pl-6 w-full md:w-fit" 
                        v-if="category.code == selectedSubCategory">
                            <div class="max-h-[calc(100vh-300px)] md:max-h-[500px] w-full md:w-fit overflow-y-auto scrollbar-neutral space-y-2 pr-4" >
                                <a 
                                :href="`/shop?category=${category.code}&subCategory=all`"
                                class="w-full md:min-w-[265px] block p-2 text-sm font-normal transition cursor-pointer rounded-lg relative capitalize hover:bg-fuchsia-100">
                                    All
                                </a>
                                <a
                                v-for="subcategory in category.subcategories"
                                :key="`subcategory-${subcategory.code}`"
                                :href="`/shop?category=${category.code}&subCategory=${subcategory.code}`"
                                class="w-full md:min-w-[265px] block p-2 text-sm font-normal transition cursor-pointer rounded-lg relative capitalize hover:bg-fuchsia-100">
                                    {{ subcategory.name }}
                                </a>
                            </div>
                        </div>
                    </template>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { 
    ChevronRightIcon,
    ChevronLeftIcon
} from "@heroicons/vue/solid";
import { ref } from 'vue';
export default {
    components: {
        ChevronRightIcon,
        ChevronLeftIcon
    },
    props: {
        show: {
            type: Boolean
        },
        categories: {
            type: Object,
        },
    },
    setup() {
        const showSubCategories = ref(false);
        const selectedSubCategory = ref('');

        function filterSubCategory(id) {
            showSubCategories.value = true;
            selectedSubCategory.value = id;
        }

        return {
            showSubCategories,
            selectedSubCategory,
            filterSubCategory
        };
    },
};
</script>
