<template>
    <ion-page class="w-[50%] mx-auto">
        <ion-buttons slot="secondary">
          <ion-button @click="router.push('delete-account')">
            <ArrowLeftIcon
              class="w-7 h-7 dark"
            />
          </ion-button>
        </ion-buttons>
        <ion-content :fullscreen="true" class="ion-padding relative">

            <ion-grid class="ion-no-padding">
                <ion-row class="mb-5">
                    <ion-col size="12">
                        <h3 class="font-bold text--black mb-4">Confirm Deletion</h3>
                        <p class="text-sm text-gray-500">Input your password to proceed.</p>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <div class="mb-5">
                            <password-input
                                v-model="form.password"
                                id="password"
                                label="Password"
                                placeholder="your password"
                            />
                            <!-- <ion-label class="frm-forms--label" position="stacked">Password</ion-label>
                            <ion-item lines="none" class="frm-forms frm-inputCon mb-2">
                                <ion-input class="frm-input" :type="[showPassword ? 'text' : 'password']" placeholder="your password"></ion-input>
                                <ion-icon v-show="!showPassword" slot="end" src="/assets/icons/ic-pass-hide.svg" class="ml-4" name="show" @click="showPassword = !showPassword"></ion-icon>
                                <ion-icon v-show="showPassword" slot="end" src="/assets/icons/ic-pass-show.svg" class="ml-4" name="show" @click="showPassword = !showPassword"></ion-icon>
                            </ion-item> -->
                        </div>
                        <div class="mb-5">
                            <password-input
                                v-model="form.confirmPassword"
                                id="confirm-password"
                                label="Confrim Password"
                                placeholder="your password"
                            />
                            <!-- <ion-label class="frm-forms--label" position="stacked">Confirm Password</ion-label>
                            <ion-item lines="none" class="frm-forms frm-inputCon mb-2">
                                <ion-input class="frm-input" :type="[showPassword2 ? 'text' : 'password']" placeholder="your password"></ion-input>
                                <ion-icon v-show="!showPassword2" slot="end" src="/assets/icons/ic-pass-hide.svg" class="ml-4" name="show" @click="showPassword2 = !showPassword2"></ion-icon>
                                <ion-icon v-show="showPassword2" slot="end" src="/assets/icons/ic-pass-show.svg" class="ml-4" name="show" @click="showPassword2 = !showPassword2"></ion-icon>
                            </ion-item> -->
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>

        </ion-content>

        <ion-footer class="ion-no-border p-4">
            <button-component @click="submit" class="w-full mt-4" size="default" color="primary">
                <template #content>
                    <template v-if="!loading">
                        Delete Account
                    </template>
                    <template v-else>
                        <ion-spinner></ion-spinner>
                    </template>
                </template>
            </button-component>            
            <!-- <ion-toolbar>
                <ion-button class="btn-danger" size="large" expand="block"
                href="/login">
                    delete account
                </ion-button>
            </ion-toolbar> -->
        </ion-footer>

    </ion-page>

</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    // IonToolbar,
    // IonButton,
    // IonItem,
    // IonLabel,
    // IonInput,
    // IonIcon
} from '@ionic/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { defineComponent, ref } from 'vue';
import { useUserStore } from '@/store/user';
import { useApiStore } from "@/store/api";
import useAuth from "@/composables/useAuth";
import useResponseHandler from '@/composables/useResponseHandler';
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'delete-account',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonFooter,
        // IonToolbar,
        // IonButton,
        // IonItem,
        // IonLabel,
        // IonInput,
        // IonIcon,
        PasswordInput,
        ButtonComponent,
        ArrowLeftIcon
    },
    data() {
        return {
            showPassword: false,
            showPassword2: false,
        };
    },

    setup() {
        const store = useUserStore();
        const apiStore = useApiStore();
        const { openResponseModal } = useResponseHandler();
        const { logout } = useAuth()
        const reasons = ref(store.userDeleteAccount);
        const loading = ref(false);
        const form = ref({
            password: null,
            confirmPassword: null,
        })
        const router = useRouter();

        const submit = () => {
            loading.value = true;
            const data = {
                password: form.value.password,
                confirmPassword: form.value.confirmPassword,
                reason: reasons.value.reason,
                providedReason: reasons.value.providedReason
            }

            if(form.value.password == null && form.value.confirmPassword == null) {
                loading.value = false;
                openResponseModal({ title: "Warning", description: "You must fill all fields", buttonText: "Okay" });
            } 
            
            else if(form.value.password === form.value.confirmPassword) {

                window.axios
                .post(apiStore.route("remove-account"), data)
                .then((result) => {
                    console.log(result)
                    logout();
                }).catch((error) => {
                    loading.value = false;
                    openResponseModal({ title: "Warning", description: error.response.data.errors.message, buttonText: "Okay" });
                })
            } else {
                loading.value = false;
                openResponseModal({ title: "Warning", description: "Your password and confirm password does not match", buttonText: "Okay" });
            }
        }


        return {
            form,
            router,
            submit,
            loading
        }
    }
})
</script>