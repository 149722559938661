import { defineStore } from 'pinia'

export const useChatStore = defineStore({
    id: 'chat',
    state: () => {
        return {
            chat: {},
            rider_name: null,
        }
    },
    getters: {},
    actions: {}
})