<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="
                        fixed
                        inset-0
                        bg-gray-500 bg-opacity-75
                        transition-opacity
                    "
                />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div
                    class="
                        flex
                        items-center
                        justify-center
                        min-h-full
                        p-4
                        text-center
                    "
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="
                                relative
                                bg-white
                                rounded-lg
                                p-4
                                lg:px-8 lg:py-11
                                text-left
                                overflow-hidden
                                shadow-xl
                                transform
                                transition-all
                                w-full
                                lg:w-fit
                            "
                        >
                            <div class="w-full lg:w-[896px]">
                                <div class="text-center">
                                    <h1
                                        class="
                                            text-primary-500
                                            font-normal
                                            text-[32px]
                                            mb-2
                                        "
                                    >
                                        Your Location
                                    </h1>
                                    <p
                                        class="
                                            text-sm text-neutral-600
                                            mb-[22px]
                                        "
                                    >
                                        Giving us your location allows us to
                                        tailor-fit our offered products to your
                                        needs.
                                    </p>
                                    <div
                                        class="
                                            flex
                                            location-search
                                            w-full
                                            lg:w-fit
                                            m-auto
                                            mb-6
                                            items-center
                                            space-x-2
                                            lg:space-x-4
                                        "
                                    >
                                        <div
                                            class="
                                                location-input
                                                relative
                                                w-full
                                                lg:w-[343px]
                                            "
                                        >     
                                            <text-input
                                                v-model="locationState.search"
                                                placeholder="Where are you?"
                                                id="location"
                                                type="text"
                                                customClass="absolute"
                                                customContainerClass="opacity-0 border-none" 
                                                required
                                                @click="isDraggable(false)"
                                                @keydown="changeFocusToMaskSearch"
                                            />                              
                                            <text-input
                                                v-model="locationState.maskSearch"
                                                placeholder="Where are you?"
                                                id="maskLocation"
                                                type="text"
                                                customClass="h-12 pr-10"
                                                required
                                                @keydown="maskSearch"
                                                :disabled="locationState.loading"
                                            />
                                            
                                            <LocationMarkerIcon
                                                @click="getCurrentLocation"
                                                class="
                                                    cursor-pointer
                                                    text-neutral-500
                                                    w-5
                                                    h-5
                                                    absolute
                                                    right-3
                                                    top-1/2
                                                    -translate-y-1/2
                                                "
                                            />
                                            <div>
                                                <p class="text-sm text-red-500 absolute">{{ noLocation }}</p>
                                            </div>
                                            <!-- <div
                                            @click="closeResult()"
                                                v-if="
                                                    !locationState.hasSelectedAddress &&
                                                    locationState.search &&
                                                    locationState
                                                        .addressCandidates
                                                        .length > 0
                                                "
                                                class="
                                                    map-search-result
                                                    absolute
                                                    z-[999]
                                                    bg-white
                                                "
                                            >
                                                <div
                                                    class="
                                                        max-h-[196px]
                                                        overflow-auto
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            px-2
                                                            lg:px-4
                                                            items-start
                                                            space-x-2
                                                            py-1
                                                            last:mb-0
                                                            border-b
                                                            cursor-pointer
                                                            hover:bg-gray-50
                                                        "
                                                        v-for="(
                                                            addressCandidate,
                                                            index
                                                        ) in locationState.addressCandidates"
                                                        :key="index"
                                                    >
                                                        <LocationMarkerIcon
                                                            class="
                                                                text-neutral-500
                                                                w-6
                                                                h-6
                                                            "
                                                        />
                                                        <p
                                                            @click="selectAddress(addressCandidate);"
                                                            class="text-sm"
                                                        >
                                                            <b>{{ addressCandidate.name }}</b> {{ addressCandidate.formattedAddress }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div
                                            @click="closeResult()"
                                                v-if="showResult == false"
                                                class="
                                                    map-search-result
                                                    absolute
                                                    z-[999]
                                                    bg-white
                                                "
                                            >
                                                <div
                                                    class="
                                                        max-h-[196px]
                                                        overflow-auto
                                                    "
                                                >
                                                    <div
                                                        v-if="addressSelected"
                                                        class="
                                                            flex
                                                            px-2
                                                            lg:px-4
                                                            items-start
                                                            space-x-2
                                                            py-1
                                                            last:mb-0
                                                            border-b
                                                            cursor-pointer
                                                            hover:bg-gray-50
                                                        "
                                                    >
                                                        <LocationMarkerIcon
                                                            class="
                                                                text-neutral-500
                                                                w-6
                                                                h-6
                                                            "
                                                        />
                                                        <p class="text-sm">
                                                            {{ addressSelected == null ? locationState.address_label : addressSelected.address_label }}
                                                            <!-- {{ addressSelected.latitude }} {{ addressSelected.longitude }} -->
                                                            <!-- {{ addressSelected == null ? locationState.customerLat : addressSelected.latitude }}  -->
                                                            <!-- {{ addressSelected == null ? locationState.customerLng :  addressSelected.longitude }} -->
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ButtonComponent
                                            size="large"
                                            fill="outline"
                                            class="h-12 lg:h-[53px]"
                                            @click="confirmAddress()"
                                            :disabled="!locationState.hasSelectedAddress"
                                        >
                                            <template #content> Go </template>
                                        </ButtonComponent>
                                    </div>
                                </div>
                                <div
                                    class="
                                        rounded-[10px]
                                        relative
                                        h-[400px]
                                        lg:h-[326px]
                                        overflow-hidden
                                    "
                                >
                                    <!-- MAP HERE -->
                                    <div
                                        id="locationMap"
                                        @click="isDraggable(true)"
                                        class="w-full h-full"
                                    ></div>
                                    <!-- MAP HERE -->
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import { ref, watch, onMounted } from "vue";
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { LocationMarkerIcon } from "@heroicons/vue/outline";
import TextInput from "@/components/inputs/TextInput.vue";
import useLocation from "@/composables/useLocation";
import { useAddressStore } from "@/store/address";
import useLocalStorage from "@/composables/useLocalStorage.js";
import debounce from "lodash/debounce";
// import { useUserStore } from '@/store/user';
// import useCart from '@/composables/useCart';
// import useResponseHandler from '@/composables/useResponseHandler';

export default {
    name: "LocationModal.vue",
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        TextInput,
        LocationMarkerIcon,
        ButtonComponent,
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { localStorage } = useLocalStorage();
        const addressStore = useAddressStore();
        // const user = useUserStore();
        // const { updateCart } = useCart();
        const {
            locationState,
            setCustomerLocation,
            checkLocationPermissions,
            isLocationEnabled,
            googleSearch,
            setSelectedAddress,
            setDefaultCoordinates,
            setNearestBranch,
            initMap,
        } = useLocation();

        const open = ref(false);
        const closeThis = () => (open.value = false);

        const showLargeMap = ref(false);
        const noLocation = ref(null);

        const settedAddress = ref(null);

        let timeOut;

        const form = ref({
            id: null,
            name: null,
            details: null,
            address: null,
            latitude: null,
            longitude: null,
            is_default: false,
        });

        const showResult = ref(true);

        const addressSelected = ref({});
        // const { openResponseModal } = useResponseHandler();

        const confirmAddress = () => {
            if(locationState.search == null) {
                open.value = true;
                noLocation.value = "Enter your location first.";
            } else {
                // Set state values
                setStateCoords();
                setStateData();

                // Set address state values to local storage
                setLocalStorageCoordsAndData();
                setBranch();
                closeThis();
                // getNearestBranch();
                // Close LocationModal
                // closeThis();
            }
            // window.location.reload();
            // window.location.href = '/home';
        };

        const setStateCoords = () => {
            addressStore.coords.longitude = locationState.customerLng;
            addressStore.coords.latitude = locationState.customerLat;
        };

        const setStateData = () => {
            addressStore.data.longitude = locationState.customerLng;
            addressStore.data.latitude = locationState.customerLat;
            addressStore.data.label = "Home";
            addressStore.data.address_label = locationState.search;
            // addressStore.data.address_label = locationState.completeAddress || locationState.search;
            // Set default = true for guest users
            addressStore.data.is_default = true;
        };

        const setBranch = async () => {
            const branches = await localStorage.value.get("branches");
            // let branchDistances = [];
            let getBranches = [];
            // branches.map((branch) => {                
            getBranches = setNearestBranch(addressStore.coords.latitude, addressStore.data.longitude, branches)
            console.log(getBranches) 
            //    setNearestBranch(addressStore.coords.latitude, addressStore.data.longitude, branch).then(({ data }) => {
            //         console.log(data)
            //         if(data.code == "Ok") {
            //             console.log("inside if")
            //             console.log(data.routes[0])
            //             branchDistances.push(data.routes[0]);


            //             console.log("testing value")
            //             console.log(branchDistances)

            //             branchDistances.map((distance) => {
            //                 console.log(distance)
            //                 distance.branch = branch.code;

            //                 return distance;
            //             });

            //             // const min = Math.min(testing)

            //             // testing.map((test) => {
            //             //     const numbers = [1,2,3,4];
            //             //     const testnumber = Math.min(numbers)
            //             //     console.log("zzzz")
            //             //     console.log(testnumber)

            //             //     const min = Math.min(test.distance)
            //             //     console.log("wew")
            //             //     console.log(min)
            //             // });

            //             // console.log(min)
            //         }
            //     }).catch((error) => console.log(error));

            // });

        }

        // const getNearestBranch = () => {
        //     let branchdistances = [];

        //     console.log("getNearestBranches")
        //     console.log(user.userBranchDistances)

        //     if(user.userBranchDistances != null) {
        //         user.userBranchDistances.map((test) => {
        //             console.log("testing")
        //             console.log(test)
        //             branchdistances.push(test.routes.distance);
        //         });

        //         console.log("getNearestBranch")
        //         console.log(branchdistances)
        //         const min = Math.min(...branchdistances);
        //         const near = user.userBranchDistances.find(x => x.routes.distance == min);

        //         user.setUserNearestBranch(near);
        //         localStorage.value.set("nearestBranch", near);

        //         console.log("after near")
        //         console.log(near)

        //         console.log("before updating the cart")

        //         // updateCart({ branch_id: near.branch })

        //         updateCart({
        //             branch_id: near.branch, 
        //         }).then(message => {
        //             console.log("update cart")
        //             console.log(message)
        //             // if(typeof message === 'string') {
        //                 console.log("update cart if")
        //                 console.log(message)
        //                 closeThis();
        //             // }
        //         });
        //     }


        // }

        const setLocalStorageCoordsAndData = () => {
            localStorage.value.set("addressCoords", addressStore.coords);
            localStorage.value.set("addressData", addressStore.data);
        };

        const setLocation = () => {
            
            if (settedAddress.value) {
                locationState.loading = false;
                Promise.allSettled([getSetAddress()]).then(() => {
                    locationState.search = settedAddress.value.address_label;
                    locationState.maskSearch = locationState.search;
                    locationState.hasSelectedAddress = true;
                    setSelectedAddress(
                        settedAddress.value.latitude,
                        settedAddress.value.longitude,
                        settedAddress.value.address_label,
                    );
                    initMap("locationMap");
                    googleSearch();
                });                
                return;
            }
            if (locationState.hasSelectedAddress) {
                locationState.loading = false;
                initMap("locationMap");
                googleSearch();
                return;
            }            

            if (isLocationEnabled.value) {
                locationState.loading = false;
                // If location service is enabled, set timeout and loop fetching/setting of rider coordinates per the interval set
                return setCustomerLocation().then(() => {
                    timeOut = setTimeout(setLocation(), 1000);
                    initMap("locationMap");
                });
            }
            else if(locationState.addressCandidates.length > 0){
                locationState.loading = false;
                setSelectedAddress(
                    locationState.addressCandidates[0]["lat"],
                    locationState.addressCandidates[0]["lng"],
                    locationState.addressCandidates[0]["formattedAddress"]
                );
                initMap("locationMap");
                googleSearch();
            }
            // else if(settedAddress.value !== null){
            //     console.log("from adress localstorage")
            //     locationState.search = settedAddress.value.address_label;
            //     if (locationState.search) {
            //         locationState.hasSelectedAddress = true;
            //     }
            //     setSelectedAddress(
            //         settedAddress.value.latitude,
            //         settedAddress.value.longitude,
            //         settedAddress.value.address_label,
            //     );
            //     initMap("locationMap");
            //     googleSearch();
            // }
            else {
                setDefaultCoordinates();
                initMap("locationMap");
                googleSearch();
                return;
            }            
        };

        const closeResult = async () => {
            showResult.value  = showResult.value == false ? true : false;

            addressSelected.value = await localStorage.value.get("addressData");
        }

        const selectAddress = (addressCandidate) => {
            locationState.hasSelectedAddress = true;
            setSelectedAddress(
                addressCandidate.lat,
                addressCandidate.lng,
                addressCandidate.formattedAddress
            );

            closeResult();
        }

        const getSetAddress = async () => {
			const address = await localStorage.value.get("addressData");
        
			if (address) {
				settedAddress.value = address;
			}
		};

        const isDraggable = (isDraggable = false) => {
            locationState.hasSelectedAddress = false
            locationState.isDraggable = isDraggable;
        }

        const maskSearch = () => {
            locationState.isDraggable = false;
            locationState.hasSelectedAddress = false;
        }

        const changeFocusToMaskSearch = () => {
            document.getElementById('maskLocation').focus()
        }

        const getCurrentLocation = () => {
            locationState.loading = true;
            checkLocationPermissions()
        }

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool),
            {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) {
                    emit("close");
                    clearTimeout(timeOut);
                } else {
                    setTimeout(() => {
                        // if(isLocationEnabled.value){
                            locationState.loading = true;
                            checkLocationPermissions(!settedAddress.value || false).then(() => setLocation());
                        // }
                    }, 500);
                }
            }
        );

        watch(
            () => locationState.search,
            debounce((currentValue) => {
                if (currentValue) {
                    // if (!disableDefaultLocationSearch.value) {
                        // googleSearch(defaultLocationSearch.value);
                    // }
                    

                    // if (locationState.addressCandidates.length > 0) {
                    //     showResult.value = true;
                    //     form.value.address =
                    //         locationState.addressCandidates[0][
                    //             "formattedAddress"
                    //         ];
                    // } else {
                    //     form.value.address = "N/A";
                    // }
                    if (locationState.addressCandidate?.length == 0) {
                        form.value.address = "N/A";
                    }
                } else {
                    locationState.addressCandidates = [];
                }
            }, 100)        
        );

        watch(
            () => locationState.maskSearch,
            debounce((currentValue) => {
                if (!locationState.isDraggable) {
                    const input = document.getElementById('location');
                    if (input) {
                        input.value = '';
                        input.value = currentValue;
                        if (currentValue) {
                            input.focus();
                        }
                    }                    
                }

            }, 700)
        );

        onMounted(() => {
            getSetAddress();
		});

        return {
            open,
            closeThis,
            setSelectedAddress,
            confirmAddress,
            showLargeMap,
            locationState,
            closeResult,
            showResult,
            addressSelected,
            noLocation,
            selectAddress,
            isDraggable,
            maskSearch,
            changeFocusToMaskSearch,
            getCurrentLocation
        };
    },
};
</script>
<style>
.pac-container {
    margin-top: 2.2% !important;
}
</style>
