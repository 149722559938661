<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="bg--light">
            <div class="p-4 mb-3 bg-white">
                <div class="flex items-center justify-between">
                    <img src="/assets/images/maya.svg" alt="" width="82">
                    <div class="flex items-center space-x-2">
                        <img src="/assets/icon/cart.svg" alt="">
                        <p class="font-bold">168.00</p>
                    </div>
                </div> 
            </div>
            <div class="p-4 bg-white">
                <div class="flex justify-between mb-3.5">
                    <p class="text-sm font-bold m-0">Debit/Credit Card</p>
                    <div class="flex space-x-2 items-center">
                        <img src="/assets/images/mastercard.svg" alt="">
                        <img src="/assets/images/visa.svg" alt="">
                        <img src="/assets/images/jcb.svg" alt="">
                    </div>
                </div>
                <div class="mb-3.5">
                    <text-input
                        v-model="form.firstName"
                        type="text"
                        label="FIRST NAME"
                        placeholder="FIRST NAME"
                    />
                </div>
                <div class="mb-3.5">
                    <text-input
                        v-model="form.lastName"
                        type="text"
                        label="LAST NAME"
                        placeholder="LAST NAME"
                    />
                </div>
                <div class="mb-3.5">
                    <text-input
                        v-model="form.cardNumber"
                        type="text"
                        label="Card Number"
                        placeholder="Card Number"
                    />
                </div>
                <div class="flex space-x-3.5">
                    <div class="flex items-start gap-2">
                        <text-input
                            v-model="form.expMonth"
                            type="text"
                            label="EXPIRY MONTH"
                            placeholder="MM"
                        />

                        <text-input
                            v-model="form.expYear"
                            type="text"
                            label="EXPIRY YEAR"
                            placeholder="YYYY"
                        />

                        <div class="relative">
                            <text-input
                                v-model="form.cvv"
                                type="text"
                                label="CVV"
                                placeholder="CVV"
                            />
                            <div
                                class="
                                    absolute
                                    bottom-1.5
                                    right-2
                                    flex
                                    items-center
                                    pointer-events-none
                                "
                            >
                                <ion-icon src="/assets/icon/ic-info.svg" class="w-8 h-8"></ion-icon>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="w-1/2">
                        <text-input
                            v-model="form.expMonth"
                            type="text"
                            label="EXPIRY MONTH"
                            placeholder="EXPIRY MONTH"
                        />
                    </div>
                    <div class="w-1/2">
                        <div class="relative">
                            <text-input
                                type="text"
                                label="CVV"
                                placeholder="CVV"
                            />
                            <div
                                class="
                                    absolute
                                    bottom-1.5
                                    right-2
                                    flex
                                    items-center
                                    pointer-events-none
                                "
                            >
                                <ion-icon src="/assets/icon/ic-info.svg" class="w-8 h-8"></ion-icon>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="w-full mt-5 mb-5 py-5">
                    <button-component size="default" color="primary" expand="block" @click="submit">
                        <template #content>
                            Complete Order
                        </template>
                    </button-component>
                </div>
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>

        <!-- <ion-footer>
            <ion-toolbar class="bg--transparent">
                
            </ion-toolbar>
        </ion-footer> -->

        <!-- <Loader 
        v-if="loader"/> -->
    </ion-page>
</template>
<script type="text/javascript">
import {
    IonPage,
    IonContent,
    // IonFooter,
    // IonToolbar,
    IonIcon,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TextInput from '@/components/inputs/TextInput.vue';
// import Loader from "@/components/Loader.vue";
import { useUserStore } from '@/store/user';
import { useApiStore } from "@/store/api";
// import { Browser } from "@capacitor/browser";
import useResponseHandler from '@/composables/useResponseHandler';
import useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
    name: 'MyOrders',
    components: {
        IonPage,
        IonContent,
        // IonFooter,
        // IonToolbar,
        IonIcon,
        ButtonComponent,
        TopNavigation,
        BottomNavigation,
        TextInput,
        // Loader
    },
    setup() {

        const router = useRouter();
        const user = useUserStore();
        const invoice = ref(user.userInvoice);
        const apiStore = useApiStore();
        const { openResponseModal } = useResponseHandler();
        const { localStorage } = useLocalStorage();

        const loader = ref(false);

        const form = ref({
            firtName: null,
            lastName: null,
            cardNumber: null,
            expMonth: null,
            expYear: null,
            cvv: null
        })

        const openLoader = () => {
            loader.value = true;

            if(loader.value == true) {
                setTimeout(() => {
                    router.push({ path:'/checkout-success' });
                }, 3000);
            }
        }

        // const redirect = async (url) => {
		// 	console.log("open browser");
		// 	await Browser.open({ url: url });
		// 	await Browser.addListener({
		// 		eventName: "browserFinished",
		// 		listenerFunc: router.push('/checkout-success'),
		// 	});
		// };

        const submit = () => {
            loader.value = true;

            const params = {
                id: invoice.value.id,
                invoice: invoice.value,
                card_number: form.value.cardNumber,
                exp_month: form.value.expMonth,
                exp_year: form.value.expYear,
                cvv: form.value.cvv,
                is_mobile: 'Web'
            }

            window.axios
            .post(apiStore.route("payment"), params)
            .then((result) => {
                loader.value = false;
                // redirect(result.data.payment.response.verificationUrl)
                // window.location.href = result.data.payment.response.verificationUrl

                if(result.data.payment.status ==  "success"){
                    localStorage.value.set("setInvoicePayment", result.data.invoice);
                    window.location.href = result.data.payment.response.verificationUrl;
                }
            })
            .catch((error) => {
                loader.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        return {
            router,
            loader,
            form,
            openLoader,
            submit
        }
    },
});
</script>