<template>
    <div class="side-bar min-w-[171px]">
        <h1 class="text-2xl lg:text-[32px] font-semibold text-primary-500 mb-[36px] lg:mb-[56px]">Hi, {{ user?.first_name }}!</h1>
        <div @click="toggleMenu" class="flex gap-3 w-fit items-center lg:hidden">
            <MenuIcon class="w-6 h-6 text-primary-500" />
            <p class="font-normal text-xl">{{ title }}</p>
        </div>
        <div class="lg:relative lg:top-auto lg:left-auto fixed top-[180px] h-full left-0 bg-white lg:p-0 p-6 w-full lg:w-auto z-10 transition duration-200 ease-in-out"
            :class="[ isOpen ? 'translate-x-0' : '-translate-x-[100%] lg:translate-x-0' ]"
        >
            <div class="flex lg:hidden items-center justify-between">
                <p class="text-xl font-normal text-primary-500 m-0">Your Account</p>
                <XIcon @click="toggleMenu" class="w-6 h-6 lg:hidden" />
            </div>
            <hr class="my-6 lg:hidden">
            <div class="flex flex-col space-y-6 mb-[60px] side-bar">
                <router-link :to="item.link" active-link="active" class="m-0 w-fit" v-for="item in sideBarLinks" :key="item">
                    <div class="flex space-x-2">
                        <img class="grayscale" :src="item.icon" alt=""><p class="text-sm font-normal text-[#BDBDBD] m-0">{{ item.label }}</p>
                    </div>
                </router-link>
            </div>
            <div>
                <a class="w-fit cursor-pointer" @click="logout()">
                    <div class="flex space-x-2">
                        <img class="grayscale" src="/assets/icon/logout.svg" alt=""><p class="text-sm font-normal text-[#BDBDBD] m-0">Logout</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { ref } from "vue";
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { XIcon,MenuIcon } from "@heroicons/vue/solid";
import { useUserStore } from '@/store/user';
import useAuth from "@/composables/useAuth";

export default defineComponent({
    name: 'SideBar',
    components: {
        XIcon,
        MenuIcon
    },
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    setup() {
        const { logout } = useAuth();

        const sideBarLinks = [
            { icon: '/assets/icon/peso.svg', label: 'Fun Han Mart Loyalty', link: '/loyalty'},
            { icon: '/assets/icon/Document.svg', label: 'Order History', link: '/order-history'},
            { icon: '/assets/icon/Notification.svg', label: 'Notifications', link: '/notification'},
            { icon: '/assets/icon/Heart.svg', label: 'Favorites', link: '/favorites'},
            { icon: '/assets/icon/Profile.svg', label: 'Account Details', link: '/account-details'},
            { icon: '/assets/icon/Location.svg', label: 'Address Book', link: '/address-book'},
            { icon: '/assets/icon/Unlock.svg', label: 'Change Password', link: '/change-password'},
        ]

        const router = useRouter({
            linkActiveClass: "active"
        });

        const isOpen = ref(false);

        function toggleMenu() {
            isOpen.value = !isOpen.value
        }

        const userStore = useUserStore();

        const user = userStore.userData;

        return {
            router,
            sideBarLinks,
            toggleMenu,
            logout,
            isOpen,
            user
        }
    },
});
</script>
