<template>
    <div class="w-full">
        <label :for="id" class="block text-sm text-gray-900">{{
            label
        }}</label>
        <div
            class="relative rounded-md"
            :class="label ? 'mt-1' : ''"
        >
            <input
                :id="id"
                :type="showPassword ? 'text' : 'password'"
                class="
                    block
                    w-full
                    text-sm
                    h-11
                    disabled:bg-gray-100
                    focus:outline-none
                    focus:ring-0
                    focus:border-gray-900
                    text-gray-800
                    border-gray-300
                    rounded-lg
                    font-normal
                    placeholder:font-normal
                    placeholder:text-[#BBB7B6]
                "
                :placeholder="placeholder"
                :value="modelValue"
                 @input="$emit('update:modelValue', $event.target.value)"
            />
            <div
                @click="showPassword = !showPassword"
                class="
                    cursor-pointer
                    absolute
                    inset-y-0
                    right-0
                    pr-3
                    flex
                    items-center
                "
            >
                <template v-if="!showPassword">
                    <ion-icon src="/assets/icon/inputs/ic-eye-off.svg" class="w-6 h-6"></ion-icon>
                </template>
                <template v-else>
                    <ion-icon src="/assets/icon/inputs/ic-eye.svg" class="w-6 h-6"></ion-icon>
                </template>
            </div>
        </div>
        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    name: "password-input",
    components: {
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        helper: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
    },
    setup() {
        const showPassword = ref(false);

        return {
            showPassword,
        };
    },
};
</script>