<template>
    <profile-layout :title="'Change Password'">
        <text-input name="email" id="email" class="opacity-0 pointer-events-none absolute"/>
        <password-input name="password" id="password" class="opacity-0 pointer-events-none absolute"/>
        <p class="text-2xl font-semibold m-0 text-neutral-600 mb-9">Change Password</p>
        <div class="space-y-6 w-full lg:w-[343px] mb-10">
            
            <password-input v-model="form.newPassword" id="new-password" name="new-password" placeholder="New password" />
            <password-input v-model="form.confirmPassword" id="confirm-password" name="confirm-password" placeholder="Confirm password" />
        </div>
        <button-component size="large" color="primary" fill="outline" @click="submit">
            <template #content>
                <template v-if="!loading">
                    Save Changes
                </template>
                <template v-else>
                    <ion-spinner></ion-spinner>
                </template>
            </template>
        </button-component>
    </profile-layout>
</template>

<script type="text/javascript">
import {
    IonSpinner, onIonViewWillEnter
} from '@ionic/vue';
import { ref } from "@vue/reactivity";
import { defineComponent } from 'vue';
import ProfileLayout from "./ProfileLayout.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import ButtonComponent from '@/components/ButtonComponent.vue';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';
import TextInput from "@/components/inputs/TextInput.vue";
export default defineComponent({
    name: 'ChangePassword',
    components: {
        PasswordInput,
        ButtonComponent,
        ProfileLayout,
        IonSpinner,
        TextInput
    },
    setup() {
        const userStore = useUserStore();
        const form = ref({
            newPassword: null,
            confirmPassword: null,
        })
        const loading = ref(null);


        const router = useRouter();

        const submit = () => {
            userStore.setUserPassword(form.value);

            router.push("/password-confirm-changes");
        }

        onIonViewWillEnter(() => {
            form.value = {
                newPassword: null,
                confirmPassword: null
            };
        });

        return {
            // router,
            form,
            loading,
            submit
        }
    },
});
</script>
