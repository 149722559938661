<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div class="recipe-view max-w-[1440px] m-auto px-4 lg:px-12 xl:px-[100px] lg:pt-20 pb-[120px] lg:pb-[200px]">
                <div class="flex flex-col lg:flex-row justify-between space-y-10 lg:space-y-0 lg:space-x-10 xl:space-x-[76px] items-center pb-10 lg:pb-[95px]">
                    <div class="w-full lg:w-1/2">
                        <img class="w-full h-full" :src="previewImage(item.file_path)">
                        <!-- <product-images
                            :items="[{ path: item.file_path}]"
                        /> -->
                    </div>
                    <div class="w-full lg:w-1/2">
                        <div>
                           <div class="mb-10">
                                <p class="font-normal text-sm text-gray-300 mb-2">PACKAGE</p>
                                <h1 class="text-[28px] lg:text-[40px] text-neutral-600 font-semibold">{{ item.name }}</h1> 
                           </div>
                           <div class="flex space-x-[60px] mb-10 justify-between md:justify-start">
                                <div class="w-fit text-center">
                                    <div class="flex space-x-[10px] mb-2 items-center">
                                        <ClockIcon class="w-5 h-5 text-amber-500" />
                                        <p class="font-normal text-neutral-600">{{ item.prep_time }}</p>
                                    </div>
                                    <p class="text-xs text-neutral-600">Preparation Time</p>
                                </div>
                                <div class="w-fit text-center">
                                    <div class="flex space-x-[10px] mb-2 items-center">
                                        <ClockIcon class="w-5 h-5 text-amber-500" />
                                        <p class="font-normal text-neutral-600">{{ item.cook_time }}</p>
                                    </div>
                                    <p class="text-xs text-neutral-600">Cooking Time</p>
                                </div>
                                 <div class="w-fit text-center">
                                    <div class="flex space-x-[10px] mb-2 items-center">
                                        <UsersIcon class="w-5 h-5 text-amber-500" />
                                        <p class="font-normal text-neutral-600">{{ item.serving_size }}</p>
                                    </div>
                                    <p class="text-xs text-neutral-600">Serving Size</p>
                                </div>
                           </div>
                           <div class="mb-10">
                                <a class="px-[6px] bg-fuchsia-100 text-xs text-fuchsia-700 py-1 rounded-lg mr-2 mb-4 inline-block"
                                v-for="tag in tags"
                                :key="tag"
                                >{{ tag }}</a>
                           </div>
                           <!-- <div class="mb-10">
                                <p class="font-normal text-neutral-600 mb-4">Product Description</p>
                                <p class="text-nuetral-600 text-sm" v-html="item.description"></p>
                           </div> -->
                           <!-- <div class="flex items-center space-x-8">
                                <p class="font-normal text-neutral-600 m-0">Share this recipe:</p>
                                <a class="m-0" href=""><img src="/assets/icon/twitter.svg" alt=""></a>
                                <a class="m-0" href=""><img src="/assets/icon/fb.svg" alt=""></a>
                           </div> -->
                        </div>
                    </div>
                </div>
                <div>
                    <p class="text-2xl font-semibold text-neutral-600 mb-10">Products</p>
                    <div class="mb-5">
                        <check-box :green="true" id="select-all" v-model="selectAll" :checked="selectAll" @change="toggleSelectAll" label="Select All"></check-box>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full mb-10">
                        <div 
                       class="flex items-start w-full relative justify-between bg-[#ffbc001a] px-4 lg:px-6 py-4 rounded-2xl"
                       :class="!checkInventory(product) ? 'out-of-stock' : ''"
                        v-for="(product, index) in item.products" :key="index">
                            <div class="flex items-start">
                                <check-box :green="true" :id="`cartitem-${product.id}`" 
                                    :checked="selected[index]" 
                                    v-model="selected[index]" />
                                <div class="flex">
                                    <!-- {{ product.images }} -->
                                    <template v-if="product.images.length == 0">
                                        <img class="w-16 h-16 rounded object-cover mr-2" :src="product.default_image" alt="">
                                    </template>
                                    <template v-else v-for="(image, i)  in product.images" :key="i">
                                        <img class="w-16 h-16 rounded object-cover mr-2" :src="image.imageUrl" alt="">
                                    </template>
                                    <!-- <img class="w-16 h-16 rounded object-cover" :src="product.file_path || product.default_image" alt=""> -->
                                    <div class="flex flex-col">
                                        <p class="text-xs m-0 product-name">{{ product.name }}</p>
                                        <p class="font-normal m-0">{{ product.price_with_tax }}</p>
                                    </div>
                                </div>
                            </div>

                            <ProductCounter
                                class="quantity-holder"
                                :recipe="true"
                                :max="(product.inventories.length > 0 ? product.inventories[0].quantity : 0 )"
                                v-model="selected_quantity[index]"
                            ></ProductCounter>
                        </div>
                    </div>
                    <div class="w-full mb-10 lg:mb-[107px]">
                        <div class="flex lg:flex-row flex-col button-hldr space-y-6 lg:space-y-0 lg:space-x-4 lg:items-start items-center w-full lg:w-fit">
                            <div class="flex button-hldr space-x-4 w-full">
                                <add-to-bag-button customClass="w-1/2 lg:w-fit" @click="addToCartHandler" :disabled="!hasAvailableStocks"></add-to-bag-button>
                                <!-- <buy-now-button customClass="w-1/2 lg:w-fit"></buy-now-button> -->
                            </div>
                            <button-component size="large" color="white" v-if="isAuthenticated" @click="addOrRemoveFavorite" :disabled="loading">
                                <template #content>
                                    <ion-icon :src="favorited ? '/assets/icon/ic-heart-fill.svg' : '/assets/icon/ic-heart.svg'" class="w-6 h-6">
                                    </ion-icon>
                                    <span class="ml-2 text-sm text-primary-500">Add to Favorites</span>
                                </template>
                            </button-component>
                        </div>
                    </div>
                    <div class="instruction lg:max-w-[800px] m-auto">
                        <p class="lg:text-center text-[32px] font-semibold mb-10">
                            Instructions
                        </p> 
                        <div class="text-sm mb-6 frm-desc" v-html="item.description"></div>
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
    modalController
} from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useApiStore } from "@/store/api";
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import { UsersIcon, ClockIcon } from "@heroicons/vue/solid";
import CheckBox from '@/components/inputs/CheckboxInput.vue';
import ProductCounter from '@/components/inputs/QuantitySelector.vue';

// Buttons
import AddToBagButton from '@/components/buttons/AddToBagButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import useLocalStorage from "@/composables/useLocalStorage.js";
import { useUserStore } from "@/store/user";
import { useCartStore } from '@/store/cart';
import useResponseHandler from '@/composables/useResponseHandler';
import useCart from '@/composables/useCart';
import MiniCart from '../recipe/MiniCart.vue';

export default defineComponent({
    name: 'RecipeView',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        UsersIcon,
        ClockIcon,
        CheckBox,
        ProductCounter,
        AddToBagButton,
        ButtonComponent
    },
    setup() {

        const route = useRoute();
        const { localStorage } = useLocalStorage();
        const cart = useCartStore();
        const { successHandler, openResponseModal, errorHandler } = useResponseHandler();
        const store = useUserStore();
        const apiStore = useApiStore();
        const item = ref({});
        const products = ref([]);
        const tags = ref([]);
        const selectAll = ref(false);
        const selected = ref([]);
        const selected_quantity = ref([]);
        const { addToCart, updateCartItem, cartFetchParams, removeCartItem } = useCart();
        const url = window.location.href;

        const fetch = () => {
            window.axios
                .get(apiStore.route('show-recipe', { id: route.params.id}), { 
                    params: { branch_id: cartFetchParams.value.branch_id }
                })
                .then((result) => {
                    item.value = result.data.recipe;
                    products.value = result.data.recipe.products
                    tags.value = result.data.tags;

                    // meta title
                    document.getElementsByTagName('title')[0].innerHTML = item.value.name;
                    document.querySelectorAll('meta[name=twitter\\:title]')[0].setAttribute('content', item.value.name)
                    document.querySelectorAll('meta[name=og\\:title]')[0].setAttribute('content', item.value.name)

                    // meta img
                    var metaImg = document.createElement('meta');
                    metaImg.setAttribute('property', 'og:image');
                    metaImg.setAttribute('content', item.value.file_path);
                    document.getElementsByTagName('head')[0].appendChild(metaImg);

                    // meta url
                    var metaLink = document.createElement('meta');
                    metaLink.setAttribute('property', 'og:url');
                    metaLink.setAttribute('content', url);
                    document.getElementsByTagName('head')[0].appendChild(metaLink);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        };

        const toggleSelectAll = () => {
            if (selected.value) {
                selected.value.forEach((x,i) => 
                    selected.value[i] = checkInventory(products.value[i]) ? selectAll.value : false)
            }
        };

        const checkInventory = (product) => {
            return product.inventories.length > 0 && product.inventories[0].quantity > 0
        }

        watch(
            () => products.value,
            (arr) => {
                selected.value = new Array(arr.length).fill(false);
                selected_quantity.value = new Array(arr.length).fill(false);
                if (arr) {
                    arr.forEach((sel, index) => {
                        selected.value[index] = checkInventory(products.value[index]) ? true : false;
                        selected_quantity.value[index] = checkInventory(products.value[index]) ? 1 : 0;
                    });
                }
            },
            { immediate: true }
        );

        watch(
            () => selected.value,
            (arr) => selectAll.value = arr.filter(x => x).length == arr.length,
            { immediate: true, deep: true }
        );

        onMounted(() => {
            fetch()
        })

        // onIonViewWillEnter(() => {
        //     fetch()
        // })

        const images = [
            { path: '/assets/images/recipe1.png' },
            { path: '/assets/images/recipe2.png' },
        ]
        const hasAvailableStocks = computed(() => selected.value?.filter((item) => item === true)?.length > 0 );
        const loading = ref(false);
        const favorited = ref(false);
        const addOrRemoveFavorite = async () => {

            const user = await localStorage.value.get("user");

            if(!user) {
                route.replace({ name : "Login"})
            }

            const params = products.value?.map((product) => {
                return { product_id: product.sku, user_id: user.id };
            });

            await window.axios
                .post(process.env.VUE_APP_API_URL + '/api/v1/customer/favorites/store', params)
                .then((response) => {
                    favorited.value = response.data.data?.status == 'favorite' ? true : false;
                    successHandler({ 
                            type: 'modal',
                            description: response.data.message, 
                            response
                        });
                })
                .catch(() => {
                    loading.value = false;
                })
                .then(() => {
                    loading.value = false;
                        
                })
        }

        const isAuthenticated = computed(() => {
            return store.guestId ? false : true;
        });

        const addToCartHandler = async () => {
            for (let index = 0; index < selected.value.length; index++) {            
                if(selected.value[index] && selected_quantity.value[index] > 0) {
                    const url = Object.keys(cart.item(products.value[index].sku)).length ? cart.item(products.value[index].sku).update_url : null;
                    let data = {
                        sku: products.value[index].sku,
                        name: products.value[index].name,
                        price: products.value[index].price,
                        updateUrl: url,
                        qty: selected_quantity.value[index],
                        stocks: products.value[index].inventories[0].quantity,
                    }

                    if(data.stocks <= 0) {
                        openResponseModal({ title: "Warning", description: "No Stocks available.", buttonText: "Okay" });
                    } else {
                        if (url) {
                            await updateItemsToCart(data); // Update Cart
                        } else {
                            await addItemsToCart(data); // Buy No | Add to Cart
                        }
                        // miniCart(data);
                    }
                    
                } else {
                    const url = Object.keys(cart.item(products.value[index].sku)).length ? cart.item(products.value[index].sku).remove_url : null;
                    if (url) {
                        removeCartItem(url);
                    }
                }
            }
        }

        const addItemsToCart = async (data) => {
            await addToCart({
                product_id: data.sku,
                quantity: data.qty,
                for_checkout: true,
            })
            .then(message => {
                if (message?.status == undefined) {
                    return;
                }
                if (message?.status === 422) {
                    errorHandler(message);
                    return;
                }

                if(typeof message === 'string') {
                    close();
                }                
            });
        }

        const updateItemsToCart = async (data) => {
            await updateCartItem(data.updateUrl, {
                quantity: data.qty,
                for_checkout: true
            })
            .then(message => {
                if (message?.status == undefined) {
                    return;
                }
                
                if (message?.status === 422) {
                    errorHandler(message);
                    return;
                }

                if(typeof message === 'string') {
                    close();
                    successHandler({ 
                        type: 'toast',
                        description: message, 
                        message,
                        duration: 2000,
                        autoClose: true
                    });
                }                
            });
        }

        const miniCart = async (data) => {
            const modal = await modalController.create({
                component: MiniCart,
                cssClass: 'gen-modal bottom-sheet',
                componentProps:{
                    sku: data.sku,
                    name: data.name,
                    price: data.price,
                    updateUrl: data.updateUrl,
                    qty: data.qty,
                    stocks: data.stocks,
                }
            });
            return modal.present();
        };

        const previewImage = (file_path) => {

            return file_path || '/assets/images/product-image.png';
        }

        return {
            route,
            item,
            products,
            images,
            selectAll,
            selected,
            toggleSelectAll,
            addOrRemoveFavorite,
            loading,
            favorited,
            isAuthenticated,
            addToCartHandler,
            selected_quantity,
            previewImage,
            miniCart,
            checkInventory,
            tags,
            hasAvailableStocks,
            addItemsToCart,
            updateItemsToCart
        }
    },
});
</script>
<style>
    .out-of-stock {
        opacity: 0.3;
    }

    .product-name {
        width: 90% !important;
        display: -webkit-box !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        -webkit-box-orient: vertical !important;
        -webkit-line-clamp: 2 !important;
        white-space: normal;
    }

    .quantity-holder {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
</style>