<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <section class="px-[100px] py-20 max-w-[1440px] m-auto">
                <div class="flex space-x-[66px]">
                    <div class="w-2/12">
                        <side-bar></side-bar>
                    </div>
                    <ConfirmChanges 
                    :submit-url="submitUrl"
                    :params="userStore.userPassword"
                    />
                </div>
            </section>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>
<script type="text/javascript">
import {
    IonPage,
} from '@ionic/vue';
import { defineComponent } from 'vue';
// import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import SideBar from './components/SideBar.vue';
import { useUserStore } from '@/store/user';
import { ref } from "@vue/reactivity";
import { useApiStore } from "@/store/api";
import ConfirmChanges from "./components/ConfirmChanges.vue"
export default defineComponent({
    name: 'ChangePassword',
    components: {
        IonPage,
        TopNavigation,
        BottomNavigation,
        SideBar,
        ConfirmChanges
    },
    setup() {
        const apiStore = useApiStore();
        const userStore = useUserStore();
        const submitUrl = ref(apiStore.route('change-password'));

        return {
            userStore,
            submitUrl
            // router,
            // form,
            // loading,
            // submit
        }
    },
});
</script>
