<template>
    <div class="space-y-10 pb-[65px]">
        <div class="card px-4 lg:px-12 py-6 lg:rounded-[20px] bg-fuchsia-100">
            <div class="m-auto text-center w-full">
                <div class="w-max m-auto mb-4">
                    <p class="font-semibold text-[32px]">{{ parseFloat(average_rating_reviews).toFixed(1) }}</p>
                    <div class="flex items-end justify-center space-x-2 mb-3">
                        <div class="stars flex space-x-1">
                            <img class="w-6" :src="renderStarIcon(1)" alt="">
                            <img class="w-6" :src="renderStarIcon(2)" alt="">
                            <img class="w-6" :src="renderStarIcon(3)" alt="">
                            <img class="w-6" :src="renderStarIcon(4)" alt="">
                            <img class="w-6" :src="renderStarIcon(5)" alt="">
                        </div>
                    </div>
                    <p v-if="!total_reviews == 0"
                    class="text-xs text-neutral-600">(based on {{ total_reviews }} {{ total_reviews > 1 ? 'reviews' : 'review' }})</p>
                </div>
                <div class="w-full overflow-auto scroll-custom">
                    <div class="w-max lg:w-full">
                        <div class="text-sm flex space-x-2 justify-center">
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 'all' ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab('all')"
                                >
                                All
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 'media' ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab('media')"
                                >
                                With Photos or Videos ({{ with_media_reviews.length }})
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 5 ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab(5)"
                                >
                                <div class="flex space-x-[3px] w-fit m-auto items-center">
                                    <StarIcon v-for="star in 5" :key="star" class="w-4 h-4 text-amber-500" />
                                    <div class="pl-1">({{ five_star_reviews.length }})</div>
                                </div>
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 4 ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab(4)"
                                >
                                <div class="flex space-x-[3px] w-fit m-auto items-center">
                                    <StarIcon v-for="star in 4" :key="star" class="w-4 h-4 text-amber-500" />
                                    <div class="pl-1">({{ four_star_reviews.length }})</div>
                                </div>
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 3 ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab(3)"
                                >
                                <div class="flex space-x-[3px] w-fit m-auto items-center">
                                    <StarIcon v-for="star in 3" :key="star" class="w-4 h-4 text-amber-500" />
                                    <div class="pl-1">({{ three_star_reviews.length }})</div>
                                </div>
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 2 ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab(2)"
                                >
                                <div class="flex space-x-[3px] w-fit m-auto items-center">
                                    <StarIcon v-for="star in 2" :key="star" class="w-4 h-4 text-amber-500" />
                                    <div class="pl-1">({{ two_star_reviews.length }})</div>
                                </div>
                            </div>
                            <div
                                class="border border-primary-500 font-normal rounded-lg px-5 py-4 text-sm w-fit"
                                :class="tab == 1 ? 'bg-primary-500 text-white' : 'text-primary-500'"
                                @click.prevent="selectedTab(1)"
                                >
                                <div class="flex space-x-[3px] w-fit m-auto items-center">
                                    <StarIcon class="w-4 h-4 text-amber-500" />
                                    <div class="pl-1">({{ one_star_reviews.length }})</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-4 lg:px-0 space-y-10">
            <review-card
            v-for="(item, index) in selected_reviews"
            :key="index"
            :item="item"
            ></review-card>
        </div>
    </div>
</template>
<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { StarIcon } from "@heroicons/vue/solid";
import { useRouter } from 'vue-router';

import useReview from "@/composables/useReview";
import ReviewCard from "./components/ReviewCard";

export default defineComponent({
    name: 'ProductReview',
    components: {
        StarIcon,
        ReviewCard
    },

    props : {
        items: {
            default: () => {
                return [];
            },
            type: Array
        },

        images: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    setup() {
        const router = useRouter();

        const { reviews, with_media_reviews, five_star_reviews, four_star_reviews, three_star_reviews, two_star_reviews, one_star_reviews,
                product_name, total_reviews, average_rating_reviews } = useReview();

        const renderStarIcon = (score, icon = null) => {
            if(average_rating_reviews.value > score) {
                icon = '/assets/icon/ic-star.svg';
            }
            else if(average_rating_reviews.value > score - 1) {
                icon = '/assets/icon/ic-halfstar.svg';
            }
            else if(average_rating_reviews.value < score) {
                icon = '/assets/icon/ic-emptystar.svg';
            }
            return icon;
        };        

        const selected_reviews = ref(reviews.value)
        const tab = ref('all')

        const selectedTab = (val) => {
            switch(val) {
                case 'all':
                    selected_reviews.value = reviews.value;
                    tab.value = val;
                    break;
                case 'media':
                    selected_reviews.value = with_media_reviews.value;
                    tab.value = val;
                    break;
                case 5:
                    selected_reviews.value = five_star_reviews.value;
                    tab.value = val;
                    break;
                case 4:
                    selected_reviews.value = four_star_reviews.value;
                    tab.value = val;
                    break;
                case 3:
                    selected_reviews.value = three_star_reviews.value;
                    tab.value = val;
                    break;
                case 2:
                    selected_reviews.value = two_star_reviews.value;
                    tab.value = val;
                    break;
                case 1:
                    selected_reviews.value = one_star_reviews.value;
                    tab.value = val;
                    break;
                default:
                    break;
            }
        }

        return {
            router,

            with_media_reviews,
            five_star_reviews,
            four_star_reviews,
            three_star_reviews,
            two_star_reviews,
            one_star_reviews,

            selected_reviews,
            product_name,
            total_reviews,
            average_rating_reviews,
            renderStarIcon,
            selectedTab,
            tab
        }
    },

});
</script>
