<template>
    
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">

            <div class="px-6 lg:px-12 pb-[120px] w-full max-w-[1440px] mx-auto">

                <div class="my-16">
                    <h3 class="text-[40px] text-gray-900 font-semibold">Blogs</h3>
                </div>

                <div class="grid grid-cols-1 lg:grid-cols-3 gap-12">
                    <template v-for="item in displayedArticles" :key="item.id">
                        <ArticleCard
                            class="cursor-pointer"
                            :item="item"
                            @click="router.push(`/blogs/view/${item.id}`)"
                        ></ArticleCard>
                    </template>
                </div>

                <div v-if="items.length > 6" class="flex items-center space-x-4 m-auto pagination w-fit mt-20">
                    <ChevronLeftIcon 
                        @click="page--" 
                        class="w-6 h-6 cursor-pointer" 
                        :class="[ page == 1 ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                    />
                    <template v-for="(pageNumber, key) in pages" :key="key">
                        <a class="m-0 cursor-pointer" @click="page = pageNumber">
                            <div class="relative w-8 h-8 rounded-full" :class="[ page === pageNumber ? 'bg-amber-400' : '']">
                                <p 
                                    class="absolute m-0 font-normal -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                                    :class="[ page === pageNumber ? 'text-white' : 'text-neutral-600' ]"
                                >
                                    {{ pageNumber }}
                                </p>
                            </div>
                        </a>
                    </template>
                    <ChevronRightIcon 
                        @click="page++"
                        class="w-6 h-6 cursor-pointer"
                        :class="[ page == pages.length ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                    />
                </div>
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>

</template>
<script>
import { ref, onMounted, watch, computed } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import { useRouter } from 'vue-router'; 
import { useApiStore } from '@/store/api';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ArticleCard from '@/components/cards/ArticleCard.vue';

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
    name: 'MyCart',
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
        ArticleCard,
        ChevronLeftIcon, 
        ChevronRightIcon
    },
    setup() {
        const apiStore = useApiStore();
        const router = useRouter();
        const items = ref([]);

        const page = ref(1);
        const perPage = ref(6);
        const pages = ref([]);

        const displayedArticles = computed(() => {
            return paginate(items.value);
        });

        const fetch =()=> {
            window.axios
                .get(apiStore.route('fetch-blogs'))
                .then((result) => {
                    items.value = result.data.items;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        };

        //  Set Number of Pages for Pagination
        const setPages =(items)=> {
            let numberOfPages = Math.ceil(items.length / perPage.value);

            for (let i = 1; i <= numberOfPages; i++) {
                pages.value.push(i);
            }
        }
         
        //  Paginate Articles
        const paginate =(items)=> {
            let from = (page.value * perPage.value) - perPage.value;
            let to = (page.value * perPage.value);

            return items.slice(from, to);
        }

        watch(
            () => items.value,
            (items) => {
                setPages(items);
            }
        );

        onMounted(() => {
            fetch();
        });

        return {
            items,
            router,
            pages,
            page,
            setPages,
            paginate,
            displayedArticles
        }
    }
}
</script>
