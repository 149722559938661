import { defineStore } from 'pinia'
// import { useApiStore } from './api'
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useUserStore = defineStore({
    id: 'user',
    state: () => {
        return {
            token: null,
            pushToken: null,
            auth: false,
            userData: null,
            userEmail: null,
            userPassword: null,
            guestId: null,
            userInvoice: null,
            userReview: null,
            userRecipe: null,
            userPromo: null,
            userAccount: null,
            userMobile: null,
            userResetToken: null,
            userBranchDistances: null,
            userNearestBranch: null,
            guestData: {},
            userDeleteAccount: null
          
        }
    },
    getters: {},
    actions: {
        setUser(value) {
            const { localStorage } = useLocalStorage();

            this.userData = value;
            localStorage.value.set('user', value)
        },

        setUserEmail(value) {
            this.userEmail = value;
        },

        setUserPassword(value) {
            this.userPassword = value;
        },

        async setGuestId() {
            const { localStorage } = useLocalStorage();
            const code = await localStorage.value.get('guestId');
            this.guestId = code || this.makeGuestId();
            localStorage.value.set('guestId', this.guestId);
        },

        resetGuestId() {
            const { localStorage } = useLocalStorage();
            this.guestId = this.makeGuestId();
            localStorage.value.set('guestId', this.guestId);
        },

        clearGuestId() {
            const { localStorage } = useLocalStorage();
            this.guestId = null;
            localStorage.value.delete('guestId');
        },

        makeGuestId(length = 20) {
            let result           = '';
            let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            let charactersLength = characters.length;
            for(let i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
           return result;
        },

        setUserInvoice(value) {
            this.userInvoice = value;
        },

        setUserReview(value) {
            this.userReview = value;
        },

        setUserRecipe(value) {
            this.userRecipe = value;
        },

        setUserPromo(value) {
            this.userPromo = value;
        },
        setUserAccount(value) {
            this.userAccount = value;
        },

        setUserMobile(value) {
            this.userMobile = value;
        },

        setUserResetToken(value) {
            this.userResetToken = value;
        },

        setUserBranchDistances(value) {
            this.userBranchDistances = value;
        },

        setUserNearestBranch(value) {
            this.userNearestBranch = value;
        },

        setUserDeleteAccount(value) {
            this.userDeleteAccount = value;
        }
    }
})