<template>
    <profile-layout :title="'Account Details'">
    <form @submit.prevent="submit">
        <p class="text-2xl font-semibold mb-[33px] hidden lg:block">Account Details</p>
        <div class="lg:max-w-[706px] grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-5 lg:gap-y-6 mb-10">
            <div class="lg:col-span-2">
                <div class="flex items-center space-x-4 w-full border border-gray-300 rounded-md p-4">
                    <div class="w-12 overflow-hidden rounded-full">
                        <div class="w-full h-0 pt-[100%] relative">
                            <template v-if="preview">
                                <div class="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center" :style="`background-image: url('${preview}')`"></div>
                            </template>
                            <template v-else>
                                <div class="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center bg-gray-200" :style="`background-image: url('${profilePhoto}')`"></div>
                            </template>
                        </div>
                    </div>
                    <div class="">
                        <p class="font-bold mb-2">{{ form.firstName }} {{ form.lastName }}</p>
                        <span class="flex items-center text-sm m-0">
                            <img src="/assets/icon/ic-image.svg" class="w-6 h-6 mr-2" />
                            <label for="myfile" class="text-xs text-neutral-500 cursor-pointer">Change Image</label>
                            <input @change="uploadProfile" type="file" id="myfile" name="profilePhoto" hidden>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <text-input
                    v-model="form.firstName"
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    disabled
                />
            </div>
            <div>
                <text-input
                    v-model="form.lastName"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    disabled
                />
            </div>
            <div>
                <text-input
                    v-model="form.email"
                    type="Email"
                    placeholder="Email"
                    name="email"
                />
            </div>
            <div>
                <mobile-number
                    v-model="form.mobileNumber"
                    placeholder="Mobile Number"
                />
            </div>
            <div>
                <text-input
                    v-model="form.gender"
                    type="text"
                    placeholder="Gender"
                    disabled
                />
                <!-- <gender-selection
                    id="Gender-id"
                    name="Gender"
                    placeholder="Gender"
                /> -->
            </div>
            <div>
                <text-input
                    v-model="form.birthdate"
                    type="date"
                    placeholder="Birthday"
                    disabled
                />
                <!-- <birthday-picker
                /> -->
            </div>
        </div>
        <button-component size="large" color="primary" fill="outline" @click="showPasswordConfirmation = true">
            <template #content>
                Save Changes
            </template>
        </button-component>
        
        <button-component class="ml-5" size="large" color="primary" fill="outline" @click="router.push('delete-account')">
            <template #content>
                Delete Account
            </template>
        </button-component>
        </form>

        <PasswordConfirmationModal
            :is-visible="showPasswordConfirmation"
            @close="showPasswordConfirmation = false"
            :cancelButton="true"
            cancelButtonLink="/account-details"
            :buttonLink="true"
            @confirm="confirmPassword"
            :form="form"
            @update:modelValue="(value) => (password = value)"
        ></PasswordConfirmationModal>
    </profile-layout>
</template>




<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import TextInput from '@/components/inputs/TextInput.vue';
import MobileNumber from '@/components/inputs/MobileNumber.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { onMounted, ref } from "@vue/runtime-core";
import { useApiStore } from "@/store/api";
import useResponseHandler from '@/composables/useResponseHandler';
import ProfileLayout from "./ProfileLayout.vue";
import PasswordConfirmationModal from '@/components/dialogs/PasswordConfirmationModal.vue';

export default defineComponent({
    name: 'AccountDetails',
    components: {
        TextInput,
        MobileNumber,
        ProfileLayout,
        ButtonComponent,
        PasswordConfirmationModal
    },
     setup() {
       
        const router = useRouter();
        const apiStore = useApiStore();
        const loading = ref(false);
        const { openResponseModal } = useResponseHandler();
        const showPasswordConfirmation = ref(false);
        const password = ref();

        const sampleOptions = [
            { id: '1', label: 'Colors', value: 'Colors'},
            { id: '2', label: 'Typography', value: 'Typography'},
            { id: '3', label: 'Forms', value: 'Forms'},
            { id: '4', label: 'Buttons', value: 'Buttons'},
            { id: '5', label: 'Cards', value: 'Cards'}
        ]

        const form = ref({
            profilePhoto: null,
            firstName: null,
            lastName: null,
            email: null,
            gender: null,
            mobileNumber: null,
            birthdate: null,
            current_password: null
        });

        const preview = ref(null);

        const fetchUser = async () => {
            window.axios
            .post(
                apiStore.route('fetch-profile'))
            .then((result) => {
                form.value.profilePhoto = result.data.item.profile_photo_url;
                form.value.firstName = result.data.item.first_name;
                form.value.lastName = result.data.item.last_name;
                form.value.email = result.data.item.email;
                form.value.gender = result.data.item.gender;
                form.value.mobileNumber = result.data.item.mobile_number;
                form.value.birthdate = result.data.item.birthdate;

                preview.value = form.value.profilePhoto;
            }).catch((error) => {
                // message.value = error.response.data;
                 openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const confirmPassword = () => {

            loading.value = true

            const data = {
                password: password.value
            }

            window.axios
            .post(apiStore.route("check-password"), data)
            .then((result) => {
                loading.value = false;
                if(result.data.status == 'OK') {
                    showPasswordConfirmation.value = false;
                    submit();
                }
            })
            .catch((error) => {
                loading.value = false;
                showPasswordConfirmation.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const submit = () => {
			loading.value = true;

            // const formData = event.target;
            // const params = new FormData(formData);
            // params.append('mobileNumber', form.value.mobileNumber)

            form.value.profilePhoto = profilePhoto.value;
			window.axios
				.post(apiStore.route("update-profile"), form.value, { 
                    headers: {
                        "content-type": "multipart/form-data"
                    }
                })
				.then(() => {
					loading.value = false;

                    resetPasswordConfirmationModal();
                    openResponseModal({ title: "Success!", description: "You have successfully update your profile.", buttonText: "Okay", buttonLink: "/account-details" });
				})
				.catch((error) => {
                    loading.value = false;

                    resetPasswordConfirmationModal();
                    openResponseModal({ title: "Warning!", description: error.response.data.message, buttonText: "Okay" });
				});
		};

        const imageLoaded = () => {
            preview.value = event.target.result;
        }

        const profilePhoto = ref(null);
        const uploadProfile = (event) => {
       
            const file = event.target.files.item(0);
            const reader = new FileReader();
            // reader.addEventListener('load', event.target.result);
            reader.addEventListener('load', imageLoaded);
            reader.readAsDataURL(file);

            profilePhoto.value = file;   
        };

        const resetPasswordConfirmationModal = () => {
            form.value.current_password = null;
            showPasswordConfirmation.value = false;
        }

        onMounted(() => {
			fetchUser();     
		});

        return {
            router,
            form,
            preview,
            submit,
            uploadProfile,
            sampleOptions,
            showPasswordConfirmation,
            confirmPassword,
            password
        }
    },
});
</script>
