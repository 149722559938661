import { ref, computed } from 'vue';
import { useApiStore } from '@/store/api';
import { useCartStore } from '@/store/cart';
import { useUserStore } from '@/store/user';
import useResponseHandler from '@/composables/useResponseHandler';
export default function useVoucher()
{
    const api = useApiStore();
    const cart = useCartStore();
    const user = useUserStore();
    const { openResponseModal } = useResponseHandler();

    const voucherLoader = ref(false);

    const fetchVouchersUrl = computed(() => api.route('fetch-vouchers'));
    const canFetchVouchers = computed(() => typeof fetchVouchersUrl.value === 'string' && (user.guestId || user.userData?.accountnum));

    const fetchVouchers = (params = {}) => {
        const url = fetchVouchersUrl.value;
        if(!voucherLoader.value && typeof url === 'string') {
            voucherLoader.value = true;
            return window.axios
                .get(url, { params })
                .then(({ data }) => {
                    voucherLoader.value = false;
                    return data;
                })
                .catch(() => {
                    voucherLoader.value = false;
                    return [];
                });
        }
        else {
            return Promise.resolve([]);
        }
    };

    const applyVoucher = (params) => {
        const url = cart.data.apply_voucher_url;
        if(!voucherLoader.value && typeof url === 'string') {
            voucherLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    voucherLoader.value = false;
                    cart.data = data.cart;
                    return data.message;
                })
                .catch((error) => {
                    voucherLoader.value = false;
                    openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
                });
        }
        else {
            return Promise.resolve();
        }
    };

    const removeVoucher = () => {
        const url = cart.data.remove_voucher_url;
        if(!voucherLoader.value && typeof url === 'string') {
            voucherLoader.value = true;
            return window.axios
                .post(url)
                .then(({ data }) => {
                    voucherLoader.value = false;
                    cart.data = data.cart;
                    return data.message;
                })
                .catch(() => {
                    voucherLoader.value = false;
                });
        }
        else {
            return Promise.resolve();
        }
    };

    return {
        voucherLoader,
        canFetchVouchers,
        fetchVouchers,
        applyVoucher,
        removeVoucher
    }
}
