import { Geolocation } from "@capacitor/geolocation";
import { Loader } from "@googlemaps/js-api-loader";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { computed, reactive, ref } from "vue";
import { useApiStore } from "@/store/api";
import { useUserStore } from '@/store/user';
import useCart from '@/composables/useCart';
import useLocalStorage from '@/composables/useLocalStorage';

// Declarations for map
const locationState = reactive({
  // Customer coordinates
  customerLat: null,
  customerLng: null,
  // Rider coordinates
  riderLat: null,
  riderLng: null,
  // Search variables
  completeAddress: null,
  search: null,
  addressCandidates: [],
  hasSelectedAddress: false,
  searchText: null,
  isDraggable: false,
  maskSearch: null,
  loading: false
});

// Quezon city coordinates will act as the default if location service has been denied
const defaultLatitude = ref(14.676208);
const defaultLongitude = ref(121.043861);

const isLocationEnabled = ref(false);


let map;
let iconExtender;
let customerIcon;
let customerMarker;
let riderIcon;
let riderMarker;

export default function useLocation() {
  const apiStore = useApiStore();
  const user = useUserStore();
  const { updateCart, fetchCart } = useCart();
  const { localStorage } = useLocalStorage();

  const hasRiderCoordinates = () => computed(() => locationState.riderLat && locationState.riderLng ? true : false);

  const clearValues = () => {
    locationState.customerLat = null;
    locationState.customerLng = null;
    locationState.riderLat = null;
    locationState.riderLng = null;
    locationState.completeAddress = null;
    locationState.search = null;
    locationState.maskSearch = null;
    locationState.addressCandidates = [];
  };

  const googleLoader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });

  // Add Marker
  const addMarker = (userType = "rider", draggable = false) => {
    iconExtender = L.Icon.extend({
      options: {
        iconSize: [35, 95],
      }
    });

    switch (userType) {
      case "customer":
        (customerIcon = new iconExtender({
          iconUrl: "/assets/icon/ic-map-pin.svg",
        })),
          // Map marker
          (customerMarker = L.marker(
            [
              parseFloat(locationState.customerLat),
              parseFloat(locationState.customerLng),
            ],
            {
              icon: customerIcon,
              draggable: draggable,
            }
          ).addTo(map));
        break;
      
      case "rider":
        (riderIcon = new iconExtender({
          iconUrl: "/assets/icon/ic-map-pin.svg",
        })),
          // Map marker
          (riderMarker = L.marker(
            [
              parseFloat(locationState.riderLat),
              parseFloat(locationState.riderLng),
            ],
            {
              icon: riderIcon,
              draggable: draggable,
            }
          ).addTo(map));
        break;
    }
  };

  // Remove Marker
  const removeMarker = (userType = "rider") => {
    switch (userType) {
      case "customer":
        map.removeLayer(customerMarker);
        break;

      case 'rider':
        map.removeLayer(riderMarker)
        break;
    }
  };

  const setBounds = (lat1, lng1, lat2, lng2, lat3, lng3) => {
    const bounds = [
      [lat1, lng1],
      [lat2, lng2],
    ];

    if (lat3 && lng3) {
      bounds.push([lat3, lng3]);
    }

    map.fitBounds(bounds, {
      padding: [40, 50],
    });
  };

  const mapCenteredMarker = (userType = "customer") => {
    map.on("move", function () {
      switch (userType) {
        case "customer":
          customerMarker.setLatLng(map.getCenter());
          // locationState.search =
          //   locationState.customerLat + ", " + locationState.customerLng;
          break;
      }
    });
  };

  const mapMoveEndEvent = (userType = "customer") => {
    map.on("moveend", function () {
      switch (userType) {
        case "customer":
            locationState.customerLat = customerMarker.getLatLng().lat;
            locationState.customerLng = customerMarker.getLatLng().lng;
            getLocation(locationState.customerLat, locationState.customerLng);
            // setSelectedAddress(
            //     locationState.customerLat,
            //     locationState.customerLng,
            //     locationState.sear
            // );   
          break;
      }
    });
  }

  // Update rider marker position
  const updateMarkerCoordinates = (userType = "customer") => {
    switch (userType) {
      case "customer":
        customerMarker?.setLatLng([
          locationState.customerLat,
          locationState.customerLng,
        ]);
        break;
      
      case "rider":
        riderMarker?.setLatLng([
          locationState.riderLat,
          locationState.riderLng,
        ]);
        break;
    }
  };

  const updateCoordinates = (userType, latitude, longitude) => {
    switch (userType) {
      case 'rider':
        locationState.riderLat = latitude;
        locationState.riderLng = longitude;
        break;

      case 'customer':
        locationState.customerLat = latitude;
        locationState.customerLng = longitude;
        break;
    }
  };

  const setDefaultCoordinates = (userType = 'customer') => {
    updateCoordinates(userType, defaultLatitude.value, defaultLongitude.value);
  };

  const draggableMarker = () => {
    customerMarker.on("dragend", function () {
        locationState.hasSelectedAddress = false;
      updateCoordinates('customer', customerMarker.getLatLng().lat, customerMarker.getLatLng().lng)
      getLocation(customerMarker.getLatLng().lat, customerMarker.getLatLng().lng);
    //   locationState.search = getLocation(customerMarker.getLatLng().lat, customerMarker.getLatLng().lng);
    //   locationState.search = getLocation(locationState.customerLat, locationState.customerLng);
      // locationState.search =
      //   locationState.customerLat + ", " + locationState.customerLng;
    });
  };

  // Instantiate map
  const setupMap = (mapId = "locationMap") => {
    // Set map and center
    map = L.map(mapId, {
      condensedAttributionControl: false,
      scrollWheelZoom: 'center'
    });


    // Set tiles
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + process.env.VUE_APP_MAP_ACCESS_TOKEN,
      {
        // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        tileSize: 512,
        zoomOffset: -1,
        accessToken: process.env.VUE_APP_MAP_ACCESS_TOKEN,
      }
    ).addTo(map);

    // Remove zoom control
    map.removeControl(map.zoomControl);

    map.attributionControl.setPrefix(
      `<span id="attribution-toggle">
        <img width="20" height="20" src="/assets/icon/ic-info.svg">
        <div class="attribution-content">
          <ul class="attribution-list">
            <li class="attribution-item"><a href="https://leafletjs.com/" target="_blank">Leaflet</a></li>
            <li class="attribution-item"><a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a></li>
            <li class="attribution-item"><a href="https://www.mapbox.com/" target="_blank">Mapbox</a></li>
          </ul>
        </div>
      </span>`
    );
    map.attributionControl.setPosition('topleft');


    return new Promise((resolve) => {
      resolve(true);
    });
  };

  const fetchDistanceAndTimeEstimate = (customerLat, customerLng) => {
    return window.axios
      .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${locationState.riderLng},${locationState.riderLat};${customerLng},${customerLat}?access_token=${process.env.VUE_APP_MAP_ACCESS_TOKEN}`);  
  };

  // Update map center position
  const updateMapCenter = (lat, lng) => {
    const zoom = isLocationEnabled.value ? 20 : 15;
    map.setView([lat, lng], zoom);
  };

  // Search feature
//   const googleSearch = async () => {   
//     if (locationState.search?.length > 3) {
//         const google = await googleLoader.load();
//         const service = new google.maps.places.PlacesService(
//         document.createElement("div")
//         );
//         // Check if search bar is not empty
//         if (locationState.search) {
//         service.textSearch(
//             {
//             query: locationState.search,
//             region: "ph",
//             },
//             (results, status) => {
//                 if (status == google.maps.places.PlacesServiceStatus.OK) {
//                     getLocation(results[0].geometry.location.lat(), results[0].geometry.location.lng(), locationState.search);
//                     // locationState.addressCandidates = results.map((result) => {
//                     // // console.log("search")
//                     // // console.log(result)
//                     // return {
//                     //     name: result.name,
//                     //     formattedAddress: result.formatted_address,
//                     //     lat: result.geometry.location.lat(),
//                     //     lng: result.geometry.location.lng(),
//                     // };
//                     // });
//                 } else {
//                     locationState.search = null;
//                     locationState.addressCandidates = [];
//                 }
//             }
//         );
//         }
//     } else {
//         locationState.addressCandidates = [];
//     }    
//   };

    const googleSearch = async () => {
            const google = await googleLoader.load();
            googlePlaceAutocomplete(google);
    };

    const findAddress = () => {
        locationState.hasSelectedAddress = false;
        locationState.customerLat = null;
        locationState.customerLng = null;
    }

  const setSelectedAddress = (lat, lng, formattedAddress) => {
    updateCoordinates('customer', lat, lng);

    if (formattedAddress) {
      locationState.completeAddress = formattedAddress;
    }

    if (map) {
      updateMapCenter(locationState.customerLat, locationState.customerLng);
      // updateMarkerCoordinates("customer");
    }

    locationState.addressCandidates = [];
  };

  // Set neares branch
  const setNearestBranch = (customerLat, customerLng, branches) => {
    let properBranches = [];
    getBranchDistances(customerLng, customerLat, branches)
    getPerUserBranchDistance(branches);    
    return {
      properBranches,
      // branch
    }
};

const getBranchDistances = (customerLng, customerLat, branches) => {  
    const renderBranches = [];
    localStorage.value.delete('branchDistances');
    branches.map((branch) => {
        window.axios
        .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${branch.longitude},${branch.latitude};${customerLng},${customerLat}?access_token=${process.env.VUE_APP_MAP_ACCESS_TOKEN}`)
        .then(({ data }) => {
            if(data.code == "Ok") {
                renderBranches.push({
                    routes: data.routes[0],
                    branch: branch.code
                });
                localStorage.value.set('branchDistances', renderBranches);
            }
        }).catch((error) => console.log(error));
    });
}

const getPerUserBranchDistance = (branches) => {
    const persist = setInterval(async () => {
        const retrieveBranchDistances = await localStorage.value.get('branchDistances');        
        if (retrieveBranchDistances?.length === branches?.length) {
            user.setUserBranchDistances(retrieveBranchDistances);
            getNearestBranch()
            clearTimeout(persist);
        }
    }, 200);
}

const getNearestBranch = () => {
  let branchdistances = [];

  if(user.userBranchDistances != null) {
      user.userBranchDistances.map((test) => {
          branchdistances.push(test.routes.distance);
      });

      const min = Math.min(...branchdistances);
      const near = user.userBranchDistances.find(x => x.routes.distance == min);

      user.setUserNearestBranch(near);
      localStorage.value.set("nearestBranch", near);

      // updateCart({ branch_id: near.branch })

      updateCart({
          branch_id: near.branch, 
      }).then(message => {
          console.log(message)
          Promise.allSettled([fetchCart()]).then(() => window.location.reload());
      });
  }
}

  // Set current customer location
  const setCustomerLocation = async () => {
    return Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout:5000,
      maximumAge: 0,
    }).then((result) => {
      if (result) {
        // Assign retrieved coordinates to searchbar
        // locationState.search = getLocation(result.coords.latitude, result.coords.longitude);
        getLocation(result.coords.latitude, result.coords.longitude);
        // locationState.search =
        //   result.coords.latitude + ", " + result.coords.longitude;

        locationState.customerLat = result.coords.latitude;
        locationState.customerLng = result.coords.longitude;
      }
    });
  };

  const getLocation = async(latitude, longitude, search = null) => {
    /*
    API REFERENCE link: https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
    */ 
    console.log(search);
    const google = await googleLoader.load();
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
        {
            location: {
                lat: latitude,
                lng: longitude,
            },
        },
        (responses, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                locationState.loading = false;
                const input = document.getElementById('location');
                responses = trimGeocodeResponse(responses);
                if (locationState.isDraggable) {
                    locationState.search = responses[0]?.formatted_address || '';
                } else {
                    if (input) {
                        locationState.search = input.value || '';
                    } else {
                        locationState.search = '';
                    }
                }
                // locationState.search = locationState.isDraggable ? (responses[0]?.formatted_address || '') : (input?.value || '');
                locationState.maskSearch = locationState.search;
                locationState.customerLat = responses[0].geometry.location.lat();
                locationState.customerLng= responses[0].geometry.location.lng();
                locationState.hasSelectedAddress = true;                  
                const geocodeResponse = responses.filter((result) => {
                    if (result.geometry.location_type != "APPROXIMATE") {
                        return result;
                    }
                }).map((result) => {
                    return {
                        name: '',
                        formattedAddress: result.formatted_address,
                        lat: result.geometry.location.lat(),
                        lng: result.geometry.location.lng()
                    };
                });
                locationState.addressCandidates = [...new Map(
                    geocodeResponse.map(item =>[item["formattedAddress"], item])).values()
                ];                
                // googlePlaceAutocomplete(google);
            } else {
                locationState.loading = false;
                // locationState.search = null;
                // locationState.addressCandidates = [];
                console.log('Place search was not successful for the following reason: ' + status);
            }
        }
    );

    // const service = new google.maps.places.PlacesService(
    //   document.createElement("div")
    // );
    // // Check if search bar is not empty
    // service.textSearch(
    //   {
    //     query: latitude + ',' + longitude,
    //     region: "ph",
    //   },
    //   (results, status) => {
    //     if (status == google.maps.places.PlacesServiceStatus.OK) {            
    //         locationState.search = results[0].formatted_address;
    //         locationState.addressCandidates = results.map((result) => {
    //             return {
    //                 name: result.name,
    //                 formattedAddress: result.formatted_address,
    //                 lat: result.geometry.location.lat(),
    //                 lng: result.geometry.location.lng(),
    //             };
    //         });
    //     } else {
    //       console.log('Place search was not successful for the following reason: ' + status);
    //     }
    //   }
    // );
}

const googlePlaceAutocomplete = (google) => {
    const input = document.getElementById('location');
    // input.focus();
    const opt = {
        strictBounds: true,
        componentRestrictions: { country: "ph" },
    };
    const autocomplete = new google.maps.places.Autocomplete(input, opt);
    autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if(place.geometry?.location == undefined) {
            return;
        }
        locationState.loading = false;
        locationState.hasSelectedAddress = true;
        locationState.maskSearch = input.value;        
        if (place.geometry?.location) {            
            setSelectedAddress(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
                input.value,
            );   
        }           
    });   
    // autocomplete.unbindAll(); 
}

const trimGeocodeResponse = (responses) => {
    return responses.filter((item) => {
        if (!item.formatted_address?.includes('+')) {
            return item;
        }
    })
}

  // Submit location form to the back-end
  const submitCustomerLocation = (payload) => {
    let request = null;

    if(payload.id) {
      request = window.axios.patch(
        apiStore.route("update-address", { address: payload.id }),
        payload
      );
    } else {
      request = window.axios.post(
        apiStore.route("store-address"),
        payload
      );
    }
    return request;
  };

  const checkLocationPermissions = async (getCurrentLocation = true) => {
    // return Geolocation.checkPermissions().then((result) => {
    //   console.log(result)
    //   // Check if the permission result is either "denied" or "prompt-with-rationale" (denying the permissions on android still returns prompt-with-rationale instead of denied)
    //   if (
    //     result.location == "denied" ||
    //     result.location == "prompt-with-rationale"
    //   ) {
    //     console.log("Location permission has either been denied or not yet accepted");
    //     isLocationEnabled.value = false;
    //   }
    //   // Check if the result is "granted"
    //   else if (result.location == "granted") {
    //     console.log("Location permission is granted");
    //     isLocationEnabled.value = true;
    //   }
    // });
    // return navigator.geolocation.getCurrentPosition((position) => {
    //   console.log("test position")
    //   console.log(position);
    //   // doSomething(position.coords.latitude, position.coords.longitude);
    // });
   navigator.geolocation.getCurrentPosition(function(position) {
      console.log(position)
      if (getCurrentLocation) {
        defaultLatitude.value = position.coords.latitude;
        defaultLongitude.value = position.coords.longitude;
        locationState.isDraggable = true;
        setDefaultCoordinates();
        initMap('locationMap');
        getLocation(defaultLatitude.value, defaultLongitude.value);
      }
      isLocationEnabled.value = true;
    },
    function(error) {
      if (error.code == error.PERMISSION_DENIED)
        console.log("you denied me :-(");
    });
  };

  const initMap = (mapId) => {
    // Check if map has already been initialized
    if (document.querySelector("#" + mapId)?.children?.length <= 0) {
        // If not initialized, initialize
        setupMap(mapId).then(() => {
            // Add customer marker
            addMarker("customer");
            
            updateMapCenter(
                locationState.customerLat,
                locationState.customerLng
            );

            // Enable the Map's moveend Event
            mapMoveEndEvent();

            mapCenteredMarker("customer");
        });
    } else {
        // If map has been initalized
        // Update customer marker coordinates
        updateMarkerCoordinates("customer");
        updateMapCenter(
            locationState.customerLat,
            locationState.customerLng
        );
    }
};

  return {
    setupMap,
    clearValues,
    addMarker,
    removeMarker,
    setBounds,
    updateMapCenter,
    updateMarkerCoordinates,
    draggableMarker,
    googleSearch,
    setCustomerLocation,
    setSelectedAddress,
    submitCustomerLocation,
    checkLocationPermissions,
    setDefaultCoordinates,
    updateCoordinates,
    hasRiderCoordinates,
    fetchDistanceAndTimeEstimate,
    mapCenteredMarker,
    initMap,
    locationState,
    isLocationEnabled,
    map,
    setNearestBranch,
    findAddress
  };
}
