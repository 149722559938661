<template>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative bg-white rounded-xl p-[50px] text-left overflow-hidden shadow-xl transform transition-all w-fit">
                            <div class="w-[361px] text-center">
                               <img class="w-10 h-10 m-auto mb-4" src="/assets/icon/check-circle.svg" alt="">
                               <p class="text-2xl text-neutral-600 font-semibold mb-4">Rating Submitted!</p>
                               <p class="text-neutral-600 font-normal mb-4 text-sm">You’re rating has been submitted. Thank you for your feedback</p>
                               <button-component
                                    size="large"
                                    color="primary"
                                    @click="closeThis()"
                                >
                                    <template #content>
                                        Okay
                                    </template>
                                </button-component>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
<script>
import { ref, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
    name: 'SuccessModal.vue',
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        ButtonComponent
      
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const closeThis =()=> open.value = false;

        const showLargeMap = ref(false);

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        return {
            open,
            closeThis,
            showLargeMap
        }
    }
}
</script>