<template>
  <ion-app>
    <ion-router-outlet animated="false"/>
  </ion-app>
</template>

<script type="text/javascript">
import { defineComponent, onMounted, watch, computed } from 'vue';
import { useApiStore } from "@/store/api.js";
import { useSettingsStore } from '@/store/settings';
import useCart from '@/composables/useCart';
import useLocalStorage from "@/composables/useLocalStorage.js";
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
	},

	setup() {
		const store = useApiStore();
		const settings = useSettingsStore();

		const { cartParamsComplete, fetchCart } = useCart();
		const { localStorage } = useLocalStorage();

		const fetch = async () => {
			window.axios
				.post(process.env.VUE_APP_API_URL + "/api/v1/customer/config/fetch")
				.then((result) => {
					window.axios.defaults.headers.common["ApiKey"] = `${process.env.VUE_APP_API_KEY}${result.data.key}`;
					store.routes = result.data?.routes || {};
					settings.deliveryMethods = result.data?.settings?.deliveryMethods || [];
					settings.deliveryOptions = result.data?.settings?.deliveryOptions || [];
					settings.paymentMethods = result.data?.settings?.paymentMethods || [];
					settings.orderStatuses = result.data?.settings?.orderStatuses || {};
					settings.suggesstedSearch = result.data?.settings?.suggesstedSearch || [];
					localStorage.value.set("routes", result.data?.routes || {});
					localStorage.value.set("branches", result.data?.settings?.branches || []);
				});
		};

		const initFetchCart = () => {
			if(cartParamsComplete.value) {
				fetchCart();
			}
		};

		watch(
			() => cartParamsComplete.value,
			(bool) => {
				if(bool) {
					initFetchCart();
				}
			},
			{ immediate: true }
		);

		const userStore = useUserStore();
		const accountnum = computed(() => userStore.userData?.accountnum || userStore.guestId);
		const listenBroadcastNotifications = () => {
			Notification.requestPermission();

			// watch order status updated by admin
			echoListener('order-status-confirmed.'+accountnum.value, '.new-order-status-confirmed');

			// // watch order status assigned updated by admin
			echoListener('order-status-assigned.'+accountnum.value, '.new-order-status-assigned');

			// // watch order status ready updated by rider
			echoListener('order-status-ready.'+accountnum.value, '.new-order-status-ready');

			// // watch order status in transit updated by rider
			echoListener('order-status-in_transit.'+accountnum.value, '.new-order-status-in_transit');

			// // watch order status completed updated by rider
			echoListener('order-status-completed.'+accountnum.value, '.new-order-status-completed');

			// // watch order status cancelled
			echoListener('order-status-cancelled.'+accountnum.value, '.new-order-status-cancelled');
		}

		const echoListener = (channel, listen) => {
			const icon = '/assets/images/product-image.png';
			const title = 'Order Status';

			window.Echo.channel(channel)
				.listen(listen, (e) => {

				new Notification(title, { body: e.message, icon: icon });
				new Audio('/assets/notification.wav').play();
			});
		}

		onMounted(() => {
			fetch();
			initFetchCart();
			listenBroadcastNotifications();
		});
		
		return {
			localStorage,
			store,
			fetch,
		};
	},
});
</script>