import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia'
import store from "./store";
import useAuth from "./composables/useAuth";
import { useUserStore } from '@/store/user';

import { IonicVue } from '@ionic/vue';

window.axios = require('axios').default;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.headers.common["App-Key"] = process.env.VUE_APP_API_URL

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Custom Font */
import './assets/fonts/trueno/stylesheet.scss';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';

/* Custom CSS */
import './assets/scss/app.scss';

import { useApiStore } from '@/store/api.js';

import Echo from 'laravel-echo';
import useLocalStorage from "@/composables/useLocalStorage.js";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_MIX_PUSHER_HOST,
  wssHost: process.env.VUE_APP_MIX_PUSHER_HOST,
  wsPort: process.env.VUE_APP_MIX_PUSHER_PORT,
  wssPort: process.env.VUE_APP_MIX_PUSHER_PORT,
  forceTLS: true,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});

window.Echo.connector.pusher.connection.bind('state_change', function(states) {
  if(states.current === 'disconnected') {
      window.Echo.connector.pusher.connect();
  }
});

Sentry.init({
  dsn: "https://3fe5353c36bc47d59302fd1708578a88@sentry.praxxys.ph/32",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "https://funhanmart-web.praxxys.ph",
        /^\//
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const pinia = createPinia();

const app = createApp(App)
  .use(pinia)
  .use(IonicVue, {
    mode: 'ios',
  })
  .use(router)
  .use(store);

const { assignToken, assignUser, fetchUserData } = useAuth();
const user = useUserStore();
const { localStorage } = useLocalStorage();

Promise.allSettled([assignToken(), assignUser(), router.isReady()])
  .then(() => {
    const apiStore = useApiStore();
    apiStore.fetchConfigs().then(async () => {
        // fetch current nearest branch via localStorage
        const branchData = await localStorage.value.get('nearestBranch');
        user.userNearestBranch = branchData;
      fetchUserData().then(() => app.mount('#app'));
    })
  });