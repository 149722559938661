import { DateTime } from "luxon";
export default function useFormat() {
    const moneyFormat = (value = 0, decimals = 2, prefix = '₱') => {
        return prefix + (parseFloat(value).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString();
    }
    
    const dateFormat = (date, format = "DDD") => {
        if(!date) {
            date = DateTime.now();
        }
        return DateTime.fromISO(date).toFormat(format);
    }

    const numberOnlyInput = (event) => {
        const result =
            event.ctrlKey ||
            event.altKey ||
            (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false) ||
            (95 < event.keyCode && event.keyCode < 106) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            (event.keyCode > 34 && event.keyCode < 40) ||
            event.keyCode == 46;

        if (!result) {
            event.preventDefault();
        }
        
        return true;
    };

    const mask = (str, maskChar, unmaskedLength, maskFromStart = false) => {
        const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
        const maskEnd = maskFromStart ? Math.max(0, str.length - unmaskedLength) : str.length;
        return str
        .split("")
        .map((char, index) => {
            if (index >= maskStart && index < maskEnd) {
                return maskChar;
            }
            else {
                return char;
            }
        })
        .join("");
    };

    return {
        moneyFormat,
        dateFormat,
        numberOnlyInput,
        mask
    }
}
