<template>
    <div>
        <div class="flex item-center relative">
            <span
                class="
                    absolute
                    left-3
                    top-[50%]
                    -translate-y-[50%]
                    pr-2
                    border-r
                    text-gray-500 text-sm
                    m-0
                "
            >
                +63
            </span>
            <input
                @keypress="numberonly"
                type="tel"
                class="
                    pl-14
                    focus:outline-none
                    focus:ring-0
                    focus:border-black 
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-lg
                    placeholder:text-[#BBB7B6]
                    font-normal
                    h-11
                "
                :placeholder="placeholder"
                maxlength="10"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            />
        </div>
        <p class="text-[10px] text-error mt-1" v-if="error">
            {{ error }}
        </p>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "MobileNumber",
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: "Mobile Number",
        },
    },
    setup() {
        const numberonly = (e) => {
            e = e ? e : window.event;
            let charCode = e.which ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                e.preventDefault();
            } else {
                return true;
            }
        };

        return {
            numberonly,
        };
    },
});
</script>