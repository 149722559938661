<template>
    <div class="px-4 lg:px-0 pt-4 lg:pt-0">
        <ArrowLeftIcon @click="$emit('cancel')" class="w-6 h-6 mb-6 lg:hidden" />
        <h3 class="text-2xl text-neutral-600 font-semibold">{{ headerLabel }}</h3>
    </div>
    <div class="mt-6 space-y-4">
        <div class="space-y-4 lg:px-0 px-4">
            <p class="bg-red-100 text-red-600 rounded-md text-sm py-2 px-3"
            v-if="error">{{ error }}</p>
            <TextInput
                v-model="form.label"
                placeholder="Label"
                helper="example: home, office"
            />
            <!-- <TextInput
                v-model="form.address_label"
                placeholder="Address"
                textAreaRows="3"
                :textarea="true"
                readonly
            /> -->
            <!-- Search on Map -->
            <div
                class="
                    flex
                    location-search
                    w-full
                    m-auto
                    mb-6
                    items-center
                    space-x-2
                    lg:space-x-4
                "
            >
                <div class="location-input relative w-full lg:w-[343px]">
                    <text-input
                        v-model="locationState.search"
                        placeholder="Where are you?"
                        id="location"
                        type="text"
                        customClass="absolute"
                        customContainerClass="opacity-0 border-none"
                        @keyup="findAddress"
                        @click="isDraggable(false)"
                        @keydown="changeFocusToMaskSearch"
                    />
                    <text-input
                        v-model="locationState.maskSearch"
                        placeholder="Where are you?"
                        id="maskLocation"
                        type="text"
                        customClass="h-12"
                        required
                        @keydown="maskSearch"
                        :helper="!resetForm && addressStore?.selectedAddress?.address_label ? `Current Location: ${addressStore?.selectedAddress?.address_label || ''}` : '' "
                        :disabled="locationState.loading"
                    />
                    <LocationMarkerIcon
                        @click="getCurrentLocation"
                        class="
                            text-neutral-500
                            w-5
                            h-5
                            absolute
                            top-3.5
                            right-3
                            bg-white
                            cursor-pointer
                        "
                    />
                    <!-- <div
                        @click="closeResult()"
                        v-if="
                            !locationState.hasSelectedAddress &&
                            locationState.search &&
                            locationState.addressCandidates.length > 0
                        "
                        class="map-search-result absolute z-[999] bg-white"
                    >
                        <div class="max-h-[196px] overflow-auto">
                            <div
                                class="
                                    flex
                                    px-2
                                    lg:px-4
                                    items-start
                                    space-x-2
                                    py-1
                                    last:mb-0
                                    border-b
                                    cursor-pointer
                                    hover:bg-gray-50
                                "
                                v-for="(
                                    addressCandidate, index
                                ) in locationState.addressCandidates"
                                :key="index"
                            >
                                <LocationMarkerIcon
                                    class="text-neutral-500 w-6 h-6"
                                />
                                <p
                                    @click="
                                        selectAddress(addressCandidate)
                                    "
                                    class="text-sm"
                                >
                                    <b>{{ addressCandidate.name }}</b> {{ addressCandidate.formattedAddress }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                    <div
                        @click="closeResult()"
                        v-if="showResult == false"
                        class="map-search-result absolute z-[999] bg-white"
                    >
                        <div class="max-h-[196px] overflow-auto">
                            <div
                                class="
                                    flex
                                    px-2
                                    lg:px-4
                                    items-start
                                    space-x-2
                                    py-1
                                    last:mb-0
                                    border-b
                                    cursor-pointer
                                    hover:bg-gray-50
                                "
                            >
                                <!-- <LocationMarkerIcon
                                    class="text-neutral-500 w-6 h-6"
                                /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of search map -->
            <TextInput
                v-model="form.address_details"
                placeholder="Address Details"
             />
            <TextInput
                v-model="form.notes"
                placeholder="Note to Rider"
            />
        </div>

        <div class="rounded-[10px] relative h-[400px] lg:h-[326px] overflow-hidden">
            <!-- MAP HERE -->
            <div id="largeLocationMap" class="w-full h-full" @click="isDraggable(true)"></div>
            <!-- MAP HERE -->
        </div>
        <!-- <div class="pb-[180px] lg:pb-[112px]">
            <div class="rounded-[10px] relative h-[200px] overflow-hidden">
                <ButtonComponent
                    size="default"
                    color="primary"
                    fill="outline"
                    customClass="absolute top-4 right-4 bg-white z-[999]"
                    @click="$emit('view-map', form)"
                >
                    <template #content>
                        <span class="text-sm font-normal text-primary-500">View Map</span>
                    </template>
                </ButtonComponent>
                MAP HERE
                    <div
                        id="smallLocationMap"
                        class="absolute w-full h-full"
                    >
                    </div>
                MAP HERE
            </div>
        </div> -->

        <div class="flex lg:flex-row flex-col items-center justify-between py-6 px-4 lg:px-8 absolute bottom-0 left-0 z-[999] w-full bg-white rounded-t-3xl">
            <ButtonComponent
                v-if="addressStore.hasSelectedAddress && !resetForm"
                color="danger"
                fill="outline"
                size="large"

                customClass="w-full lg:w-auto order-2 lg:order-1"
                @click="$emit('delete')"
            >
                <template #content>
                    <span>Delete Address</span>
                </template>
            </ButtonComponent>
            <div class="flex items-center justify-end space-x-4 w-full lg:w-auto mb-6 lg:mb-0">
                <ButtonComponent
                    color="primary"
                    fill="outline"
                    size="large"
                    customClass="w-1/2 lg:w-auto"
                    @click="$emit('cancel')"
                >
                    <template #content>
                        <span>Cancel</span>
                    </template>
                </ButtonComponent>
                <ButtonComponent
                    color="primary"
                    fill="solid"
                    size="large"
                    customClass="w-1/2 lg:w-auto"
                    @click="$emit('submit', form)"
                >
                    <template #content>
                        <span>Submit</span>
                    </template>
                </ButtonComponent>
            </div>
        </div>
    </div>

</template>
<script>
import TextInput from '@/components/inputs/TextInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { ref } from '@vue/reactivity';
import { computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
import useLocation from "@/composables/useLocation";
import { useAddressStore } from "@/store/address.js";
import { LocationMarkerIcon } from "@heroicons/vue/outline";
import debounce from "lodash/debounce";
export default {
    name: 'FormComponent',
    components: {
        TextInput,
        ButtonComponent,
        ArrowLeftIcon,
        LocationMarkerIcon
    },
    emits: ['view-map', 'delete', 'cancel', 'submit'],
    props: {
        addressForm: {
            type: Object,
            default: () => {}
        },
        error: {
            type: String,
            default: null
        },
        resetForm: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const addressStore = useAddressStore();
        const form = ref(props.addressForm);

        const {
            locationState,
            setCustomerLocation,
            isLocationEnabled,
            checkLocationPermissions,
            googleSearch,
            initMap,
            setDefaultCoordinates,
            setSelectedAddress,
            findAddress
        } = useLocation();

        const showResult = ref(true);

        let timeOut;

        const headerLabel = computed(() => addressStore.hasSelectedAddress && !props.resetForm ? 'Edit Address' : 'Add Address');

        const setLocation = () => {
            if (isLocationEnabled.value) {
                // If location service is enabled, set timeout and loop fetching/setting of rider coordinates per the interval set
                return setCustomerLocation().then(() => {
                    timeOut = setTimeout(setLocation(), 1000);
                    // initMap("smallLocationMap");
                    initMap("largeLocationMap");
                });
            } else {
                setDefaultCoordinates();
                // initMap("smallLocationMap");
                initMap("largeLocationMap");
            }
        };

        const selectAddress = (addressCandidate) => {
            locationState.hasSelectedAddress = true;
            setSelectedAddress(
                addressCandidate.lat,
                addressCandidate.lng,
                addressCandidate.formattedAddress
            );
        }

        const isDraggable = (isDraggable = false) => {
            locationState.hasSelectedAddress = false
            locationState.isDraggable = isDraggable;
            findAddress();
        }

        const maskSearch = () => {
            locationState.isDraggable = false;
            locationState.hasSelectedAddress = false;
        }

        const changeFocusToMaskSearch = () => {
            document.getElementById('maskLocation').focus()
        }

        const getCurrentLocation = () => {
            locationState.loading = true;
            checkLocationPermissions();
        }

        watch(
			() => locationState.search,
            debounce((currentValue) => {
				if (currentValue) {
					// if (locationState.addressCandidates.length > 0) {
                    //     showResult.value = true;
					// 	form.value.address_label =
					// 		locationState.addressCandidates[0][
					// 			"formattedAddress"
					// 		];

                    //     locationState.completeAddress = form.value.address_label;
					// } else {
					// 	form.value.address_label = "N/A";
					// }
                    if (locationState.addressCandidates?.length == 0) {
                        form.value.address = "N/A";
                    }
				} else {
					locationState.addressCandidates = [];
				}
			}, 500)			
		);

        watch(
            () => locationState.customerLat,
            (currentValue) => {
                if(currentValue) {
                    form.value.latitude = currentValue;
                    form.value.is_default = form.value.id ? form.value.is_default : true;
                }
            }
        );

        watch(
            () => locationState.customerLng,
            (currentValue) => {
                if(currentValue) {
                    form.value.longitude = currentValue;
                    form.value.is_default = form.value.id ? form.value.is_default : true;
                }
            }
        );

        onMounted(() => {
            locationState.search  = null; 
            locationState.maskSearch = null;
            locationState.loading = true;
            setTimeout(() => {
                checkLocationPermissions(props.resetForm).then(() => {
                    initMap("largeLocationMap");
                    locationState.hasSelectedAddress = false;
                    if (addressStore.hasSelectedAddress) {
                        locationState.loading = false;
                        locationState.customerLat = addressStore.selectedAddress.latitude;
                        locationState.customerLng = addressStore.selectedAddress.longitude;
                        locationState.search = props.resetForm ? null : addressStore.selectedAddress.address_label;
                        if (!props.resetForm) {
                            setSelectedAddress(
                                addressStore.selectedAddress.latitude,
                                addressStore.selectedAddress.longitude,
                                addressStore.selectedAddress.address_label,
                            );
                        }                   
                        // showResult.value = false;
                        // initMap("smallLocationMap");
                        initMap("largeLocationMap");
                        googleSearch()
                    } else {
                        setLocation();
                        googleSearch()
                    }
                });
            }, 500);
        });

        const closeResult = async () => {
            showResult.value  = showResult.value == false ? true : false;
        }

        watch(
            () => locationState.maskSearch,
            debounce((currentValue) => {
                if (!locationState.isDraggable) {
                    const input = document.getElementById('location');
                    if (input) {
                        input.value = '';
                        input.value = currentValue;
                        if (currentValue) {
                            input.focus();
                        }
                    }  
                }

            }, 700)
        );


        onUnmounted(() => {
            clearTimeout(timeOut);
        });

        return {
            form,
            headerLabel,
            addressStore,
            locationState,
            closeResult,
            showResult,
            setSelectedAddress,
            findAddress,
            selectAddress,
            isDraggable,
            maskSearch,
            changeFocusToMaskSearch,
            getCurrentLocation
        }
    }
}
</script>