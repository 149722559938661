<template>
    

    <div class="space-y-4" v-if="!checkout">
        <div class="flex flex-col lg:flex-row items-start space-y-[10px] lg:space-y-0 lg:space-x-2">
            <div class="flex-1 w-full lg:w-auto">
                <div>
                    <div class="relative">
                        <text-input
                        v-model="points"
                        :disabled="!isAuthenticated"
                        placeholder="Apply Points"></text-input>
                        <div @click="removeAppliedPoints" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                        <XCircleIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
                <slot name="voucher">
                    <p v-if="loyaltyPoints" class="text-xs text-gray-500 mt-1">You have {{ loyaltyPoints }} point/s</p>
                </slot>
                </div>
            </div>
            <div class="flex-initial w-full lg:w-auto">
                <ButtonComponent
                    size="default"
                    color="primary"
                    fill="outline"
                    customClass="w-full lg:w-24"
                    :disabled="!isAuthenticated"
                    @click="redeemPoints"
                >
                    <template #content>
                        <span class="text-sm">Confirm</span>
                    </template>
                </ButtonComponent>
            </div>
        </div>
        <!-- <VoucherInput
            placeholder="Apply Points"
            buttonText="Confirm"
            :helper="`You have ${ loyaltyPoints } coins`"
            @submit="redeemPoints"
            @clear="removeAppliedPoints"
            v-model="points"
        ></VoucherInput> -->
        <div class="flex flex-col lg:flex-row items-start space-y-[10px] lg:space-y-0 lg:space-x-2">
            <div class="flex-1 w-full lg:w-auto">
                <div>
                    <div class="relative">
                        <text-input
                        v-model="code"
                        placeholder="Enter a voucher code (Optional)"></text-input>
                        <div @click="removeAppliedVoucher" class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                        <XCircleIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                    </div>
                </div>
                <div>
                    <span class="font-light text-xs" v-if="cart.voucher_id">Applied Voucher: {{ cart.voucher_id }}</span>
                </div>
                    <ButtonComponent
                        fill="clear"
                        customClass="btn-no-padding h-4"
                        @click="$emit('show-vouchers')"
                    >
                        <template #content>
                            <span class="font-light text-xs text-primary-500 underline m-0">Select a voucher</span>
                        </template>
                    </ButtonComponent>
                </div>
            </div>
            <div class="flex-initial w-full lg:w-auto">
                <ButtonComponent
                    size="default"
                    color="primary"
                    fill="outline"
                    customClass="w-full lg:w-24"
                    @click="redeemVoucher"
                >
                    <template #content>
                        <span class="text-sm">Confirm</span>
                    </template>
                </ButtonComponent>
            </div>
        </div>
        <!-- <VoucherInput
            placeholder="Enter a voucher code (Optional)"
            buttonText="Verify"
            @submit="redeemVoucher"
            @clear="removeAppliedVoucher"
            v-model="code"
        >
            <template #voucher>
                <ButtonComponent
                    fill="clear"
                    customClass="btn-no-padding h-4"
                    @click="$emit('show-vouchers')"
                >
                    <template #content>
                        <span class="font-light text-xs text-primary-500 underline m-0">Select a voucher</span>
                    </template>
                </ButtonComponent>
            </template>
        </VoucherInput> -->
    </div>

    <div class="space-y-6" v-if="checkout">
        <template v-for="item in items" :key="item">
            <div class="flex justify-between space-x-6" v-if="item.quantity > 0">
                <p class="font-normal text-sm text-neutral-600 m-0">{{ item.quantity }} x {{ item.name }}</p>
                <p class="font-normal text-base text-neutral-600 m-0">
                    <RenderPrice :value="item.subtotal" />
                </p>
            </div>
        </template>
    </div>

    <div class="w-full border-t border-gray-300 my-6"></div>

    <div class="space-y-6">
        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-neutral-600 m-0">Subtotal</p>
            <p class="font-normal text-base text-neutral-600 m-0"><RenderPrice :value="cart.subtotal" /></p>
        </div>
        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-neutral-600 m-0">Discount</p>
            <p class="font-normal text-base text-neutral-600 m-0"><RenderPrice :value="discount" /></p>
        </div>
        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-red-500 m-0">Voucher Applied</p>
            <p class="font-normal text-sm text-red-500 m-0" v-if="cart.voucher_discount"><RenderPrice :value="cart.voucher_discount" /></p>
            <p class="font-normal text-base text-red-500 m-0" v-else>None</p>
        </div>
        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-red-500 m-0">Points Redeemed</p>
            <p class="font-normal text-base text-red-500 m-0"><RenderPrice :value="cart.points" v-if="cart.points" /></p>
            <p class="font-normal text-base text-red-500 m-0" v-if="!cart.points">None</p>
        </div>
        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-neutral-600 m-0">Delivery Fee</p>
            <p class="font-normal text-base text-neutral-600 m-0"><RenderPrice :value="checkout && cart.delivery_type == 2  ? 0 :cart.delivery_fee" /></p>
        </div>

        <div class="w-full border-t border-gray-300 my-6"></div>

        <div class="flex items-center justify-between">
            <p class="font-normal text-sm text-neutral-600 uppercase m-0">Total</p>
            <p class="font-normal text-base text-neutral-600 m-0"><RenderPrice :value="cart.total - (checkout && cart.delivery_type == 2  ? cart.delivery_fee : 0)" /></p>
        </div>

        <div class="w-full border-t border-gray-300 my-6"></div>

        <template v-if="isAuthenticated">
            <div class="flex items-center justify-between">
                <p class="font-normal text-sm text-neutral-600 m-0">Points to Earned</p>
                <p class="font-normal text-base text-neutral-600 m-0">{{ cart.points_earned }} Points</p>
            </div>
        </template>
    </div>

    <div class="mt-20" v-if="!checkout">
        <div class="mb-5 bg-red-200 py-1 px-2 rounded" v-if="hasMinimumOrder">
            <p class="text-sm" >Did not meet the order minimum amount. Minimum order amount is <RenderPrice :value="minimumOrder" /></p>
        </div>
        <div v-if="isFreeDelivery" class="bg-primary-200 py-1 px-2 rounded">
            <p class="text-sm" v-if="amountToFreeDelivery > 0">Add {{ moneyFormat(amountToFreeDelivery) }} more and get free delivery</p>
            <p class="text-xs" v-else>Free delivery!</p>
        </div>
        <div v-if="!isAuthenticated" class="bg-primary-200 py-1 px-2 rounded">
            <a href="/login" class="text-sm block" >Login to save points</a>
        </div>
    </div>

    <div class="mt-6 text-center space-y-6" v-if="!checkout">
         <ButtonComponent
            color="primary"
            fill="solid"
            expand="block"
            size="large"
            @click="confirmCheckout"
            :disabled="checkedNothing || !hasPaymentMethod || !hasSelectedDeliveryOption || !hasAddress"
        >
            <template #content>
                <span class="text-white text-sm">Review Payment</span>
            </template>
        </ButtonComponent>
        <ButtonComponent
            fill="clear"
            @click="router.push({ path: '/'})"
        >
            <template #content>
                <span class="text-primary-500 text-base font-normal">Continue Shopping</span>
            </template>
        </ButtonComponent>
    </div>

    <div class="mt-20 text-center space-y-10" v-if="checkout">
        <ButtonComponent
            color="primary"
            fill="solid"
            expand="block"
            size="large"
            :disabled="loading || disableCheckout?.length == 0 || !hasPaymentMethod || !hasAddress"
            @click="submit"
        >
            <template #content>
                <span v-if="!loading" class="text-white text-sm">Place Order</span>
                <ion-spinner v-else></ion-spinner>
            </template>
        </ButtonComponent>
    </div>

</template>
<script>
import {
    IonSpinner, onIonViewWillEnter 
} from '@ionic/vue';
// import VoucherInput from '@/components/inputs/VoucherInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import RenderPrice from '@/components/RenderPrice.vue';
import { useCartStore } from '@/store/cart';
import { useUserStore } from '@/store/user';
import { computed, watch, ref, onMounted } from 'vue';
import useFormat from '@/composables/useFormat';
import usePoints from '@/composables/usePoints';
import useVoucher from '@/composables/useVoucher';
import useResponseHandler from '@/composables/useResponseHandler';
import TextInput from '@/components/inputs/TextInput.vue';
import { XCircleIcon } from '@heroicons/vue/outline';
import { useSettingsStore } from '@/store/settings';
import { useRouter } from 'vue-router';
import useLocalStorage from "@/composables/useLocalStorage.js";
import useCart from '@/composables/useCart';
import { DateTime } from 'luxon';

export default {
    name: 'OrderSummary',
    components: {
        // VoucherInput,
        ButtonComponent,
        RenderPrice,
        TextInput,
        XCircleIcon,
        IonSpinner
    },
    emits: ['show-vouchers', 'confirm-checkout'],
    props: {
        checkout: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false
        },
        hasPaymentMethod: {
            type: Boolean,
            default: false
        },
        hasDeliveryOption: {
            type: Object,
            default: null
        }
    },
    setup(props, { emit }) {
        
        const cartStore = useCartStore();
        const user = useUserStore();
        const { updateCart } = useCart();
        const { moneyFormat } = useFormat();
        const { applyPoints, removePoints } = usePoints();
        const { applyVoucher, removeVoucher } = useVoucher()
        const { successHandler, openResponseModal } = useResponseHandler();
        const router = useRouter();
        const cart = computed(() => cartStore.data);
        const items = computed(() => cart.value?.items || []);
        const disableCheckout = computed(
            () => cart.value?.items?.length == 0 
                ? [] 
                : cart.value?.items?.filter((item) => {
                    if(item.quantity > 0) {
                        return item;
                    }
                })
            );
        const discount = computed(() => {
            const { voucher_discount, points } = cart.value;
            return voucher_discount + points;
        });
        const hasSelectedDeliveryOption = computed(() => {
            if (props.hasDeliveryOption) {
                if (props.hasDeliveryOption.selected_date_formatted == 'Delivery now' || props.hasDeliveryOption.selected_date_formatted == 'Pick Up now') {
                    return true;
                }
                return props.hasDeliveryOption.selected_date && props.hasDeliveryOption.selected_time ? true : false
            }
            return false;
        });

        // const loading = ref(false);
        const points = ref(null);
        const code = ref(null);
        const hasAddress = ref(false);
        const { localStorage } = useLocalStorage();

        const fetchAddress = async () => {
            const getaddress = await localStorage.value.get('addressData');
            hasAddress.value = getaddress?.address_label ? true : false;
        }

        const amountToFreeDelivery = computed(() => {
            return cart.value.min_free_delivery - cart.value.subtotal;
        });
        
        const isFreeDelivery = computed(() => settings.deliveryMethod('INHOUSE').is_free_delivery && settings.deliveryMethod('INHOUSE').is_enabled);

        const isAuthenticated = computed(() => user.token != null);
        const loyaltyPoints = computed(() => user.userData ? user.userData.loyalty_points : null); 
        const checkedNothing = computed(() => {
            return cart.value?.items?.length == 0
                || hasMinimumOrder.value
        });
        const settings = useSettingsStore();

        const redeemVoucher = () => {
            applyVoucher({ code: code.value })
                .then(message => {
                    code.value = null;
                    if(typeof message === 'string') {
                        successHandler({
                            type: 'toast',
                            description: message,
                            message,
                            duration: 2000,
                            autoClose: true
                        });
                    }
                })
                .catch((error) => {
                    // loading.value = false;
                    openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
                });
        };

        const removeAppliedVoucher = () => {
            removeVoucher();
            Promise.allSettled([removeVoucher()]).then(() => {
                code.value = null;
            });
        }

        const redeemPoints = () => {
            applyPoints({ points: points.value })
                .then(message => {
                    if(typeof message === 'string') {
                        successHandler({
                            type: 'toast',
                            description: message,
                            message,
                            duration: 2000,
                            autoClose: true
                        });
                    }
                });
        };

        const removeAppliedPoints = () => {
            if(!isAuthenticated.value) { return false; }
            removePoints();
            points.value = null;
        }

        const updateCartDeliveryOption = () => {
            if (props.hasDeliveryOption) {
                const now = DateTime.now();
                updateCart({
                    delivery_type: props.hasDeliveryOption.delivery_type,
                    scheduled_at: props.hasDeliveryOption.selected_date,
                    delivery_time: props.hasDeliveryOption.selected_time || now.plus({ hours:1 }).toFormat('HH:mm:00')
                })
            }
            
        }
    
        const minimumOrder = computed(() => settings.deliveryMethod('INHOUSE').min_order_amount);

        const hasMinimumOrder = computed(() => minimumOrder.value > cart.value.subtotal);

        const confirmCheckout = () => {
            updateCartDeliveryOption();
            emit('confirm-checkout')
        }

        const submit = () => {
            // loading.value = true;
            emit('submit-checkout')   
        }

        watch(
            () => points.value,
            (value) => {
                if(value) {
                    points.value = value;
                }
            }
        );

        onMounted(fetchAddress);
        onIonViewWillEnter(fetchAddress);

        return {
            cart,
            discount,
            amountToFreeDelivery,
            moneyFormat,
            isAuthenticated,
            checkedNothing,
            loyaltyPoints,
            redeemPoints,
            points,
            removeAppliedPoints,
            code,
            redeemVoucher,
            removeAppliedVoucher,
            items,
            disableCheckout,
            minimumOrder,
            hasMinimumOrder,
            isFreeDelivery,
            submit,
            // loading,
            confirmCheckout,
            router,
            hasAddress,
            hasSelectedDeliveryOption
        };
    },
}
</script>