<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-padding">
            <div class="w-full">
                <div class="logo-img">
                    <img src="assets/logo.png" alt="logo" class="mx-auto mb-24 mt-14">
                </div>

                <div class="">
                    <button-component
                        size="default"
                        color="primary"
                        class="mb-4"
                        expand="block"
                        fill="outline"
                    >
                        <template #content>
                            Continue as Guest
                        </template>
                    </button-component>
                    <button-component
                        size="default"
                        color="primary"
                        expand="block"
                        href="/login"
                    >
                        <template #content>
                            Login
                        </template>
                    </button-component>
                </div>

                <div class="relative my-8">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-400" />
                    </div>
                    <div class="relative flex justify-center">
                        <span class="px-10 bg-white text-sm text-black"> Or  </span>
                    </div>
                </div>

                <div class="text-center">
                    <p class="text-sm">Dont have an account yet? <a href="/register" class="font-bold">Sign Up</a></p>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default  {
    name: 'LoginOptions',

    components: {
        IonPage, 
        IonContent,
        ButtonComponent
    },

    setup() {
        return {

        };
    }
}
</script>