<template>
    <footer>
        <div class="px-4 lg:px-12 py-[48px] lg:pt-20 bg-[#5C0E7C] flex flex-col lg:flex-row justify-between lg:pb-[45px] space-y-12 lg:space-y-0">
            <div class="lg:max-w-[387px]">
                <div v-if="cmsContent !== null && getCmsContent('footer_logo') !== null">
                    <a href="/">
                        <img class="w-[239px] lg:w-[280px] object-cover brightness-0 invert mb-12 lg:mb-20" :src="getCmsContent('footer_logo') ? renderCmsImage(getCmsContent('footer_logo')) : '/assets/images/logo.png'" alt="">
                    </a>
                </div>
                <div v-else>
                    <a href="/">
                        <img class="w-[239px] lg:w-[280px] object-cover brightness-0 invert mb-12 lg:mb-20" src="/assets/images/logo.png" alt="">
                    </a>
                </div>
                <div class="w-full space-y-4 mb-12">
                    <div v-if="cmsContent !== null && getCmsContent('footer_officeAddress') !== null">
                        <a :href="getCmsContent('footer_officeMapLink')" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">
                            {{
                                getCmsContent('footer_officeAddress') ||
                                'FunhanMart Forum South Global, 7th Avenue Corner Federacion Drive, Bonifacio Global City,Taguig'
                            }}
                        </a>
                    </div>
                    <div v-else>
                        <a href="https://goo.gl/maps/GHuQuv1cqMrFjsGz5" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">FunhanMart Forum South Global, 7th Avenue Corner Federacion Drive, Bonifacio Global City,Taguig</a>
                    </div>
                    <div v-if="cmsContent !== null && getCmsContent('footer_officeNumber') !== null">
                        <a :href="`tel:${getCmsContent('footer_officeNumber') ? getCmsContent('footer_officeNumber') : '02-8235-4213'}`" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">{{ getCmsContent('footer_officeNumber') || '02-8235-4213' }}</a>
                    </div>
                    <div v-else>
                        <a href="tel:02-8235-4213" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">02-8235-4213</a>
                    </div>
                    <div v-if="cmsContent !== null && getCmsContent('footer_supportEmail') !== null">
                        <a :href="`mailto:${getCmsContent('footer_supportEmail') ? getCmsContent('footer_supportEmail') : 'customercare@funhanmart.com'}`" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">{{ getCmsContent('footer_supportEmail') || 'customercare@funhanmart.com' }}</a>
                    </div>
                    <div v-else>
                        <a href="mailto:customercare@funhanmart.com" target="_blank" rel="noopener noreferrer" class="text-xs text-white block">customercare@funhanmart.com</a>
                    </div>
                </div>
                <div v-if="cmsContent !== null && getCmsContent('footer_otherNumbers')?.length !== 0" class="w-full space-y-4">
                    <p v-for="item in getCmsContent('footer_otherNumbers')" :key="item" class="text-xs text-white">
                        {{ item.name }} : {{ item.content }}
                    </p>
                </div>
                <div v-else class="w-full space-y-4">
                    <p class="text-xs text-white">Kakao ID : funhanmart(1:1 Customer service)</p>
                    <p class="text-xs text-white">Open chat : <a href="https://open.kakao.com/o/gjdVpYdd" target="_blank" rel="noopener noreferrer">https://open.kakao.com/o/gjdVpYdd</a></p>
                    <p class="text-xs text-white">C.S Num : <a href="tel:0995-677-1867" target="_blank" rel="noopener noreferrer">0995-677-1867</a> / <a href="tel:(02) 8236-4213">(02) 8236-4213</a></p>
                </div>
            </div>
            <div>
                <div class="ftr-title mb-8">
                    <p class="uppercase font-semibold opacity-[48%] text-white text-xs">Categories</p>
                </div>
                
                <div class="lg:flex flex-col gap-4 text-left grid grid-cols-2">
                    <a v-for="category in categories" :key="category" class="text-white font-normal text-xs m-0" :href="redirect(category)">{{ category.name }}</a>
                </div>
            </div>
            <div class="flex flex-col gap-20">
                <div>
                    <div class="ftr-title mb-8">
                        <p class="uppercase font-semibold opacity-[48%] text-white text-xs">Support</p>
                    </div>
                    <div class="lg:flex flex-col gap-4 text-left grid grid-cols-2">
                        <a class="text-white font-normal text-xs m-0" href="/login">Login/Sign Up</a>
                        <a class="text-white font-normal text-xs m-0" href="/faq">FAQs</a>
                        <template v-if="userInfo">
                            <a class="text-white font-normal text-xs m-0" href="/order-history">Track Order</a>
                        </template>
                    </div>
                </div>
                <div>
                    <div class="ftr-title mb-8">
                        <p class="uppercase font-semibold opacity-[48%] text-white text-xs">About</p>
                    </div>
                    <div class="lg:flex flex-col gap-4 text-left grid grid-cols-2">
                        <a class="text-white font-normal text-xs m-0" href="/recipes">Recipes</a>
                        <a class="text-white font-normal text-xs m-0" href="/blogs">Blogs</a>
                        <a class="text-white font-normal text-xs m-0" href="/news">News</a>
                        <a class="text-white font-normal text-xs m-0" href="/about-us">About Fan Han Mart</a>
                        <a class="text-white font-normal text-xs m-0" href="/contact-us">Contact Us</a>
                    </div>
                </div>
            </div>
            <div>
                <div class="ftr-title mb-8">
                    <p class="uppercase font-semibold opacity-[48%] text-white text-xs">Legal</p>
                </div>
                <div class="lg:flex flex-col gap-4 text-left grid grid-cols-2">
                    <a class="text-white font-normal text-xs m-0" href="/terms-and-conditions">Terms of Use</a>
                    <a class="text-white font-normal text-xs m-0" href="/privacy-policy">Privacy</a>
                    <a class="text-white font-normal text-xs m-0" href="/refund-policy">Refund</a>
                </div>
            </div>
        </div>
        <div class="px-4 lg:px-12 py-[36px] lg:py-[19px] flex justify-between items-center w-full bg-primary-900">
            <div class="flex lg:space-x-1 space-y-1 lg:space-y-0 flex-col lg:flex-row">
                <div v-if="cmsContent !== null && getCmsContent('footer_copyright') !== null">
                    <p class="text-xs text-white m-0 font-normal lg:font-thin">{{ getCmsContent('footer_copyright') }}</p>
                </div>
                <div v-else>
                    <p class="text-xs text-white m-0 font-normal lg:font-thin">© 2022 Funhan Mart Corporation. All Rights Reserved.</p>
                </div>
            </div>
            <div class="flex gap-[19px]">
                <a href="https://www.facebook.com/FunHanMartPh/" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/icon/facebook.svg" alt="facebook">
                </a>
                <a href="https://www.instagram.com/funhanmart/?hl=en" target="_blank" rel="noopener noreferrer" >
                    <img src="/assets/icon/instagram.svg" alt="instagram">
                </a>
            </div>
        </div>
    </footer>
</template>
<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import useCms from '@/composables/useCms';
import { useCategoryStore } from '@/store/category';
import { useApiStore } from '@/store/api';
import useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
    setup() {
        const { cms, contentSlug, getCms, getCmsContent, renderCmsImage } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "header_footer";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        // const categories = computed(() => localStorage.getItem('CapacitorStorage.categories'));
        const categoryStore = useCategoryStore();
        const fetchCategoriesUrl = computed(() => apiStore.route('fetch-categories'));
        const categories = computed(() => categoryStore.items);
        const apiStore = useApiStore();
        const { localStorage } = useLocalStorage();


        const userInfo = ref(null);

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */
        const initPage = async()=> {
            const routes = await localStorage.value.get('routes');
            const categories = await localStorage.value.get('categories');

            categoryStore.items = categories;

            if (typeof routes['fetch-products'] === 'string') {
                // fetch();
                fetchCategories(fetchCategoriesUrl.value);
            }
        }

        // Fetch User
        const getUserProfile = () => {
			localStorage.value.get("authToken").then((value) => {
				if (value) {
					localStorage.value.get("user").then((user) => {
						// if (user) {
							userInfo.value = user;
						// }
					});
				}
			});
		};


        //  Fetch Categories
        const fetchCategories =(url)=> {
            window.axios
                .get(url)
                .then(({ data }) => {
                    categoryStore.items = data;
                    localStorage.value.set('categories', data);
                })
        }

        const redirect = (category) => {
            return `/shop?category=${category.code}&subCategory=1`;
        }

        onMounted(() => {
            initPage();
            getContentSlug();
            getUserProfile();
        });

        return {
            cmsContent,
            getCmsContent,
            renderCmsImage,
            categories,
            userInfo,
            redirect
        }
    },
})
</script>
