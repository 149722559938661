<template>
    <div
        v-for="(option, index) in options"
        :key="index"
        class="flex items-center"
    >
        <input
            :id="option.id"
            :name="name"
            type="radio"
            class="
                cursor-pointer
                h-4
                w-4
                text-primary-600
                border-gray-300
                focus:ring-0
                focus:ring-offset-0	
                focus:outline-0
                focus:outline-offset-0	
                checked:border-primary-600
            "
            @change="$emit('update:modelValue', option.value)"
            :checked="modelValue === option.value"
        />
        <label
            :for="option.id"
            class="ml-3 block text-sm text-gray-700"
        >
            {{ option.label }}
        </label>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        options: {
            type: Array,
        },
        name: {
            type: String,
        },
    },
});
</script>
