import { computed, ref } from "vue";
import { useCartStore } from "@/store/cart";
import { useUserStore } from '@/store/user';
import useModal from "@/composables/modal";

export default function useCheckout()
{
    const cart = useCartStore();
    const user = useUserStore();
    
    const modal = useModal();
    const checkoutLoader = ref(false);
    const baseCheckoutPayload = computed(() => ({
        user_id: user.userData?.accountnum,
        // guest_id: user.userData?.accountnum ? "" : (user.guestId || "")
        guest_id: user.guestId,
    }));

    const checkout = (params) => {
        
        const url = cart.checkoutUrl;
        params = Object.assign(baseCheckoutPayload.value, params);
        if(!checkoutLoader.value && typeof url === 'string') {
            checkoutLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    checkoutLoader.value = false;
                    cart.data = data.cart;
                    return data;
                })
                .catch(( error ) => {
                    checkoutLoader.value = false;
                    modal.error(error.response.data.message)
                });
        }
        else {
            return Promise.resolve();
        }
    };

    return {
        checkoutLoader,
        checkout
    }
}