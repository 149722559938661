<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div class="max-w-[1440px] m-auto pt-[72px] px-4 lg:px-12 xl:px-[148px] pb-20">
                <div class="lg:max-w-[704px] m-auto text-center mb-12">
                    <div v-if="cmsContent !== null && getCmsContent('section1_title') !== null">
                        <h1 class="text-[28px] lg:text-[40px] font-semibold mb-4">{{ getCmsContent('section1_title') || 'About Fanhan Mart'}}</h1>
                    </div>
                    <div v-else>
                        <h1 class="text-[28px] lg:text-[40px] font-semibold mb-4">About Fanhan Mart</h1>
                    </div>
                    <div v-if="cmsContent !== null && getCmsContent('section1_description') !== null">
                        <p class="text-gray-500">
                            {{
                                getCmsContent('section1_description') ||
                                'Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.'
                            }}
                        </p>
                    </div>
                    <div v-else>
                        <p class="text-gray-500">Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.</p>
                    </div>
                </div>
                <div v-if="cmsContent !== null && getCmsContent('section1_image') !== null" class="w-full mb-20">
                    <img class="w-full rounded-xl lg:rounded-none" :src="getCmsContent('section1_image') ? renderCmsImage(getCmsContent('section1_image')) : '/assets/images/about.png'" />
                </div>
                <!-- <div v-else class="w-full mb-20">
                    <img class="w-full rounded-xl lg:rounded-none" src="/assets/images/about.png" />
                </div> -->
                <div class="max-w-[1010px] m-auto">
                    <div v-if="cmsContent !== null && getCmsContent('section2_title') !== null">
                        <p class="font-semibold text-lg lg:text-xl text-neutral-600 mb-4">{{ getCmsContent('section2_title') || 'Our Mission'}}</p>
                    </div>
                    <div v-else>
                        <p class="font-semibold text-lg lg:text-xl text-neutral-600 mb-4">Our Mission</p>
                    </div>
                    <div v-if="cmsContent !== null && getCmsContent('section2_description') !== null">
                        <p class="text-neutral-600 font-normal text-xl lg:text-2xl">{{ getCmsContent('section2_description') || 'Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.' }}</p>
                    </div>
                    <div v-else>
                        <p class="text-neutral-600 font-normal text-xl lg:text-2xl">Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.</p>
                    </div>
                </div>
            </div>
            <div class="bg-amber-500">
                <div class="max-w-[1086px] m-auto py-20 lg:py-[173px]">
                    <div
                        v-if="cmsContent !== null && getCmsContent('section2_others').length !== 0"
                        class="lg:gap-[120px] gap-20 px-4 xl:px-12 flex flex-col lg:flex-row text-center justify-center"
                    >
                        <div v-for="item in getCmsContent('section2_others')" :key="item" class="text-center w-full" 
                        :class="getCmsContent('section2_others').length <= 2 ? 'lg:w-[50%]' : 'lg:w-[33.33%]'">
                            <p class="text-white font-semibold text-xl mb-4">
                                {{ item.title }}
                            </p>
                            <p class="text-sm text-white">{{ item.content }}</p>
                        </div>
                    </div>
                    <!-- <div 
                        v-else
                        class="grid grid-cols-1 lg:grid-cols-3 lg:gap-[120px] gap-20 px-4 xl:px-12"
                    >
                        <div class="text-center">
                            <p class="text-white font-semibold text-xl mb-4">
                                Korean Goodies
                            </p>
                            <p class="text-sm text-white">Order your favorite korean foods and snacks through our app.</p>
                        </div>
                        <div class="text-center">
                            <p class="text-white font-semibold text-xl mb-4">
                                Shop Voucher
                            </p>
                            <p class="text-sm text-white">Get more additional discounts and use it upon checking out orders.</p>
                        </div>
                        <div class="text-center">
                            <p class="text-white font-semibold text-xl mb-4">
                                Shop Recipes
                            </p>
                            <p class="text-sm text-white">Learn how to cook your favorite korean food and other recipes in go.</p>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="max-w-[1440px] m-auto px-4 lg:px-12 xl:px-[148px] py-20">
                <div class="space-y-20">
                    <div v-if="cmsContent !== null && getCmsContent('section3_contents').length !== 0" class="space-y-24">
                        <div
                            v-for="item in getCmsContent('section3_contents')"
                            :key="item"
                            class="flex flex-col lg:flex-row items-center about-card space-y-[57px] lg:space-y-0"
                        >
                            <div v-if="item.image" class="w-full lg:w-[53%] card-img">
                                <div class="w-full relative pt-[70%] rounded-xl overflow-hidden">
                                    <img class="w-full h-full absolute inset-0 object-cover" :src="renderCmsImage(item.image)" alt="">
                                </div>
                            </div>
                            <div class="w-full lg:w-[47%] pl-[57px] card-desc">
                                <p class="font-semibold text-2xl mb-4">{{ item.title }}</p>
                                <p>{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-else class="space-y-24">
                        <div class="flex flex-col lg:flex-row items-center about-card space-y-[57px] lg:space-y-0">
                            <div class="w-full lg:w-[53%] card-img">
                                <div class="w-full relative pt-[70%] rounded-xl overflow-hidden">
                                    <img class="w-full h-full absolute inset-0 object-cover" src="/assets/images/about.png" alt="">
                                </div>
                            </div>
                            <div class="w-full lg:w-[47%] pl-[57px] card-desc">
                                <p class="font-semibold text-2xl mb-4">Lorem ipsum dolor sit amet,<br> consectetur elit.</p>
                                <p class="font-semibold text-2xl mb-4">About Fanhan Mart</p>
                                <p>Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.</p>
                            </div>
                        </div>
                        <div class="flex flex-col lg:flex-row items-center about-card space-y-[57px] lg:space-y-0">
                            <div class="w-full lg:w-[53%] card-img">
                                <div class="w-full relative pt-[70%] rounded-xl overflow-hidden">
                                    <img class="w-full h-full absolute inset-0 object-cover" src="/assets/images/about.png" alt="">
                                </div>
                            </div>
                            <div class="w-full lg:w-[47%] pl-[57px] card-desc">
                                <p class="font-semibold text-2xl mb-4">Lorem ipsum dolor sit amet,<br> consectetur elit.</p>
                                <p class="font-semibold text-2xl mb-4">About Fanhan Mart</p>
                                <p>Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.</p>
                            </div>
                        </div>
                        <div class="flex flex-col lg:flex-row items-center about-card space-y-[57px] lg:space-y-0">
                            <div class="w-full lg:w-[53%] card-img">
                                <div class="w-full relative pt-[70%] rounded-xl overflow-hidden">
                                    <img class="w-full h-full absolute inset-0 object-cover" src="/assets/images/about.png" alt="">
                                </div>
                            </div>
                            <div class="w-full lg:w-[47%] pl-[57px] card-desc">
                                <p class="font-semibold text-2xl mb-4">Lorem ipsum dolor sit amet,<br> consectetur elit.</p>
                                <p class="font-semibold text-2xl mb-4">About Fanhan Mart</p>
                                <p>Fun Han Mart is a new and innovative Korean Convenience store that aims to undertake both the traditional and online market by offering a wide variety of Korean Products for Filipino consumption.</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import { onMounted, ref } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import useCms from '@/composables/useCms';

export default defineComponent({
    name: 'HomePage',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
       
    },
    setup() {
        const router = useRouter();
        const { cms, contentSlug, getCms, getCmsContent, renderCmsImage } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "about";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        onMounted(() => {
            getContentSlug();
        });

        return {
            router,
            cmsContent,
            getCmsContent,
            renderCmsImage
        }
    },
});
</script>
