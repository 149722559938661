<template>
    <div>
        <div class="w-full mb-4">
            <swiper
                :thumbs="{ swiper: thumbsSwiper }"
                :modules="modules"
                effect="fade"
                class="product-slider-main"
            >
                <swiper-slide

                v-for="(item,index) in items"
                :key="index"
                class="rounded-[20px] overflow-hidden border border-neutral-200">
                    <div class="relative w-full pt-[100%]">
                        <div
                        class="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover"
                        :style="{ backgroundImage: 'url(' + item.path + ')'}"></div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <!-- hide if image.length == 1 -->
        <div class="w-full" v-if="items.length > 1">
            <swiper
                @swiper="setThumbsSwiper"
                :spaceBetween="16"
                :slidesPerView="5"
                :watchSlidesProgress="true"
                :modules="modules"
                class="product-slider-thumb"
            >
                <swiper-slide
                v-for="(item,index) in items"
                :key="index"
                class="rounded-[10px] overflow-hidden product-slider-item">
                    <div class="relative w-full pt-[100%]">
                        <div
                        class="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover"
                        :style="{ backgroundImage: 'url(' + item.path + ')'}"></div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script type="text/javascript">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/thumbs"
import 'swiper/css/effect-fade';

// import required modules
import { Thumbs, EffectFade } from 'swiper';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array,
            default: null
        }
    },
    setup() {

        const thumbsSwiper = ref(null);

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };

        return {
            thumbsSwiper,
            setThumbsSwiper,
            
            modules: [
                Thumbs,
                EffectFade
            ]
        };
    }
});
</script>