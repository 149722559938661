<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <banner-slider />
            <div class="px-4 lg:px-12 pb-20 w-full">
                <div class="flex lg:items-center flex-col lg:flex-row justify-between mb-6">
                    <p class="text-2xl lg:text-[32px] font-semibold m-0 mb-4 lg:m-0">
                        Recipes
                    </p>
                    <div>
                        <SelectMenu
                            id="selector-id"
                            name="selector-name"
                            placeholder="Sort By: Best Match"
                            :options="sortOptions"
                            v-model="sortBy"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-6 lg:gap-8 mb-16 lg:mb-20">
                    <div v-for="(item) in displayedRecipes" :key="item.id">
                        <recipe-card :recipe="item"></recipe-card>
                    </div>
                    
                </div>
                <!-- Pagination -->
                <div v-if="filteredRecipes.total > filteredRecipes.per_page" class="flex items-center gap-4 m-auto pagination w-fit">
                    <pagination 
                        :page="page"
                        :items="filteredRecipes"
                        @paginate="(value) => paginate(value)"
                        @previousPage="(value) => previousPage(value)"
                        @nextPage="(value) => nextPage(value)"
                    />
                </div>                                
                <div v-if="filteredRecipes.total == 0">
                    <p class="text-sm font-normal text-center text-gray-900">No recipes available.</p>
                </div>
                <!-- <pagination :items="recipes"></pagination> -->
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
    // onIonViewWillEnter,
} from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import RecipeCard from '@/components/cards/RecipeCard.vue';
import Pagination from '@/components/Pagination.vue';
import BannerSlider from '@/components/sliders/BannerSlider.vue';
import { useApiStore } from "@/store/api";
import useModal from "@/composables/modal";
import SelectMenu from "@/components/inputs/SelectMenu.vue";

export default defineComponent({
    name: 'RecipeIndex',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        RecipeCard,
        // Pagination,
        BannerSlider,
        SelectMenu,
        Pagination
    },
    setup() {

        const recipes = ref([]);
        const apiStore = useApiStore();
        const modal = useModal();
        const sortBy = ref();


        // PAGINATION
        const page = ref(1);
        const pages = ref([]);
        const filteredRecipes = computed(() => {
            const items = ref(recipes.value);
            return items.value || [];
        });

        const displayedRecipes = computed(() => {
            return filteredRecipes.value.data;
        });

        //  Paginate Products
        const paginate =(pageNumber)=> {

            page.value = pageNumber;

            fetch({
                page: pageNumber,
                sort: sortBy.value
            });
        }

        const previousPage = (currentPage = 1) => {
            if (currentPage > 1) {
                page.value = currentPage - 1;
                fetch({
                    page: page.value,
                    sort: sortBy.value
                });
            }                        
        }

        const nextPage = (currentPage = 1) => {
            if (currentPage < pages.value.length) {
                page.value = currentPage + 1;
                fetch({
                    page: page.value,
                    sort: sortBy.value
                });
            }            
        }

        //  Set Number of Pages for Pagination
        const setPages =(items)=> {
            let numberOfPages = items.last_page;

            for (let i = 1; i <= numberOfPages; i++) {
                pages.value.push(i);
            }
        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => filteredRecipes.value,
            (items) => {
                setPages(items);
            }
        );

        const fetch = (params) => {
            window.axios
                .get(apiStore.route("fetch-recipes"), { params: params })
                .then(({ data }) => {
                    recipes.value = data.recipes;
                    pages.value = [];
                })
                .catch((error) => {
                    modal.error(error.response.data.message);
                })
        };

        onMounted(() => {
            fetch();
        })

        const slides = [
            { link: '/home', banner: '/assets/images/banner3.png' },
            { link: '/home', banner: '/assets/images/banner2.png' },
        ]

        const sortOptions = [
            { id: '1', label: 'Best Match', value: 'best-match'},
            { id: '2', label: 'Recent', value: 'recent'},
            { id: '3', label: 'High Price', value: 'high-price'},
            { id: '4', label: 'Low Price', value: 'low-price'},
        ]

        watch(
            () => sortBy.value,
            (value) => {
                if (value) {
                    fetch({
                        sort: value,
                        page: page.value
                    });
                }
            }
        )
        
        const router = useRouter();

        return {
            router,
            slides,
            sortOptions,
            recipes,
            modal,
            sortBy,
            displayedRecipes,
            pages,
            page,
            setPages,
            filteredRecipes,
            paginate,
            previousPage,
            nextPage
        }
    },
});
</script>
