<template>
    <div class="relative flex items-center">
        <div class="flex items-center h-4">
            <input
                :id="id"
                type="checkbox"
                class="
                    cursor-pointer
                    h-4
                    w-4
                    text-amber-400
                    border-gray-300
                    rounded
                    focus:ring-0
                    focus:ring-offset-0	
                    focus:outline-0
                    focus:outline-offset-0	
                    checked:border-amber-400
                "
                :class="
                    [
                        green 
                        ? 'text-green-500 checked:border-green-500'
                        : primary 
                        ? 'text-primary-500 checked:border-primary-500'
                        : 'text-amber-400 checked:border-amber-400'
                    ]
                "
                :checked="checked"
                @change="$emit('update:modelValue', $event.target.checked)"
            />
        </div>
        <template v-if="defaultSlot">
            <div class="ml-3 text-sm">
                <label
                    :for="id"
                    class="cursor-pointer text-primary-900 text-sm"
                    :class="
                        [
                            green 
                            ? 'font-normal'
                            : ' ',
                            gray 
                            ? 'text-[#BDBDBD]'
                            : ' '
                        ]
                    "
                >
                    {{ label }}
                </label>
            </div>
        </template>
        <slot v-else></slot>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: 'CheckboxInput',
    props: {
        defaultSlot: {
            type: Boolean,
            default: true,
        },
        green: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        gray: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            default: null,
            required: true,
        },
        id: {
            type: [String, Number],
        },
        label: {
            type: String,
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
});
</script>
