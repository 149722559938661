<template>
<ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div id="toTop" class="absolute offset-description -top-[200px]"></div>
            <div class="px-4 pt-12 pb-[120px] lg:pl-[100px] lg:pr-[68px] lg:py-20 w-full max-w-[1440px] m-auto relative">
                <div class="mb-[33px]">
                    <div v-if="cmsContent !== null && getCmsContent('section1_title') !== null">
                        <p class="text-[28px] lg:text-[40px] font-bold text-primary-500 m-0">{{ getCmsContent('section1_title') || 'Shop'}}</p>
                    </div>
                    <div v-else>
                        <p class="text-[28px] lg:text-[40px] font-bold text-primary-500 m-0">Shop</p>
                    </div>
                </div>
                <div @click="toggleFilter" class="flex items-center mb-6 space-x-2 lg:hidden">
                    <FilterIcon class="w-6 h-6 text-primary-500" />
                    <p class="m-0 text-xl font-normal text-amber-400">Filter</p>
                </div>
                <div class="flex flex-wrap">
                    <div class="fixed top-[158px] translate-y-6 lg:translate-y-0 left-0 lg:top-auto lg:left-auto lg:relative product-filter min-w-[19%] bg-white z-20 w-full lg:w-[19%] p-6 lg:p-0 h-screen transition duration-200 ease-in-out"
                        :class="[ isOpen ? 'translate-x-0' : '-translate-x-[100%] lg:translate-x-0' ]"
                    >
                        <div class="flex items-center justify-between mb-6">
                            <p class="m-0 text-2xl font-normal text-amber-400">Filter</p>
                            <XIcon @click="toggleFilter" class="w-6 h-6 lg:hidden" />

                        </div>
                        <hr class="mb-6 lg:hidden">
                        <ion-accordion-group :multiple="true" :value="['first']">
                            <ion-accordion value="first">
                                <ion-item slot="header" color="white">
                                    <div class="flex items-center justify-between">
                                        <p class="m-0 text-xl font-normal">CATEGORIES</p>
                                        <!-- <ChevronDownIcon class="w-5 h-5 text-primary-500" /> -->
                                    </div>
                                </ion-item>
                                <div class="pt-4" slot="content">
                                    <div class="grid grid-cols-2 gap-10 lg:grid-cols-1 lg:gap-4">
                                        <template v-for="category in categories" :key="category">
                                            <div class="flex items-center h-4">
                                                <input
                                                    :id="`cat-${category.id}`"
                                                    type="radio"
                                                    name="Categories"
                                                    :value="category.code"
                                                    class="w-4 h-4 border-gray-300 rounded cursor-pointer text-amber-400 focus:ring-0 focus:ring-offset-0 focus:outline-0 focus:outline-offset-0 checked:border-amber-400"
                                                    v-model="checkedCategory"
                                                    @click="check($event)"
                                                />
                                                <label
                                                    :for="`cat-${category.id}`"
                                                    class="ml-3 text-sm cursor-pointer text-primary-900"
                                                >
                                                    {{ category.name }}
                                                </label>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </ion-accordion>
                        </ion-accordion-group>
                        
                        
                    </div>
                    <div class="w-full lg:w-[81%] ml-auto">
                        <div class="flex justify-between lg:space-y-2 xl:space-y-0 xl:items-end mb-[29px] flex-col xl:flex-row">
                            <div class="w-full overflow-auto scrollbar-style-horizontal">
                                <div class="w-max">
                                    <div class="flex space-x-[10px] mb-12 lg:mb-0">
                                        <div 
                                            v-for="tab in tabs"
                                            :key="tab"
                                            @click="toggleTab(tab.code)"
                                            class="text-sm px-4 lg:px-5 py-[7px] lg:py-4 rounded-[20px] lg:rounded-lg border cursor-pointer capitalize"
                                            :class="[ openTab == tab.code ? 'bg-primary-500 text-white' : 'border-primary-500 text-primary-500' ]"
                                        >
                                            {{ tab.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lg:absolute lg:top-20 lg:right-[68px] z-10 mb-6 lg:mb-0">
                                <IonSelect
                                    :interfaceOptions="{ 
                                        size: 'cover'
                                    }" 
                                    placeholder="Sort By: --"
                                    interface="popover" 
                                    mode="md"
                                    class="bg-white rounded-[10px] border w-full md:w-[250px]"
                                    v-model="sortBy"
                                >
                                    <IonSelectOption
                                        v-for="(sort, index) in sortOptions" 
                                        :key="index"
                                        :value="sort.id"
                                    >
                                        {{ sort.name }}
                                    </IonSelectOption>
                                </IonSelect>
                            </div>
                            
                            
                        </div>
                        
                        <div v-if="isLoading" class="flex items-center justify-center w-full h-full">
                            <img src="/assets/images/loader.gif" class="h-auto w-14" />
                        </div>
                        <template v-else>
                            <div class="w-full"
                            v-if="router.currentRoute.value.query.search" 
                            >
                                <p 
                                    
                                    class="m-0 text-sm font-bold text-gray-400 text-right mb-5"
                                >
                                    {{ filteredProducts?.length }} items under “{{ router.currentRoute.value.query.search }}”
                                </p>
                            </div>

                            <div class="grid md:grid-cols-4 grid-cols-2 gap-x-5 gap-y-[34px] mb-20">
                                <div v-for="(item,index) in displayedProducts" :key="index">
                                    <product-card
                                    :product="item"
                                    :key="item.id"
                                    ></product-card>
                                </div>
                            </div>         
                            <div v-if="displayedProducts?.length == 0">
                                <p class="text-sm font-normal text-center text-gray-900 mb-5">No products available.</p>
                            </div>                   
                            <!-- Pagination -->
                            <div v-if="filteredProducts.total > filteredProducts.per_page" class="flex items-center justify-center space-x-4">
                                <pagination 
                                    :page="page"
                                    :items="filteredProducts"
                                    @paginate="(value) => paginate(value)"
                                    @previousPage="(value) => previousPage(value)"
                                    @nextPage="(value) => nextPage(value)"
                                />
                            </div>                           
                        </template>
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>

</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { IonAccordion, IonAccordionGroup, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { XIcon } from "@heroicons/vue/solid";
import { FilterIcon } from "@heroicons/vue/outline";
import { useCategoryStore } from '@/store/category';
import { useApiStore } from '@/store/api';
import useProduct from '@/composables/useProduct';
import useLocalStorage from '@/composables/useLocalStorage';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ProductCard from '@/components/cards/ProductCard.vue';
import useCms from '@/composables/useCms';
// import { useUserStore } from '@/store/user';
import useModal from "@/composables/modal";
import Pagination from "@/components/Pagination";

export default defineComponent({
    name: 'ShopIndex',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        ProductCard,
        IonAccordion, 
        IonAccordionGroup, 
        IonItem,
        IonSelect,
        IonSelectOption,
        // ChevronLeftIcon,
        // ChevronRightIcon,
        FilterIcon,
        XIcon,
        Pagination
    },
    setup() {

        const router = useRouter();
        const route = useRoute();
        const apiStore = useApiStore();
        const categoryStore = useCategoryStore();
        const { localStorage } = useLocalStorage();
        const { baseCatalogPayload, fetchProducts } = useProduct();
        const modal = useModal();
        // const user = useUserStore();

        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */
        const isLoading = ref(true);
        const openTab = ref(1);
        const products = ref([]);
        const page = ref(parseInt(router.currentRoute.value.query.page) || 1);
        // const perPage = ref(16);
        const pages = ref([]);
        const checkedCategory = ref(null);
        const subcategories = ref([]);

        /* Filters */
        const sortOptions = ref([
            { id: 'none', name: 'Sort by: --'},
            { id: 'recent', name: 'Recent'},
            { id: 'high-price', name: 'High Price'},
            { id: 'low-price', name: 'Low Price'},
        ]);
        const sortBy = ref(route.query.sort || sortOptions.value[0].id);

       /*
        |--------------------------------------------------------------------------
        | @Computed
        |--------------------------------------------------------------------------
        */
        const fetchCategoriesUrl = computed(() => apiStore.route('fetch-categories'));
        const categories = computed(() => categoryStore.items);
        
        const tabs = computed(() => [{ code:'all', name: 'All' }].concat(subcategories.value || []));

        const filteredProducts = computed(() => {
            const items = ref(products.value);
            return items.value || [];
        });

        const fetchPayload = computed(() => {
            // if(user.userNearestBranch != null) {
                return Object.assign(baseCatalogPayload.value);
            // }

        });

        const displayedProducts = computed(() => {
            return filteredProducts.value.data;
        });

        const queryCategory = computed(() => router.currentRoute.value.query.category || '');
        const querySubCategory = computed(() => router.currentRoute.value.query.subCategory || '');

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */
        const initPage = async()=> {
            const routes = await localStorage.value.get('routes');
            const categories = await localStorage.value.get('categories');

            categoryStore.items = categories;
            getCategoriesAndTabs(queryCategory.value);
            openTab.value = querySubCategory.value;
            restrictionPromptMessage();
            if (typeof routes['fetch-products'] === 'string') {

                const search = router.currentRoute.value.query.search;
                fetch({
                    page: page.value,
                    sort: sortBy.value,
                    tab: openTab.value,
                    category_ids: checkedCategory.value,
                    search: search,
                });
                fetchCategories(fetchCategoriesUrl.value);
            }
        }

        const restrictionPromptMessage = () => {
            if (subcategories.value?.length > 0) {
                subcategories.value.filter((item) => {
                    if (item.code == querySubCategory.value && item.restrict_to_minor) {
                        modal.error(process.env.VUE_APP_RESTRICTION_MESSAGE);
                        return;
                    }
                });

                // if (querySubCategory.value == 'all') {
                //     subcategories.value.filter((item) => {
                //         if (item.restrict_to_minor) {
                //             modal.error(process.env.VUE_APP_RESTRICTION_MESSAGE);
                //             return;
                //         }
                //     });
                // }
            }
        }

        //  Fetch Products
        const fetch = async (params)=> {

            // const branch = await localStorage.value.get("nearestBranch")
            // console.log(branch);
            // if (branch != null) {
                fetchProducts({ ...fetchPayload.value, ...params})
                    .then(items => {
                        products.value = items;
                        localStorage.value.set('products', items);
                        isLoading.value = false;
                    })
            // }
        }

        //  Fetch Categories
        const fetchCategories =(url)=> {
            window.axios
                .get(url)
                .then(({ data }) => {
                    categoryStore.items = data;
                    localStorage.value.set('categories', data);
                })
        }

        //  Paginate Products
        const paginate =(pageNumber)=> {
            resetValues();
            page.value = pageNumber;
            renderFetch(pageNumber);

        }

        const previousPage = (currentPage = 1) => {
            if (currentPage > 1) {
                resetPages();
                page.value = currentPage - 10;
                renderFetch(page.value);   
            }                     
        }

        const nextPage = (currentPage = 1) => {
            if (currentPage < pages.value.length) {
                resetPages();
                page.value = currentPage + 10;
                renderFetch(page.value);
            }            
        }

        const renderFetch = (page, removeSearch = false) => {
            const search = router.currentRoute.value.query.search;
            router.push({ path: 'shop', query: {
                search: search || '',
                category: queryCategory.value || '',
                subCategory: querySubCategory.value || '',
                page: page || '',
                sort: sortBy.value || ''
            }});
            isLoading.value = true;
            openTab.value = querySubCategory.value;            
            fetch({
                page: page,
                sort: sortBy.value,
                tab: openTab.value,
                category_ids: checkedCategory.value,
                search: removeSearch ? null : search,
            });
            // window.scrollTo(0,0);
            document.getElementById("toTop").scrollIntoView();
        }

        //  Set Number of Pages for Pagination
        const setPages =(items)=> {
            let numberOfPages = items.last_page;

            for (let i = 1; i <= numberOfPages; i++) {
                pages.value.push(i);
            }
        }

        //  Reset Values
        const resetValues =(retainTab = false)=> {
            if(!retainTab) {
                openTab.value = 'all';
            }            
            page.value = 1;
            resetPages();
        }

        const resetPages = () => {
            pages.value = [];
        }

        // Toggle Category Tabs
        const toggleTab =(tab)=> {
            if (tab == openTab.value) {
                return;
            }
            openTab.value = tab;
            page.value = 1;
            isLoading.value = true;
            router.replace({ path: '/shop', query: { category: checkedCategory.value, subCategory: tab}});            
            // renderFetch(1);            
        }

        //  Check Radio Buttons
        const check =(event)=> {
            if (event.target.value === queryCategory.value) {
                return;
            }

            resetValues();
            getCategoriesAndTabs(event.target.value);
            // renderFetch(1, true);
            page.value = 1;
            isLoading.value = true;
            router.push({ path: '/shop', query: { category: checkedCategory.value, subCategory: openTab.value}});
        }

        const getCategoriesAndTabs = (categoryCode) => {
            if(!categoryCode) return false;
            if (categories.value) {
                let parent = categories.value.find(x => x.code == categoryCode);
                if (parent) {
                    subcategories.value = parent.subcategories;
                }
            }
            checkedCategory.value = categoryCode;            
        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => filteredProducts.value,
            (items) => {
                setPages(items);
            }
        );

        watch(
            () => fetchCategoriesUrl.value,
            (url) => {
                if (typeof url === 'string') {
                    fetchCategories(url);
                }
            },
            { immediate: true }
        );

        watch(
            () => sortBy.value,
            () => {
                resetValues(true);
                renderFetch(1);
                page.value = 1;
            }
        );

        watch(
            () => queryCategory.value,
            (val) => {
                sortBy.value = 'none';
                resetValues();
                initPage();
                getCategoriesAndTabs(val);
                openTab.value = querySubCategory.value;
            }
        );

        watch(
            () => querySubCategory.value,
            (val) => {
                sortBy.value = 'none';
                resetValues();
                initPage();
                getCategoriesAndTabs(queryCategory.value);
                openTab.value = val;
            }
        );

        /*
        |--------------------------------------------------------------------------
        | @CMS
        |--------------------------------------------------------------------------
        */
        const { cms, contentSlug, getCms, getCmsContent } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "shop";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }
        
        onMounted(() => { 
            initPage();
            getContentSlug();

            window.onpopstate = () => {
                page.value = route.query.page;
                sortBy.value = route.query.sort;
                openTab.value = route.query.subCategory;
                checkedCategory.value = route.query.category
                queryCategory.value = route.query.category;
                querySubCategory.value = route.query.subCategory;
                initPage();
            }
        });

        return {
            router,
            sortOptions,
            sortBy,
            isLoading,
            filteredProducts,
            categories,
            tabs,
            displayedProducts,
            pages,
            page,
            setPages,
            checkedCategory,
            toggleTab,
            openTab,
            check,
            subcategories,
            cmsContent,
            getCmsContent,
            paginate,
            previousPage,
            nextPage,
            products
        }
    },
});
</script>
