<template>
    
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">

            <div class="px-6 lg:px-0 w-full max-w-[1024px] mx-auto">

                <div class="my-16">
                    <div class="w-full h-0 aspect-w-16 aspect-h-9 rounded-xl">
                        <img :src="item.formatted_image" class="rounded-xl object-cover"/>
                    </div>
                    <div class="mt-6 space-x-2 flex items-center">
                        <p class="text-sm m-0 text-neutral-500">Share on:</p>
                        <a 
                        class="block h-6"
                        :href="`${'https://www.facebook.com/sharer/sharer.php?u=https://funhanmart-prod-share.praxxys.ph/news/view/'+item.id}`" 
                        rel='nofollow' 
                        target='_blank'>
                            <ion-icon class="w-6 h-6 text-[#3b5998]" src="/assets/icon/share-fb.svg"/>
                        </a>
                        <a 
                        class="block h-6"
                        :href="`${'https://twitter.com/intent/tweet?url=https://funhanmart-prod-share.praxxys.ph/news/view/'+item.id+'&text='+item.title}`" 
                        rel='nofollow' 
                        target='_blank'>
                            <ion-icon class="w-6 h-6 text-[#00acee]" src="/assets/icon/share-twitter.svg"/>
                        </a>
                    </div>
                    <div class="mt-6">
                        <h3 class="font-semibold text-2xl text-gray-900">{{ item.title }}</h3>
                        <div class="mt-6" v-html="item.content"></div>
                    </div>
                </div>
                
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>

</template>
<script>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { IonPage, IonContent } from '@ionic/vue';
import { useApiStore } from '@/store/api';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';

export default {
    name: 'NewsView',
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
    },
    setup() {
        const apiStore = useApiStore();
        const route = useRoute();
        const item = ref([]);
        const url = window.location.href;

        const fetchView =()=> {
            window.axios
                .get(apiStore.route('show-news', { id: route.params.id}))
                .then((result) => {
                    item.value = result.data.item;

                    // meta title
                    document.getElementsByTagName('title')[0].innerHTML = item.value.title;
                    document.querySelectorAll('meta[name=twitter\\:title]')[0].setAttribute('content', item.value.title)
                    document.querySelectorAll('meta[name=og\\:title]')[0].setAttribute('content', item.value.title)

                    // meta description
                    document.querySelectorAll('meta[name=description]')[0].setAttribute('content', item.value.snippet)
                    // document.querySelectorAll('meta[property=og\\:description]')[0].setAttribute('content', item.value.snippet)

                    var meta = document.createElement('meta');
                    meta.setAttribute('property', 'og:description');
                    meta.setAttribute('content', item.value.snippet);
                    document.getElementsByTagName('head')[0].appendChild(meta);

                    var metaImg = document.createElement('meta');
                    metaImg.setAttribute('property', 'og:image');
                    metaImg.setAttribute('content', item.value.formatted_image);
                    document.getElementsByTagName('head')[0].appendChild(metaImg);

                    var metaLink = document.createElement('meta');
                    metaLink.setAttribute('property', 'og:url');
                    metaLink.setAttribute('content', url);
                    document.getElementsByTagName('head')[0].appendChild(metaLink);

                    // meta image
                    // document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', item.value.formatted_image)
                })
                .catch((errors) => {
                    console.log(errors);
                });
        };

        onMounted(() => {
            fetchView();
        });

        return {
            item,
            url
        }
    }
}
</script>
