<template>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-0 text-center">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 translate-y-0 scale-95" enter-to="opacity-100 translate-y-0 scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 scale-100" leave-to="opacity-0 translate-y-4 translate-y-0 scale-95">
                        <DialogPanel class="relative bg-white rounded-[10px] lg:px-4 pt-10 pb-10 text-left overflow-hidden shadow-xl transform transition-all my-8 w-full lg:w-fit">
                            <div class="w-full lg:w-[405px]">
                                <div class="px-4 lg:px-0 pt-0">
                                    <ArrowLeftIcon @click="closeThis" class="w-6 h-6 mb-6 lg:hidden" />
                                    <h3 class="lg:px-4 text-2xl text-gray-500 font-semibold">Use Voucher</h3>
                                </div>
                                
                                <div class="space-y-4 h-[510px] overflow-y-auto scrollbar-style mt-6 p-4 scroll-custom">
                                    <VoucherCard
                                        v-for="(voucher,index) in vouchers" :key="`voucher-${index}`"
                                        :item="voucher"
                                        :name="voucher.name"
                                        :discount-value="voucher.description"
                                        :expires-at="voucher.expires_at"
                                        @click="apply(voucher.code)"
                                    />
                                </div>
                                <div class="mt-6 px-4">
                                    <ButtonComponent
                                        size="default"
                                        color="primary"
                                        fill="outline"
                                        expand="block"
                                        @click="closeThis"
                                    >
                                        <template #content>
                                            <span>Cancel</span>
                                        </template>
                                    </ButtonComponent>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
<script>
import { onMounted, ref, watch, computed } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

import VoucherCard from '@/components/cards/VoucherCard.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import useVoucher from '@/composables/useVoucher';
import useResponseHandler from '@/composables/useResponseHandler';
import { onIonViewWillEnter } from '@ionic/vue';
import useProduct from '@/composables/useProduct';
import useLocalStorage from "@/composables/useLocalStorage.js";

export default {
    name: 'VoucherModal.vue',
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        VoucherCard,
        ButtonComponent,
        ArrowLeftIcon
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const closeThis =()=> open.value = false;

        const { canFetchVouchers, fetchVouchers, applyVoucher } = useVoucher();
        const { successHandler } = useResponseHandler();
        const { baseCatalogPayload } = useProduct();
        const { localStorage } = useLocalStorage();

        const fetchPayload = computed(() => {
            return Object.assign(baseCatalogPayload.value);
        });

        const vouchers = ref([]);

        const init = async () => {
            const cart = await localStorage.value.get("cart");
            if(canFetchVouchers.value) {
                let cartId = {cart_id: cart.id};
                fetchVouchers({...fetchPayload.value, ...cartId})
                    .then(data => {
                        vouchers.value = data;
                    });
            }
        };

        const apply = (code) => {
            applyVoucher({ code: code })
                .then(message => {
                    if(typeof message === 'string') {
                        successHandler({
                            type: 'toast',
                            description: message,
                            message,
                            duration: 2000,
                            autoClose: true
                        });
                    }
                    emit("close");
                });
        };

        watch(
            () => props.isVisible,
            (bool) => {
                open.value = bool
                if(bool) {
                    init();
                }                
            }, {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        onMounted(init);
        onIonViewWillEnter(init);

        return {
            open,
            closeThis,
            apply,
            vouchers
        }
    }
}
</script>