<template>
    <profile-layout :title="'Order History'">
        <div class="mb-11">
            <p class="text-2xl font-semibold text-neutral-600 mb-12 lg:block hidden">Order History</p>
            <div class="w-full overflow-auto scroll-custom">
                <div class="w-max">
                    <div @click="selectStatus('all')"
                        :class="selectedStatus === 'all' ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            All
                        </div>
                    </div>
                    <div @click="selectStatus(orderStatusList.PENDING)"
                        :class="selectedStatus === orderStatusList.PENDING ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            To Pay
                            <count-badge :count="getItemCount(orderStatusList.PENDING)"></count-badge>
                        </div>
                    </div>
                    <div @click="selectStatus(orderStatusList.CONFIRMED)"
                        :class="selectedStatus === orderStatusList.CONFIRMED ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            To Ship
                            <count-badge :count="getItemCount(orderStatusList.CONFIRMED)"></count-badge>
                        </div>
                    </div>
                    <div @click="selectStatus(orderStatusList.ASSIGNED)"
                        :class="selectedStatus === orderStatusList.ASSIGNED ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            To Receive
                            <count-badge :count="getItemCount(orderStatusList.ASSIGNED)"></count-badge>
                        </div>
                    </div>
                    <div @click="selectStatus(orderStatusList.COMPLETED, true)"
                        :class="selectedStatus === orderStatusList.COMPLETED && toRate ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            To Rate
                            <count-badge :count="getItemCount(orderStatusList.COMPLETED, true)"></count-badge>
                        </div>
                    </div>
                    <div @click="selectStatus(orderStatusList.COMPLETED, false, true)"
                        :class="selectedStatus === orderStatusList.COMPLETED && !toRate ? 'bg-primary-500 text-white' : 'text-primary-500'"
                        class="border border-primary-500 font-normal rounded-lg lg:px-5 lg:py-4 px-4 py-3 w-fit text-sm inline-block mr-2">
                        <div class="flex items-center">
                            Completed
                            <count-badge :count="getItemCount(orderStatusList.COMPLETED, false, true)"></count-badge>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-10">
            <div v-if="selectedStatus === 'all'">
                <order-card
                    class="mb-1.5"
                    v-for="invoice in invoices"
                    :key="`invoice-${invoice.id}`"
                    :item="invoice"
                    @viewOrder="viewOrder"
                ></order-card>
            </div>
            <div v-else>
                <order-card
                    class="mb-1.5"
                    v-for="invoice in filteredInvoices"
                    :key="`invoice-${invoice.id}`"
                    :item="invoice"
                    @viewOrder="viewOrder"
                ></order-card>
            </div>
        </div>
    </profile-layout>
</template>

<script type="text/javascript">
import { onIonViewWillEnter } from '@ionic/vue';
import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useSettingsStore } from '@/store/settings';
import useInvoice from '@/composables/useInvoice';
import ProfileLayout from "./ProfileLayout.vue";
import CountBadge from '@/components/CountBadge.vue';
import OrderCard from './components/OrderCard.vue';

export default defineComponent({
    name: 'OrderHistoryIndex',
    components: {
        ProfileLayout,
        CountBadge,
        OrderCard
    },
    setup() {

        const router = useRouter();
        const settings = useSettingsStore();

        const { fetchInvoicesUrl, fetchInvoices, fetchInvoiceCountsUrl, fetchInvoicesCount } = useInvoice();

        const counter = ref([]);
        const invoices = ref([]);
        const orderStatusList = computed(() => settings.orderStatuses);

        const selectedStatus = ref('all');
        const toRate = ref(false);
        const completed = ref(false);

        const initInvoices = () => {
            if(typeof fetchInvoicesUrl.value === 'string') {
                fetchInvoices()
                    .then(data => {
                        invoices.value = data;
                        initInvoicesCount();
                    });
                
            }
        };

        const initInvoicesCount = () => {
            if(typeof fetchInvoiceCountsUrl.value === 'string') {
                fetchInvoicesCount()
                    .then(data => {
                        counter.value = data;
                    });
            }
        }

        const getItemCount = (status, toRateTab = false, completedTab = false) => {
            if (toRateTab) {
                return invoices.value.filter(x => x.order_status == status && x.reviews.length == 0 && x.delivery_type != 2).length;
            } else if (completedTab) {
                return invoices.value.filter(x => x.order_status == status ).length;
            } else {
                return counter.value.length === undefined ? 0 : counter.value?.find(x => x.id == status)?.count;
            }
        };

        const viewOrder = (showUrl) => {
            // router.push({ path:'/order-history/item', query:{ showUrl } });
            // window.location.href = '/order-history/item?showUrl=' + showUrl;
            window.location.href = `/order-history/item/${showUrl}`;
        };

        const selectStatus = (status, toRateTab = false, completedTab = false) => {
            selectedStatus.value = status;
            toRate.value = toRateTab;
            completed.value = completedTab;
        }

        const filteredInvoices = computed(() => {
            if(selectedStatus.value != orderStatusList.value.ASSIGNED) {
                if (selectedStatus.value == orderStatusList.value.COMPLETED && toRate.value) {
                    return invoices.value.filter(x => x.order_status == selectedStatus.value && x.reviews.length == 0 && x.delivery_type != 2);
                } else if (selectedStatus.value == orderStatusList.value.COMPLETED && completed.value) {
                    return invoices.value.filter(x => x.order_status == selectedStatus.value );
                } else {
                    return invoices.value.filter(x => x.order_status == selectedStatus.value);
                }
            }
            else {
                return invoices.value.filter(invoice => {
                    return [
                        orderStatusList.value.ASSIGNED,
                        orderStatusList.value.READY,
                        orderStatusList.value.IN_TRANSIT
                    ].includes(invoice.order_status);
                });
            }
        });

        watch(
            () => fetchInvoiceCountsUrl.value,
            (url) => {
                if(typeof url === 'string') {
                    fetchInvoicesCount()
                    .then(data => {
                        counter.value = data;
                    });
                }
            }
        );

        watch(
            () => fetchInvoicesUrl.value,
            (url) => {
                if(typeof url === 'string') {
                    fetchInvoices()
                        .then(data => {
                            invoices.value = data;
                        });
                }
            }
        );

        onMounted(() => {
            initInvoices();
        });
        onIonViewWillEnter(() => {
            initInvoices();
        });

        return {
            router,
            orderStatusList,
            getItemCount,
            invoices,
            selectedStatus,
            selectStatus,
            filteredInvoices,
            viewOrder,
            toRate,
            counter
        }
    },
});
</script>
