<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div class="max-w-full md:max-w-[468px] px-4 md:px-0 m-auto py-12 lg:py-[72px] contact">
                <div class="mb-12 text-center">
                    <div v-if="cmsContent !== null && getCmsContent('section1_title') !== null">
                        <h1 class="text-[28px] lg:text-[40px] font-semibold mb-4">{{ getCmsContent('section1_title') || 'Get in touch!'}}</h1>
                    </div>
                    <div v-else>
                        <h1 class="text-[28px] lg:text-[40px] font-semibold mb-4">Get in touch!</h1>
                    </div>
                    <div v-if="cmsContent !== null && getCmsContent('section1_description') !== null">
                        <p class="text-neutral-600">{{ getCmsContent('section1_description') || 'Email us at support@funhanmart.com for any concerns or questions.'}}</p>
                    </div>
                    <div v-else>
                        <p class="text-neutral-600">Email us at support@funhanmart.com for any concerns or questions.</p>
                    </div>
                </div>
                <form action="">
                    <div class="mb-4">
                        <text-input
                            v-model="form.fullname"
                            type="text"
                            placeholder="Full Name"
                        />
                    </div>
                    <div class="mb-4">
                        <text-input
                            v-model="form.email"
                            type="email"
                            placeholder="Email"
                        />
                    </div>
                    <div class="mb-4">
                        <mobile-number 
                            v-model="form.mobile_number"
                            placeholder="Mobile Number"
                        />
                    </div>
                    <div class="mb-6">
                        <text-input
                            v-model="form.message"
                            type="text"
                            textarea
                            placeholder="Message"
                        />
                    </div>

                    <div class="recaptcha mb-[30px] flex items-center justify-center">
                        <vue-recaptcha
                            :sitekey="siteKey"
                            @verify="verifyRecaptcha"
                            @expired="expiredRecaptcha"
                            ref="grecaptcha"
                        ></vue-recaptcha>
                    </div>
                    <div @click="submitInquiry()" class="submit-btn w-fit m-auto">
                        <button-component
                            size="large"
                            color="primary"
                        >
                            <template #content>
                                Submit Inquiry
                            </template>
                        </button-component>
                    </div>
                </form>
              
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useApiStore } from '@/store/api';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import useModal from "@/composables/modal";
import useCms from '@/composables/useCms';
import { VueRecaptcha } from 'vue-recaptcha';
import MobileNumber from '@/components/inputs/MobileNumber.vue';

export default defineComponent({
    name: 'ContactPage',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        TextInput,
        ButtonComponent,
        VueRecaptcha,
        MobileNumber
    },
    setup() {
        const apiStore = useApiStore();
        const router = useRouter();
        const modal = useModal();

        const siteKey = process.env.VUE_APP_SITE_KEY;

        const form = ref({
            fullname: null,
            email: null,
            mobile_number: null,
            message: null,
            recaptcha: null
        });

        const clearForm = () => {
            form.value.fullname =  null;
            form.value.email =  null;
            form.value.mobile_number =  null;
            form.value.message =  null;
            form.value.recaptcha = null;
        };

        const verifyRecaptcha = () => {
            form.value.recaptcha = true;
        }

        const expiredRecaptcha = () => {
            form.value.recaptcha = null;
        }

        const grecaptcha = ref();
        const submitInquiry = () => {
            // loading.value = false;

            window.axios
                .post(
                    apiStore.route('store-inquiries'),
                    form.value
                )
                .then(({ data }) => {
                    modal.success(data.message);
                    clearForm();
                    grecaptcha.value.reset();
                    // loading.value = false;
                })
                .catch((error) => {
                    modal.error(error.response.data.message);
                    // loading.value = false;
                });
        };

        const { cms, contentSlug, getCms, getCmsContent } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "contact";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        onMounted(() => {
            getContentSlug();
        });

        return {
            router,
            submitInquiry,
            form,
            siteKey,
            cmsContent,
            getCmsContent,
            verifyRecaptcha,
            expiredRecaptcha,
            grecaptcha
        }
    },
});
</script>
