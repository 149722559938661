<template>
    <div class="space-y-6">
        <div class="flex items-center justify-start space-x-4">
            <ArrowLeftIcon
                class="w-5 h-5 cursor-pointer"
                @click="$emit('go-back')"
            />
            <div class="space-y-1">
                <h3 class="text-2xl text-gray-500 font-semibold">
                    Edit Location
                </h3>
                <p
                    v-if="addressStore.hasSelectedAddress"
                    class="text-sm text-gray-500 font-light"
                >
                    {{ addressStore.selectedAddress.address_label }}
                </p>
            </div>
        </div>
        <div
            class="
                flex
                location-search
                w-full
                m-auto
                mb-6
                items-center
                space-x-2
                lg:space-x-4
            "
        >
            <div class="location-input relative w-full lg:w-[343px]">
                <text-input
                    v-model="locationState.search"
                    placeholder="Where are you?"
                    id="location"
                    type="text"
                    customClass="absolute"
                    customContainerClass="opacity-0 border-none"
                    @click="isDraggable(false)"
                />
                <text-input
                    v-model="locationState.maskSearch"
                    placeholder="Where are you?"
                    id="maskLocation"
                    type="text"
                    customClass="h-12"
                    required
                    @keydown="maskSearch"
                    :helper="!resetForm && addressStore?.selectedAddress?.address_label ? `Current Location: ${addressStore?.selectedAddress?.address_label || ''}` : '' "
                />
                <LocationMarkerIcon
                    class="
                        text-neutral-500
                        w-5
                        h-5
                        absolute
                        right-3
                        top-1/2
                        -translate-y-1/2
                        bg-white
                    "
                />
                <!-- <div
                    @click="closeResult()"
                    v-if="
                        !locationState.hasSelectedAddress &&
                        locationState.search &&
                        locationState.addressCandidates.length > 0
                    "
                    class="map-search-result absolute z-[999] bg-white"
                >
                    <div class="max-h-[196px] overflow-auto">
                        <div
                            class="
                                flex
                                px-2
                                lg:px-4
                                items-start
                                space-x-2
                                py-1
                                last:mb-0
                                border-b
                                cursor-pointer
                                hover:bg-gray-50
                            "
                            v-for="(
                                addressCandidate, index
                            ) in locationState.addressCandidates"
                            :key="index"
                        >
                            <LocationMarkerIcon
                                class="text-neutral-500 w-6 h-6"
                            />
                            <p
                                @click="
                                    selectAddress(addressCandidate)
                                "
                                class="text-sm"
                            >
                                <b>{{ addressCandidate.name }}</b> {{ addressCandidate.formattedAddress }}
                            </p>
                        </div>
                    </div>
                </div> -->
                <div
                    @click="closeResult()"
                    v-if="showResult == false"
                    class="map-search-result absolute z-[999] bg-white"
                >
                    <div class="max-h-[196px] overflow-auto">
                        <div
                            class="
                                flex
                                px-2
                                lg:px-4
                                items-start
                                space-x-2
                                py-1
                                last:mb-0
                                border-b
                                cursor-pointer
                                hover:bg-gray-50
                            "
                        >
                            <LocationMarkerIcon
                                class="text-neutral-500 w-6 h-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rounded-[10px] relative h-[400px]">
            <!-- MAP HERE -->
            <div id="largeLocationMap" class="absolute w-full h-full" @click="isDraggable(true)"></div>
            <!-- MAP HERE -->
        </div>
        <div class="flex items-center justify-end space-x-4">
            <ButtonComponent
                color="primary"
                fill="outline"
                size="default"
                @click="$emit('cancel')"
            >
                <template #content>
                    <span>Cancel</span>
                </template>
            </ButtonComponent>
            <ButtonComponent
                color="primary"
                fill="solid"
                size="default"
                :disabled="!locationState.hasSelectedAddress"
                @click="$emit('submit', form)"
            >
                <template #content>
                    <span>Submit</span>
                </template>
            </ButtonComponent>
        </div>
    </div>
</template>
<script>
import { ArrowLeftIcon } from "@heroicons/vue/solid";
import { LocationMarkerIcon } from "@heroicons/vue/outline";

import ButtonComponent from "@/components/ButtonComponent.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted, watch } from "@vue/runtime-core";
import { useAddressStore } from "@/store/address.js";
import useLocation from "@/composables/useLocation";
import debounce from "lodash/debounce";
export default {
    name: "LargeMap",
    components: {
        ArrowLeftIcon,
        ButtonComponent,
        LocationMarkerIcon,
        TextInput,
    },
    emits: ["go-back", "cancel", "submit"],
    props: {
        addressForm: {
            type: Object,
            default: () => {},
        },
    },
    setup(props) {
        const addressStore = useAddressStore();

        const {
            locationState,
            checkLocationPermissions,
            googleSearch,
            initMap,
            setSelectedAddress,
            findAddress,
            setLocation
        } = useLocation();

        const form = ref(props.addressForm);

        const showResult = ref(true);

        let timeOut;

        const closeResult = async () => {
            showResult.value  = showResult.value == false ? true : false;
        }

        const selectAddress = (addressCandidate) => {
            locationState.hasSelectedAddress = true;
            setSelectedAddress(
                addressCandidate.lat,
                addressCandidate.lng,
                addressCandidate.formattedAddress
            );
        }

        const isDraggable = (isDraggable = false) => {
            locationState.hasSelectedAddress = false
            locationState.isDraggable = isDraggable;
            findAddress();
        }

        const maskSearch = () => {
            locationState.isDraggable = false;
            locationState.hasSelectedAddress = false;
        }

        watch(
            () => locationState.search,
            debounce((currentValue) => {
                if (currentValue) {
                    // googleSearch();

                    // if (locationState.addressCandidates.length > 0) {
                    //     form.value.address_label =
                    //         locationState.addressCandidates[0][
                    //             "formattedAddress"
                    //         ];
                    // } else {
                    //     form.value.address_label = "N/A";
                    // }
                    if (locationState.addressCandidates?.length == 0) {
                        form.value.address = "N/A";
                    }
                } else {
                    locationState.addressCandidates = [];
                }
            }, 500)            
        );

        watch(
            () => locationState.customerLat,
            (currentValue) => {
                if (currentValue) {
                    form.value.latitude = currentValue;
                }
            }
        );

        watch(
            () => locationState.customerLng,
            (currentValue) => {
                if (currentValue) {
                    form.value.longitude = currentValue;
                }
            }
        );

        watch(
            () => locationState.maskSearch,
            debounce((currentValue) => {
                if (!locationState.isDraggable) {
                    const input = document.getElementById('location');
                    if (input) {
                        input.value = '';
                        input.value = currentValue;
                        if (currentValue) {
                            input.focus();
                        }
                    }  
                }

            }, 700)
        );

        onMounted(() => {
            setTimeout(() => {
                checkLocationPermissions().then(() => {
                    initMap("largeLocationMap");
                    locationState.hasSelectedAddress = false;
                    if (addressStore.hasSelectedAddress) {
                        locationState.customerLat = addressStore.selectedAddress.latitude;
                        locationState.customerLng = addressStore.selectedAddress.longitude;
                        locationState.search = props.resetForm ? null : addressStore.selectedAddress.address_label;
                        if (!props.resetForm) {
                            setSelectedAddress(
                                addressStore.selectedAddress.latitude,
                                addressStore.selectedAddress.longitude,
                                addressStore.selectedAddress.address_label,
                            );
                        }                   
                        // showResult.value = false;
                        // initMap("smallLocationMap");
                        initMap("largeLocationMap");
                        googleSearch()
                    } else {
                        setLocation();
                        googleSearch()
                    }
                });
            }, 500);
        });

        onUnmounted(() => {
            clearTimeout(timeOut);
        });

        return {
            addressStore,
            form,
            showResult,
            locationState,
            setSelectedAddress,
            closeResult,
            findAddress,
            selectAddress,
            isDraggable,
            maskSearch
        };
    },
};
</script>