<template>
  <div class="ion-padding p-6" :class="customClass">
    <!-- <img class="mx-auto" :width="imageWidth" :height="imageHeight" :src="imageLink" alt="Icon"> -->
    <p class="font-semibold text-primary-900">{{ title }}</p>
    <p class="mt-2 mb-4 text-sm" style="color: #000">
      {{ description }}
    </p>
    <!-- <template v-if="cancelButton"> -->
      <div class="flex justify-end">
        <div class="mr-1" v-if="cancelButton">
          <ion-button
            class="btn-tertiary m-0"
            size="large"
            :expand="expand"
            @click="closeModal"
          >
            {{ cancelButtonText }}
          </ion-button>
        </div>
        <div v-show="!closeModalButton">
          <ion-button
            v-if="buttonLink"
            :href="buttonLink"
            :class="cancelButton ? 'btn-primary' : 'btn-primary btn-min'"
            :color="buttonColor"
            class="m-0"
            size="large"
            :expand="expand"
          >
            {{ buttonText }}
          </ion-button>
          <ion-button
            v-else
            @click="approveModal"
            :class="cancelButton ? 'btn-primary' : 'btn-primary btn-min'"
            :color="buttonColor"
            class="m-0"
            size="large"
            :expand="expand"
          >
            {{ buttonText }}
          </ion-button>
        </div>
      </div>
    <!-- </template> -->
  </div>
</template>

<script>
import { IonButton, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "modal-generic",
  props: {
    customClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
    },
    buttonLink: {
      type: String,
      default: null,
    },
    buttonColor: {
      type: String,
      default: "primary",
    },
    expand: {
      type: String,
      default: null,
    },
    imageLink: {
      type: String,
      default: null,
    },
    imageWidth: {
      type: String,
      default: null,
    },
    imageHeight: {
      type: String,
      default: null,
    },
    cancelButton: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    closeModalButton: {
      type: Boolean,
      default: false,
    },
    confirmLink: { type: Function },
  },
  data() {
    return {
      content: "Content",
    };
  },
  methods: {
    async closeModal() {
      await modalController.dismiss({
        type: "cancel",
      });
    },

    async approveModal() {
      await modalController.dismiss({
        type: "approved",
      });
    },
  },
  components: { IonButton },
});
</script>
