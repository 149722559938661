<template>
    <button-component size="large" color="white" @click="addOrRemoveFavorite" :disabled="loading">
        <template #content>
            <ion-icon :src="favorited ? '/assets/icon/ic-heart-fill.svg' : '/assets/icon/ic-heart.svg'" class="w-6 h-6">
            </ion-icon>
            <span class="ml-2 text-sm text-primary-500">Add to Favorites</span>
        </template>
    </button-component>
</template>

<script type="text/javascript">

import {
    IonIcon,
} from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import useModal from "@/composables/modal";
import useLocalStorage from "@/composables/useLocalStorage.js";
import ButtonComponent from '@/components/ButtonComponent.vue';
import useResponseHandler from '@/composables/useResponseHandler';
export default defineComponent({
    name: 'add-to-favorites-button',
    components: {
        IonIcon,
        ButtonComponent
    },
    props: {
        product: {
            type: Object,
            defualt: {
                product_id: 1,
                sku: 1,
                status: 'favorite',
            },
        },
        favorite: {
            type: Boolean,
            defualt: false
        },
        checkFavoriteLoading: {
            type: Boolean,
            defualt: true
        }
    },

    setup(props) {
        const router = useRouter();
        const { localStorage } = useLocalStorage();
        const modal = useModal();
        const errors = ref(false);

        let favorited = ref(props.favorite);
        let loading = ref(props.checkFavoriteLoading);
        const { successHandler } = useResponseHandler();

        const addOrRemoveFavorite = async () => { 

            loading.value = true;

            const user = await localStorage.value.get("user");

            await window.axios
                .post(process.env.VUE_APP_API_URL + '/api/v1/customer/favorite/store', {
                    user_id: user.id,
                    product_id: props.product.sku,
                })
                .then((response) => {
                    favorited.value = response.data.data?.status == 'favorite' ? true : false;
                    successHandler({ 
                        type: 'modal',
                        description: response.data.message, 
                        response
                    });
                })
                .catch((error) => {
                    errors.value = error.response.data.message;
                    modal.error(this.errors.value);
                    loading.value = false;

                }).then(() => {
                    loading.value = false;
                })
        };

        watch(
            () => props.favorite,
            (newValue) => {
                favorited.value = newValue;
            }
        );

        watch(
            () => props.checkFavoriteLoading,
            (newValue) => {
                loading.value = newValue;
            }
        );

        return {
            router,
            loading,
            favorited,
            addOrRemoveFavorite,
        }
    }
});
</script>