import { defineStore } from 'pinia'
export const useCartStore = defineStore({
    id: 'cart',
    state:() => ({
        data: {}
    }),
    getters: {
        
        addToCartUrl:(state) => state.data?.add_item_url,
        updateUrl:(state) => state.data?.update_url,
        checkoutUrl:(state) => state.data?.checkout_url,

        item:(state) => (sku => {
            const items = state.data?.items || [];
            return items.find(x => x.product_id == sku) || {};
        }),
        itemCount:(state) => state.data?.items?.reduce((a,r) => a + r.quantity, 0),
        
        inCart:(state) => (sku => {
            return Object.keys(state.item(sku)).length != 0;
        })
    },
    actions: {}
})