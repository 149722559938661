<template>
    
    <div class="relative cursor-pointer">
        <img src="/assets/images/voucher-bg.svg" class="w-full h-auto drop-shadow-[0_0_10px_rgba(0,0,0,0.15)]"/>
        <div class="absolute top-0 left-0 m-4">
            <div class="flex items-start space-x-4">
                <img :src="item.image" class="w-[70px] h-[70px] lg:w-20 lg:h-20 object-cover"/>
                <div class="space-y-1">
                    <p class="text-base font-normal">{{ name }}</p>
                    <p class="text-xs font-light">Discount Value: {{ discountValue }}</p>
                    <p class="text-xs font-light text-error">Expires on {{ expiresAt }}</p>
                </div>
            </div>
        </div>
        <div class="absolute bottom-0 left-0 m-2 md:m-4">
            <p class="text-xs font-light text-gray-500">Terms and Conditons apply</p>
        </div>
    </div>

</template>
<script>
export default {
    name: 'VoucherCard',
    props: {
        name: {
            type: String,
            default: null
        },
        discountValue: {
            type: String,
            default: null
        },
        expiresAt: {
            type: String,
            default: null
        },
        item: {
            type: Object,
            default: () => {}
        }
    }
}
</script>