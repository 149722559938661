<template>
    <ion-page class="w-[50%] mx-auto">
        <ion-buttons slot="secondary">
          <ion-button @click="router.push('account-details')">
            <ArrowLeftIcon
              class="w-7 h-7 dark"
            />
          </ion-button>
        </ion-buttons>
        <ion-content :fullscreen="true" class="ion-padding relative">

            <ion-grid class="ion-no-padding">
                <ion-row class="mb-5">
                    <ion-col size="6">
                        <h3 class="text-2xl font-semibold mb-[33px] hidden lg:block">{{ confirmation ? 'Reason for Deletion' : 'Delete Account' }}</h3>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <template v-if="!confirmation">
                            <p class="font-bold mb-3">You are about to permanently delete your account.</p>
                            <p class="text-sm text-gray-500">If you delete your account you wont be able to retrieve all your information. Account deletion is irreversible.</p>
                            <ul>
                                <li class="text-sm text-gray-500">You won't be able to use your points accumulated.</li>
                                <li class="text-sm text-gray-500">You won't be return or refund items ordered by that account</li>
                            </ul>

                        </template>

                        <template v-if="confirmation">
                            <p class="text-gray-500 mb-4">May we know the reason why you’re deleting your account?</p>
                            
                            <ion-item lines="none" class="frm-forms frm-inputCon frm-selectCon mb-4 border-primary-50 " style="border: solid; border-radius:14px">                            
                                <ion-select v-model="form.reason" value="" interface="action-sheet" class="frm-input w-full" name="plate" placeholder="Reason">
                                    <ion-select-option value="I don’t find the app useful anymore.">I don’t find the app useful anymore.</ion-select-option>
                                    <ion-select-option value="I am no longer eaarning points.">I am no longer eaarning points.</ion-select-option>
                                    <ion-select-option value="I prefer to buy on physical store">I prefer to buy on physical store</ion-select-option>
                                    <ion-select-option value="Others">Others</ion-select-option>
                                </ion-select>
                                <ion-icon slot="end" src="/assets/icons/ic-down-arrow.svg" class="ml-4" name="selectIcon" style="position: absolute; right: 20px"></ion-icon>
                            </ion-item>

                            <text-input
                            v-model="form.providedReason"
                            type="text"
                            textarea
                            label="Provide reason"
                            placeholder="Enter more information here..."
                            helper="e.g. I dont use the app anymore"
                            />

                            <!-- <ion-label class="frm-forms--label" position="stacked">Provide reason</ion-label>
                            <ion-item lines="none" class="frm-forms frm-inputCon mb-2 frm-input-textarea w-full">
                                <ion-textarea placeholder="Enter more information here..." class="frm-input chat-textarea"></ion-textarea>
                            
                            </ion-item> -->
                        </template>
                    </ion-col>
                </ion-row>
            </ion-grid>

        </ion-content>

        <ion-footer class="ion-no-border p-4">
            <ion-toolbar>
                <template v-if="!confirmation">
                    <button-component @click="confirmation = !confirmation" class="w-full mt-4" size="default" color="primary">
                        <template #content>
                            <template v-if="!loading">
                                Proceed Anyway
                            </template>
                            <template v-else>
                                <ion-spinner></ion-spinner>
                            </template>
                        </template>
                    </button-component>
                </template>
                <template v-if="confirmation">
                    <ion-button @click="goToDeleteConfirmation()" class="btn-danger" size="large" expand="block" fill="outline">
                        CONTINUE
                    </ion-button>
                </template>
            </ion-toolbar>
        </ion-footer>

    </ion-page>

</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    IonToolbar,
    IonButton,
    IonItem,
    // IonLabel,
    IonSelect
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
// import SelectOptions from "@/components/inputs/SelectOptions.vue";
import TextInput from '@/components/inputs/TextInput.vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { ArrowLeftIcon } from "@heroicons/vue/outline";

export default defineComponent({
    name: 'delete-account',
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonFooter,
        IonToolbar,
        IonButton,
        IonItem,
        // IonLabel,
        IonSelect,
        ButtonComponent,
        TextInput,
        ArrowLeftIcon
    },
    data() {
        return {
        };
    },

    setup() {
        const userStore = useUserStore();
        const confirmation = ref(false);
        const router = useRouter();

        const form = ref({
            reason: null,
            providedReason: null
        });


        const goToDeleteConfirmation = () => {
            userStore.setUserDeleteAccount(form.value);
            router.push('confirm-deletion')
        }

        return { 
            form,
            router,
            confirmation,
            goToDeleteConfirmation
        }
    }
})
</script>