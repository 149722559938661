import useAuth from './useAuth';

import { modalController, toastController } from "@ionic/vue";
// import Toast from '@/components/ResponseToast.vue';
import Modal from '@/components/dialogs/Modal.vue';

export default function useResponseHandler() 
{
    const { logout } = useAuth();

    /**
     * Open information dialog
     * 
     * @param   object options
     * @returns Promise
     */
    const openResponseModal = async ({ title, description, buttonText, buttonLink, imageLink }) => {
        const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                    title,
                    description,
                    imageLink,
                    imageWidth: '80',
                    imageHeight: '78',
                    buttonText,
                    buttonLink,
    
            }
        });
        return modal.present();
    };

    const openToast = async ({ message, duration, icon, color }) => {
        // const toast = await toastController.create({
        //     component: Toast,
        //     cssClass: 'gen-modal toast-modal',
        //     showBackdrop: false,
        //     componentProps
        // });
        // return toast.present();
        const toast = await toastController
        .create({
          message,
          duration: duration || 1500,
          icon,
          position: 'top',
          cssClass: color,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ]
        })

        await toast.present();
        await toast.onDidDismiss();
    };

    const dialogService = (options) => {
        if(options.type == 'modal') openResponseModal(options);
        if(options.type == 'toast') openToast(options);
    };

    /**
     * Handle success response
     * 
     * @param   string description
     * @returns void
     */
     const successHandler = ({ type, title, description, buttonText, imageLink, message, icon, color, duration, autoClose }) => {
        dialogService({
            duration,
            autoClose,
            position: 'top',
            color: color || 'success-toast',
            type: type || 'toast',
            title: title || 'Success!',
            description: description || 'This action was successful',
            buttonText: buttonText || 'Okay',
            imageLink: imageLink || '/assets/icon/ic-status-check.svg',
            message: message || description || 'Success!',
            icon: icon || '/assets/icon/ic-face-id-verified.svg',
        });
    };

    /**
     * Handler error response
     * 
     * @param   object response
     * @param   boolean autoClose
     * @param   string type
     * 
     * @returns void
     */
    const errorHandler = (response, autoClose = true, type = 'modal') => {
        if(response.status === 401) logout();
        else {
            const { title, message, error, buttonText, imageLink, icon, duration } = response.data;
            dialogService({
                type,
                duration,
                autoClose,
                position: 'top',
                color: 'danger-toast',
                title: title || 'Oops!',
                description: message || error || 'There was something wrong',
                buttonText: buttonText || 'Close',
                imageLink: imageLink || '/assets/icon/ic-warning.svg',
                message: message || 'Failed!',
                icon: icon || '/assets/icon/ic-face-id-verified.svg',
            });
        }
    };

    return {
        openResponseModal,
        openToast,
        successHandler,
        errorHandler
    }
}