import { computed, ref } from "vue";
import { useReviewStore } from '@/store/review.js';
import { useApiStore } from '@/store/api.js';
import useLocalStorage from "@/composables/useLocalStorage.js";
import useModal from "@/composables/modal";
import { useRoute } from 'vue-router';

export default function useReview() {

    const apiStore = useApiStore();

    const reviewStore = useReviewStore();
    const { localStorage } = useLocalStorage();
    const modal = useModal();
    const router = useRoute();

    const reviewLoader = ref(false);
    const errors = ref(false);

    const fetchInvoiceUrl = computed(() => apiStore.route("fetch-invoice-ratings"));
    const productReviewUrl = computed(() => apiStore.route("product-review"));
    const uploadProductReviewFileUrl = computed(() => apiStore.route("upload-product-review-image"));
    const removeProductReviewFileUrl = computed(() => apiStore.route("remove-product-review-image"));

    const reviews = computed(() => reviewStore.reviews );
    const with_media_reviews = computed(() => reviewStore.with_media_reviews );
    const five_star_reviews = computed(() => reviewStore.five_star_reviews );
    const four_star_reviews = computed(() => reviewStore.four_star_reviews );
    const three_star_reviews = computed(() => reviewStore.three_star_reviews );
    const two_star_reviews = computed(() => reviewStore.two_star_reviews );
    const one_star_reviews = computed(() => reviewStore.one_star_reviews );

    const total_reviews = computed(() => reviewStore.total_reviews );
    const average_rating_reviews = computed(() => reviewStore.average_rating_reviews );

    const selected_review = computed(() => reviewStore.selected_review );
    const product_name = computed(() => reviewStore.product_name );

    const images = computed(() => reviewStore.images );

    const step = ref(1);
    const invoice_id = ref(null);
    const invoice = ref(null);
    const invoice_items = ref(null);
    const form = ref({});
    const product_reviews = ref([]);

    const fetchInvoice = (id, productPreview = []) => {
        if(reviewLoader.value) return;
        reviewLoader.value = true;

        invoice_id.value = id;

        window.axios
        .post(fetchInvoiceUrl.value, { invoice_id : invoice_id.value })
        .then((response ) => {
            invoice.value = response.data.invoice;
            invoice_items.value = response.data.invoice_items;
            form.value.invoice_id = invoice.value.id;
            form.value.rider_id = invoice.value.delivery_method != 'LALAMOVE' ? invoice.value.rider_id : null;
            form.value.rider_score = 5;
            form.value.rider_message = '';

            product_reviews.value = [];
            for (let index = 0; index < invoice_items.value.length; index++) {
                let message = productPreview.length > 0 && productPreview[index].sku == invoice_items.value[index].product.sku 
                    ? productPreview[index].message 
                    : '';
                const rate_item = {
                    sku: invoice_items.value[index].product.sku,
                    name: invoice_items.value[index].product.name,
                    score: 5,
                    file_path: '',
                    message: message,
                    rating_images: invoice_items.value[index].rating_images,
                    image: invoice_items.value[index].product_image,
                }
                product_reviews.value.push(rate_item);
                form.value.product_reviews = product_reviews.value;
            }

            reviewLoader.value = false;
        })
        .catch((error) => {
            const message = error.response.data.error ? error.response.data.error : error.response.data.message;
            
            requestFail(message)
        });
    }

    const submit = () => {
        if(reviewLoader.value) return;
        reviewLoader.value = true;

        window.axios
        .post(productReviewUrl.value, form.value)
        .then((response ) => {
            reviewLoader.value = false;
            step.value = 1;
            // modal.success(response.data.message, '/order-history/item?showUrl='+router.query.showUrl);
            modal.success(response.data.message, '/order-history/item/' + router.params.id);
        })
        .catch((error) => {
            requestFail(error.response.data.message)
        });
    }

    function uploadProductReviewFile(id, product_id, file, productPreviews = []) {
        if(reviewLoader.value) return;
        reviewLoader.value = true;

        const payloads = new FormData();
        payloads.append('invoice_id', id ? id : '');
        payloads.append('product_id', product_id ? product_id : '');
        payloads.append('file_path', file ? file : '');

        window.axios
        .post(uploadProductReviewFileUrl.value, payloads)
        .then((response ) => {
            console.log(response.data)
            reviewLoader.value = false;
            fetchInvoice(invoice_id.value, productPreviews)
        })
        .catch((error) => {
            requestFail(error.response.data.message)
        });
    }

    const removeProductReviewFile = (id, productReviews = [])  => {
        if(reviewLoader.value) return;
        reviewLoader.value = true;

        window.axios
        .post(removeProductReviewFileUrl.value, { id : id }) 
        .then((response) => {
            console.log(response.data)
            reviewLoader.value = false;
            fetchInvoice(invoice_id.value, productReviews)
        })
        .catch((error) => {
            requestFail(error.response.data.message)
        });
    }
    
    
    const updateStoreReview = (reviews, total_reviews, average_rating_reviews) => {
        reviewStore.reviews = reviews != null ? reviews : reviewStore.reviews;
        reviewStore.with_media_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.images.length > 0) : reviewStore.with_media_reviews;
        reviewStore.five_star_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 5) : reviewStore.five_star_reviews;
        reviewStore.four_star_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 4) : reviewStore.four_star_reviews;
        reviewStore.three_star_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 3) : reviewStore.three_star_reviews;
        reviewStore.two_star_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 2) : reviewStore.two_star_reviews;
        reviewStore.one_star_reviews = reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 1) : reviewStore.one_star_reviews;

        reviewStore.total_reviews = total_reviews ? total_reviews : reviewStore.total_reviews;
        reviewStore.average_rating_reviews = average_rating_reviews ? average_rating_reviews : reviewStore.average_rating_reviews;

        let images = [];
        for (let index = 0; index < reviewStore.reviews.length; index++) {
            if(reviewStore.reviews[index].images.length > 0) {
                images = [...images, ...reviewStore.reviews[index].images]
            }
        }

        reviewStore.images = images != null ? images : reviewStore.images;

        localStorage.value.set('reviews', reviews != null ? reviews : reviewStore.reviews );
        localStorage.value.set('with_media_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.images.length > 0) : reviewStore.with_media_reviews );
        localStorage.value.set('five_star_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 5) : reviewStore.five_star_reviews );
        localStorage.value.set('four_star_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 4) : reviewStore.four_star_reviews );
        localStorage.value.set('three_star_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 3) : reviewStore.three_star_reviews );
        localStorage.value.set('two_star_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 2) : reviewStore.two_star_reviews );
        localStorage.value.set('one_star_reviews', reviewStore.reviews != null ? reviewStore.reviews.filter(x => x.score == 1) : reviewStore.one_star_reviews );
        

        localStorage.value.set('total_reviews', total_reviews != null ? total_reviews : reviewStore.total_reviews );
        localStorage.value.set('average_rating_reviews', average_rating_reviews != null ? average_rating_reviews : reviewStore.average_rating_reviews );

        localStorage.value.set('images', images != null ? images : reviewStore.images );
    }

    const updateStoreSelectedReview = (id) => {
        reviewStore.selected_review = id != null ? reviewStore.reviews.find(x => x.id) : reviewStore.selected_review;
        reviewStore.product_name = reviewStore.selected_review != null ? reviewStore.selected_review.product.name : reviewStore.product_name;

        localStorage.value.set('selected_review', id != null ? reviewStore.reviews.find(x => x.id) : reviewStore.selected_review );
        localStorage.value.set('product_name', id != null ? reviewStore.selected_review.product.name : reviewStore.product_name );
    }

    const requestFail = (error) => {
        errors.value = error;
        modal.error(errors.value);

        reviewLoader.value = false;
    };

    return {
        reviewLoader,

        fetchInvoice,
        submit,
        uploadProductReviewFile,
        removeProductReviewFile,

        updateStoreReview,
        updateStoreSelectedReview,

        reviews,
        with_media_reviews,
        five_star_reviews,
        four_star_reviews,
        three_star_reviews,
        two_star_reviews,
        one_star_reviews,

        total_reviews,
        average_rating_reviews,

        selected_review,
        product_name,

        images,

        step,
        invoice_id,
        invoice,
        invoice_items,
        product_reviews,
        form
    }
}