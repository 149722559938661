<template>

    <swiper class="frm-slider frm-slider__promo mb-10 lg:mb-[60px]" :options="slide" :pagination="{type: 'fraction'}" :modules="modules">
        <slot name="slide">
            <template v-if="cmsContent !== null && getCmsContent('section1_banner').length !== 0">
                <swiper-slide
                    v-for="(slide, index) in getCmsContent('section1_banner')"
                    :key="index"
                >
                    <div class="w-full h-0 pt-[40%] relative">
                        <div @click="redirect(slide.url)" class="absolute top-0 left-0 h-full w-full bg-cover bg-no-repeat bg-center cursor-pointer"
                        :style="`background-image: url('${renderCmsImage(slide.image)}')`"></div>
                    </div>
                </swiper-slide>
            </template>
            <template v-else>
                <swiper-slide
                    v-for="(slide, index) in slides"
                    :key="index"
                >
                    <div class="w-full h-0 pt-[40%] relative">
                        <a :href="slide.link">
                            <div class="absolute top-0 left-0 h-full w-full bg-cover bg-no-repeat bg-center"
                            :style="`background-image: url('${slide.banner}')`"></div>
                        </a>
                    </div>
                </swiper-slide>
            </template>
        </slot>
    </swiper>
</template>
<script type="text/javascript">
import { onMounted, ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from "swiper";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import 'swiper/css';
import useCms from '@/composables/useCms';

export default defineComponent({
	name: 'HomeSlider',
	components: {
        Swiper, SwiperSlide
	},
	setup() {

		const slide = {
			speed: 400,
			slidesPerView: 1,
			spaceBetween: 10,
			loop: true,
		};
        
        const { cms, contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();
        const cmsContent = ref(null);

		const router = useRouter();

        const slides = [
            { link: '/home', banner: '/assets/images/banner1.png' },
            { link: '/home', banner: '/assets/images/banner2.png' },
        ]

        const getContentSlug =()=> {
            contentSlug.value = "home";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        const redirect = (url) => {
            if (url.includes('http')) {
                window.location.href =  url
            } else {
                router.push(url);
            }

        }

        onMounted(() => {
            getContentSlug();
        });

		return {
			slide,
			router,
            modules: [Pagination, Navigation],
            slides,
            getCmsContent,
            getCmsImage,
            renderCmsImage,
            cmsContent,
            redirect
		}
	},
});
</script>
