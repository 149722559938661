import { defineStore } from 'pinia'
import useLocalStorage from "@/composables/useLocalStorage.js";

export const useApiStore = defineStore({
  id: 'api',
  state: () => {
    return {
      routes: [],
      cms: []
    }
  },
  getters: {
    route: (state) => {
      return (route, params = {}) => {
        if (state.routes[route] !== undefined) {
          let url = state.routes[route]

          for (let [key, value] of Object.entries(params)) {
            url = url.replace('::' + key + '::', value)
          }

          return url;
        } else {
          return '';
        }
      };
    },
  },
  actions: {
    async fetchConfigs() {
      const { localStorage } = useLocalStorage();
      
      await window.axios
        .post(process.env.VUE_APP_API_URL + "/api/v1/customer/config/fetch")
        .then((result) => {
          window.axios.defaults.headers.common["ApiKey"] = `${process.env.VUE_APP_API_KEY}${result.data.key}`;
          this.routes = result.data.routes;
          this.cms = result.data.cms;
          localStorage.value.set("routes", result.data.routes);
          localStorage.value.set("cms", result.data.cms);
        })
    }
  }
})