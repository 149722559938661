import { defineStore } from 'pinia'
export const useCategoryStore = defineStore({
    id: 'category',
    state:() => ({
        items: []
    }),
    getters: {
        subcategories:(state) => state.items.reduce((a,r) => a.concat(r.subcategories), []),
        parentOf:(state) => (subcategory_id => {
            const sub = state.subcategories.find(x => x.code == subcategory_id) || {};
            return state.items.find(x => x.code == sub.parent_id) || {};
        }),
    },
    actions: {}
})
