<template>
    <div class="flex items-center justify-center">
        <img v-if="!items.length > 0" src="/assets/images/loader.gif" class="w-14 h-14"/>
        <swiper
            v-else
            :slidesPerView="'auto'"
            :spaceBetween="0"
            :freeMode="true"
            :pagination="{
                clickable: true,
            }"
            :modules="modules"
            class="card-slider w-full"
        >
            <swiper-slide
                v-for="(item, index) in items.slice(0, 5)"
                :key="index"
                class="w-[46%] md:w-[32%] lg:w-[18%] mr-[2%]"
            >  
                <template v-if="isReview != true">
                    <product-card :product="item"></product-card>
                </template>
                <template v-else>
                    <review-card :item="item"></review-card>
                </template>
            </swiper-slide>
            <template v-if="isReview != true">
                <swiper-slide 
                    v-if="items.length >= 5"
                    class="slider-view-all"
                    @click="router.push(redirectLink)"
                >
                        <p class="font-normal text-primary-500">View All</p>
                </swiper-slide>
            </template>
            
        </swiper>
    </div>
</template>

<script type="text/javascript">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Pagination } from "swiper";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import 'swiper/css';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import ProductCard from '@/components/cards/ProductCard.vue';
import ReviewCard from '@/components/cards/ReviewCard.vue';

export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
        ProductCard,
        ReviewCard
    },
    props: {
        items: {
            type: Object
        },
        isReview: {
            type: Boolean,
            default: false
        },
        redirectLink: {
            type: String,
            default: null
        }
    },
    setup() {

        const router = useRouter();

        return {
            router,
            modules: [
                FreeMode, 
                Pagination
            ]
        };
    }
});
</script>