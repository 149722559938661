<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <!-- <banner-slider :slides="slides" /> -->
            <div v-if="cmsContent !== null && getCmsContent('section4_image') !== null" class="w-full mb-6">
                <img class="w-full object-cover" :src="getCmsContent('section4_image') ? renderCmsImage(getCmsContent('section4_image')) : '/assets/images/f2banner.png'"/>
            </div>
            <div class="px-4 lg:px-12 pb-20 w-full">
                <div class="flex flex-col lg:flex-row lg:items-center justify-between mb-6">
                    <p class="text-2xl lg:text-[32px] font-semibold m-0 mb-4 lg:m-0">
                        New
                    </p>
                    <div>
                        <SelectMenu
                            id="selector-id"
                            name="selector-name"
                            placeholder="Sort By: Best Match"
                            :options="sortOptions"
                            v-model="sortBy"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-8 mb-16 lg:mb-20">
                    <div v-for="(item) in displayedPromos" :key="item.id">
                        <product-card :product="item"></product-card>
                    </div>
                </div>

                <!-- Pagination -->
                <div v-if="filteredPromos.total > filteredPromos.per_page" class="flex items-center gap-4 m-auto pagination w-fit">
                    <pagination 
                        :page="page"
                        :items="filteredPromos"
                        @paginate="(value) => paginate(value)"
                        @previousPage="(value) => previousPage(value)"
                        @nextPage="(value) => nextPage(value)"
                    />
                </div>                                
                <div v-if="filteredPromos.total == 0">
                    <p class="text-sm font-normal text-center text-gray-900">No products available</p>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ProductCard from '@/components/cards/ProductCard.vue';
import Pagination from '@/components/Pagination.vue';
// import BannerSlider from '@/components/sliders/BannerSlider.vue';
import { useApiStore } from "@/store/api";
import useModal from "@/composables/modal";
import useProduct from '@/composables/useProduct';
import SelectMenu from "@/components/inputs/SelectMenu.vue";
import useCms from "@/composables/useCms";

export default defineComponent({
    name: 'PromoIndex',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        ProductCard,
        // Pagination,
        // BannerSlider,
        SelectMenu,
        Pagination
    },
    setup() {

        // const slides = [
        //     { link: '/home', banner: '/assets/images/banner3.png' },
        //     { link: '/home', banner: '/assets/images/banner2.png' },
        // ]

        const { getCmsContent, renderCmsImage, getCms, contentSlug, cms } = useCms();
        const router = useRouter();
        const { baseCatalogPayload } = useProduct();
        const recipes = ref([]);
        const apiStore = useApiStore();
        const modal = useModal();
        const sortBy = ref();
        const cmsContent = ref(null);
        const getContentSlug = () => {
            contentSlug.value = "home";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        // PAGINATION
        const page = ref(1);
        const pages = ref([]);
        const filteredPromos = computed(() => {
            const items = ref(recipes.value);
            return items.value || [];
        });

        const fetchPayload = computed(() => {
            return Object.assign(baseCatalogPayload.value);
        });

        const displayedPromos = computed(() => {
            return filteredPromos.value.data;
        });

        //  Paginate Products
        const paginate =(pageNumber)=> {

            page.value = pageNumber;

            fetch(params({
                page: pageNumber,
                sort: sortBy.value
            }));
        }

        const previousPage = (currentPage = 1) => {
            if (currentPage > 1) {
                page.value = currentPage - 1;
                fetch(params({
                    page: page.value,
                    sort: sortBy.value
                }));
            }         
        }

        const nextPage = (currentPage = 1) => {
            if (currentPage < pages.value.length) {
                page.value = currentPage + 1;
                fetch(params({
                    page: page.value,
                    sort: sortBy.value
                }));
            }
        }

        //  Set Number of Pages for Pagination
        const setPages =(items)=> {
            let numberOfPages = items.last_page;

            for (let i = 1; i <= numberOfPages; i++) {
                pages.value.push(i);
            }
        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => filteredPromos.value,
            (items) => {
                setPages(items);
            }
        );

        const params = (param) => {
            let addOns = param || { page: page.value };
            return { ...fetchPayload.value, ...addOns};
        }

        const fetch = (params) => {
            window.axios
                .get(apiStore.route("fetch-new-products"), { params: params })
                .then(({ data }) => {
                    recipes.value = data;
                    pages.value = [];
                })
                .catch((error) => {
                    modal.error(error.response.data.message);
                })
        };

        onMounted(() => {
            fetch(params());
            getContentSlug();
        })

        const sortOptions = [
            { id: '1', label: 'Best Match', value: 'best-match'},
            { id: '2', label: 'Recent', value: 'recent'},
            { id: '3', label: 'High Price', value: 'high-price'},
            { id: '4', label: 'Low Price', value: 'low-price'},
        ]

        watch(
            () => sortBy.value,
            (value) => {
                if (value) {
                    page.value = 1;
                    fetch(params({
                        sort: value,
                        page: page.value
                    }));
                }
            }
        )

        return {
            router,
            // slides,
            sortOptions,
            recipes,
            modal,
            sortBy,
            displayedPromos,
            pages,
            page,
            setPages,
            filteredPromos,
            paginate,
            previousPage,
            nextPage,
            getCmsContent,
            renderCmsImage,
            cmsContent
        }
    },
});
</script>
