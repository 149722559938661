<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-133px)] flex items-center justify-center">
                <div class="w-full sm:w-[445px] mx-auto my-12 sm:p-0 px-4">
                    <form>
                        <h3 class="text-[40px] font-bold text-primary-500 text-center mb-[52px]">Sign Up</h3>
                        <p class="text-sm text-center">Kindly enter the 6-digit code we sent to your mobile number.</p>
                        
                        <Otp 
                            :validate-url="apiStore.route('otp-validation')"
                            :resend-url="apiStore.route('otp-resend')"
                            :value="store.userEmail"
                            is-register="1"
                        />
                        
                    </form>
                    <div class="text-center mt-8">
                        <p class="text-sm text-gray-300">
                            Already have an account?
                            <a @click="router.push('login')" class="font-normal text-primary-500">Login now</a>
                        </p>
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent 
} from '@ionic/vue';
import { useRouter } from 'vue-router';

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import Otp from '@/components/forms/Otp.vue';
import { useApiStore } from "@/store/api";
import { useUserStore } from "@/store/user";


export default  {
    name: 'RegisterVerification',

    components: {
        IonPage, 
        IonContent,
        TopNavigation,
        BottomNavigation,
        Otp
    },

    setup() {
        const router = useRouter();
        const store = useUserStore();
        const apiStore = useApiStore();

        return {
            router,
            store,
            apiStore
        };
    }
}
</script>