<template>
    
    <div class="mt-10">
        <p class="text-sm text-gray-500">Choose your delivery option:</p>
        <div class="mt-4">
            <RadioGroup v-model="deliveryType" >
                <div class="grid grid-cols-1 gap-y-6">
                    <RadioGroupOption as="template" v-for="deliveryOption in deliveryOptions" :key="deliveryOption.id" :value="deliveryOption.id" v-slot="{ checked, active }">
                        <div>
                            <div 
                            @click="$emit('hasSelectedPaymentMethod', true)"
                            v-if="showDeliveryOption(deliveryOption)"
                            :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                                <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500']" aria-hidden="true" />
                                <span :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                                <div class="flex-1 flex ml-3">
                                    <div class="flex flex-col w-full">
                                        <div class="w-full">
                                            <p class="font-normal text-base text-gray-500">{{ deliveryOption.name }}</p>
                                            <div class="mt-3.5 grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
                                                <div class="space-y-2">
                                                    <p class="text-gray-400 text-xs uppercase">
                                                        <template v-if="deliveryOption.name == 'Delivery'">
                                                            Delivery Date
                                                        </template>
                                                        <template v-else>
                                                            Pickup Date
                                                        </template>
                                                    </p>
                                                    <div class="w-full">
                                                        <!-- <SelectOptions
                                                            v-model="selectedDate"
                                                            :options="dateOptions"
                                                            placeholder="Select delivery date"
                                                        ></SelectOptions> -->
                                                        <template v-if="1 == deliveryType && deliveryOption.id == 1">
                                                            <SelectMenu
                                                                v-model="selectedDate"
                                                                :options="selectDateOption(deliveryOption)"
                                                                :placeholder="'Select delivery date'"
                                                            ></SelectMenu>
                                                        </template>
                                                        <template v-else-if="2 == deliveryType && deliveryOption.id == 2">
                                                            <SelectMenu
                                                                v-model="selectedDate"
                                                                :options="selectDateOption(deliveryOption)"
                                                                :placeholder="'Select pickup date'"
                                                            ></SelectMenu>
                                                        </template>
                                                        <template v-else>
                                                            <SelectMenu
                                                                :options="selectDateOption(deliveryOption)"
                                                                :placeholder="deliveryOption.name == 'Delivery' ? 'Select delivery date' : 'Select pickup date'"
                                                            ></SelectMenu>
                                                        </template>                                                        
                                                    </div>
                                                </div>
                                                <div class="space-y-2" v-if="showTime == true">
                                                    <p class="text-gray-400 text-xs uppercase" v-if="!deliverToday">
                                                        <template v-if="1 == deliveryType && deliveryOption.id == 1">
                                                            Delivery Time
                                                        </template>
                                                        <template v-if="2 == deliveryType && deliveryOption.id == 2">
                                                            Pickup Time
                                                        </template>
                                                    </p>
                                                    <div class="w-full" v-if="!deliverToday">
                                                        <!-- <SelectOptions
                                                            v-model="selectedTime"
                                                            :options="timeOptions"
                                                            placeholder="Select delivery time"
                                                        ></SelectOptions> -->
                                                        <template v-if="1 == deliveryType && deliveryOption.id == 1">
                                                            <SelectMenu
                                                                v-model="selectedTime"
                                                                :options="optTime"
                                                                placeholder="Select delivery time"
                                                            ></SelectMenu>
                                                        </template>
                                                        <template v-else>
                                                            
                                                        </template>

                                                        <template v-if="2 == deliveryType && deliveryOption.id == 2">
                                                            <SelectMenu
                                                                v-model="selectedTime"
                                                                :options="optTime"
                                                                placeholder="Select pickup time"
                                                            ></SelectMenu>
                                                        </template>
                                                        <template v-else>
                                                            
                                                        </template>
                                                        
                                                    </div>
                                                </div>

                                                <div v-else class="tes">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </div>
    </div>

</template>
<script>
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import { useSettingsStore } from '@/store/settings';
// import useCart from '@/composables/useCart';

import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { CheckCircleIcon } from '@heroicons/vue/solid';

//import Selector from '@/components/inputs/Selector.vue';
// import SelectOptions from '@/components/inputs/SelectOptions.vue';
import SelectMenu from '@/components/inputs/SelectMenu.vue';

export default {
    name: 'deliveryOptions',
    components: {
        RadioGroup,
        RadioGroupOption,
        CheckCircleIcon,
        //Selector,
        // SelectOptions,
        SelectMenu
    },
    props: [],
    setup(props, { emit }) {
        // const deliveryOptions = [{
        //         id: 1,
        //         option: 'Delivery'
        //     },
        //     {
        //         id: 2,
        //         option: 'Pick Up'
        //     },
        // ];
        // const selectedDeliveryOption = ref(deliveryOptions[0])
        
        // const deliveryDate = [
        //     { id: 1, label: 'Today (Est.30 min)', value: 'Today (Est.30 min)'},
        //     { id: 2, label: 'Tomorrow, Apr 27', value: 'Tomorrow, Apr 27' },
        //     { id: 3, label: 'Wednesday, Apr 28', value: 'Wednesday, Apr 28' },
        // ];
        // const deliveryTime = [
        //     { id: 1, label: '7:00 AM', value: '7:00 AM'},
        //     { id: 2, label: '8:00 AM', value: '8:00 AM' },
        //     { id: 3, label: '9:00 AM', value: '9:00 AM' },
        //     { id: 4, label: '4:00 PM', value: '4:00 PM' },
        // ];
        // const selectedDeliveryDate = ref(null);
        // const selectedDeliveryTime = ref(null);

        // return {
        //     deliveryOptions,
        //     selectedDeliveryOption,
        //     deliveryDate,
        //     deliveryTime,
        //     selectedDeliveryDate,
        //     selectedDeliveryTime
        // }
        const router = useRouter();
        const cartStore = useCartStore();
        const settings = useSettingsStore();

        // const { updateCart } = useCart();

        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */

        const deliveryType = ref(null);
        const selectedDate = ref(null);
        const selectedTime = ref(null);
        const dateToday = ref(DateTime.now().toISODate());

        const cart = computed(() => cartStore.data);
        const deliveryOptions = computed(() => settings.deliveryOptions);
        const isIncomplete = computed(() => !deliveryType.value || !selectedDate.value || !selectedTime.value);


        const optTime = ref([
            {id: 1, label: '12am to 6am', value: '12am to 6am'},
            {id: 2, label: '6am to 12am', value: '6am to 12am'},
            {id: 3, label: '12pm to 6pm', value: '12pm to 6pm'},
            {id: 4, label: '6pm to 12am', value: '6pm to 12am'},
        ])

        const dateOptions = computed(() => {
            const today = DateTime.now();
            const tomorrow = today.plus({ days: 1 });
            const dat = today.plus({ days: 2 });
            return [
                { id: 1, label: 'Today, '+ today.toFormat('LLL dd'), value: today.toISODate() },
                { id: 2, label: 'Tomorrow, '+ tomorrow.toFormat('LLL dd'), value: tomorrow.toISODate() },
                { id: 3, label: dat.toFormat('cccc, LLL dd'), value: dat.toISODate() },
            ];
        });

        const timeOptions = computed(() => {
            const options = [];
            const startHour = parseInt((cart.value.branch.start_time).split(':')[0]);
            const endHour = parseInt((cart.value.branch.end_time).split(':')[0]);
            const startTime = DateTime.now().set({ hour: startHour, minute:0 });
            const endTime = DateTime.now().set({ hour: endHour, minute:0 });
            const diff = endTime.diff(startTime, [ 'hours' ]);

            for(let i = 1; i <= diff.hours; i++) {
                const dt = startTime.plus({ hours:i });
                options.push({
                    id: i, 
                    label: dt.toFormat('h:mm a'),
                    value: dt.toFormat('HH:mm:00')
                });
            }
            return options;
        });

        const deliverToday = computed(() => {
            return selectedDate.value ? ['delivery now', 'pick up now'].filter(x => x === selectedDate.value?.toLowerCase() ).length > 0 : true;
            // return selectedDate.value ? DateTime.now().toISODate() === selectedDate.value : true;
        });

        const showTime = ref(false);



        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

        // const submit = (schedule = null) => {
        //     updateCart({
        //         delivery_type: deliveryType.value,
        //         scheduled_at: schedule ? schedule.selectedDate : `${selectedDate.value}`,
        //         delivery_time: schedule ? schedule.selectedTime : selectedTime.value
        //     })
        // }

        const scheduleDate = (date) => {
            const now = DateTime.now();

            return date == 'Delivery now'  || date == 'Pick Up now' ? now.toISODate() : date;
        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => deliverToday.value,
            (date) => {
                const now = DateTime.now();
                if(now.toISODate() === date) {
                    selectedDate.value = now.toISODate();
                }
            }
        )

        watch(
            () => deliveryType.value,
            (val) => {
                if (val) {
                    // const now = DateTime.now();
                    // selectedDate.value = now.toISODate();
                    // selectedTime.value = now.plus({ hours:1 }).toFormat('HH:mm:00');
                    // submit();
                    selectedDate.value = null;
                    selectedTime.value = null;
                    emit('hasDeliveryOption', {
                        delivery_type: val,
                        selected_date: scheduleDate(selectedDate.value),
                        selected_date_formatted: selectedDate.value,
                        selected_time: selectedTime.value
                    })
                }
            },
            { immediate: true }
        );

        watch(
            () => selectedDate.value,
            (date) => {
                selectedTime.value = null;
                emit('hasDeliveryOption', {
                    delivery_type: deliveryType.value,
                    selected_date: scheduleDate(date),
                    selected_date_formatted: selectedDate.value,
                    selected_time: selectedTime.value
                });
                const now = DateTime.now();
                if(now.toISODate() === date) {
                    // selectedTime.value = now.plus({ hours:1 }).toFormat('HH:mm:00');
                    showTime.value = false;
                    // submit()
                } 
                else {
                    if(date != 'Delivery now') {
                        showTime.value = true;
                    }
                    selectedTime.value = null;
                }
            }
        );
        
        watch(
            () => selectedTime.value,
            (val) => {
                if (val) {
                    // submit();
                    emit('hasDeliveryOption', {
                        delivery_type: deliveryType.value,
                        selected_date: scheduleDate(selectedDate.value),
                        selected_date_formatted: selectedDate.value,
                        selected_time: val
                    })
                }
            },
            { immediate: true }
        );

        // watch(
        //     () => cart.value.scheduled_at,
        //     (val) => {
        //         const dt = DateTime.fromJSDate(new Date(val));
        //         selectedDate.value = dt.toISODate();
        //         selectedTime.value = dt.toFormat('HH:mm:00');
        //     },
        //     { immediate: true }
        // );

        const showDeliveryOption = (deliveryOption) => {
            if(deliveryOption.name == 'Delivery') {
                return cart.value.branch?.has_delivery>0;
            }

            if(deliveryOption.name == 'Pickup') {
                return cart.value.branch?.has_pickup>0;
            }            
        }

        const selectDateOption = (deliveryOption) => {
            const today = DateTime.now();
            const tomorrow = today.plus({ days: 1 });
            const dat = today.plus({ days: 2 });
            return [
                { id: 1, label: 'Today, '+ today.toFormat('LLL dd'), value: deliveryOption.name === 'Pickup' ? 'Pick Up now' : 'Delivery now' },
                { id: 2, label: 'Tomorrow, '+ tomorrow.toFormat('LLL dd'), value: tomorrow.toISODate() },
                { id: 3, label: dat.toFormat('cccc, LLL dd'), value: dat.toISODate() },
            ];
        }

        return {
            router,
            isIncomplete,
            deliverToday,
            deliveryType,
            selectedDate,
            selectedTime,
            deliveryOptions,
            timeOptions,
            dateOptions,
            // submit,
            showDeliveryOption,
            optTime,
            selectDateOption,
            dateToday,
            showTime
        }
    }

}
</script>