<template>
    <div class="w-10/12 pt-24">
        <!-- <p class="text-2xl font-semibold m-0 text-neutral-600 mb-9">Change Password</p> -->
        <p class="text-neutral-600">To save your changes please input your current password.</p>
        <div class="space-y-6 w-[343px] mb-10">
            <password-input
                v-model="password"
                id="password"
                placeholder="Password"
            />
        </div>
        <button-component
            size="large"
            color="primary"
            fill="outline"
            @click="checkPassword"
        >
            <template #content>
                <template v-if="!loading">
                    Save Changes
                </template>
                <template v-else>
                    <ion-spinner></ion-spinner>
                </template>
            </template>
        </button-component>
    </div>
</template>
<script type="text/javascript">
import {
    // IonPage,
    IonSpinner
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ref } from "@vue/reactivity";
import useResponseHandler from '@/composables/useResponseHandler';
import { useApiStore } from "@/store/api";
export default defineComponent({
    name: 'ConfirmChanges',
    props: {
        submitUrl: {
            type: String
        },
        params: {
            type: Object
        }
    },
    components: {
        ButtonComponent,
        IonSpinner,
        PasswordInput
    },
    setup(props) {
        const password = ref(null)
        const router = useRouter();
        const loading = ref(false);
        const { openResponseModal } = useResponseHandler();
        const apiStore = useApiStore();

        const checkPassword = () => {
            loading.value = true

            const data = {
                password: password.value
            }

            window.axios
            .post(apiStore.route("check-password"), data)
            .then((result) => {
                loading.value = false;
                if(result.data.status == 'OK') {
                    submit(data);
                }
            })
            .catch((error) => {
                loading.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const submit = (oldPass) => {
            const data = {
                password: props.params.newPassword,
                confirm_password: props.params.confirmPassword,
                oldPassword: oldPass.password
            }

            window.axios
            .post(props.submitUrl, data)
            .then((result) => {
                loading.value = false;
                console.log(result)
                openResponseModal({ title: "Success", description: "Password Changed.", buttonText: "Okay", buttonLink: "/account-details" });
            })
            .catch((error) => {
                loading.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        return {
            router,
            password,
            checkPassword,
            loading,
            submit
        }
    },
});
</script>
