import { ref } from 'vue';
import { Camera, CameraResultType } from '@capacitor/camera';
export default function useCamera() {

    const capturedFile = ref(null);

    const openCamera = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false ,
            resultType: CameraResultType.Base64,
        });

        let blob = b64toBlob(image.base64String, "image/jpg", 512);
        capturedFile.value = blob;
    }
    
    function b64toBlob(b64Data, contentType = 'image/jpg', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    return {
        openCamera,
        capturedFile
    }
}
