<template>
    
    <select required class="focus:outline-none focus:ring-0 focus:border-black text-sm font-normal block w-full border-gray-300 rounded-lg h-11 mb-4" @change="$emit('update:modelValue', $event.target.value)">
        <option selected disabled value>
            {{placeholder}}
        </option>

        <template 
            v-for="(option, index) in options" 
            :key="index"
        >
            <option :value="option.value">
                {{option.value}}
            </option>
        </template>
    </select>

</template>
<script>
export default {
    props: {
        modelValue: {
            default: null,
            // required: true,
        },
        placeholder: {
            type: String,
            default: null
        },
        options: {
            type: Array
        }
    }
}
</script>