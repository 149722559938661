<template>
    <profile-layout :title="'Favorite'">
        <p class="text-2xl font-semibold mb-[33px] hidden lg:block">Favorites</p>

            <div v-if="isLoading" class="flex items-center justify-center w-full h-full">
                <img src="/assets/images/loader.gif" class="h-auto w-14" />
            </div>

            <section v-else class="py-10 max-w-[1440px] m-auto">
                <div class="grid grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-8">
                    <template v-for="(item) in displayedFavorites" :key="item">
                        <div v-if="item.product.length > 0">
                            <product-card :product="item.product[0]" :key="item.product[0].id"></product-card>
                        </div>
                    </template>                    
                </div>

                <!-- Pagination -->
                <div v-if="filterFavorites.total > filterFavorites.per_page" class="flex items-center gap-4 p-5 m-auto pagination w-fit">
                    <ChevronLeftIcon 
                    @click="previousPage(page)" 
                    class="w-6 h-6 cursor-pointer" 
                    :class="[ page == 1 ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                    />
                    <paginate
                    v-model="page"
                    :page-count="pages.length"
                    :click-handler="paginate"
                    :container-class="'flex items-center justify-center space-x-4 pagination m-0'"
                    :page-class="'frm-paginate-btn rounded-full m-0 font-normal text-neutral-600 cursor-pointer hover:bg-neutral-200 transition'"
                    :active-class="'bg-amber-400 text-white'"
                    :hide-prev-next="true"
                    prev-text=""
                    next-text=""
                    >
                    </paginate>
                    <ChevronRightIcon 
                        @click="nextPage(page)"
                        class="w-6 h-6 cursor-pointer"
                        :class="[ page == pages.length ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
                        />
                </div>                                
                <div v-if="filterFavorites.total == 0">
                    <p class="text-sm font-normal text-center text-gray-900">No favorites available.</p>
                </div>
            </section>
    </profile-layout>
</template>

<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { onMounted, ref, computed, watch } from "@vue/runtime-core";
import { useRouter } from 'vue-router';
import ProductCard from '@/components/cards/ProductCard.vue';
import ProfileLayout from "./ProfileLayout.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { useApiStore } from "@/store/api";
import useProduct from '@/composables/useProduct';
import Paginate from 'vuejs-paginate-next';

export default defineComponent({
    name: 'FavoritePage',
    components: {
        ProductCard,
        ProfileLayout,
        ChevronLeftIcon,
        ChevronRightIcon,
        Paginate
    },
    setup() {
        const router = useRouter();
        const isLoading = ref(false);
        const apiStore = useApiStore();
        const { baseCatalogPayload } = useProduct();

        // PAGINATION
        const favorites = ref([]);
        const page = ref(1);
        const pages = ref([]);
        const filterFavorites = computed(() => {
            const items = ref(favorites.value);
            return items.value || [];
        });

        const displayedFavorites = computed(() => {
            return filterFavorites.value.data;
        });

        const fetchPayload = computed(() => {
            return Object.assign(baseCatalogPayload.value);
        });

        //  Paginate Products
        const paginate =(pageNumber)=> {

            page.value = pageNumber;

            fetch(
                params({
                    page: pageNumber
                })
            );
        }

        const previousPage = (currentPage = 1) => {
            if (currentPage > 1) {
                page.value = currentPage - 1;
                fetch(
                    params({
                        page: page.value
                    })
                );
            }            
        }

        const nextPage = (currentPage = 1) => {
            if (currentPage < pages.value.length) {
                page.value = currentPage + 1;
                fetch(
                    params({
                        page: page.value
                    })
                );
            }
        }

        //  Set Number of Pages for Pagination
        const setPages =(items)=> {
            let numberOfPages = items.last_page;

            for (let i = 1; i <= numberOfPages; i++) {
                pages.value.push(i);
            }
        }
        
         /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => filterFavorites.value,
            (items) => {
                setPages(items);
            }
        );

        const fetch = (params) => {
            isLoading.value = true;
            window.axios
                .get(apiStore.route("fetch-favorites"), { params: params })
                .then(({ data }) => {
                    favorites.value = data.favorites;
                    pages.value = [];
                    isLoading.value = false;
                })
                .catch(() => {
                    isLoading.value = false;
                    // modal.error(error.response.data.message);
                })
        };

        const params = (param) => {
            let addOns = param || { page: page.value };
            return { ...fetchPayload.value, ...addOns};
        }
        
        onMounted(() => {
            fetch(params());
        });

        return {
            router,
            fetch,
            displayedFavorites,
            pages,
            page,
            setPages,
            filterFavorites,
            paginate,
            previousPage,
            nextPage,
            favorites
        }
    },
});
</script>
