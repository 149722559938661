import { modalController } from '@ionic/vue';
import Modal from '@/components/dialogs/Modal.vue';

export default function useModal() {
    const error = async (errors) => {
        const modal = await modalController.create({
            component: Modal,
            cssClass: 'gen-modal',
            componentProps: {
                title: 'Warning!',
                description: errors,
                imageLink: '/assets/icon/ic-warning.svg',
                imageWidth: '80',
                imageHeight: '78',
                buttonText: "Okay"
            },
        });

        return modal.present();
    }

    const success = async (message, link, callback = null) => {
        const modal = await modalController.create({
            component: Modal,
            cssClass: 'gen-modal',
            componentProps: {
                title: 'Success!',
                description: message,
                imageLink: '/assets/icon/ic-success.svg',
                imageWidth: '80',
                imageHeight: '78',
                buttonText: "Okay",
                buttonLink: link,
            },
        });

        if (callback != null) {
            modal.onDidDismiss().then(() => {
                callback();
            });
        }

        return modal.present();
    }

    return {
        error: error,
        success: success,
    }
}
