<template>
    <button-component size="large" color="primary" :disabled="disabled" @click="addToCart">
        <template #content>
            Add to Bag
        </template>
    </button-component>
</template>

<script type="text/javascript">

import { defineComponent } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default defineComponent({
    name: 'add-to-bag',
    components: {
        ButtonComponent
    },
    props: {
        disabled:{
            type:Boolean,
            default:false
        }
    },
    emits:[ 'add' ],
    setup(props, { emit }) {
        const addToCart = () => {
            emit('add');
        };
        return {
            addToCart
        };
    }
});
</script>