<template>
    
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">
            <div v-if="isLoading" class="flex items-center justify-center w-full h-full">
                <img src="/assets/images/loader.gif" class="h-auto w-14" />
            </div>
            <div class="w-full lg:w-[88%] px-4 mx-auto pt-12 pb-[120px] lg:py-16" v-else>

                <div class="mb-12">
                    <h3 class="text-[28px] lg:text-[40px] text-primary-500 font-semibold">My Cart</h3>
                </div>

                <div class="flex flex-col lg:flex-row items-start justify-between lg:space-x-6">
                    <section class="flex-1 w-full">
                        <div class="rounded-[10px] bg-secondary-500/10 p-4 mb-6" v-if="amountToFreeDelivery > 0">
                            <div class="flex items-center">
                                <div class="flex-shrink-0 h-5">
                                    <ion-icon src="/assets/icon/ic-warning.svg" class="w-5 h-5"></ion-icon>
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-sm font-light text-gray-600">
                                        You’re <RenderPrice :value="amountToFreeDelivery" /> away from getting FREE SHIPPING on your order! (Minimum fee: <RenderPrice :value="minimumFee" />)
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div v-if="isAuthenticated">
                            <AddressSelection
                                @add-new="(value) => addNewAddres(value)"
                                :setAddresses="addresses"
                                :localAddress="address"
                            />
                        </div>
                        
                        <div v-else>
                            <!-- <template v-if="!showAddresslist"> -->
                                <template v-if="address">
                                    <div class="rounded-[10px] bg-secondary-500/10 py-5 pl-10 pr-4 lg:px-12 w-full">
                                        <div class="space-y-1">
                                            <p class="font-normal text-base">{{ address.label }}</p>
                                            <p class="font-light text-sm">{{ address.address_label }}</p>
                                        </div>
                                    </div>
                                </template>
                            <!-- </template> -->
                        </div>
                        
                        <div class="mt-16">
                            <h3 class="font-semibold text-2xl text-gray-600">Estimated Delivery</h3>
                            <DeliveryOption 
                                @hasSelectedPaymentMethod="(value) => hasSelectedPaymentMethod = value"
                                @hasDeliveryOption="(value) => hasDeliveryOption = value"
                            />
                        </div>

                        <div class="mt-16">
                            <h3 class="font-semibold text-2xl text-gray-600">Order Information</h3>
                            <OrderInformation />
                        </div>
                    </section>
                    
                    <section class="flex-none w-full lg:w-[456px]">
                        <div class="rounded-[10px] bg-secondary-500/10 p-4 lg:p-6">
                            <OrderSummary 
                                @show-vouchers="showVoucherModal = true"
                                @confirm-checkout="redirect"
                                :has-payment-method="hasSelectedPaymentMethod"
                                :has-delivery-option="hasDeliveryOption"
                            />
                        </div>
                    </section>
                </div>
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>

        <!---------------------------------------------------------------------------------------------- 
            NEW ADDRESS MODAL 
        ------------------------------------------------------------------------------------------------>
        <NewAddressModal
            :is-visible="showAddressModal"
            @close="showAddressModal = false"
            @addresses="(value) => addresses = value"
            :reset-form="resetModalFormAddress"
            @isLoading="(value) => isLoading = value"
        ></NewAddressModal>

        <!---------------------------------------------------------------------------------------------- 
            VOUCHER MODAL 
        ------------------------------------------------------------------------------------------------>
        <VoucherModal
            :is-visible="showVoucherModal"
            @close="showVoucherModal = false"
        ></VoucherModal>

        <!---------------------------------------------------------------------------------------------- 
            CONFIRMATION MODAL 
        ------------------------------------------------------------------------------------------------>
        <ConfirmationModal
            :is-visible="confirmCheckout"
            @close="confirmCheckout = false"
            title="Go to checkout"
            message="Before proceeding to checkout, would you prefer continuing as guest or log into an account?"
            cancelButtonLink="/checkout"
            cancelButtonText="Continue as Guest"
            buttonLink="/login"
            buttonText="Login"
        />

    </ion-page>

</template>
<script>
import { onMounted, ref, computed } from 'vue';
import { IonPage, IonContent, onIonViewWillEnter } from '@ionic/vue';

import { useCartStore } from '@/store/cart';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';
import useCart from '@/composables/useCart';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import AddressSelection from './components/AddressSelection.vue';
import DeliveryOption from './components/DeliveryOption.vue';
import OrderInformation from './components/OrderInformation.vue';
import RenderPrice from '@/components/RenderPrice.vue';
import OrderSummary from './components/OrderSummary.vue';
import NewAddressModal from '@/components/dialogs/AddressBook/NewAddressModal.vue';
import VoucherModal from '@/components/dialogs/Vouchers/VoucherModal.vue';
import ConfirmationModal from '@/components/dialogs/ConfirmationModal.vue';
import { useSettingsStore } from '@/store/settings';
import useLocalStorage from "@/composables/useLocalStorage.js";

export default {
    name: 'MyCart',
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
        AddressSelection,
        DeliveryOption,
        OrderInformation,
        OrderSummary,
        RenderPrice,
        NewAddressModal,
        VoucherModal,
        ConfirmationModal
    },
    setup() {
        const isLoading = ref(false);
        const showAddressModal = ref(false);
        const showVoucherModal = ref(false);
        const confirmCheckout = ref(false);
        const hasSelectedPaymentMethod = ref(false);
        const hasDeliveryOption = ref({});

        const router = useRouter();
        const cartStore = useCartStore();
        const user = useUserStore();
        const { cartParamsComplete, fetchCart } = useCart();
        const { localStorage } = useLocalStorage();

        const cart = computed(() => cartStore.data);

        const isAuthenticated = computed(() => user.token != null);
        const amountToFreeDelivery = computed(() => cart.value.min_free_delivery - cart.value.subtotal);

        const address = ref({});
        const addresses = ref({});
        const resetModalFormAddress = ref(false);
    

        // const address = computed(() => cart.value.data.customer_address || {});

        const settings = useSettingsStore();

        const fetchAddress = async()=> {
            const getaddress = await localStorage.value.get('addressData');
            address.value = getaddress;

        }

        const init = () => {
            if(cartParamsComplete.value) {
                fetchCart();
            }
            fetchAddress();
            isLoading.value = false;
        };

        const redirect = () => {
            if(isAuthenticated.value) {
                router.push('/checkout');
            } 
            else {
                confirmCheckout.value = true;
            }
        };

        const addNewAddres = (value) => {
            resetModalFormAddress.value = value;
            showAddressModal.value = true
        }

        const minimumFee = computed(() => settings.deliveryMethod('INHOUSE').min_free_delivery);

        onMounted(init);
        onIonViewWillEnter(init);

        return {
            showAddressModal,
            showVoucherModal,
            confirmCheckout,
            cart,
            amountToFreeDelivery,
            isAuthenticated,
            redirect,
            minimumFee,
            address,
            hasSelectedPaymentMethod,
            addresses,
            hasDeliveryOption,
            addNewAddres,
            resetModalFormAddress,
            isLoading
        }
    }
}
</script>
