import { computed, ref, onMounted } from "vue";
import { useApiStore } from '@/store/api';
import { useUserStore } from '@/store/user';
import useModal from "@/composables/modal";
// import useLocalStorage from "@/composables/useLocalStorage.js";

export default function useProduct() {
    
    const api = useApiStore();
    const user = useUserStore();
    // const { localStorage } = useLocalStorage();

    const modal = useModal();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const productLoader = ref(false);
    const productList = ref([]);
    
    const fetchProductsUrl = computed(() => api.route('fetch-products'));
    const payloadComplete = computed(() => typeof fetchProductsUrl.value === 'string' && (user.userData?.accountnum || user.guestId));

    const nearestBranch = ref(null); 

    const fetchNearestBranches = async () => {
        nearestBranch.value = null;
        // nearestBranch.value = await localStorage.value.get("nearestBranch");
    }

    const baseCatalogPayload = computed(() => ({
        branch_id: user?.userNearestBranch?.branch,
        guest_id: user.guestId,
        user_id: user.userData?.accountnum,
        platform: 'web'
    }));

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const fetchProducts = (params) => {
        const url = fetchProductsUrl.value;
        if(!productLoader.value && typeof url === 'string') {
            productLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    productLoader.value = false;
                    productList.value = data;
                    return data;
                })
                .catch((error) => {
                    productLoader.value = false;
                    if (error?.response?.data?.message) {
                        modal.error(error.response.data.message);
                    }
                    return [];
                });
        } 
        else {
            return Promise.resolve([]);
        }
    };

    onMounted(() => {
        fetchNearestBranches();
    });
    
    return {
        productLoader,
        payloadComplete,
        baseCatalogPayload,
        productList,
        fetchProducts
    }
}
