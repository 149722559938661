<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class=""
        :scroll-events="true"
        @ion-scroll="logScrolling($event)">
            <div class="max-w-[1440px] m-auto">
                <div class="pt-0 px-4 lg:py-[103px] lg:px-12 xl:px-[100px]">
                    <div class="flex justify-between flex-col lg:flex-row space-y-16 lg:space-y-0 lg:space-x-10 xl:space-x-[106px] pb-[75px] lg:pb-[85px]">
                        <div class="image-hldr lg:w-1/2">
                            <product-images
                                :items="images"
                            />
                        </div>
                        <div class="lg:w-1/2">
                            <template v-if="isLoading">
                                <div class="flex items-center justify-center w-full h-full">
                                    <img src="/assets/images/loader.gif" class="h-auto w-14" />
                                </div>
                            </template>
                            <template v-else>
                                <p class="mb-2 text-sm text-gray-400 uppercase">{{ product.category_name }}</p>
                                <h1 class="text-[40px] font-semibold text-gray-600 mb-10">
                                    {{ product.name }}
                                </h1>
                                <div class="flex items-center mb-4 space-x-2">
                                    <p v-if="product.promo_id" class="m-0 text-2xl font-normal text-gray-300 line-through">{{ moneyFormat(product.price_with_tax) }}</p>
                                    <p class="m-0 text-2xl font-semibold text-primary-500">{{ moneyFormat(product.discounted_price) }}</p>
                                </div>
                                <div class="bg-fuchsia-100 text-primary-500 text-xs leading-[24px] px-[6px] rounded-lg w-fit mb-4">
                                    {{ product.subcategory_name }}
                                </div>
                                <div class="flex items-center mb-4 space-x-2">
                                    <div class="flex space-x-[2px]">
                                        <img class="w-4 H-4" :src="renderStarIcon(1)" />
                                        <img class="w-4 H-4" :src="renderStarIcon(2)" />
                                        <img class="w-4 H-4" :src="renderStarIcon(3)" />
                                        <img class="w-4 H-4" :src="renderStarIcon(4)" />
                                        <img class="w-4 H-4" :src="renderStarIcon(5)" />
                                    </div>
                                    <p class="m-0 text-sm font-normal text-gray-800">({{ product.review_count }})</p>
                                </div>
                                <!-- <div class="mb-10 product-desc">
                                    <p>Storage: 90 C</p>
                                </div> -->
                                <div class="mb-10 counter">
                                    <product-counter v-model="quantity" :max="product.stocks" @updateQuantityByEvent="(value) => updateQuantityByEvent(value, true)"></product-counter>
                                </div>
                                <div class="flex items-center space-x-10">
                                    <div class="flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 button-hldr">
                                        <div class="flex space-x-4">
                                            <add-to-bag-button :disabled="!product.have_stocks || quantity > product.stocks || quantity == 0" @add="addToCartHandler(existingQuantity + quantity)"></add-to-bag-button>
                                            <buy-now-button :disabled="!product.have_stocks || quantity > product.stocks" @buy-now="buyNowHandler(existingQuantity + 1)"></buy-now-button>
                                        </div>
                                        <add-to-favorites-button v-if="userCanAddToFavorite" :product="product" :favorite="favorite" :loading="checkFavoriteLoading"></add-to-favorites-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="px-4 lg:px-[90px] lg:pt-[45px]">
                    <div class="flex border-primary-500  w-full  overflow-hidden m-auto mb-[46px] text-center"
                    :class="isScrolled ? 'lg:w-full fixed left-0 top-[170px] lg:top-[136px] bg-white z-[90]' : 'border rounded-lg w-[30%]'">
                        <div 
                        @click="scrollToDescription"
                        class="w-1/2 px-8 py-5 m-0 font-normal"
                        :class="descriptionContent ? 'bg-white text-primary-500 border-b-4 border-primary-500' : 'border-b-4 border-gray-200 bg-gray-200 text-white text-primary-500'">
                            Description
                        </div>
                        <div 
                        @click="scrollToReviews"
                        class="w-1/2">
                            <div class="w-full px-8 py-5 m-0 font-normal"
                            :class="reviewContent ? 'bg-white text-gray-400 mx-auto border-b-4 border-primary-500' : 'border-gray-200 border-b-4 bg-gray-200 text-primary-500'">
                                Reviews ({{ total_reviews <= 99 ? total_reviews : '99+' }})
                            </div>
                        </div>
                    </div>
                    <div class="relative">
                        <div ref="description" class="absolute offset-description -top-[200px]"></div>
                        <div v-if="product.description" class="w-full lg:w-[1049px] m-auto mb-[65px] frm-desc ck-content" v-html="product.description"></div>
                    </div>
                </div>
                <div class="lg:px-20 xl:px-[154px] pb-20 lg:pb-[134px] relative">
                    <div ref="reviews" class="absolute offset-review -top-[200px]"></div>
                    <p class="lg:pl-0 pl-4 text-2xl lg:text-[32px] font-semibold mb-[67px]">Reviews ({{ total_reviews <= 99 ? total_reviews : '99+' }})</p>
                    <product-review v-if="total_reviews" ></product-review>
                    <!-- <pagination></pagination> -->
                </div>
                <div class="related-items px-4 lg:px-12 lg:pt-[85px] pb-[102px]">
                    <p class="text-[32px] font-semibold mb-10 text-neutral-700">Related Items</p>
                    <div class="">
                        <product-card-slider :items="sortedRelatedItems" :redirectLink="'/shop?category='+product.category_id+'&subCategory='+product.subcategory_id" />
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">

import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import useCart from '@/composables/useCart';
import useFormat from '@/composables/useFormat';
import useProduct from '@/composables/useProduct';
import useResponseHandler from '@/composables/useResponseHandler';

import {
    IonContent,
    IonPage,
	// onIonViewWillEnter,
} from '@ionic/vue';
// import { StarIcon } from "@heroicons/vue/solid";
import AddToBagButton from '@/components/buttons/AddToBagButton.vue';
import BuyNowButton from '@/components/buttons/BuyNowButton.vue';
import AddToFavoritesButton from '@/components/buttons/AddToFavoritesButton.vue';
import ProductCardSlider from '@/components/sliders/ProductCardSlider.vue';
import ProductImages from '@/components/sliders/ProductImages.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ProductCounter from '@/components/inputs/QuantitySelector.vue';
import ProductReview from './ProductReview.vue';
import useReview from "@/composables/useReview";
import useLocalStorage from "@/composables/useLocalStorage.js";
import { useApiStore } from "@/store/api.js";

export default defineComponent({
    name: 'ProductView',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        // StarIcon,
        AddToBagButton,
        BuyNowButton,
        AddToFavoritesButton,
        ProductCardSlider,
        ProductImages,
        ProductReview,
        ProductCounter
    },
    data: () => ({
        isScrolled: false,
        descriptionContent: true,
        reviewContent: false,
    }),
    methods: {
        logScrolling(e) {
            if (e.detail.scrollTop > 1000) {
                this.isScrolled = true;
            } else if (e.detail.scrollTop < 1004) {
                this.isScrolled = false;
            }
        },
        scrollToReviews() {
            this.$refs["reviews"].scrollIntoView({ behavior: "smooth" });
            this.reviewContent = true;
            this.descriptionContent = false;
        },
        scrollToDescription() {
            this.$refs["description"].scrollIntoView({ behavior: "smooth" });
            this.reviewContent = false;
            this.descriptionContent = true;
        }
    },
    setup() {

        const route = useRoute();
        const router = useRouter();
        const cart = useCartStore();
        
        const { addToCart, updateCartItem, removeCartItem } = useCart();
        const { moneyFormat } = useFormat();
        const { baseCatalogPayload } = useProduct();
        const { successHandler, errorHandler } = useResponseHandler();
        const { updateStoreReview, total_reviews } = useReview();
        const { localStorage } = useLocalStorage();
        const apiStore = useApiStore();

        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */

        const isLoading = ref(false);
        const favorite = ref(false);
        const checkFavoriteLoading = ref(false);
        const product = ref([]);
        const relatedItems = ref([]);
        const quantity = ref(1);
        const existingQuantity = ref(0);
        const fetchPayload = computed(() => baseCatalogPayload.value);

        const cartItem = computed(() => cart.item(product.value.sku));
        const images = computed(() => {
            const images = product.value?.images || [];
            if (!images.length) {
                images.push('/assets/images/product-image.png', '/assets/images/product-image.png');
            }

            return images.map(image => ({ path: image }));
        });

        const sortedRelatedItems = computed(() => {
            const items = ref(relatedItems.value);
            let itemArray = Object.values(items.value);
            itemArray = itemArray.sort((a,b) => a.rating < b.rating ? 1 : -1);

            return itemArray;
        });

        const userCanAddToFavorite = computed(() => fetchPayload.value.user_id != undefined);

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

        const renderStarIcon = (score, icon = null) => {
            if(product.value.rating > score) {
                icon = '/assets/icon/ic-star.svg';
            }
            else if(product.value.rating > score - 1) {
                icon = '/assets/icon/ic-halfstar.svg';
            }
            else if(product.value.rating < score) {
                icon = '/assets/icon/ic-emptystar.svg';
            }
            return icon;
        };

        const init =()=> {
            if (fetchPayload.value.guest_id || fetchPayload.value.user_id) {
                fetch();
            }
        }

        const fetch =()=> {
            // if (!isLoading.value) {
                isLoading.value = true;
                window.axios
                    .get(apiStore.route('show-product', { product: route.params.id }), { params: fetchPayload.value })
                    .then(({ data }) => {
                        isLoading.value = false;
                        
                        product.value = data;
                        relatedItems.value = data.related_items;

                        updateStoreReview(data.reviews, data.review_count, data.rating);

                        checkFavorite();
                    })
                    .catch((error) => {
                        isLoading.value = false;
                        console.log(error);
                    })
            // }
        }

        const addToCartHandler = (quantity) => {
            if (quantity > 0) {
                addToCart({
                    product_id: product.value.sku,
                    quantity,
                    for_checkout: true
                })
                .then(message => {
                    if(message.status === 422) {
                        errorHandler(message);
                        return;
                    }

                    if(typeof message === 'string') {
                        successHandler({ 
                            type: 'toast',
                            description: message, 
                            message,
                            duration: 2000,
                            autoClose: true
                        });
                    }
                });
            }            
        };

        const buyNowHandler = (quantity) => {
            addToCart({
                product_id: product.value.sku,
                quantity,
                for_checkout: true
            })
            .then(message => {
                if (message.status === 422) {
                    // updateQuantityByEvent(quantity.value);
                    errorHandler(message);
                    return;
                }
                if(typeof message === 'string') {
                        successHandler({
                            type: 'toast',
                            description: message,
                            message,
                            duration: 2000,
                            autoClose: true
                        });
                    }
                router.push('/cart');
            });
        };

        const updateCartItemHandler = (quantity) => {
            if(quantity == cartItem.value.quantity) return;
            if(quantity === 0) {
                updateCartItem(cartItem.value.update_url, { quantity: quantity, for_checkout: true }).then(() =>  removeCartItem(cartItem.value.remove_url));
            } else {
                updateCartItem(cartItem.value.update_url, { quantity:quantity, for_checkout: true });
            }
            
        };

        const checkFavorite = async () => { 
            checkFavoriteLoading.value = true;

            const user = await localStorage.value.get("user");
            if (user?.id) {
                await window.axios
                .post(process.env.VUE_APP_API_URL + '/api/v1/customer/favorite/check', {
                    user_id: user.id,
                    product_id: product.value.sku,
                })
                .then((response) => {
                    favorite.value = response.data.data !== null;
                    
                })
                .catch((error) => {
                    //  errors.value = error.message;
                    // modal.error(errors.value);
                    // loading.value = false;
                    console.log(error);
                }).then(() => {
                    checkFavoriteLoading.value = false;

                });
            }            
        }

        const updateQuantityByEvent = (value = null, autoCart = false) => {
            const updateQuantity = (value || quantity.value) + existingQuantity.value;
            if (!autoCart) {
                if (!cartItem.value.product_id) {
                    addToCartHandler(updateQuantity);
                } else if (cartItem.value.product_id) {
                    updateCartItemHandler(updateQuantity);
                }
            }            
        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => fetchPayload.value.guest_id || fetchPayload.value.user_id,
            (bool) => {
                if (bool) fetch();
            }
        );

        watch(
            () => cartItem.value.quantity,
            (val) => {
                if(!isNaN(val)) {
                    existingQuantity.value = val;
                }
            },
            { immediate: true }
        );

        // watch(
        //     () => quantity.value,
        //     (val, oldVal) => {
        //         if(oldVal == 0 && !cartItem.value.product_id) {
        //             addToCartHandler(val);
        //         }
        //         else if(cartItem.value.product_id) {
        //             updateCartItemHandler(val);
        //         }
        //     }
        // );

        watch(
            // () => route.query.showUrl,
            () => route.params.id,
            () => {
                init();
            }
        );

        onMounted(init);

        // onIonViewWillEnter(init);
        

        return {
            router,
            product,
            relatedItems,
            quantity,
            images,
            moneyFormat,
            renderStarIcon,
            addToCartHandler,
            total_reviews,
            sortedRelatedItems,
            buyNowHandler,
            favorite,
            checkFavoriteLoading,
            userCanAddToFavorite,
            isLoading,
            updateQuantityByEvent,
            existingQuantity
        }
    },
});
</script>
