<template>
    <div class="mb-5 mt-5 flex lg:flex-row flex-col justify-between cursor-pointer">
        <a class="w-fit m-0 mb-4 lg:m-0" @click="viewOrder(item.id)">
            <div>
                <p class="text-xl font-normal text-neutral-600 mb-4">{{ item.invoice_no }}</p>
                <p class="text-sm text-neutral-600 mb-[10px]">Deliver from: {{ item.branch_name }}</p>
                <p class="text-sm text-neutral-600 mb-[10px]">Placed at {{ item.formatted_placed_on }}</p>
                <p class="text-sm text-neutral-600">{{ item.payment_method_name }}</p>
            </div>
        </a>
        <div class="text-left lg:text-right">
            <p class="font-normal text-neutral-600 mb-3">{{ item.formatted_order_status }}</p>
            <!-- hide if order status are cancelled or rejected -->
            <p class="text-sm text-neutral-600 mb-[28px]" v-if="item.order_status !== 7 && item.order_status !== 9">Points Earned: {{ item.points_earned }} pts.</p>
            <p class="text-xl text-primary-500 font-normal">{{ moneyFormat(item.total) }}</p>
            <template v-if="item.formatted_order_status === 'In Transit'">
                <div class="flex lg:justify-end w-full mt-6">
                    <ChatButton 
                    :invoice-id="item.id"
                    :rider="item.rider_name"
                    />
                </div>
            </template>
            <template v-if="item.formatted_order_status === 'Completed' && item.reviews.length > 0">
                <div class="flex lg:justify-end w-full mt-6">
                    <button-component size="default" color="primary" fill="outline">
                        <template #content>
                            Reorder Items
                        </template>
                    </button-component>
                </div>
            </template>
        </div>
    </div>
    <hr>
</template>
<script>
import { defineComponent } from "vue";
import useFormat from "@/composables/useFormat";
import ButtonComponent from '@/components/ButtonComponent.vue';
import ChatButton from '@/components/chat/ChatButton.vue';
export default defineComponent({
    name: "order-card",
    components: {
        ButtonComponent,
        ChatButton
    },
    props: {
        item: {
            type: Object,
            default:() => ({}),
        },
    },
    emits: ['viewOrder'],
    setup(props, { emit }) {
        const { moneyFormat } = useFormat();

        const viewOrder = (url) => {
            emit('viewOrder', url);
        }
        return {
            moneyFormat,
            viewOrder
        }
    }
});
</script>
