import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/home/Home.vue'
import AboutPage from '../views/about/About.vue'
import FaqPage from '../views/faq/Faq.vue'
import ShopPage from '../views/shop/ShopIndex.vue'
import Product from '../views/shop/ProductView.vue'
import Recipes from '../views/recipe/RecipeIndex.vue'
import Recipe from '../views/recipe/RecipeView.vue'
import Promo from '../views/promo/PromoIndex.vue'
import PromoDiscount from '../views/promo/DiscountIndex.vue';
import BestSeller from '../views/best-seller/BestSellerIndex.vue'
import NewProducts from '../views/new-products/NewProductsIndex.vue'
import Contact from '../views/contact/Contact.vue'
import PrivacyPolicy from '../views/generic-pages/PrivacyPolicy.vue'
import TermsAndConditions from '../views/generic-pages/TermsAndConditions.vue'
import RefundPolicy from '../views/generic-pages/RefundPolicy.vue';
import Stylesheet from "@/views/Stylesheet.vue";

// Profile
import Loyalty from '../views/profile/Loyalty.vue'
import OrderHistoryIndex from '../views/profile/OrderHistoryIndex.vue'
import OrderHistoryView from '../views/profile/OrderHistoryView.vue'
import Notification from '../views/profile/Notification.vue'
import Favorites from '../views/profile/Favorite.vue'
import AccountDetails from '../views/profile/PersonalInformation.vue'
import AddressBook from '../views/profile/AddressBook.vue'
import ChangePassword from '../views/profile/ChangePassword.vue'
import PasswordConfirmChanges from '../views/profile/PasswordConfirmChanges.vue'
import DeleteAccount from '../views/profile/DeleteAccount.vue'
import ConfirmDeletion from '../views/profile/ConfirmDeletion.vue'


// Auth pages
import LoginOptions from "@/views/auth/LoginOptions.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RegisterVerification from "@/views/auth/RegisterVerification.vue";
import ValidateUserOtp from "@/views/auth/ValidateUserOtp.vue";
import ForgotPassword from "@/views/auth/password/ForgotPassword.vue";
import ResetPassword from "@/views/auth/password/ResetPassword.vue";
import VerificationCode from "@/views/auth/password/VerificationCode.vue";

// Article pages
import NewsIndex from "@/views/news/NewsIndex.vue";
import NewsView from "@/views/news/NewsView.vue";
import BlogsIndex from "@/views/blogs/BlogsIndex.vue";
import BlogView from "@/views/blogs/BlogView.vue";

// Cart
import MyCart from "@/views/cart/MyCart.vue";

import Checkout from "@/views/checkout/Checkout.vue";
import CheckoutPayment from "@/views/checkout/CheckoutPayment.vue";
import OrderConfirmation from "@/views/checkout/OrderConfirmation.vue";
import OrderSuccess from "@/views/checkout/OrderSuccess.vue";
import OrderFail from "@/views/checkout/OrderFail.vue";
import OrderCancel from "@/views/checkout/OrderCancel.vue";
import MobileOrderSuccess from "@/views/checkout/MobileOrderSuccess.vue";
import MobileOrderFail from "@/views/checkout/MobileOrderFail.vue";
import MobileOrderCancel from "@/views/checkout/MobileOrderCancel.vue";
import useLocalStorage from "@/composables/useLocalStorage.js";

const { localStorage } = useLocalStorage();

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login-options',
    name: 'LoginOptions',
    component: LoginOptions
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/register-verification',
    name: 'RegisterVerification',
    component: RegisterVerification
  },
  {
    path: '/validate-user-otp',
    name: 'ValidateUserOtp',
    component: ValidateUserOtp
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/verification-code',
    name: 'VerificationCode',
    component: VerificationCode
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/news',
    name: 'NewsIndex',
    component: NewsIndex
  },
  {
    path: '/news/view/:id',
    name: 'NewsView',
    component: NewsView
  },
  {
    path: '/blogs',
    name: 'BlogsIndex',
    component: BlogsIndex
  },
  {
    path: '/blogs/view/:id',
    name: 'BlogView',
    component: BlogView
  },
  {
    path: '/about-us',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: FaqPage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/refund-policy',
    name: 'RefundPolicy',
    component: RefundPolicy
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/shop',
    name: 'ShopPage',
    component: ShopPage
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
  },
  {
    path: '/show/recipe/:id',
    name: 'Recipe',
    component: Recipe
  },
  {
    path: '/sale',
    name: 'Sale',
    component: Promo
  },
  {
    path: '/promo',
    name: 'Promo',
    component: PromoDiscount
  },
  {
    path: '/best-seller',
    name: 'Best Seller',
    component: BestSeller
  },
  {
    path: '/new-products',
    name: 'New Products',
    component: NewProducts
  },
  {
    path: '/cart',
    name: 'MyCart',
    component: MyCart
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/checkout-payment',
    name: 'CheckoutPayment',
    component: CheckoutPayment
  },
  {
    path: '/order-confirmation',
    name: 'OrderConfirmation',
    component: OrderConfirmation
  },
  {
    path: '/order-success',
    name: 'OrderSuccess',
    component: OrderSuccess
  },
  {
    path: '/order-fail',
    name: 'OrderFail',
    component: OrderFail
  },
  {
    path: '/order-cancel',
    name: 'OrderCancel',
    component: OrderCancel
  },
  {
    path: '/mobile-order-success',
    name: 'MobileOrderSuccess',
    component: MobileOrderSuccess
  },
  {
    path: '/mobile-order-fail',
    name: 'MobileOrderFail',
    component: MobileOrderFail
  },
  {
    path: '/mobile-order-cancel',
    name: 'MobileOrderCancel',
    component: MobileOrderCancel
  },
  {
    path: '/loyalty',
    name: 'Loyalty',
    component: Loyalty
  },
  {
    path: '/order-history',
    name: 'OrderHistoryIndex',
    component: OrderHistoryIndex
  },
  {
    path: '/order-history/item/:id',
    name: 'OrderHistoryView',
    component: OrderHistoryView
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: AccountDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: DeleteAccount,
    meta: { requiresAuth: true }
  },
  {
    path: '/confirm-deletion',
    name: 'ConfirmDeletion',
    component: ConfirmDeletion,
    meta: { requiresAuth: true }
  },
  {
    path: '/address-book',
    name: 'AddressBook',
    component: AddressBook
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/password-confirm-changes',
    name: 'PasswordConfirmChanges',
    component: PasswordConfirmChanges
  },
  {
    path: '/stylesheet',
    name: 'Stylesheet',
    component: Stylesheet
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta) {
      if (to.meta.requiresAuth) {
          // this route requires auth, check if logged in
          // if not, redirect to login page.
          if (!await localStorage.value.get("authToken")) {
              next({
                  path: '/home',
              })
          }
      }
  }

  next() // make sure to always call next()!
})

export default router
