<template>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative bg-white rounded-xl px-8 py-11 pb-0 text-left overflow-hidden shadow-xl transform transition-all w-fit">
                            <div class="w-[412px] max-h-[440px] overflow-auto scroll-custom pb-11">
                                <div class="flex items-center justify-between pb-6 border-b mb-6">
                                    <p class="font-normal text-2xl text-neutral-600 m-0">Rate Service</p>
                                </div>
                                <p class="text-sm text-neutral-600 mb-6">Rate the items on your order:</p>
                                <div class="space-y-6">
                                    <div class="pb-6 border-b">
                                        <div class="flex space-x-5 mb-4">
                                            <div class="w-[71px] h-[82px] rounded-[6px] border">
                                                <img class="w-full h-full object-cover" src="/assets/images/placeholder.png" alt="">
                                            </div>
                                            <div class="w-[180px]">
                                                <p class="font-normal text-neutral-600 mb-3">Rider Name</p> 
                                                <div class="flex space-x-[11px]">
                                                    <StarIcon class="w-5 h-5 text-amber-500" />
                                                    <StarIcon class="w-5 h-5 text-amber-500" />
                                                    <StarIcon class="w-5 h-5 text-amber-500" />
                                                    <StarIcon class="w-5 h-5 text-amber-500" />
                                                    <StarIcon class="w-5 h-5 text-amber-500" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="text-xs text-gray-300 mb-2">REMARKS</p>
                                            <text-input
                                                type="text"
                                                placeholder="Input Remarks"
                                                customClass="placeholder:font-thin bg-gray-50"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button-component
                                    size="default"
                                    color="primary"
                                    customClass="font-thin"
                                    expand="block"
                                    @click="openSuccessModal() || closeThis()"
                                >
                                    <template #content>
                                        Submit Rating
                                    </template>
                                </button-component>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <success-modal 
        :is-visible="showSuccessModal"
        @close="showSuccessModal = false"
    ></success-modal>

</template>
<script>
import { ref, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { StarIcon } from "@heroicons/vue/solid";
import TextInput from '@/components/inputs/TextInput.vue';
import SuccessModal from '@/components/dialogs/SuccessModal.vue';

export default {
    name: 'RateRiderModal.vue',
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        StarIcon,
        ButtonComponent,
        TextInput,
        SuccessModal
        // LocationMarkerIcon,
        // ButtonComponent
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const closeThis =()=> open.value = false;

        const showLargeMap = ref(false);

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        function openSuccessModal() {
            showSuccessModal.value = true;
        }

        const showSuccessModal = ref(false);

        return {
            open,
            closeThis,
            showLargeMap,
            openSuccessModal,
            showSuccessModal
        }
    }
}
</script>