import { computed, ref } from "vue";
// import { useRoute } from "vue-router";
import { useApiStore } from '@/store/api';
import { useCartStore } from "@/store/cart";
import { useUserStore } from '@/store/user';
import useLocalStorage from '@/composables/useLocalStorage';
import useResponseHandler from '@/composables/useResponseHandler';

export default function useCart() {
    
    // const route = useRoute();

    const api = useApiStore();
    const cart = useCartStore();
    const user = useUserStore();

    const { localStorage } = useLocalStorage();
    const { successHandler } = useResponseHandler();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const cartLoader = ref(false);
    const fetchCartUrl = computed(() => api.route('fetch-cart'));

    const cartParamsComplete = computed(() => typeof fetchCartUrl.value === 'string' && (user.guestId || user.userData?.accountnum));
    const cartFetchParams = computed(() => ({
        branch_id: user?.userNearestBranch?.branch,
        distance: user?.userNearestBranch?.routes?.distance || 0,
        guest_id: user.guestId,
        user_id: user.userData?.accountnum
    }));

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const fetchCart = (params = {}) => {
        if (!user?.userNearestBranch?.branch) {
            return;
        }
        const url = fetchCartUrl.value;
        params = Object.assign(cartFetchParams.value, params);
        params.on_checkout = true;
        // params.on_checkout = ['MyCart','CheckOut'].includes(route.name) ? 1 : 0;

        if(!cartLoader.value && typeof url === 'string') {
            cartLoader.value = true;
            return window.axios
                .get(url, { params })
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data);
                    return data;
                })
                .catch(() => {
                    cartLoader.value = false;
                    saveCart({});
                    return {};
                });
        } 
        else {
            saveCart({});
            return Promise.resolve({});
        }
    };

    const updateCart = (params) => {
        
        const url = cart.updateUrl;
        params.on_checkout = true;
        // params.on_checkout = ['MyCart','CheckOut'].includes(route.name) ? 1 : 0;

        if(!cartLoader.value && typeof url === 'string') {
            cartLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data.cart);
                    return data.message;
                })
                .catch(() => {
                    cartLoader.value = false;
                });
        } 
        else {
            return Promise.resolve();
        }
    };

    const addToCart = (params) => {
        params.on_checkout = true;
        const url = cart.addToCartUrl;
        if(!cartLoader.value && typeof url === 'string') {
            cartLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data.cart);
                    successHandler({
                        type: 'toast',
                        description: data.message,
                        duration: 2000,
                        autoClose: true
                    });
                    return data.message;
                })
                .catch((error) => {
                    cartLoader.value = false;
                    return error.response;
                })
        }
        else {
            return Promise.resolve();
        }
    };

    const updateCartItem = (url, params) => {
        params.on_checkout = true;
        // params.on_checkout = ['MyCart','CheckOut'].includes(route.name) ? 1 : 0;
        if(!cartLoader.value && typeof url === 'string') {
            cartLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data.cart);
                    successHandler({
                        type: 'toast',
                        description: data.message,
                        duration: 2000,
                        autoClose: true
                    });
                    return data.message;
                })
                .catch((error) => {
                    cartLoader.value = false;
                    return error.response;
                })
        }
        else {
            return Promise.resolve();
        }
    };

    const removeCartItem = (url) => {
        if(!cartLoader.value && typeof url === 'string') {
            cartLoader.value = true;
            return window.axios
                .delete(url)
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data.cart);
                    successHandler({
                        type: 'toast',
                        description: data.message,
                        duration: 2000,
                        autoClose: true
                    });
                    return data.message;
                })
                .catch(() => {
                    cartLoader.value = false;
                })
        }
        else {
            return Promise.resolve();
        }
    };

    const removeCartItems = (urls) => {

        cartLoader.value = true;
        if (urls) {
            urls.forEach(url => {
                return window.axios
                .delete(url)
                .then(({ data }) => {
                    cartLoader.value = false;
                    saveCart(data.cart);
                    successHandler({
                        type: 'toast',
                        description: data.message,
                        duration: 2000,
                        autoClose: true
                    });
                    return data.message;
                })
                .catch(() => {
                    cartLoader.value = false;
                })
            });
        }    
    };

    const saveCart = (data) => {
        data.items = data.items?.filter((item) => {
            if (item.for_checkout) {
                return item;
            }
        });
        cart.data = data;
        localStorage.value.set('cart', data);
    };
    
    return {
        cartLoader,
        cartParamsComplete,
        cartFetchParams,
        fetchCart,
        updateCart,
        addToCart,
        updateCartItem,
        removeCartItem,
        removeCartItems
    }
}
