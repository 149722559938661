<template>
    <div class="w-full">
        <label :for="id" class="block text-xs text-neutral-500 font-light uppercase">{{
            label
        }}</label>
        <div
            class="relative rounded-lg border border-gray-300 focus-within:border-black overflow-hidden"
            :class="[customContainerClass, add_on_right || add_on_left ? 'flex' : '', label ? 'mt-2' : '', disabled ? 'border-gray-100' : '']"
        >
            <textarea
                v-if="textarea"
                :name="name"
                :id="id"
                :rows="textAreaRows"
                class="
                    block
                    w-full
                    text-sm
                    disabled:bg-gray-100
                    focus:outline-none
                    focus:ring-0
                    focus:border-black 
                    border-0
                    text-black 
                    placeholder:text-[#BBB7B6]
                    rounded-lg
                    font-normal
                "
                :class="
                    error
                        ? 'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                        : 'text-gray-800 border-gray-300 placeholder-gray-500 focus:border-gray-900'
                "
                :placeholder="placeholder"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :disabled="disabled"
                :readonly="readonly"
                :maxlength="maxlength"
            />

            <span
                v-if="add_on_left"
                class="
                    inline-flex
                    items-center
                    px-3
                    rounded-l-[10px]
                    text-[#BBB7B6]
                    text-sm
                    m-0
                    font-normal
                "
                >{{ add_on_text }}</span
            >

            <input
                v-if="!textarea"
                :type="type"
                :name="name"
                :id="id"
                class="
                    block
                    w-full
                    text-sm
                    h-11
                    disabled:bg-gray-100
                    focus:outline-none
                    focus:ring-0
                    focus:border-black 
                    rounded-lg
                    text-gray-800
                    border-0
                    placeholder:text-[#BBB7B6]
                    font-normal
                "
                :class="[
                    customClass,
                    error
                        ? 'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                        : add_on_left
                        ? 'text-gray-800 placeholder-gray-500 flex-1 min-w-0 block w-full px-3 py-2.5 pl-0 rounded-none rounded-r-md focus:ring-gray-900 focus:border-gray-900 text-sm border-gray-300'
                        : add_on_right
                        ? 'text-gray-800 placeholder-gray-500 flex-1 min-w-0 block w-full px-3 py-2.5 rounded-none rounded-l-md focus:ring-gray-900 focus:border-gray-900 text-sm border-gray-300'
                        : 'text-gray-800 border-gray-300 placeholder-gray-400 focus:border-gray-900 rounded-md'
                ]"
                :placeholder="placeholder"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :disabled="disabled"
                :readonly="readonly"
                :min="min"
                :max="max"
                :step="step"
                :maxlength="maxlength"
            />

            <span
                v-if="add_on_right"
                class="
                    inline-flex
                    items-center
                    px-3
                    rounded-r-md
                    border border-l-0 border-gray-300
                    bg-gray-50
                    text-gray-500
                    text-sm
                    m-0
                "
                >{{ add_on_text }}</span
            >
            <div
                v-if="error"
                class="
                    absolute
                    inset-y-0
                    right-0
                    pr-3
                    flex
                    items-center
                    pointer-events-none
                "
            >
                <ExclamationCircleIcon
                    class="h-5 w-5 text-red-500"
                    aria-hidden="true"
                />
            </div>
        </div>

        <small
            class="block text-right mt-1"
            :class="[
                modelValue.length >= maxlength
                    ? 'text-red-600'
                    : 'text-gray-400',
            ]"
            v-if="showInputLimit"
        >
            {{ modelValue.length }}/{{ maxlength }}
        </small>

        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
    components: {
        ExclamationCircleIcon,
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        helper: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: "text",
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        textAreaRows: {
            type: Number,
            default: 10,
        },
        add_on_right: {
            type: Boolean,
            default: false,
        },
        add_on_text: {
            type: String,
            default: null,
        },
        add_on_left: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
        },
        step: {
            type: Number,
            default: 1,
        },
        maxlength: {
            type: Number,
        },
        showInputLimit: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: "",
        },
        customContainerClass: {
            type: String,
            default: "",
        }
    },
};
</script>
