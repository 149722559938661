<template>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-center justify-center min-h-full text-center p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 translate-y-0 scale-95" enter-to="opacity-100 translate-y-0 scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 scale-100" leave-to="opacity-0 translate-y-4 translate-y-0 scale-95">
                        <DialogPanel class="relative bg-white rounded-lg p-0 text-left overflow-hidden shadow-xl transform transition-all my-8 w-full lg:w-fit lg:p-6">
                            <template v-if="!showLargeMap">
                                <div class="w-full lg:w-[680px] lg:max-h-[582px] xl:max-h-none overflow-auto scroll-custom">
                                    <Form
                                        :address-form="form"
                                        @view-map="(updatedAddressForm) => viewMap(updatedAddressForm)"
                                        @cancel="closeThis"
                                        @submit="(updatedAddressForm) => submitForm(updatedAddressForm)"
                                        @delete="deleteAddress()"
                                        :error="addressError"
                                        :reset-form="resetForm"
                                    ></Form>
                                </div>
                            </template>
                            <template v-if="showLargeMap">
                                <div class="w-[980px]">
                                    <LargeMap
                                        :address-form="form"
                                        @go-back="showLargeMap = false"
                                        @cancel="closeThis"
                                        @submit="(updatedAddressForm) => submitForm(updatedAddressForm)"
                                    ></LargeMap>
                                </div>
                            </template>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
<script>
import {
    onIonViewDidEnter,
    onIonViewWillEnter
} from "@ionic/vue";
import { ref, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

import LargeMap from '@/components/dialogs/AddressBook/LargeMap.vue';
import Form from '@/components/dialogs/AddressBook/Form.vue';
import { useAddressStore } from "@/store/address.js";
import useLocation from "@/composables/useLocation";
import useModal from "@/composables/modal";
import { useApiStore } from "@/store/api.js";
import useCart from "@/composables/useCart";
import { useCartStore } from '@/store/cart';

export default {
    name: 'NewAddressModal',
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        Form,
        LargeMap,
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        resetForm: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const modal = useModal();
        const addressStore = useAddressStore();
        const apiStore = useApiStore();
        const { fetchCart } = useCart();
        const cartStore = useCartStore();

        const {
            locationState,
            submitCustomerLocation,
            // googleSearch,
        } = useLocation();

        const open = ref(false);
        const showLargeMap = ref(false);
        const addressError = ref(null);
        const addresses = ref(null);

        const form = ref({
            id: null,
            label: null,
            address_label: null,
            address_details: null,
            latitude: null,
            longitude: null,
            notes: null,
            cart_id: null,
            is_default: false
        });

        const closeThis = () => {
            open.value = false
        };

        const setAddressValues = (address) => {
            const keys = Object.keys(form.value);
            if (keys) {
                keys.forEach(key => form.value[key] = address[key]);
            }
        };

        const clearForm = () => {
            form.value.id = null;
            form.value.label = null;
            form.value.address_label = null;
            form.value.address_details = null;
            form.value.latitude = null;
            form.value.longitude = null;
            form.value.notes = null;
            form.value.cart_id = null; 
            form.value.is_default = false;
            addressError.value = null;
        };

        const saveAddress = () => {
            // loading.value = true;

            form.value.latitude = locationState.customerLat;
            form.value.longitude = locationState.customerLng;
            form.value.address_label = locationState.search;
            // form.value.address_label = locationState.completeAddress || locationState.search;
            form.value.cart_id = cartStore.data?.id;

            submitCustomerLocation(form.value)
                .then(({ data }) => {
                    // Clear values once request succeeds
                    clearForm();
                    // Toggle off loading
                    // loading.value = false;
                    closeThis();
                    fetchAddresses();
                    fetchCart();
                    // Display success modal
                    modal.success(data.message, '', function() {
                        emit('isLoading', true);
                        window.location.reload();
                    });
                })
                .catch((error) => {
                    // loading.value = false;
                    // modal.error(error.response.data.message);
                    addressError.value = error.response?.data?.message;
                });
        };

        const submitForm = (address) => {
            setAddressValues(address);
            saveAddress();
        }

        const viewMap = (address) => {
            setAddressValues(address);
            showLargeMap.value = true;
        }

        const deleteAddress = () => {
            // loading.value = true;
            window.axios
                .delete(apiStore.route("destroy-address", { address: form.value.id }))
                .then(({ data }) => {
                    // loading.value = false;
                    closeThis();
                    fetchAddresses();
                    fetchCart();
                    modal.success(data.message);
                })
                .catch((error) => {
                    // loading.value = false;
                    // modal.error(error.response.data.message);
                    addressError.value = error.response.data.message;
                });
        };

        const setDefaultAddressLabel = () => {
            form.value.address_label = locationState.addressCandidates.length > 0 
                ? locationState.addressCandidates[0]["formattedAddress"] 
                : 'Select address from the map';
        }

        const fetchAddresses = () => {
            window.axios
                .get(apiStore.route("fetch-addresses"))
                .then(({ data }) => {
                    addresses.value = data.addresses;
                    emit('addresses', data.addresses)
                })
                .catch((error) => {
                    modal.error(error.response.data.message)
                });
        };

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) {
                    showLargeMap.value = false;
                    // addressStore.selectedAddress = {};
                    clearForm();
                    emit("close");
                } else {
                    setDefaultAddressLabel();
                    if(addressStore.hasSelectedAddress) {
                        setAddressValues(addressStore.selectedAddress);
                    }
                }

                if (props.resetForm) {
                    clearForm();
                }
            }
        );

        // onMounted(() => {
        //     fetchAddresses();
        //     console.log("on mounted");
        // });

        onIonViewDidEnter(() => {
            fetchAddresses();
        });

        onIonViewWillEnter(() => {
            fetchAddresses();
        });

        return {
            open,
            closeThis,
            submitForm,
            deleteAddress,
            viewMap,
            showLargeMap,
            form,
            addressError
        }
    }
}
</script>