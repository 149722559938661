import { computed, ref } from "vue";
// import { useRouter } from "vue-router";
import { useApiStore } from '@/store/api.js';
import { useChatStore } from '@/store/chat.js';
import useLocalStorage from "@/composables/useLocalStorage.js";
import useModal from "@/composables/modal";

export default function useChat() {

    // const router = useRouter();
    const apiStore = useApiStore();
    const chatStore = useChatStore();
    const { localStorage } = useLocalStorage();
    const modal = useModal();

    const chatLoader = ref(false);
    const errors = ref(false);

    const chat = computed(() => chatStore.chat);

    const fetchChatUrl = computed(() => apiStore.route("fetchChat"));
    const sendChatUrl = computed(() => apiStore.route("sendChat"));

    const fetchChat = (url, payload) => {
        if(chatLoader.value) return;
        chatLoader.value = true;
        
        window.axios
            .post(url || fetchChatUrl.value, { invoice_id : payload })
            .then((response) => {
                updateStoreChat(response.data)
            })
            .catch((error) => {
                requestFail(error.response.data.message)
            });
    };

    const sendChat = (url, payload) => {
        if(chatLoader.value) return;
        chatLoader.value = true;

        window.axios
            .post(url || sendChatUrl.value, payload)
            .then((response) => {
                updateStoreChat(response.data)
            })
            .catch((error) => {
                requestFail(error.response.data.message)
            });
    };

    /**
     * Success handler to update store chat
     * 
     * @param   object { data }
     * @returns void
     */
    
    const updateStoreChat = (data) => {
        chatStore.chat = data.chat ? data.chat : chatStore.chat ;

        localStorage.value.set('chat', data.chat ? data.chat : chatStore.chat );
        
        chatLoader.value = false;
    }

    const requestFail = (error) => {
        errors.value = error;
        modal.error(errors.value);

        chatLoader.value = false;
    };

    return {
        chatLoader,

        fetchChatUrl,
        sendChatUrl,

        fetchChat,
        sendChat,

        chat,
    }
}