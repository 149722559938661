<template>

    <ion-page>
        <div v-if="isLoading" class="flex items-center justify-center w-full h-full">
            <img src="/assets/images/loader.gif" class="h-auto w-14" />
        </div>
        <template v-else>
            <top-navigation></top-navigation>
            <ion-content :fullscreen="true">

                <div class="w-full px-4 lg:px-0 lg:w-[88%] mx-auto pt-12 pb-[120px] lg:py-16">
                    
                    <div class="lg:mb-12 mb-10">
                        <h3 class="text-[28px] lg:text-[40px] text-primary-500 font-semibold">Checkout</h3>
                    </div>

                    <div class="flex items-start justify-between flex-col lg:flex-row space-x-0 lg:space-x-6">
                        <section class="flex-1 w-full lg:mb-0 mb-10">
                            <div class="mb-[21px]">
                                <p class="text-neutral-600 font-semibold text-2xl">Delivery Address</p>
                            </div>

                            <!---------------------------------------------- 
                                GUEST CHECKOUT 
                            ----------------------------------------------->
                            <div class="mb-10 lg:mb-16" v-if="!isAuthenticated">
                                <GuestDetails />
                            </div>

                            <div class="mb-10 lg:mb-16" v-if="isAuthenticated">
                                <AddressSelection
                                :view-only="true"
                                :localAddress="localAddress"
                                />
                            </div>

                            <div class="mb-4 lg:mb-[21px]">
                                <p class="text-neutral-600 font-semibold text-2xl">Payment Method</p>
                            </div>
                            <div class="mb-4 lg:mb-10">
                                <RadioGroup v-model="selectedPaymentMethod" :disabled="loading">
                                    <div class="grid grid-cols-1 gap-y-6">
                                        <template v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
                                            <template v-if="paymentMethod.is_enabled > 0">
                                                <template v-if="paymentMethod.code === 'PAYMAYA_CREDIT_CARD'">
                                                <RadioGroupOption as="template" :value="paymentMethod.code" v-slot="{ checked, active }">
                                                    <div :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                                                    <CheckCircleIcon
                                                        :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500 translate-y-[2px]']"
                                                        aria-hidden="true" />
                                                    <div class="pl-1">
                                                        <p class="font-normal text-neutral-600">{{ paymentMethod.name }}</p>
                                                        <p class="font-normal text-fhmgray mb-[6px]">{{ paymentMethod.description }}</p>
                                                        <img src="/assets/images/payment.png" alt="">
                                                    </div>
                                                    <span
                                                        :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                                                        aria-hidden="true" />
                                                    </div>                                     
                                                </RadioGroupOption>
                                                </template>

                                                <template v-if="deliveryType == 1 && paymentMethod.code === 'CASH'">
                                                <RadioGroupOption as="template" :value="paymentMethod.code" v-slot="{ checked, active }">
                                                    <div :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                                                    <CheckCircleIcon
                                                        :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500 translate-y-[2px]']"
                                                        aria-hidden="true" />
                                                    <div class="pl-1">
                                                        <p class="font-normal text-neutral-600">{{ paymentMethod.name }}</p>
                                                        <p class="font-normal text-fhmgray">{{ paymentMethod.description }}</p>
                                                    </div>                                                
                                                    <span
                                                        :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                                                        aria-hidden="true" />
                                                    </div>                                     
                                                </RadioGroupOption>
                                                </template>

                                                <template v-else-if="deliveryType != 1 && paymentMethod.code === 'PICKUP'">
                                                <RadioGroupOption as="template" :value="paymentMethod.code" v-slot="{ checked, active }">
                                                    <div :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                                                    <CheckCircleIcon
                                                        :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500 translate-y-[2px]']"
                                                        aria-hidden="true" />
                                                    <div class="pl-1">
                                                        <p class="font-normal text-neutral-600">{{ paymentMethod.name }}</p>
                                                        <p class="font-normal text-fhmgray">{{ paymentMethod.description }}</p>
                                                    </div>                                                
                                                    <span
                                                        :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                                                        aria-hidden="true" />
                                                    </div>                                     
                                                </RadioGroupOption>
                                                </template>
                                            </template>   
                                        </template>
                                    </div>
                                </RadioGroup>
                            </div>
                            <a class="w-fit m-0" href="/shop">
                                <div class="flex items-center">
                                    <ChevronLeftIcon class="w-5 h-5 text-fhmgray" />
                                    <p class="text-fhmgray text-sm m-0 font-normal">RETURN TO SHOPPING</p>
                                </div>
                            </a>
                        </section>

                        <section class="flex-none w-full lg:w-[456px]">
                            <div class="mb-[21px]">
                                <p class="text-neutral-600 font-semibold text-2xl">Order Summary</p>
                            </div>
                            <div class="rounded-[10px] bg-secondary-500/10 p-6">
                                <OrderSummary 
                                :checkout="true"
                                :loading="loading"
                                :has-payment-method="selectedPaymentMethod ? true : false"
                                @submit-checkout="submit"
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <bottom-navigation></bottom-navigation>
            </ion-content>
        </template>        
    </ion-page>

</template>
<script type="text/javascript">
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { IonPage, IonContent } from '@ionic/vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/vue/solid';
import OrderSummary from '@/views/cart/components/OrderSummary.vue';
import AddressSelection from '@/views/cart/components/AddressSelection.vue';
import GuestDetails from '@/views/checkout/components/GuestDetails.vue';

// import { useRouter } from 'vue-router';
import { useSettingsStore } from '@/store/settings';
import { useAddressStore } from '@/store/address';
import { useUserStore } from '@/store/user';
import useCart from '@/composables/useCart';
import useCheckout from '@/composables/useCheckout';
import { ref, computed, watch, onMounted } from 'vue';
import { useCartStore } from '@/store/cart';
import useModal from '@/composables/modal';
import useLocalStorage from "@/composables/useLocalStorage.js";

export default {
    name: 'CheckOut',
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
        RadioGroup,
        RadioGroupOption,
        CheckCircleIcon,
        ChevronLeftIcon,
        OrderSummary,
        AddressSelection,
        GuestDetails
    },
    setup() {

        // const router = useRouter();
        const settings = useSettingsStore();
        const address = useAddressStore();
        const user = useUserStore();
        const cartStore = useCartStore();
        const { localStorage } = useLocalStorage();

        const { updateCart, fetchCart } = useCart();
        const { checkout } = useCheckout();

        const paymentMethods = computed(() => settings.paymentMethods);
        const deliveryType = computed(() => cartStore.data?.delivery_type);

        const selectedPaymentMethod = ref(null)
        const isAuthenticated = computed(() => user.token != null);
        const modal = useModal();
        const localAddress = ref({});

        const loading = ref(false);
        const isLoading = ref(false);
        const submit = () => {
            if (!selectedPaymentMethod.value) {
                modal.error('Please select payment method before checkout!');
                return;
            }
            loading.value = true;
            const params = Object.assign(address.data, user.guestData);
            checkout(params)
                .then(data => {
                    loading.value = false;
                    if(data?.status == 'SUCCESS' || data?.status == 'PICKUP') {
                        if(isAuthenticated.value) {
                            redirect(data);
                        } 
                        else {
                            resetGuestCart(data);
                        }
                    }
                    else if(typeof data?.status === 'string') {
                        // user.userInvoice = data.invoice;
                        window.location.href = data.response.original.payment.response.redirectUrl;
                        // router.push('/checkout-payment');
                    }
                }).catch(() => loading.value = false);
        };

        const resetGuestCart = (data) => {
            user.resetGuestId();
            user.guestData = {};
            updateCart({ guest_id: user.guestId })
                .then(message => {
                    if(typeof message === 'string') {
                        redirect(data);
                    }
                });
        };

        const redirect = (data) => {
            window.location.href = `/order-confirmation?invoiceNo=${data.invoice.invoice_no}`;
            // router.push(`/order-confirmation?invoiceNo=${data.invoice.invoice_no}`);
        };

        const fetchAddress = async () => {
            const getAddress = await localStorage.value.get('addressData');
            localAddress.value = getAddress;
        }

        watch(
            () => selectedPaymentMethod.value,
            (val) => {
                if (val) {
                    updateCart({ payment_method: val })
                }
            },
            { immediate: true }
        );

        onMounted(() => {
            fetchAddress()
            isLoading.value = true;
            Promise.allSettled([fetchCart()]).then(() => isLoading.value = false);
        });

        return {
            paymentMethods,
            selectedPaymentMethod,
            updateCart,
            submit,
            isAuthenticated,
            deliveryType,
            loading,
            isLoading,
            localAddress
        }
    }
}
</script>
