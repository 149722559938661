import { useApiStore } from '@/store/api';
import { useUserStore } from '@/store/user';
import useModal from './modal.js';
import useLocalStorage from "./useLocalStorage.js";
import { ref } from 'vue';
// import { useRouter } from "vue-router";
import { useAddressStore } from '@/store/address.js';

export default function useAuth() {
    const apiStore = useApiStore();
    const userStore = useUserStore();
    const addressStore = useAddressStore();
    const modal = useModal();
    const errors = ref([]);
    const { localStorage } = useLocalStorage();
    // const router = useRouter();

    const assignToken = async () => {
        return localStorage.value.get("authToken")
            .then((token) => {
                window.axios.defaults.headers.common["Authorization"] = token;
                userStore.token = token;

                return true;
            }).catch((error) => {
                errors.value = error.response.data.errors.message;
                modal.error(errors.value);
            })
    };

    const assignUser = async () => {
        return localStorage.value.get("user")
            .then((user) => {
                userStore.userData = user;
                return true;
            }).catch((error) => {
                errors.value = error.response.data.errors.message;
                modal.error(errors.value);
            })
    };

    const setToken = (token, user) => {
        window.axios.defaults.headers.common["Authorization"] = token;

        localStorage.value.set("authToken", token);
        localStorage.value.set("user", user);

        userStore.token = token;
        userStore.userData = user;

        fetchUserData();
    }

    const logout = () => {
        localStorage.value.delete("authToken");
        localStorage.value.delete("user");

        userStore.token = null;
        userStore.userData = null;

        // Redirect to the home page
        window.location.href = '/login';
        // router.replace('/login')
    }

    const fetchUserData = async () => {
        // get updates from user
        if (await localStorage.value.get("authToken")) {

            window.axios
                .post(
                    apiStore.route('fetch-profile'))
                .then((result) => {
                    localStorage.value.set("user", result.data.item);
                    userStore.setUser = result.data.user;
                })
                .catch(({ response }) => {
                    if(response.status == 401) {
                        userStore.setGuestId();
                    }
                });
                
        } else {
            userStore.setGuestId();
        }

        await addressStore.reassignAddressState("addressCoords");
        await addressStore.reassignAddressState("addressData");
        return true;
    }

    return {
        setToken: setToken,
        assignToken: assignToken,
        assignUser: assignUser,
        logout: logout,
        fetchUserData: fetchUserData,
    }
}
