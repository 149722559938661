<template>
    <ion-page>
        <top-navigation
        :back-button="true"
        back-url="/forgot-password"
        ></top-navigation>
        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-133px)] flex items-center justify-center">
                <div class="w-96 mx-auto">
                    <div class="text-center mb-11 mt-5">
                        <h3 class="text-[40px] font-bold text-primary-500 text-center">Verification Code</h3>
                        <p class="text-sm text-gray-900">Verification code sent to {{ store.userEmail }}. Enter the 6-digit verification code to reset your password.</p>
                    </div>

                    <Otp 
                    :validate-url="apiStore.route('validate-otp')"
                    :resend-url="apiStore.route('resend-otp')"
                    :value="store.userEmail"
                    />
                
                </div>
            </div>

            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent 
} from '@ionic/vue';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import Otp from '@/components/forms/Otp.vue';
import { useApiStore } from "@/store/api";
import { useUserStore } from "@/store/user";

export default  {
    name: 'VerificationCode',

    components: {
        IonPage, 
        IonContent,
        TopNavigation,
        BottomNavigation,
        Otp
    },

    setup() {
        const store = useUserStore();
        const apiStore = useApiStore();

        return {
            store,
            apiStore
        };
    }
}
</script>