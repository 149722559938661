<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <banner-slider :slides="slides" />
            <div class=" space-y-10 lg:space-y-20 pl-4 pr-0 lg:px-12 pb-[120px] w-full max-w-[1440px] m-auto">               
                <!-- <div class="mb-10 lg:mb-20">
                    <div v-if="isLoading" class="w-full mx-auto">
                        <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
                    </div>
                    <product-card-slider
                        v-else
                        :items="promos.products.slice(0, 5)"
                    />
                </div> -->
                <!-- <div class="grid grid-cols-5 gap-[31px] mb-20">
                    <product-card :testProducts="testProducts"></product-card>
                </div> -->
                <div class="space-y-10 lg:space-y-20">
                    <div v-if="bestSeller.length > 0">
                        <a @click="router.push('/best-seller')" class="m-0 flex items-center space-x-2 w-fit mb-4 cursor-pointer">
                            <div v-if="cmsContent !== null && getCmsContent('section3_title') !== null">
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section3_title') || 'Best Seller' }}</p>
                            </div>
                            <div v-else>
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">Best Seller</p>
                            </div>
                            <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" />
                        </a>
                        <div v-if="cmsContent !== null && getCmsContent('section3_description') !== null">
                            <p class="mb-6 pr-4">
                                {{ getCmsContent('section3_description') }}
                            </p>
                        </div>
                        <div v-if="cmsContent !== null && getCmsContent('section3_image') !== null" class="w-full mb-6 pr-4 cursor-pointer">
                            <img @click="router.push('/best-seller')" class="rounded-xl w-full object-cover" :src="getCmsContent('section3_image') ? renderCmsImage(getCmsContent('section3_image')) : '/assets/images/f2banner.png'"/>
                        </div>
                        <div class="mb-10 lg:mb-20">
                            <div v-if="isLoading" class="w-full mx-auto">
                                <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
                            </div>
                            <product-card-slider
                                v-else
                                :items="bestSeller.slice(0, 5)"
                                redirect-link="/best-seller"
                            />
                        </div>
                    </div>
                    <div v-if="newProducts.length > 0">
                        <a @click="router.push('/new-products')" class="m-0 flex items-center space-x-2 w-fit mb-4 cursor-pointer">
                            <div v-if="cmsContent !== null && getCmsContent('section4_title') !== null">
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section4_title') || 'New' }}</p>
                            </div>
                            <div v-else>
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">New</p>
                            </div>
                            <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" />
                        </a>
                        <div v-if="cmsContent !== null && getCmsContent('section4_description') !== null">
                            <p class="mb-6 pr-4">
                                {{ getCmsContent('section4_description') }}
                            </p>
                        </div>
                        <div v-if="cmsContent !== null && getCmsContent('section4_image') !== null" class="w-full mb-6 pr-4 cursor-pointer">
                            <img @click="router.push('/new-products')" class="rounded-xl w-full object-cover" :src="getCmsContent('section4_image') ? renderCmsImage(getCmsContent('section4_image')) : '/assets/images/f2banner.png'"/>
                        </div>
                        <div class="mb-10 lg:mb-20">
                            <div v-if="isLoading" class="w-full mx-auto">
                                <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
                            </div>
                            <product-card-slider
                                v-else
                                :items="newProducts.slice(0, 5)"
                                redirect-link="/new-products"
                            />
                        </div>
                    </div>
                    <div v-if="saleProducts.length > 0">
                        <a @click="router.push('/sale')" class="m-0 flex items-center space-x-2 w-fit mb-4 cursor-pointer">
                            <div v-if="cmsContent !== null && getCmsContent('section5_title') !== null">
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section5_title') || 'Sale' }}</p>
                            </div>
                            <div v-else>
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">Sale</p>
                            </div>
                            <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" />
                        </a>
                        <div v-if="cmsContent !== null && getCmsContent('section5_description') !== null">
                            <p class="mb-6 pr-4">
                                {{ getCmsContent('section5_description') }}
                            </p>
                        </div>
                        <div v-if="cmsContent !== null && getCmsContent('section5_image') !== null" class="w-full mb-6 pr-4 cursor-pointer">
                            <img @click="router.push('/sale')" class="rounded-xl w-full object-cover" :src="getCmsContent('section5_image') ? renderCmsImage(getCmsContent('section5_image')) : '/assets/images/f2banner.png'"/>
                        </div>
                        <div class="mb-10 lg:mb-20">
                            <div v-if="isLoading" class="w-full mx-auto">
                                <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
                            </div>
                            <template v-else>
                                <product-card-slider
                                    :items="saleProducts.slice(0, 5)"
                                    redirect-link="/sale"
                                />
                            </template>
                            
                        </div>
                    </div>

                    <template v-if="cmsContent !== null">
                        <div>
                            <a class="flex items-center space-x-2 w-fit m-0 mb-4 cursor-pointer" @click="router.push(getCmsContent('section2_url'))">
                                <div v-if="cmsContent !== null && getCmsContent('section2_title') !== null">
                                    <p class="text-2xl lg:text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section2_title') || 'How about this item?' }}</p>
                                </div>
                                <div v-else>
                                    <p class="text-2xl lg:text-2xl lg:text-[32px] font-semibold m-0">How about this item?</p>
                                </div>
                                <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" />
                            </a>
                            <div v-if="cmsContent !== null && getCmsContent('section2_description') !== null">
                                <p class="mb-6 pr-4">
                                    {{ getCmsContent('section2_description') }}
                                </p>
                            </div>
                            <div v-if="cmsContent !== null && getCmsContent('section2_image') !== null" class="w-full mb-6 pr-4 cursor-pointer">
                                <img @click="router.push(getCmsContent('section2_url'))" class="rounded-xl w-full object-cover" :src="getCmsContent('section2_image') ? renderCmsImage(getCmsContent('section2_image')) : '/assets/images/f2banner.png'"/>
                            </div>
                            <div v-else class="mb-6">
                                <img class="rounded-xl w-full object-cover" src="/assets/images/f2banner.png"/>
                            </div>
                        </div>
                    </template>

                    <div v-if="reviews.length > 0">
                        <div class="m-0 flex items-center space-x-2 w-fit mb-4">
                            <div v-if="cmsContent !== null && getCmsContent('section6_title') !== null">
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section6_title') || 'Best Reviews' }}</p>
                            </div>
                            <div v-else>
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">Best Reviews</p>
                            </div>
                            <!-- <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" /> -->
                        </div>
                        <div v-if="cmsContent !== null && getCmsContent('section6_description') !== null">
                            <p class="mb-6 pr-4">
                                {{ getCmsContent('section6_description') }}
                            </p>
                        </div>
                        <div class="mb-10 lg:mb-20">
                            <div v-if="isLoading" class="w-full mx-auto">
                                <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
                            </div>
                            <product-card-slider
                                v-else
                                :items="reviews.slice(0, 5)"
                                :is-review="true"
                                class="cursor-pointer"
                            />
                        </div>
                    </div>
                    <div v-if="recipes.length > 0">
                        <a @click="router.push('/recipes')" class="m-0 flex items-center space-x-2 w-fit mb-4 cursor-pointer">
                            <div v-if="cmsContent !== null && getCmsContent('section7_title') !== null">
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">{{ getCmsContent('section7_title') || 'Recipe' }}</p>
                            </div>
                            <div v-else>
                                <p class="text-2xl lg:text-[32px] font-semibold m-0">Recipe</p>
                            </div>
                            <ArrowRightIcon class="w-7 lg:w-8 h-7 lg:h-8" />
                        </a>
                        <div v-if="cmsContent !== null && getCmsContent('section7_description') !== null">
                            <p class="mb-6 pr-4">
                                {{ getCmsContent('section7_description') }}
                            </p>
                        </div>
                        <div class="">
                            <recipe-card-slider :items="recipes" class="cursor-pointer"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <DailyAttendance 
            v-if="userProfile.checked_in === 0"
            /> -->
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { computed, onMounted, ref } from "@vue/runtime-core";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowRightIcon } from "@heroicons/vue/outline";

import useProduct from '@/composables/useProduct';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import BannerSlider from '@/components/sliders/BannerSlider.vue';
// import DailyAttendance from '@/components/daily-attendance/DailyAttendance.vue';
import ProductCardSlider from '@/components/sliders/ProductCardSlider.vue';
import RecipeCardSlider from '@/components/sliders/RecipeCardSlider.vue';
import useLocalStorage from "@/composables/useLocalStorage.js";
import useAuth from "@/composables/useAuth";
import useCms from '@/composables/useCms';
import { useApiStore } from "@/store/api";
// import { useUserStore } from '@/store/user';

export default defineComponent({
    name: 'HomePage',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        BannerSlider,
        ArrowRightIcon,

        // RecipeCard,
        // DailyAttendance,
        ProductCardSlider,
        RecipeCardSlider
    },
    setup() {

        const images = [
            { path: '/assets/images/product1.png' },
            { path: '/assets/images/product2.png' },
            { path: '/assets/images/product1.png' },
            { path: '/assets/images/product2.png' },
            { path: '/assets/images/product1.png' },
            { path: '/assets/images/product2.png' },
        ]

        const slides = [
            { link: '/home', banner: '/assets/images/banner1.png' },
            { link: '/home', banner: '/assets/images/banner2.png' },
        ]
        
        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */
        const isLoading = ref(true);
        const router = useRouter();
        const apiStore = useApiStore();
        const userProfile = ref({});
        const { fetchUserData } = useAuth();
        const { localStorage } = useLocalStorage();
        // const { baseCatalogPayload, fetchProducts } = useProduct();
        const { baseCatalogPayload } = useProduct();
        const products = ref([]);
        const reviews = ref([]);
        const recipes = ref([]);
        const newProducts = ref([]);
        const bestSeller = ref([]);
        const saleProducts = ref([]);
        const promos = ref({
            id: null,
            products: []
        });
        // const user = useUserStore();

        /*
        |--------------------------------------------------------------------------
        | @Computed
        |--------------------------------------------------------------------------
        */

        const fetchPayload = computed(() => {
            // if(user.userNearestBranch != null) {
                return Object.assign(baseCatalogPayload.value);
            // }
        });

        // const bestSeller =computed(() => {
        //     return products.value.filter(x => x.is_best == true);
        // })

        // const newProducts =computed(() => {
        //     return products.value.filter(x => x.is_new == true);
        // })

        // const saleProducts =computed(() => {
        //     return products.value.filter(x => x.discounted_price < x.price_with_tax);
        // });

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

        const fetchReviews = async () => {
            isLoading.value = true;

            // const branch = await localStorage.value.get("nearestBranch");
            // console.log(branch);
            // if (branch != null) {
                window.axios
                .get(apiStore.route("fetch-home"), { params: fetchPayload.value })
                .then(({ data }) => {
                    isLoading.value = false;
                    reviews.value = data.items
                    recipes.value = data.recipes;
                    newProducts.value = data.new_products;
                    bestSeller.value = data.best_sellers;
                    data.promos.map((promo) => {
                        if (promo.participating_products) {
                            promo.participating_products.forEach(
                                (product) => saleProducts.value.push(product)
                            );
                        }                        
                    });
                    promos.value.id = data.promos[0]?.id || null;
                    promos.value.products = data.promos[0]?.participating_products || [];
                 })
                .catch(() => {
                    isLoading.value = false;
                })
            // }
        };

        const getUserProfile = async () => {
			const user = await localStorage.value.get("user");

			if (user) {
				userProfile.value = user;
			}
		};

        const initPage = async()=> {
            const routes = await localStorage.value.get('routes');

            if (typeof routes['fetch-products'] === 'string') {
                // fetch();
            }
        }

        // const fetch =()=> {
        //     fetchProducts(fetchPayload.value)
        //         .then(items => {
        //             products.value = items.data;
        //             localStorage.value.set('products', items);
        //             isLoading.value = false;
        //             console.log('products', products.value);
        //         })
        // }

        const { cms, contentSlug, getCms, getCmsContent, renderCmsImage } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "home";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

		onMounted(() => {
			fetchUserData();
            fetchReviews()
            getUserProfile();
            initPage();
            getContentSlug();
		});

        return {
            images,
            router,
            slides,
            recipes,
            userProfile,
            products,
            isLoading,
            bestSeller,
            newProducts,
            saleProducts,
            cmsContent,
            getCmsContent,
            renderCmsImage,
            reviews,
            promos
        }
    },
});
</script>
