<template>

    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative bg-white rounded-xl px-8 py-11 pb-0 text-left overflow-hidden shadow-xl transform transition-all w-fit">
                            <div class="w-[412px] max-h-[550px] overflow-auto scroll-custom pb-11">
                                <div class="flex items-center justify-between mb-6">
                                    <p class="font-normal text-2xl text-neutral-600 m-0">Rate Service</p>
                                    <button @click="closeThis()">
                                        <XIcon class="w-7 h-7" />
                                    </button>
                                </div>
                                <template v-if="step == 1">
                                    <p class="text-sm text-neutral-600 mb-6">Rate the items on your order:</p>
                                    <div class="space-y-6">
                                        <template v-for="(product_review, index) in product_reviews" :key="index">
                                            <div class="pb-6 border-b">
                                                <div class="flex space-x-5 mb-4">
                                                    <div class="w-[71px] h-[82px] rounded-[6px] border">
                                                        <img class="w-full h-full object-cover" :src="imagePreview(product_review)" alt="">
                                                    </div>
                                                    <div class="w-[180px]">
                                                        <p class="font-normal text-neutral-600 mb-3">{{ product_review.name }}</p> 
                                                        <div class="flex flex-row gap-2 mb-4">
                                                            <div
                                                                v-for="i in 5"
                                                                :key="i"
                                                                @click.prevent="rate(i, index)"
                                                            >
                                                                <template v-if="i > product_review.score">
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.9184 12.32C16.6594 12.571 16.5404 12.934 16.5994 13.29L17.4884 18.21C17.5634 18.627 17.3874 19.049 17.0384 19.29C16.6964 19.54 16.2414 19.57 15.8684 19.37L11.4394 17.06C11.2854 16.978 11.1144 16.934 10.9394 16.929H10.6684C10.5744 16.943 10.4824 16.973 10.3984 17.019L5.96839 19.34C5.74939 19.45 5.50139 19.489 5.25839 19.45C4.66639 19.338 4.27139 18.774 4.36839 18.179L5.25839 13.259C5.31739 12.9 5.19839 12.535 4.93939 12.28L1.32839 8.78C1.02639 8.487 0.921388 8.047 1.05939 7.65C1.19339 7.254 1.53539 6.965 1.94839 6.9L6.91839 6.179C7.29639 6.14 7.62839 5.91 7.79839 5.57L9.98839 1.08C10.0404 0.98 10.1074 0.888 10.1884 0.81L10.2784 0.74C10.3254 0.688 10.3794 0.645 10.4394 0.61L10.5484 0.57L10.7184 0.5H11.1394C11.5154 0.539 11.8464 0.764 12.0194 1.1L14.2384 5.57C14.3984 5.897 14.7094 6.124 15.0684 6.179L20.0384 6.9C20.4584 6.96 20.8094 7.25 20.9484 7.65C21.0794 8.051 20.9664 8.491 20.6584 8.78L16.9184 12.32Z"
                                                                stroke="#FFB800"
                                                                />
                                                                </svg>
                                                                </template>
                                                                <template v-else>
                                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.9184 12.32C15.6594 12.571 15.5404 12.934 15.5994 13.29L16.4884 18.21C16.5634 18.627 16.3874 19.049 16.0384 19.29C15.6964 19.54 15.2414 19.57 14.8684 19.37L10.4394 17.06C10.2854 16.978 10.1144 16.934 9.93939 16.929H9.66839C9.57439 16.943 9.48239 16.973 9.39839 17.019L4.96839 19.34C4.74939 19.45 4.50139 19.489 4.25839 19.45C3.66639 19.338 3.27139 18.774 3.36839 18.179L4.25839 13.259C4.31739 12.9 4.19839 12.535 3.93939 12.28L0.328388 8.78C0.0263875 8.487 -0.0786125 8.047 0.0593875 7.65C0.193388 7.254 0.535388 6.965 0.948388 6.9L5.91839 6.179C6.29639 6.14 6.62839 5.91 6.79839 5.57L8.98839 1.08C9.04039 0.98 9.10739 0.888 9.18839 0.81L9.27839 0.74C9.32539 0.688 9.37939 0.645 9.43939 0.61L9.54839 0.57L9.71839 0.5H10.1394C10.5154 0.539 10.8464 0.764 11.0194 1.1L13.2384 5.57C13.3984 5.897 13.7094 6.124 14.0684 6.179L19.0384 6.9C19.4584 6.96 19.8094 7.25 19.9484 7.65C20.0794 8.051 19.9664 8.491 19.6584 8.78L15.9184 12.32Z"
                                                                fill="#FFB800"
                                                                />
                                                                </svg>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button-component
                                                    size="default"
                                                    color="primary"
                                                    fill="outline"
                                                    customClass="mb-6 font-thin"
                                                    @click="takePicture(product_review.sku, product_reviews)"
                                                >
                                                    <template #content>
                                                        Upload Media
                                                    </template>
                                                </button-component>
                                                <div class="mb-6 flex">
                                                    <template v-for="(rating_image, index) in product_review.rating_images" :key="index">
                                                        <div class="w-1/4 px-1 mb-2">
                                                            <div class="relative w-full pt-[100%] h-0 mb-2">
                                                                <div class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-no-repeat rounded-2xl"
                                                                :style="`background-image: url(${rating_image.formatted_file_path})`"></div>
                                                                <ion-icon src="/assets/icon/ic-close.svg" class="w-6 h-6 absolute top-[-6px] right-[-6px]" @click.prevent="removeProductReviewFile(rating_image.id, product_reviews)"></ion-icon>
                                                            </div>
                                                        </div>
                                                    </template>   
                                                </div>
                                                <div>
                                                    <p class="text-xs text-gray-300 mb-2">REMARKS</p>
                                                    <text-input
                                                        v-model="product_review.message"
                                                        type="text"
                                                        placeholder="Input Remarks"
                                                        customClass="placeholder:font-thin bg-gray-50"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="space-y-6">
                                        <div class="pb-6 border-b">
                                            <div class="flex space-x-5 mb-4">
                                                <div class="w-[71px] h-[82px] rounded-[6px] border">
                                                    <img v-if="rider" class="w-full h-full object-cover" :src="rider.profile_photo_url" alt="">
                                                </div>
                                                <div class="w-[180px]">
                                                    <p v-if="rider" class="font-normal text-neutral-600 mb-3">{{ rider.name }}</p> 
                                                    <div class="flex flex-row gap-2 mb-4">
                                                        <div
                                                            v-for="i in 5"
                                                            :key="i"
                                                            @click.prevent="rateRider(i)"
                                                        >
                                                            <template v-if="i > form.rider_score">
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.9184 12.32C16.6594 12.571 16.5404 12.934 16.5994 13.29L17.4884 18.21C17.5634 18.627 17.3874 19.049 17.0384 19.29C16.6964 19.54 16.2414 19.57 15.8684 19.37L11.4394 17.06C11.2854 16.978 11.1144 16.934 10.9394 16.929H10.6684C10.5744 16.943 10.4824 16.973 10.3984 17.019L5.96839 19.34C5.74939 19.45 5.50139 19.489 5.25839 19.45C4.66639 19.338 4.27139 18.774 4.36839 18.179L5.25839 13.259C5.31739 12.9 5.19839 12.535 4.93939 12.28L1.32839 8.78C1.02639 8.487 0.921388 8.047 1.05939 7.65C1.19339 7.254 1.53539 6.965 1.94839 6.9L6.91839 6.179C7.29639 6.14 7.62839 5.91 7.79839 5.57L9.98839 1.08C10.0404 0.98 10.1074 0.888 10.1884 0.81L10.2784 0.74C10.3254 0.688 10.3794 0.645 10.4394 0.61L10.5484 0.57L10.7184 0.5H11.1394C11.5154 0.539 11.8464 0.764 12.0194 1.1L14.2384 5.57C14.3984 5.897 14.7094 6.124 15.0684 6.179L20.0384 6.9C20.4584 6.96 20.8094 7.25 20.9484 7.65C21.0794 8.051 20.9664 8.491 20.6584 8.78L16.9184 12.32Z"
                                                            stroke="#FFB800"
                                                            />
                                                            </svg>
                                                            </template>
                                                            <template v-else>
                                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.9184 12.32C15.6594 12.571 15.5404 12.934 15.5994 13.29L16.4884 18.21C16.5634 18.627 16.3874 19.049 16.0384 19.29C15.6964 19.54 15.2414 19.57 14.8684 19.37L10.4394 17.06C10.2854 16.978 10.1144 16.934 9.93939 16.929H9.66839C9.57439 16.943 9.48239 16.973 9.39839 17.019L4.96839 19.34C4.74939 19.45 4.50139 19.489 4.25839 19.45C3.66639 19.338 3.27139 18.774 3.36839 18.179L4.25839 13.259C4.31739 12.9 4.19839 12.535 3.93939 12.28L0.328388 8.78C0.0263875 8.487 -0.0786125 8.047 0.0593875 7.65C0.193388 7.254 0.535388 6.965 0.948388 6.9L5.91839 6.179C6.29639 6.14 6.62839 5.91 6.79839 5.57L8.98839 1.08C9.04039 0.98 9.10739 0.888 9.18839 0.81L9.27839 0.74C9.32539 0.688 9.37939 0.645 9.43939 0.61L9.54839 0.57L9.71839 0.5H10.1394C10.5154 0.539 10.8464 0.764 11.0194 1.1L13.2384 5.57C13.3984 5.897 13.7094 6.124 14.0684 6.179L19.0384 6.9C19.4584 6.96 19.8094 7.25 19.9484 7.65C20.0794 8.051 19.9664 8.491 19.6584 8.78L15.9184 12.32Z"
                                                            fill="#FFB800"
                                                            />
                                                            </svg>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="text-xs text-gray-300 mb-2">REMARKS</p>
                                                <text-input
                                                    v-model="form.rider_message"
                                                    type="text"
                                                    placeholder="Input Remarks"
                                                    customClass="placeholder:font-thin bg-gray-50"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <button-component
                                    size="default"
                                    color="primary"
                                    customClass="font-thin"
                                    expand="block"
                                    @click="submitRating"
                                >
                                    <template #content>
                                        Submit Rating
                                    </template>
                                </button-component>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <rate-rider-modal 
        :is-visible="showRateRiderModal"
        @close="showRateRiderModal = false"
    ></rate-rider-modal>

</template>
<script>
import { defineComponent, onMounted, computed, ref, watch } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
// import ButtonComponent from '@/components/ButtonComponent.vue';
import { XIcon } from "@heroicons/vue/solid";
import TextInput from '@/components/inputs/TextInput.vue';
import RateRiderModal from './RateRiderModal.vue';

import useReview from "@/composables/useReview";
import useCamera from '@/composables/useCamera';

export default defineComponent({
    name: 'RateProductModal.vue',
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        XIcon,
        ButtonComponent,
        TextInput,
        RateRiderModal
        // LocationMarkerIcon,
        // ButtonComponent
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },

        id : {
            default : null,
            type : Number
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const closeThis =()=> open.value = false;

        const showLargeMap = ref(false);

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        function openRateRiderModal() {
            showRateRiderModal.value = true;
        }

        const showRateRiderModal = ref(false);

        const { openCamera, capturedFile } = useCamera();
        const { fetchInvoice, uploadProductReviewFile, removeProductReviewFile, submit, invoice, invoice_items, product_reviews, form, step, reviewLoader } = useReview();

        const id = computed(() => props.id);
        const rider = computed(() => invoice.value.rider ? invoice.value.rider: null )

        const rate = (i, index) => {
            product_reviews.value[index].score = i;
        }

        const rateRider = (index) => {
            form.value.rider_score = index;
        }

        const takePicture = (product_id, product_reviews) => {
            openCamera()
            .then(() => {
                if(capturedFile.value) uploadProductReviewFile(id.value, product_id, capturedFile.value, product_reviews);
            });
        }

        onMounted(() => {
            fetchInvoice(id.value)
        })

        const submitRating = () => {
            if(step.value == 1) {
                if(invoice.value.rider != null) {
                    step.value++
                } else {
                    submit();
                    closeThis();
                }        
            } else {
                submit();
                closeThis();
            }
        }

        const imagePreview = (product_review) => {
            return product_review.image ? product_review.image : '/assets/images/product-image.png'; 
        }

        return {
            open,
            closeThis,
            showLargeMap,
            openRateRiderModal,
            showRateRiderModal,
            submit,

            step,
            submitRating,
            rate,
            rateRider,
            takePicture,
            uploadProductReviewFile,
            removeProductReviewFile,

            rider,
            invoice,
            invoice_items,
            product_reviews,
            form,
            reviewLoader,
            imagePreview
        }
    }
})
</script>