<template>
    <div class="w-full">
        <label :for="id" class="block text-sm text-gray-900">
            {{ label }}
        </label>

        <div
            class="w-full relative"
            :class="label ? 'mt-2' : ''"
        >
            <text-input
                placeholder="Birthdate"
                id="date"
                locale="en-PH"
                :modelValue="date"
            ></text-input>
            <div
                class="
                    absolute
                    inset-y-0
                    right-0
                    pr-2
                    flex
                    items-center
                "
            >
                <ion-icon
                    src="assets/icon/inputs/ic-calendar.svg"
                    class="w-6 h-6"
                ></ion-icon>
            </div>
        </div>
        <ion-popover trigger="date" size="cover">
            <ion-datetime
                presentation="date"
                v-model="date"
                show-default-buttons="true"
                class="w-full max-w-full"
            ></ion-datetime>
        </ion-popover>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonPopover, IonDatetime } from "@ionic/vue";

import TextInput from "@/components/inputs/TextInput.vue";
export default defineComponent({
    name: 'birthday-picker',

    components: {
        TextInput,
        IonPopover,
        IonDatetime,
    },

    props: {
        modelValue: {
            default: null,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
    },

    setup() {
        const date = ref();

        return {
            date
        };
    }
});
</script>
