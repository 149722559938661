import { ref } from 'vue';
import { useCartStore } from '@/store/cart';
import { useUserStore } from '@/store/user';
export default function usePoints()
{
    const cart = useCartStore();
    const user = useUserStore();

    const pointLoader = ref(false);

    const applyPoints = (params) => {
        
        const url = cart.data.apply_point_url;
        
        params = Object.assign(params, {
            user_id: user.userData?.accountnum
        });

        if(!pointLoader.value && typeof url === 'string') {
            pointLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    pointLoader.value = false;
                    cart.data = data.cart;
                    return data.message;
                })
                .catch(({ response }) => {
                    pointLoader.value = false;
                    return {
                        error: response.status,
                        message: response.data.message
                    };
                });
        }
        else {
            return Promise.resolve();
        }
    };

    const removePoints = () => {
        
        const url = cart.data.remove_point_url;
        const params = { user_id: user.userData?.accountnum || '00000000' };

        if(!pointLoader.value && typeof url === 'string') {
            pointLoader.value = true;
            return window.axios
                .post(url, params)
                .then(({ data }) => {
                    pointLoader.value = false;
                    cart.data = data.cart;
                    return data.message;
                })
                .catch(() => {
                    pointLoader.value = false;
                });
        }
        else {
            return Promise.resolve();
        }
    };

    return {
        pointLoader,
        applyPoints,
        removePoints
    }
}
