<template>
    <ion-header class="top-nav bg-white md:drop-shadow">
        <div class="top-bar px-4 lg:px-12 py-[7px] bg-primary-900 w-full leading-[18px]">
            <span v-if="cmsContent !== null && getCmsContent('header_contact')">
                <a class="text-xs text-white font-normal leading-[18px]" :href="`tel:${getCmsContent('header_contact')}`">Inquiries? Call us! {{ getCmsContent('header_contact') || '(02) 8235-4213'}}</a>
            </span>
            <span v-else>
                <a class="text-xs text-white font-normal leading-[18px]" href="tel:(02) 8235-4213">Inquiries? Call us! (02) 8235-4213</a>
            </span>
        </div>
        <div class="px-4 lg:px-12 pt-6 pb-6 lg:pb-0 flex justify-between flex-col lg:flex-row space-y-6 lg:space-y-0 relative space-x-0 lg:space-x-16">
            <div class="flex relative w-full lg:w-fit">
                <div class="hidden lg:flex items-center mr-6 gap-2 h-fit pt-[10px] cursor-pointer" @mouseenter="showCategoriesMenu = true">
                    <MenuIcon class="w-5 h-5 text-primary-500" />
                    <p class="font-normal">Categories</p>
                </div>
                <div class="block lg:hidden h-8" @click="showCategoriesMenu = !showCategoriesMenu">
                    <img src="/assets/icon/menu.svg" alt="">
                </div>

                <div class="w-[115px] h-8 lg:w-[148px] lg:h-10 absolute left-1/2 -translate-x-1/2 lg:translate-x-0 lg:left-0 lg:relative">
                    <span v-if="cmsContent !== null && getCmsContent('header_logo') !== null">
                        <a class="w-fit h-fit" href="/">
                            <img class="w-full h-full object-cover" :src="getCmsContent('header_logo') ? renderCmsImage(getCmsContent('header_logo')) : '/assets/images/logo.png'" alt="">
                        </a>
                    </span>
                    <span v-else>
                        <a class="w-fit h-fit" href="/">
                            <img class="w-full h-full object-cover" src="/assets/images/logo.png" alt="">
                        </a>
                    </span>
                </div>
            </div>
            <div class="flex items-center lg:items-start w-full">
                <div class="lg:pb-[10px] w-full mr-3 lg:mr-4">
                    <div class="relative w-full">
                        <input 
                            class="search-input placeholder-gray-400 py-3 px-[52px] rounded-[38px] bg-[#efefef] text-sm border-none w-full mb-1 ring-0 relative z-10" 
                            type="text" 
                            placeholder="Find Something..."
                            v-model="searchInput"
                            id="maskSearch"
                            name="search"
                            @focus="inActive(false)"
                            @blur="inActive"
                            @keyup.enter="search()"
                            autocomplete="off"
                        >
                        <SearchIcon @click="search()" class="cursor-pointer absolute top-1/2 -translate-y-1/2 left-4 w-5 h-5 text-primary-900 z-10" />
                        <!-- Need Backend to attached -->
                        <div v-if="showSuggested" class="w-full bg-[#efefef] absolute top-0 left-0 z-1 rounded-[20px] pt-12 px-4 pb-4" @mouseover="activeSuggestions = true" @mouseleave="activeSuggestions = false">
                            <div class="text-sm text-gray-400 lg:px-9 mb-4">Suggested Searches:</div>
                            <div class="lg:px-9 space-y-4 flex flex-col text-left max-h-[144px] overflow-y-auto">
                                <template v-for="suggessted in settings.suggesstedSearch" :key="suggessted">
                                    <div><a :href="`/shop?search=${encodeURIComponent(suggessted.name)}`" class="text-sm w-fit hover:text-primary-600">{{ suggessted.name }}</a></div>
                                </template>  
                            </div>
                        </div>
                        <!-- Need Backend to attached -->
                    </div>
                    <div class="hidden xl:flex gap-4">
                        <template v-for="suggessted in settings.suggesstedSearch" :key="suggessted">
                            <a :href="`/shop?search=${suggessted.name}`" class="text-xs hover:text-primary-500 transition text-fhmgray">{{ suggessted.name }}</a>
                        </template>                        
                    </div>
                </div>
                <div class="flex lg:space-x-4 h-9 items-center w-fit">
                    <div class="flex space-x-4 items-center absolute top-6 right-4 lg:top-0 lg:right-0 lg:relative">
                        <p class="text-xs hidden lg:inline-flex truncate" v-if="hasSelectedAddress">{{ cart.data?.branch?.name }}</p>
                        <LocationMarkerIcon @click="openLocationModal()" class="flex-shrink-0 text-primary-900 w-8 h-8 cursor-pointer" v-if="$route.name != 'Checkout'"/>
                        <a class="w-fit cursor-pointer" @click="goToAccountDetailsOrLogin()">
                            <UserCircleIcon class="text-primary-900 w-8 h-8" />
                        </a>
                    </div>
                    <CartButton></CartButton>
                </div>
            </div>
        </div>

        <!-- categories -->
        <categories-menu 
        :show="showCategoriesMenu"
        :categories="categories"
        @mouseleave="showCategoriesMenu = false"
        />


        <LocationModal
            :is-visible="showLocationModal"
            @close="showLocationModal = false"
        ></LocationModal>
    </ion-header>
</template>
<script>
import { computed, ref, onMounted, watch } from 'vue';
import {
    IonHeader,
} from "@ionic/vue";
import { 
    MenuIcon,
} from "@heroicons/vue/solid";
import { SearchIcon,UserCircleIcon,LocationMarkerIcon } from "@heroicons/vue/outline";
import { useApiStore } from '@/store/api';
import { useCategoryStore } from '@/store/category';
import { useUserStore } from '@/store/user';
import useLocalStorage from '@/composables/useLocalStorage';
import { useRouter } from 'vue-router';

// import ButtonComponent from '@/components/ButtonComponent.vue';
// import ProductCounter from '@/components/inputs/QuantitySelector.vue';
// import RenderPrice from '@/components/RenderPrice.vue';
import LocationModal from '@/components/dialogs/AddressBook/LocationModal.vue';
import CartButton from './CartButton.vue';
import useCms from '@/composables/useCms';
import { useSettingsStore } from '@/store/settings';
import { useAddressStore } from "@/store/address";
import CategoriesMenu from './CategoriesMenu.vue';
import { useCartStore } from '@/store/cart';

export default {
    name: "top-navigation",
    components: {
        IonHeader,
        MenuIcon,
        SearchIcon,
        UserCircleIcon,
        LocationMarkerIcon,
        LocationModal,
        CartButton,
        CategoriesMenu,
    },

    setup() {

        const router = useRouter();
        const searchInput = ref(null);
        const apiStore = useApiStore();
        const categoryStore = useCategoryStore();
        const userStore = useUserStore();
        const { localStorage } = useLocalStorage();
        const addressStore = useAddressStore();
        const cart = useCartStore();

        const fetchCategoriesUrl = computed(() => apiStore.route('fetch-categories'));
        const categories = computed(() => categoryStore.items);

        const isAuthenticated = computed(() => userStore.userData !== null);

        const settings = useSettingsStore();
        const showSuggested = ref(false);
        const activeSuggestions = ref(false);
        const hasSelectedAddress = ref(null);

        const fetch =(url)=> {
            window.axios
                .get(url)
                .then(({data}) => {
                    categoryStore.items = data;
                    localStorage.value.set('categories', data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        const goToAccountDetailsOrLogin = () => {
            const route = isAuthenticated.value ? '/account-details' : '/login';
            router.push(route);
        };

        function openLocationModal() {
            showLocationModal.value = true;
        }

        const showLocationModal = ref(false);

        const search =()=> {
            window.location.href = `/shop?search=${encodeURIComponent(searchInput.value )|| ''}&subCategory=all`;
        };

        const inActive = (disabled = true) => {
            if(!disabled) {
                return showSuggested.value = true;
            }
            if(!activeSuggestions.value) {
                showSuggested.value = false;
            }
        }

        const checkIfHasSelectedAddress = async () => {
            const address = await localStorage.value.get('addressData');
            hasSelectedAddress.value = address != null ? true : false;
        }

        // watch(
        //     showSuggested,
        //     (val) => {
        //         console.log(val);
        //     }
        // );

        watch(
            () => router.currentRoute.value.query.search,
            (search) => {
                if (search) searchInput.value = search;
            }
        );

        watch(
            () => fetchCategoriesUrl.value,
            (url) => {
                if (typeof url === 'string') fetch(url);
            }, { immediate: true }
        );

        const { cms, contentSlug, getCms, getCmsContent, renderCmsImage } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "header_footer";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        const showCategoriesMenu = ref(false);

        onMounted(() => {
            getContentSlug();
            showLocationModal.value = addressStore.data?.longitude ? false : true;
            checkIfHasSelectedAddress();
        });

        return {
            showLocationModal,
            openLocationModal,
            goToAccountDetailsOrLogin,
            searchInput,
            search,
            categories,
            router,
            cmsContent,
            getCmsContent,
            renderCmsImage,
            settings,
            showSuggested,
            inActive,
            activeSuggestions,
            showCategoriesMenu,
            cart,
            hasSelectedAddress
        };
    },
    methods: {  
        closeCart() {  
            const elem = document.getElementById("cart-btn");  
            elem.click();  
        },  
        
    },  
};
</script>