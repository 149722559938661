<template>
    <profile-layout :title="'Notifications'">
        <div class="flex items-center justify-between mb-[33px]">
            <p class="text-2xl font-semibold text-neutral-600 m-0 hidden lg:block">Notifications</p>
            <div class="relative flex items-center">
        <div class="flex items-center h-4">
            <input
                id="read-all"
                type="checkbox"
                class="
                    cursor-pointer
                    h-4
                    w-4
                    text-amber-400
                    border-gray-300
                    rounded
                    focus:ring-0
                    focus:ring-offset-0	
                    focus:outline-0
                    focus:outline-offset-0	
                    checked:border-amber-400
                    text-primary-500 checked:border-primary-500
                    text-amber-400 checked:border-amber-400
                "
                @change="readAllNotification()"
            />
        </div>
            <div class="ml-3 text-sm">
                <label
                    for="read-all"
                    class="cursor-pointer text-primary-900 text-sm text-[#BDBDBD]"
                >
                    Mark All As Read
                </label>
            </div>
    </div>
            <!-- <checkbox-input id="markall" label="Mark All as read" gray /> -->
        </div>
        <div class="flex flex-col space-y-6">
            <template v-for="item in notifications" :key="item">
                <div @click="viewNotification(item)" class="flex w-full">
                    <template v-if="item.read_at == null">
                        <div class="pt-2">
                            <div class="w-2 h-2 rounded-full bg-red-500 mr-3"></div>
                        </div>
                    </template>
                    <div class="w-full">
                        <div class="flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:justify-between mb-2">
                            <p class="font-normal m-0" :class="item.read_at == null ? 'text-primary-500' : ''">{{ item.data.title }}</p>
                            <p class="text-[#ADB5BD] text-sm m-0">{{ item.created_at }}</p>
                        </div>
                        <p class="text-sm text-neutral-600">{{ item.data.message }}</p>
                    </div>
                </div>
                <hr>    
            </template>
        </div>
    </profile-layout>
</template>


<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import ProfileLayout from "./ProfileLayout.vue";
// import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import { onMounted } from "@vue/runtime-core";
// import pinia from "@/store/index.js";
import { useApiStore } from "@/store/api.js";
import useModal from "@/composables/modal";


export default defineComponent({
    name: 'NotificationPage',
    components: {
        // CheckboxInput,
        ProfileLayout,
    },
    setup() {
        const router = useRouter();
        const apiStore = useApiStore();
        const modal = useModal();

        const notifications = ref([]);
        const errors = ref(null);

        const fetch = () => {
			window.axios
				.get(apiStore.route("fetchNotifications"))
				.then((result) => {
                    notifications.value = result.data.all_notifications
				})
				.catch((error) => {
                    errors.value = error.response.data.error == null ?error.response.data.message : error.response.data.error;
					modal.error(errors.value);
				});
		};


        const viewNotification = (notification) => {
           
            const id = notification.id;
            window.axios
                .post(apiStore.route("readNotifications"), { id })
                .then((result) => {
                    console.log(result.data)
                    fetch();
                }).catch((error) => {
                    console.log(error)
                });
        }

        const readAllNotification = () => {
           
           window.axios
               .post(apiStore.route("readAllNotifications"))
               .then((result) => {
                   console.log(result.data)
                   fetch();
               }).catch((error) => {
                   console.log(error)
               });
       }

        onMounted(() => {
			fetch();
		});

        return {
            router,
            notifications,
            viewNotification,
            readAllNotification
        }
    },
});
</script>
