<template>
    <div>
        <swiper
            :slidesPerView="'auto'"
            :spaceBetween="0"
            :freeMode="true"
            :pagination="{
                clickable: true,
            }"
            :modules="modules"
            class="card-slider"
        >
            <swiper-slide
            v-for="(item,index) in items"
            :key="index"
            class="w-[96%] md:w-[46%] lg:w-[32%] mr-[2%]">
                <recipe-card :recipe="item"></recipe-card>
            </swiper-slide>
            
        </swiper>
    </div>
</template>

<script type="text/javascript">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper";

import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import RecipeCard from '@/components/cards/RecipeCard.vue';

export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
        RecipeCard
    },
    props: {
        items: {
            type: Array,
            default: null
        }
    },
    setup() {

        const router = useRouter();

        return {
            router,
            modules: [
                FreeMode, 
                Pagination
            ]
        };
    }
});
</script>