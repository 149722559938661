<template>

    <profile-layout :title="'Order History'">
        <template v-if="isLoading">
            <div class="w-full mx-auto">
                <img src="/assets/images/loader.gif" class="w-14 h-auto mx-auto" />
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col space-y-5 text-gray-800 border-b pb-[33px] mb-6">
                <div class="flex space-x-6">
                    <div class="w-[105px]">
                        <p class="font-normal text-sm">Order Number:</p>
                    </div>
                    <p class="text-sm">{{ invoice.invoice_no }}</p>
                </div>
                <div class="flex space-x-6">
                    <div class="w-[105px]">
                        <p class="font-normal text-sm">Ordered On:</p>
                    </div>
                    <p class="text-sm">{{ invoice.placed_on }}</p>
                </div>
                <div class="flex space-x-6">
                    <div class="w-[105px]">
                        <p class="font-normal text-sm">Status:</p>
                    </div>
                    <p class="text-sm">{{ invoice.formatted_order_status }} </p>
                </div>
            </div>
            <div class="flex flex-col space-y-[9px] pb-[33px] mb-6 border-b">
                <p class="font-normal text-sm m-0">Contact Information:</p>
                <p class="text-sm m-0">{{ invoice.customer_name }}</p>
                <p class="text-sm m-0">{{ invoice.customer_contact_number }}</p>
                <p class="text-sm m-0">{{ invoice.address_details }}</p>
            </div>
            <div class="flex flex-col pb-[33px]">
                <p class="font-normal text-sm m-0">Fulfillment Method</p>                
                <p class="text-sm m-0 mb-[14px]" v-if="invoice.delivery_type == 1">Delivery({{invoice.delivery_method_name}})</p>
                <p class="text-sm m-0" v-else>Pick Up</p>
                <template v-if="orderStatus.IN_TRANSIT == invoice.order_status && canChatToRider && invoice.delivery_method == 'INHOUSE'">
                <ChatButton
                    :invoice-id="invoice.id"
                    :disabled="invoice.formatted_order_status === 'In Transit' ? false : true"
                    :rider="invoice.rider_name"
                ></ChatButton>                  
                </template>    
                <p class="text-sm m-0 mt-6" v-if="invoice.delivery_type == 1">{{ invoice.rider_name }}</p>                 
            </div>
            <hr>            
            <div class="flex flex-col pt-2 pb-[33px]">
                <p class="font-normal text-sm m-0 mb-[6px]">Payment Method:</p>
                <p class="text-sm m-0 mb-10">{{ invoice.payment_method_name }}</p>
                <p class="font-normal text-sm m-0 mb-[6px]">Branch:</p>
                <p class="text-sm m-0 mb-6">{{ invoice.branch_name }}</p>
            </div>
            <hr>
            <div class="flex flex-col pt-[36px]">
                <p class="font-normal text-sm m-0 mb-[30px]">Number of items: {{ itemCount }}</p>
                <div class="flex flex-col space-y-6 pb-10" v-for="item in invoice.items" :key="`invoice-item-${item.id}`">
                    <div class="flex max-w-[692px] w-full justify-between">
                        <div class="flex space-x-[18px]">
                            <div class="w-[54px] h-[54px] rounded-lg border border-gray-300 overflow-hidden">
                                <img class="w-full h-full object-cover" :src="productImage(item)" alt="">
                            </div>
                            <div>
                                <p class="text-neutral-600 font-normal">{{ item.name }}</p>
                                <p class="text-sm font-normal">x {{ item.quantity }}</p>
                            </div>
                        </div>
                        <div class="price-hldr">
                            <p class="font-normal text-primary-500">{{ moneyFormat(item.subtotal) }}</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="py-10 flex flex-col space-y-10">
                    <div class="flex justify-between items-center">
                        <p class="text-sm font-normal text-neutral-600 m-0">Subtotal</p>
                        <p class="font-normal text-neutral-600 m-0">{{ moneyFormat(invoice.subtotal) }}</p>
                    </div>
                    <!-- <div class="flex justify-between items-center">
                        <p class="text-sm font-normal text-neutral-600 m-0">Discount</p>
                        <p class="font-normal text-neutral-600 m-0">{{ moneyFormat(discount) }}</p>
                    </div> -->
                    <div class="flex justify-between items-center">
                        <p class="text-sm font-normal text-red-500 m-0">Voucher Applied</p>
                        <p class="font-normal text-red-500 m-0">None</p>
                    </div>
                    <template v-for="(discount,index) in invoice.discounts" :key="`discount-${index}`">
                        
                        <div class="flex justify-between items-center">
                            <p class="text-sm font-normal text-red-500 m-0">{{ discount.name }}</p>
                            <p class="font-normal text-red-500 m-0">{{ moneyFormat(discount.amount) }}</p>
                        </div>
                    </template>
                    <template v-for="(fee,index) in invoice.fees" :key="`fee-${index}`">
                        <div class="flex justify-between items-center" v-if="fee.name != 'Delivery Fee(RAW)'">
                            <p class="text-sm font-normal text-neutral-600 m-0">{{ fee.name }}</p>
                            <p class="font-normal text-neutral-600 m-0">{{ moneyFormat(fee.amount) }}</p>
                        </div>
                    </template>
                </div>
                <hr>
                <div class="py-[17px] flex justify-between items-center">
                    <h3 class="text-2xl font-normal text-neutral-600 m-0">TOTAL</h3>
                    <h3 class="text-2xl font-normal text-primary-500 m-0">{{ moneyFormat(invoice.total) }}</h3>
                </div>
                <hr>
                <div class="flex justify-between items-center pt-4 pb-[63px]">
                    <p class="text-sm font-normal text-neutral-600 m-0">Points Earned</p>
                    <p class="font-normal text-neutral-600 m-0">{{ invoice.points_earned }}</p>
                </div>
                <div class="flex justify-between items-center pb-7">
                    <button-component size="default" color="primary" fill="outline" v-if="invoice.order_status == 6 && invoice.reviews.length == 0 && invoice.delivery_type != 2" @click="openRateModal()">
                        <template #content>
                            Rate Order
                        </template>
                    </button-component>
                    <button-component size="default" color="primary" fill="outline" v-if="invoice.order_status == orderStatus.COMPLETED || invoice.order_status == orderStatus.CANCELLED || invoice.order_status == orderStatus.REJECTED" @click="reorder">
                        <template #content>
                            Reorder Items
                        </template>
                    </button-component>
                </div>
                <a class="w-fit m-0" href="/order-history">
                    <div class="flex items-center">
                        <ChevronLeftIcon class="w-5 h-5 text-gray-300" />
                        <p class="text-gray-400 text-sm m-0 font-normal">RETURN TO ORDER HISTORY</p>
                    </div>
                </a>
            </div>
        </template>
    </profile-layout>

    <rate-product-modal
    v-if="showRateModal"
    :is-visible="showRateModal"
    :id="invoice.id"
    @close="showRateModal = false"
    ></rate-product-modal>

</template>

<script type="text/javascript">
import {
    onIonViewWillEnter
} from '@ionic/vue';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ProfileLayout from "./ProfileLayout.vue";
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ChevronLeftIcon } from "@heroicons/vue/solid";
import ChatButton from '@/components/chat/ChatButton.vue';
import RateProductModal from './components/RateProductModal.vue';
import { useSettingsStore } from '@/store/settings';
import useInvoice from '@/composables/useInvoice';
import useFormat from '@/composables/useFormat';
import useResponseHandler from '@/composables/useResponseHandler';
import { useChatStore } from "@/store/chat.js";
import { useApiStore } from "@/store/api.js";

export default defineComponent({
    name: 'OrderHistoryView',
    components: {
        ButtonComponent,
        ChevronLeftIcon,
        ChatButton,
        RateProductModal,
        ProfileLayout
    },
    setup() {

        function openRateModal() {
            showRateModal.value = true;
        }

        const showRateModal = ref(false);
        const openChat = ref(false);

        const router = useRouter();
        const route = useRoute();
        const settings = useSettingsStore();
        const chatStore = useChatStore()
        const apiStore = useApiStore();

        const { showInvoice, buyAgain } = useInvoice();
        const { moneyFormat } = useFormat();
        const { successHandler } = useResponseHandler();

        const invoice = ref({});
        const orderStatus = computed(() => settings.orderStatuses);

        const items = ref([]);
        const itemCount = ref();
        const isLoading = ref(false);

        const init = () => {
            isLoading.value = true;
            showInvoice(apiStore.route('show-invoice', { invoice: route.params.id }))
                .then(data => {
                    isLoading.value = false;
                    invoice.value = data;
                    sumQuantity();
                });
        };

        const reorder = () => {
            buyAgain(invoice.value.buy_again_url)
                .then(message => {
                    if(typeof message === 'string') {
                        successHandler({ 
                            type: 'modal',
                            description: message, 
                            message
                        });
                    }
                });
        };

        const chatRider = () => {
            chatStore.rider_name = 'Customer Name' //TODO
            openChat.value = true;
            // router.push(`/chat-page/${route.params.id}`)
        }

        const sumQuantity = () => {
            items.value = invoice.value.items;
            let sum = 0;
            if (items.value) {
                items.value.forEach(item => {
                    sum = sum + item.quantity;
                    itemCount.value = sum;
                })
            }
        }

        onMounted(init);
        onIonViewWillEnter(init);

        const canChatToRider = computed(() => {

            // hide chat to rider if order status are Pending, Confirmed, Completed
            if(invoice.value?.order_status != 1 && invoice.value?.order_status != 2 && invoice.value?.order_status != 6) {
                return true;
            }

            return false;
        } );

        const productImage = (item) => {
            return item.file_path || '/assets/images/product-image.png';
        }

        return {
            router,
            openChat,
            openRateModal,
            showRateModal,
            moneyFormat,
            invoice,
            orderStatus,
            reorder,
            chatRider,
            sumQuantity,
            items,
            itemCount,
            productImage,
            canChatToRider,
            isLoading
        }
    },
});
</script>
