<template>
    <ion-page>
        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-148px)] lg:min-h-[calc(100vh-133px)] flex items-center justify-center" @keyup.enter="submit">
                <div class="w-full sm:w-96 mx-auto sm:p-0 px-4">
                    <form>
                        <h3 class="text-[40px] font-bold text-primary-500 text-center">Login</h3>
                        <div class="mt-12">
                            <div class="space-y-6">
                                <text-input
                                    v-model="form.email"
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    @keyup.enter="submit"
                                />
                                <password-input
                                    v-model="form.password"
                                    id="password"
                                    name="password"
                                    placeholder="Password"
                                    @keyup-enter="submit"
                                />
                            </div>
                            <div class="flex justify-between items-center mt-2">
                                <checkbox-input
                                primary
                                id="checkbox"
                                :default-slot="false">
                                    <div class="ml-2">
                                        <label
                                            for="checkbox"
                                            class="cursor-pointer text-gray-900 text-xs leading-8"
                                        >
                                            Remember me
                                        </label>
                                    </div>
                                </checkbox-input>
                                <a @click.prevent="router.push('forgot-password')" class="cursor-pointer text-sm font-normal text-primary-500 m-0">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="mt-12">
                            <button-component
                                size="default"
                                color="primary"
                                expand="block"
                                @click="submit"
                            >
                                <template #content>
                                    Login
                                </template>
                            </button-component>
                        </div>
                    </form>
                    <div class="text-center mt-8">
                        <p class="text-sm text-gray-400">
                            Dont have an account yet? 
                            <a @click.prevent="router.push('register')" class="cursor-pointer font-normal underline text-primary-500">Sign up now</a>
                        </p>
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent, 
	onIonViewWillEnter,
	onIonViewWillLeave
} from '@ionic/vue';
import { useRouter } from 'vue-router';

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import { ref } from "@vue/reactivity";
// import useModal from "@/composables/modal";
import useAuth from "@/composables/useAuth";
import { useApiStore } from "@/store/api";
import { useRoute } from "vue-router";
import useResponseHandler from '@/composables/useResponseHandler';
import useCart from "@/composables/useCart";
import useLocalStorage from "@/composables/useLocalStorage.js";
import useLocation from "@/composables/useLocation";

export default  {
    name: 'LoginPage',

    components: {
        IonPage, 
        IonContent,
        ButtonComponent,
        TextInput,
        PasswordInput,
        CheckboxInput,
        TopNavigation,
        BottomNavigation
    },

    setup() {
        const {
            submitCustomerLocation,
            // googleSearch,
        } = useLocation();
        const { setToken, fetchUserData } = useAuth();
		const apiStore = useApiStore();
		// const modal = useModal();
		const router = useRouter();
		const route = useRoute();
        const { openResponseModal } = useResponseHandler();
        const { cartParamsComplete, fetchCart } = useCart();
        const { localStorage } = useLocalStorage();
        const cartStore = ref(null);
        const settedAddress = ref(null);
		const form = ref({
			email: route.query.credential,
			password: null,
		});

        const addressForm = ref({
            id: null,
            label: 'Home',
            address_label: null,
            address_details: null,
            latitude: null,
            longitude: null,
            notes: null,
            cart_id: null,
        });

        const clearForm = () => {
            addressForm.value.id = null;
            addressForm.value.label = null;
            addressForm.value.address_label = null;
            addressForm.value.address_details = null;
            addressForm.value.latitude = null;
            addressForm.value.longitude = null;
            addressForm.value.notes = null;
            addressForm.value.cart_id = null; 
        };

        const loading = ref(false);
        // const errors = ref(false);

        const submit = async () => {
			loading.value = true;
            const previousRoute = await localStorage.value.get('previousRoute');
			await window.axios
				.post(apiStore.route("login"), form.value)
				.then((result) => {
					loading.value = false;                    
					setToken(
						"Bearer " + result.data.access_token,
						result.data.user
					);                    
                    // saveAddress();
					fetchUserData();
                    Promise.allSettled([fetchCart()]).then(() => {
                        fetchCartLocal()
                        setTimeout(() => {
                            saveAddress();
                        }, 500);
                    });                    

                    if (previousRoute) {
                        localStorage.value.delete('previousRoute');
                        router.replace(previousRoute);
                    } else {
                        router.replace("/home");
                    }
				})
				.catch((error) => {
                    if(error.response.data.error == 'This Account is not verified. You will received an OTP to verify your account.') {
                        localStorage.value.set("validateEmail", error.response.data.user.email);
                        // localStorage.value.set("validateEmail", error.response.data.user.mobile_number);
                        openResponseModal({ title: "Warning", description:  error.response.data.error != null ? error.response.data.error : error.response.data.message, buttonText: "Verify", buttonLink: "/validate-user-otp" });
                    } else {
                        openResponseModal({ title: "Warning", description:  error.response.data.error != null ? error.response.data.error : error.response.data.message, buttonText: "Okay" });
                    }
				});
		};

        const getSetAddress = async () => {
			const address = await localStorage.value.get("addressData");
            if (address) {
                settedAddress.value = address;
            }
		};

        const fetchCartLocal = async () => {
            const cart = await localStorage.value.get('cart');

            if (cart) {
                cartStore.value = cart;
            }
        }

        const saveAddress = () => {
            addressForm.value.latitude = settedAddress.value.latitude;
            addressForm.value.longitude = settedAddress.value.longitude;
            addressForm.value.address_label = settedAddress.value.address_label;
            addressForm.value.cart_id = cartStore.value?.id;

            submitCustomerLocation(addressForm.value)
                .then(() => {
                    // Clear values once request succeeds
                    clearForm();
                    fetchCart();
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        const goToRegister = () => {

            router.push('/register');
        }

        const goToForgotPassword = () => {

            router.push('/forgot-password');
        }

        const init = () => {
            if(cartParamsComplete.value) {
                fetchCart();
                getSetAddress();
            }
        }

        onIonViewWillEnter(() => {
            init();
        });
        
        onIonViewWillLeave(() => {
            init();
        });

        return {
            form,
            router,
            submit,
            goToRegister,
            goToForgotPassword
        };
    }
}
</script>