<template>
    <SelectOptions
        name="gender"
        id="gender"
        placeholder="Gender"
        :options="gender"
    />
</template>

<script>
import { defineComponent } from 'vue';
import SelectOptions from "@/components/inputs/SelectOptions.vue";
export default defineComponent({
    name: 'gender-selection',

    components: {
        SelectOptions
    },

    setup() {
        const gender = [
            { id: '1', label: 'Male', value: 'Male'},
            { id: '2', label: 'Female', value: 'Female'},
            { id: '3', label: 'Prefer not to say', value: 'Prefer not to say'}
        ]

        return {
            gender
        };
    }
});
</script>