<template>
    <profile-layout :title="'Address Book'">
        <div v-if="isLoading" class="flex items-center justify-center w-full h-full">
            <img src="/assets/images/loader.gif" class="h-auto w-14" />
        </div>
        <template v-else>
            <div class="flex items-center justify-between mb-[33px]">
                <p class="text-2xl font-semibold m-0 text-neutral-600 hidden lg:block">Address Book</p>
                <p class="lg:font-normal text-[#BDBDBD] m-0 cursor-pointer hover:text-primary-500 transition block lg:hidden">Edit</p>
                <p @click="openAddressModal()"
                    class="lg:font-normal text-[#BDBDBD] m-0 cursor-pointer hover:text-primary-500 transition">Add New Address
                </p>
            </div>
            <div class="flex-col flex space-y-[18px]">
                <template v-if="addresses.length > 0">
                    <div v-for="(address, index) in addresses" :key="index" class="p-5 bg-fuchsia-100 rounded-[10px]">
                        <div class="flex justify-between">
                            <p class="font-normal m-0">{{ address.label }}</p>
                            <p class="text-sm text-primary-500 m-0 font-normal cursor-pointer"
                            @click="openAddressModal(address)">Edit</p>
                        </div>
                        <p class="text-sm">{{ address.address_label }}</p>
                        <div class="mt-3 bg-white rounded-md p-[10px]">
                            <p class="text-[10px] font-bold text-gray-600 mb-2">Note to Driver</p>
                            <p class="text-[10px] font-normal text-gray-600">{{ address.notes }}</p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="text-center text-gray-600">No results found.</p>
                </template>
            </div>
        </template>        
    <NewAddressModal :is-visible="showAddressModal" @close="closeAddressModal()" @isLoading="(value) => isLoading = value" :reset-form="resetModalFormAddress"></NewAddressModal>
    </profile-layout>
</template>

<script type="text/javascript">
import { onIonViewWillEnter } from '@ionic/vue';
import { ref } from 'vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ProfileLayout from "./ProfileLayout.vue";
import NewAddressModal from '@/components/dialogs/AddressBook/NewAddressModal.vue';
import useModal from "@/composables/modal";
import { useApiStore } from "@/store/api.js";
import { useAddressStore } from "@/store/address.js";
export default defineComponent({
    name: 'AddressBook',
    components: {
        NewAddressModal,
        ProfileLayout
    },
    setup() {
        const router = useRouter();
        const modal = useModal();
        const apiStore = useApiStore();
        const addressStore = useAddressStore();
        const showAddressModal = ref(false);
        const addresses = ref([]);
        const isLoading = ref(false);
        const resetModalFormAddress = ref(false);
        function openAddressModal(address) {
            if(address) {
                addressStore.selectedAddress = address;
            }

            resetModalFormAddress.value = address ? false : true;
            showAddressModal.value = true;
        }

        const fetchAddresses = () => {
            // loading.value = true;

            window.axios
                .get(apiStore.route("fetch-addresses"))
                .then(({ data }) => {
                    // loading.value = false;
                    addresses.value = data.addresses;
                    // loading.value = false;
                })
                .catch((error) => {
                    // loading.value = false;
                    modal.error(error.response.data.message);
                });
        };

        const closeAddressModal = () => {
            fetchAddresses();
            showAddressModal.value = false
        }

        onIonViewWillEnter(() => fetchAddresses());

        return {
            router,
            showAddressModal,
            openAddressModal,
            closeAddressModal,
            addresses,
            isLoading,
            resetModalFormAddress
        }
    },
});
</script>
