import { defineStore } from 'pinia'
export const useSettingsStore = defineStore({
    id: 'settings',
    state:() => ({
        deliveryMethods: [],
        deliveryOptions: [],
        paymentMethods: [],
        orderStatuses: {},
        suggesstedSearch: {}
    }),
    getters: {
        deliveryMethod:(state) => (code => state.deliveryMethods.find(x => x.code == code) || {})
    },
    actions: {}
})