<template>
    <slot name="recipe-card">
        <div>
            <a @click="router.push(`/show/recipe/${recipe.id}`)" class="w-full">
                <div>
                    <div class="relative w-full pt-[60%] overflow-hidden rounded-xl mb-4">
                        <img class="absolute top-0 left-0 w-full h-full object-cover" :src="previewImage(recipe.file_path)" alt="">
                    </div>
                    <p class="font-normal mb-1">{{ recipe.name }}</p>
                    <p class="text-xs text-gray-500">{{ recipe.products.length }} items on this recipe</p>
                    <!-- <p class="text-xs text-gray-500" v-html="recipe.description.substring(0,90)+'...'"></p> -->
                </div>
            </a>
        </div>
    </slot>
</template>
<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'RecipeCard',
    components: {
    },
    props: {
        // recipes: {
        //     type: [Array, null],
        //     default: () => [

        //     ]
        // },
        recipe: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const router = useRouter();

        const previewImage = (file_path) => {
            return file_path || '/assets/images/product-image.png';
        }

        return {
            router,
            previewImage
        }
    },
});
</script>
