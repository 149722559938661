<template>
    <ion-page>
        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-133px)] flex items-center justify-center">
                <div class="w-96 mx-auto">
                    <form>
                        <h3 class="text-[40px] font-bold text-primary-500 text-left">Forgot Password?</h3>
                        <p class="text-sm text-left">We will send instructions on how to reset your password.</p>
                        <div class="mt-12">
                            <div class="space-y-6">
                                <text-input
                                    v-model="email"
                                    type="email"
                                    placeholder="Email Address"
                                />
                            </div>
                        </div>
                        <div class="mt-12">
                            <button-component
                                size="default"
                                color="primary"
                                expand="block"
                                @click="submit"
                            >
                                <template #content>
                                    <template v-if="!loading">
                                        Send Password Reset Code
                                    </template>
                                    <template v-else>
                                        <ion-spinner></ion-spinner>
                                    </template>
                                </template>
                            </button-component>
                        </div>
                    </form>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonSpinner 
} from '@ionic/vue';
import { useRouter } from 'vue-router';

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import { ref } from '@vue/reactivity';
import { useUserStore } from "@/store/user";
import { useApiStore } from "@/store/api";
import useResponseHandler from '@/composables/useResponseHandler';

export default  {
    name: 'ForgotPassword',

    components: {
        IonPage, 
        IonContent,
        IonSpinner,
        ButtonComponent,
        TextInput,
        TopNavigation,
        BottomNavigation
    },

    setup() {
        const apiStore = useApiStore();
        const email = ref(null);
        const loading = ref(false);
        const router = useRouter();
        const store = useUserStore();
        const { openResponseModal } = useResponseHandler();

        const submit = () => {
            loading.value = true;

            const data = {
                email: email.value
            }

            window.axios
            .post(apiStore.route("forgot-password"), data)
            .then((result) => {
                loading.value = false;

                store.setUserEmail(result.data.email);
                // store.commit('user/setUserEmail', result.data.email);
                openResponseModal({ title: "Success!", description: result.data.message, buttonText: "Okay" });
                router.push("/verification-code")
            })
            .catch((error) => {
                loading.value = false;

                let message = error.response.data.error ? error.response.data.error : error.response.data.message;
                openResponseModal({ title: "Warning!", description: message, buttonText: "Okay" });
            })

        }
    
        return {
            router,
            email,
            loading,
            submit,
        };
    }
}
</script>