<template>
    <span
    class="
        text-[10px] 
        w-4
        h-4 
        rounded-full 
        border-red-500
        flex 
        items-center 
        justify-center 
        ml-3
    "
    :class="count == 0 ? 'hidden' : 'bg-red-500 text-white'"
    >
        {{ count }}
    </span>
</template>

<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CountBadge',
    components: {
    },
    props: {
        count: {
            type: String,
        },
    },
    setup() {

        return {
        }
    },
});
</script>