<template>

    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true">
            <div class="pt-20 pb-[220px] px-4 md:px-0 max-w-full md:max-w-[700px] m-auto text-center">
                <h1 class="font-semibold text-primary-500 text-2xl lg:text-[40px] mb-10 lg:mb-[53px]">Order Confirmed!</h1>
                <p class="text-sm leading-7">Your order is now being processed. You may continue shopping or view your order/s.<br>Your order numbers are:</p>
                <div class="w-full sm:w-[360px] py-5 text-center bg-fuchsia-100 rounded-[10px] m-auto text-2xl font-semibold text-neutral-600 my-10">
                    {{ invoiceNumber || noInvoiceCreated }}
                </div>
                <p class="text-sm leading-7 mb-8">Thank you for shopping at Fun Han Mart</p>
                <div class="flex w-fit space-x-4 m-auto">
                    <ButtonComponent color="primary" fill="solid" expand="block" size="large" v-if="isAuthenticated" @click="router.push('/order-history')">
                        <template #content>
                            <span class="text-white text-sm">View Orders</span>
                        </template>
                    </ButtonComponent>
                    <ButtonComponent color="primary" fill="outline" expand="block" size="large" @click="router.push('/shop')">
                        <template #content>
                            <span class="text-sm">Continue Shopping</span>
                        </template>
                    </ButtonComponent>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>

</template>
<script>
import { IonPage, IonContent, onIonViewWillEnter } from '@ionic/vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/store/user';

export default {
    name: 'OrderConfirmation',
    components: {
        IonPage,
        IonContent,
        TopNavigation,
        BottomNavigation,
        ButtonComponent
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const user = useUserStore();
        const isAuthenticated = computed(() => user.token != null);
        const invoiceNumber = ref(router.currentRoute.value.query.invoiceNo);
        const cartId = ref(route.query.cart_id);
        const noInvoiceCreated = ref('Processing');


        const fetch = () => {
            if (cartId.value) {
                window.axios
				.post(process.env.VUE_APP_API_URL + "/api/v1/customer/invoice/created", { cart_id: cartId.value })
                .then((result) => {
                    if (!result?.data?.invoice?.invoice_no) {
                        noInvoiceCreated.value = 'No Invoice created. Please contact admin.';
                    } else {
                        invoiceNumber.value = result?.data?.invoice?.invoice_no;
                    }
                })
                .catch(() => {
                    noInvoiceCreated.value = 'No Invoice created. Please contact admin.';
                });
            }            
        }

        onMounted(fetch);

        onIonViewWillEnter(fetch);
        
        return {
            router,
            isAuthenticated,
            invoiceNumber,
            noInvoiceCreated
        }
    }
}
</script>
