<template>
    <div>
        <!-- <div @click="openChat = !openChat"
            class="rider-chat bg-primary-500 px-6 py-4 rounded-[10px] flex space-x-4 items-center w-fit">
            <img src="/assets/icon/chat.svg" alt="">
            <p class="text-sm text-white">Chat with your rider</p>
        </div> -->
        <button-component size="default" color="primary"
        @click="openChat = !openChat">
            <template #content>
                <ion-icon
                src="/assets/icon/chat.svg"
                class="w-6 h-6"></ion-icon>
                <span class="ml-4 text-sm">Chat with your rider</span>
            </template>
        </button-component>

        <!--------------------------------------- 
            CHAT MENU
        ---------------------------------------->
        <SideChatMenu
            :invoice-id="invoiceId"
            :is-visible="openChat"
            :rider="rider"
            @close="openChat = false"
        />
    </div>
</template>

<script type="text/javascript">

import {
    IonIcon,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import SideChatMenu from '@/components/chat/SideChatMenu.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default defineComponent({
    name: 'ChatButton',
    components: {
        IonIcon,
        ButtonComponent,
        SideChatMenu
    },
    props : {
        invoiceId : {
            default: null,
            type : Number
        },
        rider: {
            type: String,
            default: null
        }
    },
    setup() {

        const openChat = ref(false);

        return {
            openChat
        }
    },
});

</script>