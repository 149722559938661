<template>
    
    <div class="w-full">
        <div class="w-full h-0 aspect-w-16 aspect-h-9 rounded-xl">
            <img :src="item.formatted_image" class="rounded-xl object-cover"/>
        </div>
        <div class="mt-4 space-y-2">
            <h3 class="text-gray-900 font-semibold text-xl">{{ item.title }}</h3>
            <p class="text-gray-600 text-sm font-normal line-clamp-3">
                {{ item.snippet }}
            </p>
        </div>
    </div>

</template>
<script>
export default {
    name: 'ArticleCard',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
}
</script>