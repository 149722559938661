<template>
    <slot name="product-card">
        <!-- <div v-for="product in products" :key="product"> -->
            <div class="bseller-card border rounded-xl overflow-hidden relative h-full flex flex-col align-start">
                <a @click="showProduct" class="cursor-pointer block w-full m-0">
                    <div class="tags absolute top-0 left-0 z-10 flex overflow-hidden rounded-tl-lg rounded-br-lg">
                        <div v-if="product.is_new"
                            class="new text-[10px] py-[7px] px-[10px] font-bold text-white bg-red-500">
                            NEW</div>
                        <div v-if="product.is_best"
                            class="best text-[10px] py-[7px] px-[10px] font-bold text-white bg-primary-500">BEST</div>
                    </div>
                    <div class="bseller-img relative w-full pt-[100%]">
                        <img class="absolute top-0 left-0 w-full h-full object-cover" :src="productImage" alt="">
                        <div v-if="!product.have_stocks"
                            class="preparing w-full h-full bg-[#00000040] absolute top-0 left-0 flex">
                            <p class="text-white font-bold text-xl">PREPARING</p>
                        </div>
                        <div v-if="product.promo_id" class="percent-discount w-6/12 absolute bottom-0 right-0">
                            <img class="w-full" src="/assets/images/blob.png" alt="">

                            <p class="text-white font-bold absolute bottom-1 right-1 lg:bottom-2 lg:right-3 md:text-xl lg:text-lg xl:text-2xl">{{ product.promo_discount
                            }}</p>
                        </div>
                    </div>
                    <div class="px-[12.5px] py-4 text-left">
                        <p class="line-clamp-2 text-sm m-0 mb-1">{{ product.name }}</p>
                        <div class="flex justify-between">
                            <div class="flex flex-col">
                                <div class="flex gap-1">
                                    <p
                                    class="prdct-price-gray" 
                                    :class="[product.promo_id ? 'text-xs line-through' : 'font-normal mb-1']"
                                    :data-title="product.promo_id ? moneyFormat(product.price_with_tax).slice(-3) : moneyFormat(product.discounted_price).slice(-3)">
                                        {{ product.promo_id ? moneyFormat(product.price_with_tax).slice(0, -3) : moneyFormat(product.discounted_price).slice(0, -3) }}
                                    </p>
                                    <p 
                                    v-if="product.promo_id" 
                                    class="font-bold text-red-500 prdct-price-red"
                                    :data-title="moneyFormat(product.discounted_price).slice(-3)">
                                        {{ moneyFormat(product.discounted_price).slice(0, -3) }}
                                    </p>
                                </div>
                                <div class="flex">
                                    <img class="w-3 mr-[2px]" src="/assets/icon/ic-halfstar.svg" alt="">
                                    <p class="text-xs text-yellow-500 font-bold m-0 mr-1">{{ product.rating }}</p>
                                    <p v-if="product.review_count" class="text-gray-400 text-xs m-0">{{ product.review_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                <div v-if="!product.have_stocks" 
                    @click="toggleNotifyMeIfHasStock" 
                    class="h-[44px] flex items-center text-center justify-center border-[2px] border-primary-500 rounded-b-xl mt-auto"
                    :class="notifyMeIfHasStock ? 'bg-primary-500' : null"
                    >
                    <BellIcon class="w-5 h-5"
                    :class="notifyMeIfHasStock ? 'text-white' : 'text-primary-500'" />
                </div>
                <div v-else class="mt-auto">
                    <product-counter productCard v-model="quantity" :max="product.stocks" @updateQuantityByEvent="(value) => updateQuantityByEvent(value)"></product-counter>
                </div>
            </div>
        <!-- </div> -->
    </slot>
</template>
<script type="text/javascript">

import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import useCart from '@/composables/useCart';
import useFormat from "@/composables/useFormat";
import useResponseHandler from '@/composables/useResponseHandler';

import { BellIcon } from "@heroicons/vue/outline";
import ProductCounter from '@/components/inputs/QuantitySelector.vue';
import { useApiStore } from "@/store/api";
import useLocalStorage from "@/composables/useLocalStorage.js";
import useModal from "@/composables/modal";

export default defineComponent({
    name: 'ProductCard',
    components: {
        ProductCounter,
        BellIcon
    },
    props: {
        product: {
			type: Object,
			required: true,
		},
    },
    setup(props) {
        
        const router = useRouter();
        const cart = useCartStore();
        const modal = useModal();

        const { addToCart, updateCartItem, removeCartItem, cartFetchParams } = useCart();
        const { moneyFormat } = useFormat();
        const { errorHandler, openResponseModal } = useResponseHandler();
        const { localStorage } = useLocalStorage();
        const apiStore = useApiStore();

        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */

        const quantity = ref(0);
        const cartItem = computed(() => cart.item(props.product.sku));
        const productImage = computed(() => props.product.image || '/assets/images/product-image.png')
        const outOfStockProducts = ref([]);
        const notifyMeIfHasStock = computed(() => {
            let status = false;
            if (outOfStockProducts.value?.length > 0) {
                outOfStockProducts.value.forEach((item) => {
                    if(item.product_id == props.product.sku && item.branch_id == cartFetchParams.value.branch_id) {
                        status = true;
                    }
                });
            }

            return status;
        });

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

        const showProduct =()=> {
            // const showUrl = props.product.show_url;
            window.location.href = `/product/${props.product.id}`;
            // window.location.href = `/product?showUrl=${showUrl}`;
            // router.push({ path: '/product', query: { showUrl: showUrl }});
        }

        const addToCartHandler = (qty) => {
            addToCart({
                product_id: props.product.sku,
                quantity: qty,
                for_checkout: true
            })
            .then(message => {
                if (message?.status == undefined) {
                    return;
                }
                if(message.status === 422) {
                    quantity.value = props.product.stocks;
                    updateQuantityByEvent(quantity.value);
                    errorHandler(message);
                }
            })
        };

        const updateCartItemHandler = (qty) => {
            if(qty === 0) {
                updateCartItem(cartItem.value.update_url, { quantity: qty, for_checkout: true }).then(() =>  removeCartItem(cartItem.value.remove_url));
            } else {
                updateCartItem(cartItem.value.update_url, { quantity: qty, for_checkout: true })
                .then(message => {
                    if (message?.status == undefined) {
                        return;
                    }
                    if(message.status === 422) {
                        quantity.value = props.product.stocks;
                        updateQuantityByEvent(quantity.value);
                        errorHandler(message);
                    }              
                });
            }            
        };

        const toggleNotifyMeIfHasStock = () => {
            
            let product = props.product;
            let params = {
                product: product.sku,
                delete: notifyMeIfHasStock.value,
                branch_id: cartFetchParams.value.branch_id
            };

            window.axios
                .post(apiStore.route("out-of-stock.store"), params)
                .then(({ data }) => {
                    outOfStockProducts.value = [data.product];
                    if (data.message) {
                        openResponseModal({ title: "Success", description: data.message, buttonText: "Okay" });
                    }
                })
                .catch((error) => {
                    modal.error(error.response.data.message);
                })
        }

        // Fetch User
        const getUserProfile = () => {
			localStorage.value.get("authToken").then((value) => {
				if (value) {
					localStorage.value.get("user").then((user) => {
                        outOfStockProducts.value = user.out_of_stock_products;
					});
				}
			});
		};

        const updateQuantityByEvent = (value) => {
            if (!cartItem.value.product_id) {
                addToCartHandler(value);
            } else if (cartItem.value.product_id) {
                updateCartItemHandler(value);
            }

        }

        /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

        watch(
            () => cartItem.value.quantity,
            (val) => {
                if(!isNaN(val)) {
                    quantity.value = val;
                }
            },
            { immediate: true }
        );

        // watch(
        //     () => quantity.value,
        //     (val, oldVal) => {
        //         if(oldVal == 0 && !cartItem.value.product_id) {
        //             addToCartHandler(val);
        //         }
        //         else if(cartItem.value.product_id) {
        //             updateCartItemHandler(val);
        //         }
        //     }
        // );

        onMounted(getUserProfile);

        return {
            cartItem,
            router,
            moneyFormat,
            quantity,
            productImage,
            showProduct,
            toggleNotifyMeIfHasStock,
            notifyMeIfHasStock,
            updateQuantityByEvent
        }
    },
});
</script>