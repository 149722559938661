import { defineStore } from 'pinia';
import useLocalStorage from "@/composables/useLocalStorage";

export const useAddressStore = defineStore({
    id: 'address',
    state: () => {
        return {
            branch: {},
            coords: {},
            data: {},
            items: [],
            selectedAddress: {}
        }
    },
    getters: {
        hasSelectedAddress(state) {
            return Object.keys(state.selectedAddress).length > 0 ? true : false;
        }
    },
    actions: {
        async reassignAddressState(key = "addressCoords") {
            const { localStorage } = useLocalStorage();

            await localStorage.value.get(key).then((result) => {
                if(result) {
                    switch (key) {
                        case "addressCoords":
                            this.coords = result;
                            break;

                        case "addressData":
                            this.data = result;
                            break;
                    }
                }
            });
        },
    }
})