<template>
    <div class="reviews-card border rounded-lg overflow-hidden h-full"
    @click="showReview(item.product.id)">
        <div class="reviews-card__img">
            <div
				class="
					w-full
					h-0
					pt-[90%]
					relative
				"
			>
				<div
                    v-if="item.images.length != 0"
					class="
						absolute
						top-0
						left-0
						h-full
						w-full
						bg-cover bg-no-repeat bg-center
					"
					:style="`background-image: url('${item.images[0].formatted_file_path}')`"
				></div>
                <div
                    v-else
					class="
						absolute
						top-0
						left-0
						h-full
						w-full
						bg-cover bg-no-repeat bg-center
					"
					:style="`background-image: url('/assets/images/product-image.png')`"
				></div>
            </div>
            <!-- <img class="w-full h-[140px] object-cover" :src="item.productImage" alt=""> -->
        </div>
        <div class="p-2 text-left">
            <div class="mb-1">
                <p class="line-clamp-2 text-sm m-0 mb-2">{{ item.product.name }}</p>
            </div>
            <div class="flex space-x-2 relative w-full mb-1">

                <img class="w-[33px] h-[33px] object-cover rounded-full"
                    :src="item.user.profile_photo_url" alt="">

                <div class="w-full">
                    <p class="text-md font-bold line-clamp-2 text-primary-500">{{ item.customer_name }}</p>
                    
                    <div class="flex space-x-1">
                        <StarIcon class="w-4 h-4 text-yellow-500" />
                        <p class="text-[10px] font-bold text-yellow-500">{{ item.score }}</p>
                    </div>
                </div>

                <div class="self-end text-right">
                    <p class="text-[10px] text-gray-500">{{ date(item.created_at) }}d
                    </p>
                </div>
            </div>
            <div>
                <p class="line-clamp-2 text-sm mt-2">{{ item.message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { StarIcon } from "@heroicons/vue/solid";
import { DateTime } from 'luxon';
// import { useUserStore } from "@/store/user";

export default defineComponent({
    name: 'ReviewCard',
    components: {
        StarIcon
    },
    props: {
        // item: {
        //     type: [Array, null],
        //     default: () => [

        //     ]
        // },
        item: {
			type: Object,
			required: true,
		},
    },
    setup() {
        const router = useRouter();
        // const store = useUserStore();

        const showReview = (value) => {
            // const showUrl = process.env.VUE_APP_API_URL;
            window.location.href = `/product/${value}`;
            // window.location.href = `/product?showUrl=${showUrl}/api/v1/customer/product/show/${value}`;
        }

        const date = (value) => {
            const getDate = DateTime.fromISO(value);
            const now = DateTime.now();
            const diffDate = getDate.diff(now, ["days"]);
            const date = diffDate.toString().slice(2,3);

            return date;
        }

        return {
            router,
            date,
            showReview
        }
    },
});
</script>