<template>
    <div class="frm-datepicker">
        <DatePicker
            :uid="id"
            v-model="value"
            :disabled="disabled"
            :placeholder="placeholder"
            :timePicker="timePicker"
            :spaceConfirm="spaceConfirm"
            :enableTimePicker="enableTimePicker"
            :enableSeconds="enableSeconds"
            :partialRange="!range"
            :range="range"
            :multiCalendars="multiCalendars"
            :presetRanges="presetRanges"
            @update:modelValue="updateDate"
            :minDate="minDate"
            :inline="inline"
            :weekPicker="weekPicker"
            :autoApply="autoApply"
            :weekStart="weekStart"
            :minTime="minTime"
            :maxTime="maxTime"
            :startTime="startTime"
            utc="preserve"
            :auto-apply="true"
            month-name-format="long"
            week-start="0"
            :markers="markers"
            :max-date="maxDate"
            :name="name"
        />
        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>
            <!-- :highlight="highlight" -->
            <!-- :disabledWeekDays="disabledWeekDays" -->
            <!-- :allowedDates="allowedDates" -->
    </div>
</template>

<script>
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";

export default {
    components: {
        DatePicker,
        // ExclamationCircleIcon,
    },
    props: {
        modelValue: {
            default: null,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        timePicker: {
            type: Boolean,
            default: false,
        },
        enableTimePicker: {
            type: Boolean,
            default: false,
        },
        enableSeconds: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        multiCalendars: {
            type: Boolean,
            default: false,
        },
        presetRanges: {
            default: [],
        },
        spaceConfirm: {
            type: Boolean,
            default: true,
        },
        minDate: {
            type: Date,
            default: null,
        },
        // highlight: {
        //     type: Array,
        //     default: [],
        // },
        // disabledWeekDays: {
        //     type: Array,
        //     default: [],
        // },
        inline: {
            type: Boolean,
            default: false,
        },
        weekPicker: {
            type: Boolean,
            default: false,
        },
        autoApply: {
            type: Boolean,
            default: false,
        },
        // allowedDates: {
        //     type: Array,
        //     default: [],
        // },
        weekStart: {
            type: Number,
            default: 1,
        },
        minTime: {
            default: null,
        },
        maxTime: {
            default: null,
        },
        maxDate: {
            default: null,
        },
        startTime: {
            default: null,
        }
    },
    setup(props, { emit }) {
        const value = ref(props.modelValue);

        const updateDate = (value) => {
            emit("update:modelValue", value);
        };

        // const date = ref(new Date());
        // const markers = ref([
        //     {
        //         date: addDays(new Date(), 1),
        //         type: 'dot',
        //         tooltip: [{ text: 'Dot with tooltip' }],
        //     },
        //     {
        //         date: addDays(new Date(), 3),
        //         type: 'dot',
        //     },
        // ])

        return {
            updateDate,
            value,
            // date,
            // markers
        };
    },
};
</script>
