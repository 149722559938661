<template>
    <div class="ion-padding p-4" :key="sku">
        <div class="close-btn h-[6px] w-[104px] rounded-full bg-gray-300 mx-auto mb-6" @click="close()"></div>
        <div class="text-center mb-4">
            <p class="font-bold">Add to your cart</p>
        </div>

        <div class="w-full"
                v-for="i in 1" :key="i">
            <div class="mb-[21px]">
                <p class="mb-3">{{ name }}</p>
                <p class="text-sm font-bold m-0">{{ moneyFormat(subtotal) }}</p>
            </div>
            <div class="justify-self-end ml-auto">
                <p class="text-xs mb-1">Quantity</p>
                <QuantitySelector class="ml-auto" :max="stocks" :key="`minicart-qty-${quantity}`" v-model="quantity" :disabled-plus="quantity === stocks"/>
            </div>
        </div>

        <button-component class="mt-8" size="default" color="primary" expand="block" @click="submit">
            <template #content>
                Update Cart
            </template>
        </button-component>
    </div>
</template>

<script>

import { computed, defineComponent, ref } from 'vue';
import useCart from '@/composables/useCart';
import useFormat from '@/composables/useFormat';
import useResponseHandler from '@/composables/useResponseHandler';

import { modalController } from '@ionic/vue';
import ButtonComponent from "@/components/ButtonComponent.vue";
import QuantitySelector from "@/components/inputs/QuantitySelector.vue";

export default defineComponent({
    name: 'mini-cart',
    components: { 
        QuantitySelector,
        ButtonComponent
    },
    props: {
        sku:{
            type:Number,
            default:null
        },
        name:{
            type:String,
            default:null
        },
        price:{
            type:Number,
            default:0
        },
        updateUrl:{
            type:String,
            default:null
        },
        qty:{
            type: Number,
            default: 1
        },
        stocks:{
            type: Number,
            default: 0
        }
    },
    setup(props) {

        // const router = useRouter();

        const { addToCart, updateCartItem } = useCart();
        const { moneyFormat } = useFormat();
        const { successHandler, errorHandler } = useResponseHandler();

        const quantity = ref(props.qty);
        const subtotal = computed(() => props.price * quantity.value);

        const close = async() => {
            await modalController.dismiss();
        }

        const submit = () => {
            if(props.updateUrl) {
                updateCartItemHandler(); /* Add to Cart */
            }
            else {
                addToCartHandler(); /* Buy Now */
            }
        };

        const updateCartItemHandler = () => {
            updateCartItem(props.updateUrl, {
                quantity: quantity.value,
                for_checkout: true
            })
            .then(message => {
                if (message.status === 422) {
                    errorHandler(message);
                    return;
                }

                if(typeof message === 'string') {
                    close();
                    successHandler({ 
                        type: 'toast',
                        description: message, 
                        message,
                        duration: 2000,
                        autoClose: true
                    });
                }                
            });
        };

        const addToCartHandler = () => {
            addToCart({
                product_id: props.sku,
                quantity: quantity.value,
                for_checkout: true,
            })
            .then(message => {
                if (message.status === 422) {
                    errorHandler(message);
                    return;
                }

                if(typeof message === 'string') {
                    close();
                }                
            });
        };

        return {
            moneyFormat,
            quantity,
            subtotal,
            close,
            submit
        }
    }
})
</script>