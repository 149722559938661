<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div class="max-w-[1440px] m-auto px-4 lg:px-12 xl:px-[140px] py-12 lg:py-[72px] faq">
                <div class="mb-12">
                    <h1 class="text-[28px] lg:text-[40px] font-semibold">Frequently Asked Questions</h1>
                </div>
                <template v-if="faqs.length > 0">
                    <ion-accordion-group>
                        <ion-accordion v-for="(faq, index) in faqs" :key="index">
                            <ion-item slot="header" color="light">
                                <ion-label class="text-xl font-normal mb-[14px]">{{ faq.question }}</ion-label>
                            </ion-item>
                            <div class="ion-padding" slot="content">
                                <p class="frm-desc" v-html="faq.answer"></p>
                            </div>
                        </ion-accordion>
                    </ion-accordion-group>
                </template>

                <template v-else>
                    <p class="text-center text-gray-600">No results found.</p>
                </template>

            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
    IonAccordion, 
    IonAccordionGroup,
    IonItem, 
    IonLabel,
    onIonViewWillEnter
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import { useApiStore } from '@/store/api';
import useModal from "@/composables/modal";

export default defineComponent({
    name: 'FAQ',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        IonAccordion, 
        IonAccordionGroup,
        IonItem, 
        IonLabel
    },
    setup() {
        const router = useRouter();
        const apiStore = useApiStore();
        const modal = useModal();

        const faqs = ref([]);
        // const loading = ref(false);

        const fetch = () => {
            // loading.value = true;

            window.axios
            .get(
                apiStore.route('fetch-faqs'))
            .then(({ data }) => {
                faqs.value = data.faqs;
                // loading.value = false;
            }).catch((error) => {
                modal.error(error.response.data.message);
                // loading.value = false;
            })
        };

        onIonViewWillEnter(() => fetch());

        return {
            router,
            faqs
        }
    },
});
</script>
