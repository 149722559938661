<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <div class="max-w-[1440px] m-auto py-12 px-4 lg:px-[100px] lg:py-20 frm-desc">
                <div v-if="cmsContent !== null && getCmsContent('section1_title') !== null">
                    <h1 class="text-[28px] lg:text-[32px] mb-6 lg:mb-[55px] font-semibold text-primary-500">{{ getCmsContent('section1_title') || 'Refund Policy'}}</h1>
                </div>
                <div v-else>
                    <h1 class="text-[28px] lg:text-[32px] mb-6 lg:mb-[55px] font-semibold text-primary-500">Refund Policy</h1>
                </div>

                <div v-if="cmsContent !== null && getCmsContent('section1_content') !== null">
                    <div v-html="getCmsContent('section1_content')"></div>
                </div>
                <div v-else>
                    <h2>GENERAL</h2><br>
                    <p>Guidetothe Philippines Inc. and its affiliates (also collectively referred as “Company”, “we”, “our” and “us”) established this Data Refund Policy to show that we recognize and value your Refund rights.</p>
                    <br>

                    <h2>APPLICABILITY</h2><br>
                    <p>This Data Refund Policy applies to Personal Information we collect on our website, guidetothephilippines.ph.</p>
                    <br>
                    <p>We have prepared this Refund Policy to help you understand how GuidetothePhilippines collects, uses, and seeks to safeguard the Personal Information (as defined below) you provide to us on our websites, via email, and through our service providers and distribution partners. This Refund Policy does not address, and we are not responsible for, the Refund, information or other practices of any third party, including any third-party distribution partners, suppliers and tour operators, and any third party operating any site to which our websites contain a link. By downloading, accessing or using our websites, or providing information to us in connection with our websites, you agree to the terms and conditions of this Refund Policy.</p>
                    <br>

                    <h2>PROCESSING OF INFORMATION BY THIRD-PARTY AND LINKS TO OTHER WEBSITES</h2><br>
                    <p>This Data Refund Policy shall not apply to third-party service providers and to other websites which have white label link to our Sites. The processing of your Personal Information in the third party’s Sites is subject to their respective Data Refund Policy. In no event will the Company be responsible for the information these third parties will collect and process, neither will the Company be liable for any loss, damage or injury arising out of or in connection with the use of or reliance on such third party.</p>
                    <br>
                </div>

            </div>
            
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import { onMounted, ref } from 'vue';
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import useCms from '@/composables/useCms';

export default defineComponent({
    name: 'RefundPolicy',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        
    },
    setup() {
        const router = useRouter();
        const { cms, contentSlug, getCms, getCmsContent } = useCms();
        const cmsContent = ref(null);

        const getContentSlug =()=> {
            contentSlug.value = "refund";
            getCms(contentSlug.value);
            cmsContent.value = cms._rawValue.content;
        }

        onMounted(() => {
            getContentSlug();
        });

        return {
            router,
            cmsContent,
            getCmsContent
        }
    },
});
</script>
