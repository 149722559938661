<template>

    <!-- <template v-if="!showAddresslist"> -->
        <div class="rounded-[10px] bg-secondary-500/10 py-5 pl-10 pr-4 lg:px-12 w-full relative" v-if="userData.name">
            <ion-spinner class="absolute m-auto left-0 right-0 top-[40%]" v-if="loading" />
            <div class="space-y-1" :class="loading ? 'opacity-50' : ''">
                <p class="font-normal text-base" v-if="!guestCheckout">{{ address.label }}</p>
                <p class="font-light text-sm" v-if="!guestCheckout">{{ userData.name }}</p>
                <p class="font-light text-sm" v-if="!guestCheckout">{{ userData.mobile_number }}</p>
                <p class="font-light text-sm">{{ address.address_label }}</p>
            </div>
            <div class="bg-white w-full px-2 py-1 mt-3 rounded"  :class="loading ? 'opacity-50' : ''" v-if="address.notes">
                <p class="text-[10px] font-bold text-gray-300">Note to Driver</p>
                <p class="text-[10px] font-normal text-gray-300">{{ address.notes }}</p>
            </div>
        </div>
    <!-- </template> -->
    <!-- <template v-else>
        <div>
            <RadioGroup v-model="selectedaddress">
                <div class="mt-4 grid grid-cols-1 gap-y-6">
                    <RadioGroupOption as="template" v-for="address in addresses" :key="address.id" :value="address.id" v-slot="{ checked, active }">
                        <div :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                            <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500']" aria-hidden="true" />
                            <span :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                            <div class="flex-1 flex ml-3">
                                <div class="flex flex-col">
                                    <div class="space-y-1">
                                        <p class="font-normal text-base">{{ address.label }}</p>
                                        <p class="font-light text-sm">{{ userData.name }}</p>
                                        <p class="font-light text-sm">{{ userData.mobile_number }}</p>
                                        <p class="font-light text-sm">{{ address.address_label }}</p>
                                    </div>
                                    <div class="bg-white w-full px-2 py-1 space-y-1 mt-3" v-if="address.notes">
                                        <p class="text-xs font-bold text-gray-600">Note to Driver</p>
                                        <p class="text-xs font-light text-gray-600">{{ address.notes }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </div>
    </template> -->

    <div class="mt-2 flex flex-wrap items-start justify-start" v-if="!viewOnly">
        <ButtonComponent
            fill="clear"
            @click="refreshAddress"
        >
            <template #content>
                <span class="underline text-sm flex items-center">
                    <ChevronRightIcon class="w-4 h-4 mr-1 transition"
                    :class="showAddresslist ? 'rotate-90' : ''"
                    />
                    Select Address
                </span>
            </template>
        </ButtonComponent>
        <ButtonComponent
            fill="clear"
            @click="$emit('add-new', true)"
        >
            <template #content>
                <span class="underline text-sm">+ Add Address</span>
            </template>
        </ButtonComponent>

        <ButtonComponent
            fill="clear"
            @click="$emit('add-new', false)"
            v-if="addressStore.hasSelectedAddress && cart.data?.customer_address"
        >
            <template #content>
                <span class="underline text-sm">Edit Address</span>
            </template>
        </ButtonComponent>
    </div>

    <transition
    enter-active-class="duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0">
        <div v-show="showAddresslist">
            <RadioGroup v-model="selectedaddress">
                <div class="mt-4 grid grid-cols-1 gap-y-6">
                    <RadioGroupOption as="template" v-for="address in addresses" :key="address.id" :value="address.id" v-slot="{ checked, active }" @click="checkCurrentAddress(address)">
                        <div :class="[checked ?? 'border-transparent', active ? 'border-secondary-500 ring-2 ring-secondary-500' : '', 'relative bg-secondary-500/10 rounded-lg shadow-sm p-4 pr-10 flex cursor-pointer focus:outline-none']">
                            <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-secondary-500 mt-0.5']" aria-hidden="true" />
                            <span :class="[active ? 'border' : 'border-2', checked ? 'border-secondary-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                            <div class="flex-1 flex ml-3">
                                <div class="flex flex-col w-full">
                                    <div class="space-y-1">
                                        <p class="font-normal text-base">{{ address.label }}</p>
                                        <p class="font-light text-sm">{{ userData.name }}</p>
                                        <p class="font-light text-sm">{{ userData.mobile_number }}</p>
                                        <p class="font-light text-sm">{{ address.address_label }}</p>
                                    </div>
                                    <div class="bg-white w-full px-2 py-1 space-y-1 mt-3" v-if="address.notes">
                                        <p class="text-[10px] font-bold text-gray-300">Note to Driver</p>
                                        <p class="text-[10px] font-normal text-gray-300">{{ address.notes }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </div>
    </transition>

</template>
<script>
import { IonSpinner } from '@ionic/vue';
import { ref, computed, onMounted, watch } from 'vue'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { useAddressStore } from '@/store/address';
import { useCartStore } from '@/store/cart';
import { useUserStore } from '@/store/user';
import { useApiStore } from "@/store/api.js";
import useCart from '@/composables/useCart';
import useModal from "@/composables/modal";

import ButtonComponent from '@/components/ButtonComponent';

export default {
    name: 'AddressSelection',
    components: {
        RadioGroup,
        RadioGroupOption,
        CheckCircleIcon,
        ButtonComponent,
        ChevronRightIcon,
        IonSpinner
    },
    emits: ['add-new'],
    props: {
        viewOnly: {
            type: Boolean,
            default: false,
        },
        guestCheckout: {
            type: Boolean,
            default: false,
        },
        setAddresses: {
            type: Object,
            default: () => {}
        },
        localAddress: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const selectedaddress = ref(null)
        const showAddresslist = ref(false);
        const loading = ref(false);

        const addressStore = useAddressStore();
        const cart = useCartStore();
        const user = useUserStore();
        const apiStore = useApiStore();

        const userData = computed(() => user.userData || {})
        
        const form = ref({});

        const address = computed(() => cart.data?.customer_address || props.localAddress);
        const addresses = ref([]);

        const modal = useModal();

        const { updateCart } = useCart();

        const fetchAddresses = () => {

            window.axios
                .get(apiStore.route("fetch-addresses"))
                .then(({ data }) => {
                    if(data.addresses.length > 0) {
                        addresses.value = data.addresses;
                        selectedaddress.value = address.value.id
                        addressStore.selectedAddress = address.value;
                    }                    
                })
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        modal.error(error.response.data.message)
                    }
                });
        };

        const checkCurrentAddress = (addr) => {
            if (selectedaddress.value == addr.id) {
                showAddresslist.value = false;
            }
        }

        const refreshAddress = () => {
            Promise.allSettled([fetchAddresses()]).then(() => {
                setTimeout(() => {showAddresslist.value = !showAddresslist.value}, 500);
            });            
        }

        watch(() => selectedaddress.value,
            (val) => {
                loading.value = true;
                showAddresslist.value = false;
                updateCart({
                    address_id: val,
                }).then(() => {
                    loading.value = false;
                    fetchAddresses()
                })
            }
        )

        watch(
            () => props.setAddresses,
            (val) => {
                addresses.value = val;
                // selectedaddress.value = address.value.id
                // addressStore.selectedAddress = address.value;
            }
        );

        onMounted(() => {
            fetchAddresses();
        })
        

        const isAuthenticated = computed(() => user.token != null);

        return {
            selectedaddress,
            showAddresslist,
            cart,
            form,
            isAuthenticated,
            addressStore,
            address,
            user,
            userData,
            addresses,
            loading,
            checkCurrentAddress,
            refreshAddress
        }
    }
}
</script>