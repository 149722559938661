import { defineStore } from 'pinia'

export const useReviewStore = defineStore({
    id: 'review',
    state: () => {
        return {
            reviews: [],
            with_media_reviews: [],
            five_star_reviews: [],
            four_star_reviews: [],
            three_star_reviews: [],
            two_star_reviews: [],
            one_star_reviews: [],

            total_reviews: 0,
            average_rating_reviews: 0,

            selected_review: {},
            product_name: null,
            
            images: []
        }
    },
    getters: {},
    actions: {}
})