<template>
    
    <div class="flex items-center justify-between border-b py-6">
        <div class="flex items-stretch space-x-4">
            <img :src="productImage" class="w-20 h-20 border rounded-[10px]" />
            <div class="flex flex-col justify-between">
                <p class="text-gray-500 text-base m-0">{{ item.name }} <span class="text-sm ml-2">{{ item.quantity }}x</span></p>
                <!-- <QuantitySelector
                    v-model="quantity"
                    :max="100"
                ></QuantitySelector> -->
                <product-counter v-model="quantity" :max="item.stocks" @updateQuantityByEvent="(value) => updateQuantityByEvent(value)"></product-counter>
            </div>
        </div>
        <div class="flex flex-col items-end space-y-6">
            <p class="text-primary-500 text-base font-normal m-0">
                <RenderPrice :value="item.subtotal"></RenderPrice>
            </p>
            <ButtonComponent
                fill="clear"
                customClass="btn-no-padding h-5"
            >
                <template #content>
                    <span class="underline text-gray-300 text-xs font-normal m-0" @click="removeCartItem(item.remove_url)">Remove Item</span>
                </template>
            </ButtonComponent>
        </div>
    </div>

</template>
<script>
import ProductCounter from '@/components/inputs/QuantitySelector.vue';
import RenderPrice from '@/components/RenderPrice.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ref, watch, computed } from 'vue';
import useCart from '@/composables/useCart';
import useResponseHandler from '@/composables/useResponseHandler';

export default {
    name: 'OrderItem',
    components: {
        ProductCounter,
        RenderPrice,
        ButtonComponent
    },
    props: {
        item:{
            type:Object,
            default:() => ({})
        }
    },
    setup(props) {

        const { updateCartItem, removeCartItem } = useCart();
        const { errorHandler } = useResponseHandler();
        const computedQuantity = computed(() => props.item.quantity);
        const quantity = ref(props.item.quantity);
        const productImage = computed(() => props.item.file_path || `/assets/images/product-image.png`);

        const updateCartItemHandler = (qty) => {
            if(qty === 0) {
                updateCartItem(props.item.update_url, { quantity: qty, for_checkout: true}).then(() => removeCartItem(props.item.remove_url));
            } else {
                updateCartItem(props.item.update_url, { quantity: qty, for_checkout: true })
                .then(message => {
                    if (message?.status == undefined) {
                        return;
                    }
                    if(message.status === 422) {
                        quantity.value = props.item.stocks;
                        updateQuantityByEvent(quantity.value);
                        errorHandler(message);
                    }
                });
            }            
        };

        const updateQuantityByEvent = (value) => {
            updateCartItemHandler(value);
        }

        // watch(
        //     () => quantity.value,
        //     (val) => {
        //         if (val === 0) {
        //             updateCartItem(props.item.update_url, { quantity: val, for_checkout: true}).then(() => removeCartItem(props.item.remove_url));
        //         } else {
        //             updateCartItem(props.item.update_url, { quantity: val, for_checkout: true });
        //         }
        //     }
        // );

        watch(
            () => computedQuantity.value,
            (val) => {
                quantity.value = val;
            }
        );

        const removeItem = (url) => {
            const val = 0;
            updateCartItem(props.item.update_url, { quantity: val, for_checkout: true}).then(() => removeCartItem(url));
        }
        
        return {
            quantity,
            removeCartItem,
            productImage,
            removeItem,
            updateQuantityByEvent
        }
    }
}
</script>