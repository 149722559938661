<template>
    <div class="px-4 lg:px-[39px] py-[46px] border rounded-[20px]">
        <div class="flex flex-col lg:flex-row relative w-full lg:items-center mb-4">
            <img class="w-[41px] h-[41px] object-cover rounded-full mr-4"
                :src="item.user ? ( item.user.profile_photo_url ? item.user.profile_photo_url : item.user.profile_photo_path ) : 'assets/images/product-image.png'" alt="">
            <div class="flex flex-col mb-4 lg:mb-0">
                <p class="m-0 mb-[6px] h-4 text-primary-500 font-normal">{{ mask(item.user ? item.user.name : '', '*', 3) }}</p>
                <div class="flex items-end space-x-2 mb-2">
                    <div class="stars flex space-x-1">
                        <img class="w-4" :src="renderStarIcon(1)" alt="">
                        <img class="w-4" :src="renderStarIcon(2)" alt="">
                        <img class="w-4" :src="renderStarIcon(3)" alt="">
                        <img class="w-4" :src="renderStarIcon(4)" alt="">
                        <img class="w-4" :src="renderStarIcon(5)" alt="">
                    </div>
                    <p class="text-gray-500 text-xs m-0">({{ item.score }})</p>
                </div>
            </div>
            <p class="relative lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:right-0 text-sm text-gray-400 m-0">Posted on
                {{ dateFormat(item.created_at) }}
            </p>
        </div>
        <div class="comment mb-4">
            <p class="text-sm line-clamp-2">{{ item.message }}</p>
        </div>
        <div v-if="item.images.length > 0">
            <lightgallery
            class="grid grid-cols-4 gap-2 w-[229px]"
            :settings="{ 
                speed: 500,
                getCaptionFromTitleOrAlt: false,
                counter: false
            }"
            >
                <a 
                v-for="(image, ii) in item.images" 
                :key="ii"
                :href="image.formatted_file_path"
                class="w-full h-0 pt-[100%] relative rounded overflow-hidden">
                    <img 
                    alt="img1" 
                    class="absolute top-0 left-0 h-full w-full object-cover"
                    :src="image.formatted_file_path" />
                </a>
            </lightgallery>
        </div>
    </div>
</template>
<script type="text/javascript">
import {
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import useReview from "@/composables/useReview";
import useFormat from "@/composables/useFormat";

import Lightgallery from 'lightgallery/vue';
import 'lightgallery/scss/lightgallery.scss';

export default defineComponent({
    name: 'ReviewCard',
    components: {
        Lightgallery
    },

    props : {
        item: {
            default: () => {
                return {};
            },
            type: Object
        },

    },

    setup(props) {
        const router = useRouter();
        const { updateStoreSelectedReview } = useReview();
        const { dateFormat, mask } = useFormat();

        const selectedReview = (id) => {
            updateStoreSelectedReview(id);
            router.push({ name : 'Review' })
        }

        const renderStarIcon = (score, icon = null) => {
            if(props.item.score >= score) {
                icon = '/assets/icon/ic-star.svg';
            }
            else if(props.item.score > score - 1) {
                icon = '/assets/icon/ic-halfstar.svg';
            }
            else if(props.item.score < score) {
                icon = '/assets/icon/ic-emptystar.svg';
            }
            return icon;
        };

        return {
            router,
            selectedReview,
            dateFormat,
            renderStarIcon,
            mask
        }
    },

});
</script>
