<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild
            as="template" 
            enter="ease-out duration-300" 
            enter-from="opacity-0" 
            enter-to="translate-x-0" 
            leave="ease-in duration-200" 
            leave-from="opacity-100" 
            leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-0 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-center justify-end h-screen p-0">
                    <TransitionChild 
                    as="template" 
                    enter="ease-out duration-300" 
                    enter-from="opacity-100 translate-y-4 sm:translate-y-0 translate-x-[100%]" 
                    enter-to="opacity-100 translate-y-0 translate-x-0" 
                    leave="ease-in duration-200" 
                    leave-from="opacity-100 translate-y-0 translate-x-0" 
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 translate-x-[100%]">
                        <DialogPanel
                        class="
                            relative 
                            bg-white 
                            overflow-hidden 
                            transform 
                            transition-all 
                            w-[90%] 
                            sm:w-[400px] 
                            shadow-lg
                            h-screen
                        ">
                            <chat-component
                            @close="open = false"
                            :invoice-id="invoiceId"
                            :rider="rider"
                            ></chat-component>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
} from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import ChatComponent from "./ChatComponent.vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default defineComponent({
    name: "SideChatMenu",
    components: { 
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        ChatComponent,
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        iconLink: {
            type: String,
            default: '/assets/icon/modal/ic-question-mark.svg',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
        iconHeight: {
            type: String,
            default: '40',
        },
        buttonText: {
            type: String,
            default: 'Okay',
        },
        buttonLink: {
            type: String,
            default: null,
        },
        cancelButtonLink: {
            type: String,
            default: null,
        },
        cancelButton: {
            type: Boolean,
            default: true,
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        invoiceId : {
            default: null,
            type : Number
        },
        rider: {
            type: String,
            default: null
        }
    },
    setup(props, { emit }) {

        const open = ref(false);

        const closeThis =()=> open.value = false;

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        return {
            open,
            closeThis,
        }
    },
});
</script>
