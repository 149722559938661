import { computed } from 'vue';
import { Storage } from "@capacitor/storage";

export default function useLocalStorage() {
    const localStorage = computed(() => {
        return {
            async get(key) {
                const { value } = await Storage.get({ key: key });
                return JSON.parse(value);
            },
            async set(key, value) {
                value = JSON.stringify(value);
                await Storage.set({ key: key, value: value });
            },
            async delete(key) {
                await Storage.remove({ key: key });
            },
        };
    })

    return {
        localStorage
    }
}
