<template>
    <div v-if="productCard" class="w-full flex justify-between bg-[#F2F2F2]">
        <img class="cursor-pointer" 
        @click="
            () => {
                if (quantity > 0) {
                    quantity--;
                    $emit('update:modelValue', quantity);
                    $emit('updateQuantityByEvent', quantity);
                }
            }
        "
        :disabled="quantity <= 0"
        src="/assets/icon/minus.svg" alt="">
        <input
            type="tel"
            class="
                border-0
                w-12
                text-center
                text-sm
                font-semibold
                focus:ring-0
                p-0

                bg-[#F2F2F2]
            "
           @change="addToCart()"
           @keyup="addToCart()"
            v-model="quantity"
            min="0"
            step="1"
            :max="max"
        />
        <img class="cursor-pointer" 
        @click="
            () => {
                if (quantity < max) {
                    quantity++;
                    $emit('update:modelValue', quantity);
                    $emit('updateQuantityByEvent', quantity);
                }
            }
        "
        :disabled="quantity === max || max == 0 || disabled"
        :class="quantity === max || max == 0 || disabled ? 'pointer-events-none opacity-10' : ''"
        src="/assets/icon/plus.svg" alt="">
    </div>
    <div v-else-if="recipe" class="w-[107px] flex justify-between">
        <img class="w-8 h-8 object-fit cursor-pointer"
        @click="
            () => {
                if (quantity > 0) {
                    quantity--;
                    $emit('update:modelValue', quantity);
                    $emit('updateQuantityByEvent', quantity);
                }
            }
        "
        :disabled="quantity <= 0"
        src="/assets/icon/Increment.svg" alt="">
        <input
            type="tel"
            class="
                border-0
                w-12
                text-center
                text-sm
                font-semibold
                focus:ring-0
                p-0
                bg-[#F2F2F2]
            "
            @change="addToCart()"
            @keyup="addToCart()"
            v-model="quantity"
            min="0"
            step="1"
            :max="max"
        />
        <img class="w-8 h-8 object-fit rounded-lg cursor-pointer"
        @click="
            () => {
                if (quantity < max) {
                    quantity++;
                    $emit('update:modelValue', quantity);
                    $emit('updateQuantityByEvent', quantity);
                }
            }
        "
        :disabled="quantity === max || max == 0 || disabled"
        :class="quantity === max || max == 0 || disabled ? 'pointer-events-none opacity-10' : ''"
        src="/assets/icon/plus.svg" alt="">
    </div>
    <div v-else class="flex items-center">
        <button
            class="
                bg-primary-500
                rounded-lg
                text-white text-center
                w-8
                h-8
                disabled:bg-white
                disabled:text-gray-400
                disabled:border
                disabled:border-solid
                disabled:border-gray-400
            "
            @click="
                () => {
                    if (quantity > 0) {
                        quantity--;
                        $emit('update:modelValue', quantity);
                        $emit('updateQuantityByEvent', quantity);
                    }
                }
            "
            :disabled="quantity <= 0"
        >
            <ion-icon :src="removeOutline" class="p-1 w-6 h-6"></ion-icon>
        </button>
        <input
            type="tel"
            class="
                border-0
                w-12
                text-center
                text-sm
                font-semibold
                focus:ring-0
                p-0
            "
            @change="addToCart()"
            @keyup="addToCart()"
            v-model="quantity"
            min="0"
            step="1"
            :max="max"
        />
        <button
            class="
                bg-primary-500
                rounded-lg
                text-white text-center
                w-8
                h-8
                disabled:bg-white
                disabled:text-gray-400
                disabled:border
                disabled:border-solid
                disabled:border-gray-400
            "
            @click="
                () => {
                    if (quantity < max) {
                        quantity++;
                        $emit('update:modelValue', quantity);
                        $emit('updateQuantityByEvent', quantity);
                    }
                }
            "
            :disabled="quantity === max || max == 0 || disabled"
        >
            <ion-icon :src="addOutline" class="p-1 w-6 h-6"></ion-icon>
        </button>
    </div>
    
</template>
<script>
import { addOutline, removeOutline } from "ionicons/icons";
import { ref } from "@vue/reactivity";
import { IonIcon } from "@ionic/vue";
import { watch } from '@vue/runtime-core';

export default {
    name: "QuantitySelectorComponent",
    components: {
        IonIcon,
    },
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        productCard: {
            type: Boolean,
            default: false,
        },
        recipe: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const quantity = ref(props.modelValue);

        watch(
            () => props.modelValue,
            (val) => {
                if(!isNaN(val)) {
                    if (val > props.max) {
                        quantity.value = props.max;
                    } else {
                        quantity.value = val;
                    }
                }
            }
        );

        watch(
            () => quantity.value,
            (val) => {
                if(!isNaN(val)) {
                    if (val > props.max) {
                        quantity.value = props.max;
                    } else {
                        quantity.value = val;
                    }
                    // addToCart();
                }
            }
        );

        const addToCart = () => {            
            emit('update:modelValue', quantity.value);
            if (quantity.value) {
                emit('updateQuantityByEvent', quantity.value);
            }            
        }

        return {
            addOutline,
            removeOutline,
            quantity,
            addToCart
        };
    },
};
</script>