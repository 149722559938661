<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-[0.45] transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">

                <div class="flex items-center justify-center min-h-full first-letter:text-center p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative bg-white rounded-[10px] overflow-hidden transform transition-all w-[90%] sm:w-[460px] p-6 sm:p-[50px]">
                            <!-- content -->
                            <div class="text-center">
                                <img
                                    :src="iconLink"
                                    :width="iconWidth"
                                    :height="iconHeight"
                                    alt="icon"
                                    class="mx-auto mb-6"
                                />

                                <p class="font-semibold mb-6 text-2xl text-neutral-600">
                                    {{ title }}
                                </p>

                                <p class="mb-6 text-sm font-normal text-neutral-600">
                                    {{ message }}
                                </p>
                                
                                <div class="flex justify-center items-center space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 md:flex-row flex-col">
                                    <!-- secondary -->
                                    <template v-if="cancelButton">
                                        <button-component
                                            size="large"
                                            color="primary"
                                            fill="outline"
                                            customClass="w-fit"
                                            v-if="cancelButtonLink"
                                            @click.prevent="() => [open = false, router.push(cancelButtonLink)]"
                                        >
                                            <template #content>
                                                {{ cancelButtonText }}
                                            </template>
                                        </button-component>
                                        <button-component
                                            size="large"
                                            color="primary"
                                            fill="outline"
                                            customClass="w-fit"
                                            v-else
                                        >
                                            <template #content>
                                                {{ cancelButtonText }}
                                            </template>
                                        </button-component>

                                    </template>


                                    <!-- primary -->
                                    <button-component
                                        size="large"
                                        color="primary"
                                        customClass="w-fit"
                                        v-if="buttonLink"
                                        @click="redirectLink"
                                    >
                                        <template #content>
                                            {{ buttonText }}
                                        </template>
                                    </button-component>
                                    <button-component
                                        size="large"
                                        color="primary"
                                        customClass="w-fit"
                                        v-else
                                    >
                                        <template #content>
                                            {{ buttonText }}
                                        </template>
                                    </button-component>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
} from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useRouter, useRoute } from 'vue-router';
import  useLocalStorage from "@/composables/useLocalStorage.js";

export default defineComponent({
    name: "ConfirmationModal",
    components: { 
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
        ButtonComponent,
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        iconLink: {
            type: String,
            default: '/assets/icon/modal/ic-question-mark.svg',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
        iconHeight: {
            type: String,
            default: '40',
        },
        buttonText: {
            type: String,
            default: 'Okay',
        },
        buttonLink: {
            type: String,
            default: null,
        },
        cancelButtonLink: {
            type: String,
            default: null,
        },
        cancelButton: {
            type: Boolean,
            default: true,
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        }
    },
    setup(props, { emit }) {

        const router = useRouter();
        const route = useRoute();
        const open = ref(false);
        const { localStorage } = useLocalStorage();

        const closeThis =()=> open.value = false;

        const redirectLink = () => {
            open.value = false;
            localStorage.value.set('previousRoute', route.path);
            router.push(props.buttonLink);
        }

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        return {
            open,
            closeThis,
            router,
            redirectLink
        }
    },
});
</script>
