<template>
    
    <div class="mt-10">
        <div class="flex items-center justify-between">
            <p class="text-sm text-neutral-600 font-normal m-0">Number of items: {{ items.length > 0 ? items.length : '' }}</p>
            <ButtonComponent
                fill="clear"
                customClass="btn-no-padding h-5"
                @click="removeItems()"
            >
                <template #content>
                    <span class="underline text-gray-300 text-xs font-normal m-0">Remove All</span>
                </template>
            </ButtonComponent>
            <!-- <a class="font-normal text-gray-300 m-0 text-sm underline" @click="removeCartItem(items.remove_items_url)">Remove All</a> -->
        </div>
        <div class="mt-4" v-for="item in items" :key="`cart-item-${item.id}`">
            <OrderItem :item="item" v-if="item.quantity > 0"></OrderItem>
        </div>
    </div>

</template>
<script>
import OrderItem from './OrderItem.vue';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import useCart from '@/composables/useCart';
import { onIonViewWillEnter } from '@ionic/vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default {
    name: 'OrderInformation',
    components: {
        OrderItem,
        ButtonComponent
    },
    setup() {

        const router = useRouter();
        const cart = useCartStore();

        const { removeCartItems, removeCartItem } = useCart();

        const items = computed(() => cart.data.items || []);

        const itemCount = ref();

        const sumQuantity = () => {
            let sum = 0;
            if (items.value) {
                items.value.forEach(item => {
                    sum = sum + item.quantity;
                    itemCount.value = sum;
                })
            }
        }


        const removeItems = () => {
            if (items.value) {
                items.value.forEach(item => {
                    let urls = [];
                    const url = item.remove_url;
                    urls.push(url);
                
                    removeCartItems(urls);
                })
            }
        }

        onMounted(sumQuantity);
        onIonViewWillEnter(sumQuantity);

        watch(
            () => items.value,
            () => {
                sumQuantity();
            }
        );

        // watch(
        //     () => items.value,
        //     (arr) => {
        //         selected.value = new Array(arr.length).fill(false);
        //         arr.forEach((item,index) => {
        //             selected.value[index] = item.for_checkout;
        //         });
        //     },
        //     { immediate: true }
        // );

        // watch(
        //     () => selected.value,
        //     (arr) => selectAll.value = arr.filter(x => x).length == arr.length,
        //     { immediate: true, deep: true }
        // );

        return {
            router,
            items,
            removeCartItem,
            itemCount,
            sumQuantity,
            removeItems
        }
    }
}
</script>
