<template>
    <div
        class="rounded-3xl px-3 py-1 flex items-center justify-center gap-2"
        :class="color"
    >
        <slot name="leftIcon" />
        <p
            class="text-sm font-semibold"
            :class="textColor"
        >
            {{ text }}
        </p>
        <slot name="rightIcon" />
        <template v-if="forwardIcon">
            <ion-icon
                :src="chevronForwardOutline"
                class="w-4 h-4"
                :class="textColor"
            />
        </template>
    </div>
</template>

<script>
import { IonIcon } from '@ionic/vue'
import { chevronForwardOutline } from "ionicons/icons";

export default {
    name: "badge-display",
    components: {
        IonIcon
    },
    props: {
        color: {
            type: String,
            default: "black",
        },
        text: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            default: "white",
        },
        forwardIcon: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            chevronForwardOutline,
        };
    },
};
</script>