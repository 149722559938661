<template>
    <div class="pagination flex gap-4 items-center w-fit m-auto" v-if="items.total > 0">
        <ChevronDoubleLeftIcon 
            @click="$emit('paginate', 1)" 
            class="w-6 h-6 cursor-pointer m-0" 
            :class="[ page == 1 ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
            />
        <ChevronLeftIcon 
            @click="$emit('previousPage', items.current_page)" 
            class="w-6 h-6 cursor-pointer m-0" 
            :class="[ page == 1 ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
        />
        <ul class="flex items-center justify-center space-x-4 pagination m-0">
            <template v-for="(item, key) in renderPages" :key="key">
                <li 
                    class="frm-paginate-btn rounded-full m-0 font-normal cursor-pointer hover:bg-neutral-200 transition"
                    :class="item.page == page ? 'bg-amber-400 text-white' : 'text-dark'"
                >
                    <a class="page-link" @click="$emit('paginate', item.page)">{{ item.page }}</a>
                </li>
            </template>                                    
        </ul>
        
        <template v-if="(page +renderPerPage)  > items.last_page">
            <ChevronRightIcon 
                class="w-6 h-6 cursor-pointer m-0 text-neutral-600 pointer-events-none" 
            />

            <ChevronDoubleRightIcon 
                class="w-6 h-6 cursor-pointer m-0 text-neutral-600 pointer-events-none" 
            />
        </template>
        <template v-else>
            <ChevronRightIcon 
                @click="$emit('nextPage', items.current_page)"
                class="w-6 h-6 cursor-pointer m-0"
                :class="[ page == items.last_page ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
            />

            <ChevronDoubleRightIcon 
                @click="$emit('paginate', items.last_page)" 
                class="w-6 h-6 cursor-pointer m-0" 
                :class="[ page == items.last_page ? 'text-neutral-600 pointer-events-none' : 'text-primary-500']"
            />
        </template>
        
    </div>
</template>

<script type="text/javascript">

import { computed, defineComponent, onMounted, ref } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon} from "@heroicons/vue/solid";

export default defineComponent({
    name: 'HomePage',
    components: {
        ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon
    },
    props: {
        items: Object,
        paginate: {
            type: Number,
            default: 1
        },
        previousPage: {
            type: Number,
            default: 1 
        },
        nextPage: {
            type: Number,
            default: 1
        },
        page: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const currentPageNr = ref(0); 
        const firstPageNr =ref(0);
        const lastPageNr = ref(0);
        const renderPages = ref([]);
        const page = computed(() => props.page);
        const renderPerPage = ref(10);

        const getPageStart = (pageSize, pageNr) => {
            return pageSize * pageNr;
        }

        const getPageLabel = (total, pageSize, pageNr = 0, currentPage = 1) => {
            const start = Math.max(
                getPageStart(pageSize, pageNr),
                0
            );
            const end = Math.min(
                getPageStart(pageSize, pageNr + 1),
                total
            );
            
            const arr = [];
            for(let i = (start + 1); i <= end; i++) {
                arr.push({
                    page: i, 
                    currentPage: i == currentPage ? true : false,
                })
            }
            return arr;
        }

        const renderCustomPages = (items) => {     
            const perPage = 10;
            const pageNr = Math.ceil(items.last_page / perPage); 
            lastPageNr.value = pageNr;
            const pages = Array.from(
            { length: pageNr },
            (a, i) => getPageLabel(items.last_page, perPage, i, page.value)
            );
            pages.filter((items, i) => {
                const result = items.filter((item) => {
                    if (item.page == page.value) {
                        return item;
                    }
                });

                if (result.length > 0) {
                    currentPageNr.value = i;
                }
            })

            renderPages.value = (props.page > props.items.last_page) ? [] : pages[currentPageNr.value];    
        }

        onMounted(() => {
            renderCustomPages(props.items);
        });

        return {
            currentPageNr,
            firstPageNr,
            lastPageNr,
            renderPages,
            renderPerPage
        }
    }
});
</script>
