<template>
    <ion-page>
        <top-navigation></top-navigation>
        <ion-content :fullscreen="true" class="">
            <section class="px-4 lg:px-[100px] pt-[18px] pb-[120px] lg:py-20 max-w-[1440px] m-auto relative">
                <div class="flex flex-col lg:flex-row lg:space-y-0 space-x-0 lg:space-x-[66px]">
                    <div class="w-full lg:w-2/12">
                        <side-bar :title=title></side-bar>
                    </div>
                    <div class="w-full lg:w-10/12 pt-10 lg:pt-[82px]">
                        <slot></slot>
                    </div>
                </div>
            </section>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
import {
    IonContent,
    IonPage,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import SideBar from './components/SideBar.vue';
export default defineComponent({
    name: 'OrderHistoryIndex',
    components: {
        IonContent,
        IonPage,
        TopNavigation,
        BottomNavigation,
        SideBar,
    },
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    setup() {
        
      
        const router = useRouter();

        return {
            router,
        }
    },
});
</script>
