<template>
    <Menu as="div">
        <MenuButton id="cart-btn" class="flex h-9 px-4 py-2 bg-[#353236] items-center gap-[10px] rounded-3xl cursor-pointer" @click="refreshCart">
            <p class="text-sm text-white font-normal">Cart</p>
            <div class="w-[6px] h-[6px] bg-white rounded-full"></div>
            <p class="text-white font-normal text-sm">{{ cartStore.itemCount }}</p>
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 translate-x-[50%]" enter-to-class="transform opacity-100 translate-x-0" leave-active-class="transition ease-in duration-200" leave-from-class="transform opacity-100 translate-x-0" leave-to-class="transform opacity-0 translate-x-[50%]">
            <MenuItems class="fixed top-0 right-0 cart-cntnr z-10 h-full md:h-screen">
                <div class="bg-white w-screen lg:w-[678px] py-6 px-4 lg:px-10 h-full flex flex-col items-center">
                    <div class="flex justify-between items-center pb-[14px] border-b mb-6 w-full">
                        <h2 class="text-2xl font-semibold text-neutral-600 m-0">Cart ({{ cartStore.itemCount }})</h2>
                        <MenuItem v-slot="{ active }">
                            <button :active="active">
                                <XIcon class="w-7 h-7" />
                            </button>
                        </MenuItem>
                    </div>
                    <div class="flex flex-col space-y-9 pb-4 max-h-[calc(100vh-380px)] overflow-auto w-full">
                        <template v-for="item in cartStore.data.items" :key="`cart-item-${item.id}`">
                            <CartItem :item="item" v-if="item.quantity > 0"></CartItem>
                        </template>
                    </div>
                    <div class="pt-4 mt-auto w-full mobile-mini-cart border-t">
                        <div class="flex items-center justify-between mb-9">
                            <h2 class="text-2xl font-normal text-neutral-600 m-0">TOTAL</h2>
                            <h2 class="text-2xl font-normal text-primary-500 m-0">{{ moneyFormat(cart.subtotal) }}</h2>
                        </div>
                        <div v-if="isFreeDelivery" class="bg-fuchsia-100 py-1 px-[6px] rounded">
                            <p class="text-sm leading-5" v-if="amountToFreeDelivery">Add {{ moneyFormat(amountToFreeDelivery) }} more and get free delivery</p>
                            <p class="text-sm leading-5" v-else>Free delivery!</p>
                        </div>
                        <div v-if="!isAuthenticated" class="bg-fuchsia-100 py-1 px-[6px] rounded mt-4">
                            <p class="text-sm leading-5" >Login to save points</p>
                        </div>
                        <ButtonComponent
                            size="large"
                            expand="block"
                            customClass="cart-btn mt-8"
                            @click="router.push('/cart')"
                            :disabled="itemCount==0"
                        >
                            <template #content>
                                View Cart
                            </template>
                        </ButtonComponent>
                    </div>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
<script>

import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useCartStore } from '@/store/cart';
import { useSettingsStore } from '@/store/settings';
import { useUserStore } from '@/store/user';
import useFormat from '@/composables/useFormat';
import useCart from '@/composables/useCart';

import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { XIcon } from "@heroicons/vue/solid";
import ButtonComponent from '@/components/ButtonComponent.vue';
import CartItem from './CartButttonItem.vue';

export default defineComponent({
    components:{
        Menu, MenuButton, MenuItems, MenuItem,
        XIcon,
        ButtonComponent,
        CartItem
    },
    setup() {

        const router = useRouter();
        const cartStore = useCartStore();
        const settings = useSettingsStore();
        const user = useUserStore();
        const { fetchCart } = useCart();

        const { moneyFormat } = useFormat();

        const cart = computed(() => cartStore.data);
        const itemCount = computed(() => cartStore.itemCount);
        const items = computed(() => cartStore.data.items);
        const isAuthenticated = computed(() => user.token != null);

        const amountToFreeDelivery = computed(() => {
            const min = settings.deliveryMethod('INHOUSE').min_free_delivery;
            const amt = min - cart.value.subtotal;
            return amt > 0 ? amt : 0;
        });

        const isFreeDelivery = computed(() => settings.deliveryMethod('INHOUSE').is_free_delivery && settings.deliveryMethod('INHOUSE').is_enabled);

        const refreshCart = () => {
            fetchCart();
        }

        return {
            router,
            moneyFormat,
            cart,
            itemCount,
            items,
            isAuthenticated,
            amountToFreeDelivery,
            isFreeDelivery,
            refreshCart,
            cartStore
        }
    },
});
</script>