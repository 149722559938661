<template>
    <div class="w-full">
        <div class="flex flex-wrap -mx-1">
            <div class="w-1/2 px-1 mb-6">
                <text-input type="text" placeholder="First Name" v-model="user.guestData.first_name" />
            </div>
            <div class="w-1/2 px-1 mb-6">
                <text-input type="text" placeholder="Last Name" v-model="user.guestData.last_name" />
            </div>
            <div class="w-1/2 px-1 mb-6">
                <text-input type="email" placeholder="Email" v-model="user.guestData.email" />
            </div>
            <div class="w-1/2 px-1 mb-6">
                <mobile-number placeholder="Mobile Number" v-model="user.guestData.mobile_number" />
            </div>
        </div>
        <div class="grid grid-cols-1 gap-y-6" v-if="guestAddress">
            <div class="border-transparent border-secondary-500 ring-2 ring-secondary-500 relative bg-secondary-500/10 rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none">
                <div class="flex-1 flex ml-3">
                    <div class="flex flex-col">
                        <div class="space-y-1">
                            <p class="font-normal text-base">{{ guestAddress.label }}</p>
                            <p class="font-light text-sm">{{ guestAddress.address_label }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import TextInput from '@/components/inputs/TextInput.vue';
import MobileNumber from '@/components/inputs/MobileNumber.vue';
import { ref, computed, watch } from 'vue';
import { useAddressStore } from '@/store/address';
import { useUserStore } from '@/store/user';

export default {
    name: 'GuestDetails',
    components: {
        TextInput,
        MobileNumber,
    },
    setup() {
        const form = ref({});
        const address = useAddressStore();
        const user = useUserStore();

        watch(() => form.value,
            (value) => {
                user.guestData = value;
            }
        )

        const guestAddress = computed(() => address.data );

        return {
            form,
            guestAddress,
            user
        }
    }
}
</script>