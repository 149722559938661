<template>
    <ion-page>
        <top-navigation
        :back-button="true"
        back-url="/forgot-password"
        ></top-navigation>
        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-133px)] flex items-center justify-center">
                <div class="w-96 mx-auto">
                    <div class="text-left mb-11 mt-5">
                        <h3 class="text-[40px] font-bold text-primary-500">Reset Password</h3>
                        <p class="text-sm text-gray-900">Enter your new password to proceed with your password reset.</p>
                    </div>
                    <form class="">
                        <div class="space-y-6">
                            <password-input
                                v-model="new_password"
                                placeholder="New Password"
                            />
                            <password-input
                                v-model="confirm_password"
                                placeholder="Confirm Password"
                            />
                        </div>
                        <div class="mt-12">
                            <button-component
                                size="default"
                                color="primary"
                                expand="block"
                                @click="submit"
                            >
                                <template #content>
                                    <template v-if="!loading">
                                        Reset Password
                                    </template>
                                    <template v-else>
                                        <ion-spinner></ion-spinner>
                                    </template>
                                </template>
                            </button-component>
                        </div>
                    </form>
                </div>
            </div>

            <bottom-navigation></bottom-navigation>

        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonSpinner 
} from '@ionic/vue';

import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { ref } from '@vue/reactivity';
import { useUserStore } from '@/store/user';
import { useApiStore } from "@/store/api";
import useResponseHandler from '@/composables/useResponseHandler';

export default  {
    name: 'ResetPassword',

    components: {
        IonPage, 
        IonContent,
        IonSpinner,
        TopNavigation,
        BottomNavigation,
        ButtonComponent,
        PasswordInput,
    },

    setup() {
        const new_password = ref(null);
        const confirm_password = ref(null);
        const loading = ref(false);
        const store = useUserStore();
        const apiStore = useApiStore();
        const userEmail = ref(store.userEmail);
        const userResetToken = ref(store.userResetToken);
        const { openResponseModal } = useResponseHandler();
        const showPassword = ref(false);
        const showPassword2 = ref(false);

        const submit = () => {
            loading.value = true;

            const params = {
                token: userResetToken.value,
                new_password: new_password.value,
                confirm_password: confirm_password.value,
                email: userEmail.value
            }

            window.axios
            .post(apiStore.route("reset-password"), params)
            .then((result) => {
                loading.value = false;
                openResponseModal({ title: "Success", description: result.data.message, buttonText: "Okay", buttonLink: "/login" });
            })
            .catch((error) => {
                loading.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })

        }

        return {
            new_password,
            confirm_password,
            loading,
            submit,
            showPassword,
            showPassword2
        }
    }
}
</script>