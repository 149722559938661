<template>
    <div>
        <form action="#" class="mb-8">
            <div class="w-full mx-auto">
                <div class="flex space-x-2">
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num1"
                        ref="otp1" 
                        @keyup="moveFocus($event, $refs.otp2.$el, '')" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num2"
                        ref="otp2"
                        @keyup="moveFocus($event, $refs.otp3.$el, $refs.otp1.$el)" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num3"
                        ref="otp3"
                        @keyup="moveFocus($event, $refs.otp4.$el, $refs.otp2.$el)" 
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num4" 
                        ref="otp4"
                        @keyup="moveFocus($event, $refs.otp5.$el, $refs.otp3.$el)"
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num5" 
                        ref="otp5"
                        @keyup="moveFocus($event, $refs.otp6.$el, $refs.otp4.$el)"
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                    <div class="relative flex justify-center">
                        <ion-input 
                        v-model="otpNum.num6" 
                        ref="otp6"
                        @keyup="moveFocus($event, '', $refs.otp5.$el)"
                        class="otp otp-input" 
                        maxlength="1" 
                        inputmode="numeric" 
                        autocomplete="off">
                        </ion-input>
                        <span class="underline"></span>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-center">
            <div class="flex w-fit space-x-2 m-auto">
                <p class="text-sm text-gray-400 m-0 mb-2">Didn’t get a code?</p>
                <template v-if="timerCount !== 0">
                    <p class="text-sm font-semibold text-gray-400 m-0">Resend in {{ timerCount }}s</p>
                </template>
                <template v-else>
                    <p @click="resendOtp" class="cursor-pointer text-sm font-bold m-0 text-primary-500">Resend</p>
                </template>
            </div>

            <button-component
                size="default"
                color="primary"
                expand="block"
                @click="validate"
                class="mt-6"
            >
                <template #content>
                    <template v-if="!loading">
                        Verify
                    </template>
                    <template v-else>
                        <ion-spinner></ion-spinner>
                    </template>
                </template>
            </button-component>
        </div>
    </div>
</template>

<script>
import { IonInput, IonSpinner } from '@ionic/vue';
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import ButtonComponent from '@/components/ButtonComponent.vue';
import useResponseHandler from '@/composables/useResponseHandler';
import { useUserStore } from "@/store/user";
import useLocalStorage from "@/composables/useLocalStorage.js";
import { onMounted } from '@vue/runtime-core';
import useAuth from "@/composables/useAuth";
import useCart from "@/composables/useCart";

export default  {
    name: 'OTPPage',
    components: { 
        IonInput,
        IonSpinner,
        ButtonComponent
    },
    props: {
        mobile: {
            type: String,
        },
        validateUrl: {
            type: String,
        },
        resendUrl: {
            type: String,
        },
        email: {
            type: String,
        },
        value: {
            type: String,
        },
        code: {
            type: Number,
        },
        isRegister: {
            type: Boolean,
            default: false
        }
    },
    watch: {

        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    setup(props) {
        const loading = ref(false);
        const router = useRouter();
        const timerCount = ref(10);
        const timerEnabled = ref(true);
        const { openResponseModal } = useResponseHandler();
        const store = useUserStore();
        const { localStorage } = useLocalStorage();       
        const email = ref(props.value); 
        const { setToken, fetchUserData } = useAuth();
        const { fetchCart } = useCart();

        const otpNum = [
            { num1: ref(null)},
            { num2: ref(null)},
            { num3: ref(null)},
            { num4: ref(null)},
            { num5: ref(null)},
            { num6: ref(null)}
        ];

        const validate = () => {
            const otpVal = `${otpNum.num1}${otpNum.num2}${otpNum.num3}${otpNum.num4}${otpNum.num5}${otpNum.num6}`;

            const data = {
                'value': email.value,
                'otp': otpVal
            };

             window.axios
            .post(props.validateUrl, data)
            .then((result) => {
                loading.value = false;
                if(props.isRegister == 1) {
                    localStorage.value.set("registerUser", result.data.user);

                    setToken(
                        "Bearer " + result.data.access_token,
                        result.data.user
                    );

                    fetchUserData();
                    fetchCart();
                    store.setUserEmail(result.data.user.email);
                    store.setUserMobile(result.data.user.mobile_number);
                    router.replace("/home");
                } else {
                    store.setUserResetToken(result.data.token);
                    router.push("/reset-password/" + result.data.token)
                }
            })
            .catch((error) => {
                loading.value = false;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const resendOtp = () => {
            const data = {
                'val': props.value,
            };

             window.axios
            .post(props.resendUrl, data)
            .then((result) => {
                loading.value = false;
                openResponseModal({ title: "Success!", description: result.data.message, buttonText: "Okay" });
                timerCount.value = 130;
            })
            .catch((error) => {
                loading.value = false;
                openResponseModal({ title: "Warning!", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const moveFocus = (event, nextElement, previousElement) => {
            if (event.keyCode == 8 && previousElement) {
                previousElement.setFocus();
            } else if (event.keyCode >= 48 && event.keyCode <= 57) {
                if (nextElement) {
                    nextElement.setFocus();
                }
            } else if (event.keyCode >= 96 && event.keyCode <= 105) {
                if (nextElement) {
                    nextElement.setFocus();
                }
            } else {
                event.path[0].value = "";
            }
        };

        const fetch = async () => {
            let user = await localStorage.value.get('registerUser');
            email.value = user.email;
            return user;
        }

        onMounted(async () => {
            await fetch();
        });
        
        return {
            loading,
            otpNum,
            router,
            validate,
            resendOtp,
            timerCount,
            timerEnabled,
            moveFocus
        }
    }
}
</script>
