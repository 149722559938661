<template>
    <ion-page>
        <top-navigation></top-navigation>

        <ion-content :fullscreen="true">
            <div class="w-full min-h-[calc(100vh-133px)] flex items-center justify-center">
                <div class="px-4 md:p-0 w-full md:w-[445px] mx-auto my-12">
                    <form>
                        <text-input name="email" id="emails" class="opacity-0 pointer-events-none absolute" />
                        <password-input name="password" id="passwords" class="opacity-0 pointer-events-none absolute" />
                        <h3 class="text-[40px] font-bold text-primary-500 text-center">Sign Up</h3>
                        <div class="mt-12">
                            <div class="space-y-6">
                                <text-input
                                    @keyup.enter="submit()"
                                    v-model="form.email"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    :error="errors?.email ? errors.email[0] : null"
                                />
                                <text-input
                                    @keyup.enter="submit()"
                                    v-model="form.firstName"
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    :error="errors?.first_name ? errors.first_name[0] : null"
                                />
                                <text-input
                                    @keyup.enter="submit()"
                                    v-model="form.lastName"
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    :error="errors?.last_name ? errors.last_name[0] : null"
                                />
                                <mobile-number 
                                    @keyup.enter="submit()"
                                    v-model="form.mobileNumber"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    :error="errors?.mobile_number ? errors.mobile_number[0] : null"
                                />
                                <password-input
                                    @keyup.enter="submit()"
                                    v-model="form.password"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    :error="errors?.password ? errors.password[0] : null"
                                />
                                <password-input
                                    @keyup.enter="submit()"
                                    v-model="form.confirm_password"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    :error="errors?.confirm_password ? errors.confirm_password[0] : null"
                                />
                                <!-- <gender-selection
                                /> -->
                                <SelectMenu
                                    @keyup.enter="submit()"
                                    v-model="form.gender"
                                    name="gender"
                                    id="gender"
                                    placeholder="Gender"
                                    :options="genderOptions"
                                />
                                <span class="text-[10px] text-error" v-if="errors?.gender">{{ errors.gender[0] || null }}</span>
                                
                                <!-- <birthday-picker
                                v-model="form.birthdate"
                                ></birthday-picker> -->
                                <DatePicker 
                                id="birthdate"
                                name="birthdate"
                                placeholder="Birthday (mm/dd/yyyy)"
                                v-model="form.birthdate"
                                :error="errors?.birthdate ? errors.birthdate[0] : null"
                                :max-date="limitMaxDate"
                                />

                                <!-- <text-input
                                @keyup.enter="submit()"
                                v-model="form.birthdate"
                                type="date"
                                placeholder="Birthdate"
                                id="birthdate"
                                name="birthdate"
                                :error="errors?.birthdate ? errors.birthdate[0] : null"
                                :max="limitMaxDate"
                                >
                                </text-input> -->
                            </div>
                            <div class="mt-6 items-start">
                                <checkbox-input
                                    @keyup.enter="submit()"
                                    id="agree"
                                    name="agree"
                                    v-model="form.agree"
                                    :default-slot="false"
                                >
                                    <div class="ml-2">
                                        <label for="agree" class="text-xs font-light text-gray-400 leading-none block">
                                            By clicking the submit button below, I hereby agree to and accept Fun Han Mart’s <a href="/terms-and-conditions" class="text-xs text-primary-500 hover:underline">Terms and Conditions</a> governing my use of this website.
                                            <!-- By creating account, you agree with our <a href="/terms-and-conditions" class="text-primary-500 text-xs">Terms of Use</a> and <a href="/privacy-policy" class="text-primary-500 text-xs">Privacy Policy</a> -->
                                        </label>
                                    </div>
                                </checkbox-input>
                                <span class="text-[10px] text-error" v-if="errors?.agree">{{ errors.agree[0] || null }}</span>
                            </div>
                        </div>
                        <div class="mt-12 text-center">
                            <button-component
                                size="default"
                                color="primary"
                                @click="submit"
                            >
                                <template #content>
                                    <template v-if="!loading">
                                        Sign up
                                    </template>
                                    <template v-else>
                                        <ion-spinner></ion-spinner>
                                    </template>
                                </template>
                            </button-component>
                        </div>
                    </form>
                    <div class="text-center mt-8">
                        <p class="text-sm text-gray-400">
                            Already have an account?
                            <a  @click="router.push('login')" class="cursor-pointer font-normal text-primary-500">Login now</a>
                        </p>
                    </div>
                </div>
            </div>
            <bottom-navigation></bottom-navigation>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonSpinner 
} from '@ionic/vue';
import { useRouter } from 'vue-router';

import TopNavigation from "@/partials/TopNavigation.vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import MobileNumber from '@/components/inputs/MobileNumber.vue';
// import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue';
// import GenderSelection from '@/components/inputs/GenderSelection.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import { ref } from '@vue/reactivity';
import { useApiStore } from "@/store/api";
import useResponseHandler from '@/composables/useResponseHandler';
import useLocalStorage from "@/composables/useLocalStorage.js";
// import useAuth from "@/composables/useAuth";
import SelectMenu from "@/components/inputs/SelectMenu.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useUserStore } from "@/store/user";
import { computed, onMounted } from 'vue';
import { DateTime } from "luxon";

export default  {
    name: 'LoginPage',

    components: {
        IonPage, 
        IonContent,
        IonSpinner,
        ButtonComponent,
        TextInput,
        PasswordInput,
        TopNavigation,
        BottomNavigation,
        MobileNumber,
        // BirthdayPicker,
        // GenderSelection,
        CheckboxInput,
        SelectMenu,
        DatePicker
    },

    setup() {
        // const { setToken, fetchUserData } = useAuth();
        const apiStore = useApiStore();
        const { openResponseModal } = useResponseHandler();
        const router = useRouter();
        const isOpen = ref(false);
        const { localStorage } = useLocalStorage();
        const store = useUserStore();
        const errors = ref(null);
        const form = ref({
            email: null,
            firstName: null,
            lastName: null,
            mobileNumber: null,
            gender: null,
            birthdate: null,
            password: null,
            confirm_password: null,
            checked_in: false,
        });
        const limitMaxDate = computed(() => DateTime.now().toFormat('y-MM-dd'));
        

        const loading = ref(false);

        const genderOptions = [
            { id: '1', label: 'Male', value: 'Male'},
            { id: '2', label: 'Female', value: 'Female'},
            { id: '3', label: 'Prefer not to say', value: 'Prefer not to say'}
        ]


        const submit = () => {
            loading.value = true
            errors.value = null;
            const params = {
                email: form.value.email,
                first_name: form.value.firstName,
                last_name: form.value.lastName,
                mobile_number: form.value.mobileNumber,
                gender: form.value.gender,
                birthdate: form.value.birthdate,
                password: form.value.password,
                confirm_password: form.value.confirm_password,
                agree: form.value.agree,
                loyalty_points: 100,
            }

            window.axios
            .post(apiStore.route("register"), params)
            .then((result) => {
                loading.value = false;
                localStorage.value.set("registerUser", result.data.user);

                // setToken(
                //     "Bearer " + result.data.access_token,
                //     result.data.user
                // );

                // fetchUserData();
                store.setUserEmail(result.data.user.email);
                store.setUserMobile(result.data.user.mobile_number);
                router.replace("/register-verification");

                // openResponseModal({ title: "Success", description: result.data.message, buttonText: "Okay", buttonLink: "/login" });
            })
            .catch((error) => {
                loading.value = false;
                errors.value = error.response.data.errors;
                openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })

        }

        const goToLogin = () => {
            router.push('/login');
        }

        onMounted(() => {
            localStorage.value.delete('previousRoute');
        });

        return {
            form,
            store,
            router,
            isOpen,
            loading,
            submit,
            goToLogin,
            genderOptions,
            errors,
            limitMaxDate
        };
    }
}
</script>
