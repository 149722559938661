import { ref, computed } from "vue";
import { useApiStore } from '@/store/api';
import { useCartStore } from '@/store/cart';
import useCart from "./useCart";

export default function useInvoice()
{
    const api = useApiStore();
    const cart = useCartStore();

    const { cartFetchParams } = useCart();

    const invoiceLoader = ref(false);
    const fetchInvoicesUrl = computed(() => api.route('fetch-invoices'));
    const fetchInvoiceCountsUrl = computed(() => api.route('fetch-invoices-count'));

    const fetchInvoices = (params = {}) => {
        const url = fetchInvoicesUrl.value;
        if(!invoiceLoader.value && typeof url === 'string') {
            invoiceLoader.value = true;
            return window.axios
                .get(url, { params })
                .then(({ data }) => {
                    invoiceLoader.value = false;
                    return data || [];
                })
                .catch(() => {
                    invoiceLoader.value = false;
                    return [];
                });
        }
        else {
            return Promise.resolve([]);
        }
    };

    const fetchInvoicesCount = () => {
        const url = fetchInvoiceCountsUrl.value;
        if(!invoiceLoader.value && typeof url === 'string') {
            invoiceLoader.value = true;
            return window.axios
                .get(url)
                .then(({ data }) => {
                    invoiceLoader.value = false;
                    return data || {};
                })
                .catch(() => {
                    invoiceLoader.value = false;
                    return {};
                });
        }
        else {
            return Promise.resolve({});
        }
    };

    const showInvoice = (url) => {
        if(!invoiceLoader.value && typeof url === 'string') {
            invoiceLoader.value = true;
            return window.axios
                .get(url)
                .then(({ data }) => {
                    invoiceLoader.value = false;
                    return data;
                })
                .catch(() => {
                    invoiceLoader.value = false;
                    return {};
                });
        }
        else {
            return Promise.resolve({});
        }
    };

    const buyAgain = (url) => {
        if(!invoiceLoader.value && typeof url === 'string') {
            invoiceLoader.value = true;
            return window.axios
                .post(url, cartFetchParams.value)
                .then(({ data }) => {
                    invoiceLoader.value = false;
                    cart.data = data.cart;
                    return data.message;
                })
                .catch(() => {
                    invoiceLoader.value = false;
                });
        }
        else {
            return Promise.resolve();
        }
    };

    return {
        invoiceLoader,
        fetchInvoicesUrl,
        fetchInvoiceCountsUrl,
        fetchInvoices,
        fetchInvoicesCount,
        showInvoice,
        buyAgain
    }
}