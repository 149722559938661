<template>

    <profile-layout :title="'Fun Han Mart Loyalty'">
        <p class="text-2xl font-semibold leading-9 mb-6 text-neutral-600">Fun Han Mart Loyalty</p>
        <div class="flex items-center mb-6">
            <img class="mr-1" src="/assets/icon/p.svg" alt="">
            <p class="text-xl text-neutral-600 font-normal m-0">Your Points: {{ totalPoints }} points</p>
        </div>
        <div v-if="barcode" class="mb-6">
            <img class="w-[321px]" :src="barcode" alt="">
        </div>
        <div class="card bg-primary-500 p-4 rounded-lg w-full md:w-[454px] mb-[29px]">
            <div class="flex mb-[18px]">
                <img class="w-[58px] h-[58px] object-cover mr-[18px] rounded-full" :src="tierLevel.icon" alt="">
                <p class="text-yellow-500 font-bold m-0">{{ tierLevel.tier }}</p>
            </div>
            <div class="mb-2">
                <ion-progress-bar :value="progress" color="light"></ion-progress-bar>
                <div class="flex text-white justify-between">
                    <p class="m-0 font-normal text-[10px]">P{{ earnAmount }}</p>
                    <p class="m-0 font-normal text-[10px]">P40,000</p>
                </div>
            </div>
            <div class="flex items-center space-x-1">
                <template v-if="currentLevel != 3">
                    <img src="/assets/icon/Lock.svg" alt="">
                    <p class="text-xs text-white m-0">Unlock diamond tier</p>
                </template>
            </div>
        </div>
        <div>
            <div class="flex space-x-3 items-center mb-6">
                <img src="/assets/icon/Discount.svg" alt="">
                <p class="font-normal text-xl m-0">Benefits</p>
            </div>
            <div class="flex flex-col space-y-6">
                <div class="pb-6 border-b">
                    <div class="flex space-x-2 mb-4">
                        <img src="/assets/icon/silver.svg" alt="">
                        <p class="font-normal m-0">Silver Tier</p>
                    </div>
                    <p class="pl-6 text-sm text-neutral-600">Collect 0.50% equivalent of points from your order subtotal</p>
                </div>
                <div class="pb-6 border-b">
                    <div class="flex space-x-2 mb-4">
                        <img src="/assets/icon/gold.svg" alt="">
                        <p class="font-normal m-0">Gold Tier</p>
                    </div>
                    <p class="pl-6 text-sm text-neutral-600">Collect 2% equivalent of points from your order subtotal</p>
                </div>
                <div class="pb-6 border-b">
                    <div class="flex space-x-2 mb-4">
                        <img src="/assets/icon/diamond.svg" alt="">
                        <p class="font-normal m-0">Diamond Tier</p>
                    </div>
                    <p class="pl-6 text-sm text-neutral-600">Collect 5% equivalent of points from your order subtotal</p>
                </div>
            </div>
        </div>
    </profile-layout>
</template>

<script type="text/javascript">
import {
    IonProgressBar
} from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useApiStore } from "@/store/api";
import useResponseHandler from '@/composables/useResponseHandler';
import ProfileLayout from "./ProfileLayout.vue";
export default defineComponent({
    name: 'LoyaltyPage',
    components: {
        IonProgressBar,
        ProfileLayout
    },
    setup() {
        const router = useRouter();
        const apiStore = useApiStore();
        const progress = ref(null);
        const currentLevel = ref(null);
        const nextLevel = ref(null);
        const earnAmount = ref(null);
        const totalPoints = ref(null);
        const barcode = ref(null);
        const { openResponseModal } = useResponseHandler();

        const fetchUser = async () => {
            window.axios
            .get(
                apiStore.route('fetch-customer-tier'))
            .then((result) => {
                earnAmount.value = result.data.user[0].totalAmount;
                currentLevel.value = result.data.user[0].tierLevel;
                totalPoints.value = result.data.user[0].loyalty_points;
                progress.value = result.data.user[0].progressBar;
                nextLevel.value = result.data.user[0].nextLevel;
                barcode.value = result.data.user[0].loyalty_barcode_path;

            }).catch((error) => {
                 openResponseModal({ title: "Warning", description: error.response.data.message, buttonText: "Okay" });
            })
        }

        const tierLevel = computed(() => {
            let icon, tier;

            if (currentLevel.value === 1) {
                icon = "/assets/icon/tier-level/silver.jpg";
                tier = "Silver";
            } else if (currentLevel.value === 2) {
                icon = "/assets/icon/tier-level/gold.jpg";
                tier = "Gold";
            } else if (currentLevel.value === 3) {
                icon = "/assets/icon/tier-level/diamond.jpg";
                tier = "Diamond";
            }

            return { icon, tier };
        });

        onMounted(() => {
            fetchUser();
        });

        return {
            router,
            progress,
            earnAmount,
            currentLevel,
            nextLevel,
            tierLevel,
            totalPoints,
            barcode
        }
    },
});
</script>
